import React, {useCallback, useContext, useMemo} from 'react';
import {observer} from 'mobx-react';
import Search from 'js/containers/Pages/Search/Search';
import Profile from 'js/containers/Pages/Profile/Profile';
import {Switch, Route, Redirect, useLocation} from 'react-router';
import {CommonContext} from 'js/context/CommonContext/CommonContext';
import Page404Template from 'js/containers/Pages/404/Page404Template';

import useTemplateRouter from '../TemplateRouter/useTemplateRouter';
import useSinglePostRouter from '../PostTypeRouter/SinglePostRouter/useSinglePostRouter';
import useTaxonomyRouter from '../TaxonomyRouter/useTaxonomyRouter';
import SearchStore from 'js/store/SearchStore';

const Router = () => {
	const {searchQuery} = SearchStore || {};
	const { pathname } = useLocation();
	const post = useSinglePostRouter();
	const taxonomy = useTaxonomyRouter();
	const template = useTemplateRouter();
	const {isCustomerLogged} = useContext(CommonContext);
	const staticLoggedOnlyPagesSlug = useMemo(() => ['/search', '/profile', '/homepage'], []);

	const setRedirects = useCallback(() => {
		if (isCustomerLogged === false && staticLoggedOnlyPagesSlug.includes(pathname)) {
			return <Redirect to={'/'} />;
		}
		if (isCustomerLogged && pathname === '/search' && !searchQuery?.length) {
			return <Redirect to={'/homepage'} />;
		}
		if (isCustomerLogged && pathname === '/') {
			return <Redirect to={'/homepage'} />;
		}
	}, [isCustomerLogged, pathname, searchQuery, staticLoggedOnlyPagesSlug]);

	return (
		<Switch>
			<Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
			{setRedirects()}
			<Route exact key="searchRoute" path='/search' render={() => <Search />} />
			<Route exact key="myProfileRoute" path='/profile' render={() => <Profile />} />
			{template}
			{taxonomy}
			{post}
			{!template && !taxonomy && !post && <Route exact key={'404Route'} component={Page404Template} />}
		</Switch>
	);
};
export default observer(Router);
