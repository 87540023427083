const rangeValidateValue = (e, type: 'min' | 'max', rangeValue) => {
	const re = /^[0-9\b]+$/;
	const {value} = e?.target || {};

	const condition = value === '' || re.test(value) && value !== '0';
	if (!condition) {
		return null;
	}
	const rangeValueObj = {...rangeValue};
	rangeValueObj[type] = value;
	return rangeValueObj;
};

export default rangeValidateValue;
