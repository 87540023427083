import validationRules from 'js/helpers/validationRules';

const setDefaultValue = (type, initialValue) => {
	switch (type) {
		case 'checkbox' :
		case 'radio' : return initialValue;
		case 'checkBoxesList' : return [];
		default : return '';
	}
};

export const validateFormElement = (validation, value, name, data, checkBoxesData) => {

	if (validation && validation?.rules) {
		const { rules } = validation || {};

		const results = rules.map((ruleName) => {
			const rule = validationRules[ruleName.type];

			if (ruleName.type === 'confirm') {
				return rule(value, data[ruleName?.confirmFieldName]?.value || '');
			}

			if (ruleName.type === 'atLeastOneChecked') {
				return checkBoxesData && checkBoxesData?.length ? rule(value) : false;
			}

			return rule(value);
		});

		const errorIndex = results.findIndex(item => item === false);
		if (errorIndex === -1) {
			return {
				isValid: true,
				errorMessage: '',
				name,
				value
			};
		}
		return {
			isValid: false,
			errorMessage: validation?.rules[errorIndex].message,
			name,
			value
		};
	}
	return {
		isValid: true,
		errorMessage: '',
		name,
		value
	};
};

// Create initial data to avoid an empty state error
export const getInitialFormData = (children) => {
	const initFormData = {};

	children.forEach((child) => {
		const { name, type, validation, checked, defaultValue, checkBoxesData } = child?.props || {};
		if (name) {
			const value = setDefaultValue(type, defaultValue || checked);
			const { isValid } = validateFormElement(validation, value, name, initFormData, checkBoxesData);
			initFormData[name] = {
				value,
				isValid,
				errorMessage: ''
			};
		}
	});

	return initFormData;
};

export const getItemValueByType = (type, e) => {
	switch (type) {
		case 'checkbox' :
		case 'radio' :
			return e?.target?.checked;
		case 'ReCaptcha' :
		case 'checkBoxesList' :
			return e;
		default : return  e?.target?.value;
	}
};
