import React from 'react';
import Image from 'js/components/Common/Image/Image';
import Heading from 'js/components/Common/Heading/Heading';
import DefaultUserLogo from 'images/default-user-avatar.jpg';

import classes from '../GetToKnowItem/GetToKnowItem.module.pcss';

const GetToKnowItem = ({ item }) => {
	const { mediaDetails, mediaItemUrl } = item?.featuredImage || {};
	const { title } = item;
	const { position } = item?.Team || {};
	const trimName = (name: string): string => name.substring(0, 25);
	return (
		<figure className={classes.GetToKnowItem}>
			{item.featuredImage ?
				<Image
					mediaItemUrl={mediaItemUrl}
					mediaDetails={mediaDetails}
					alt={title}
					size="240x240" /> :
				<img src={DefaultUserLogo} alt={title} />}
			<div className={classes.GetToKnowItemInfo}>
				<Heading text={trimName(title)} type="h6" />
				<span className={classes.position}>{position}</span>
			</div>
		</figure>
	);
};

export default GetToKnowItem;
