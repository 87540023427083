import React, {useRef} from 'react';
import Intro from 'js/components/Common/Intro/Intro';
import TopBanner from 'js/components/Common/TopBanner/TopBanner';
import FilterCourses from 'js/components/Filter/Entities/Courses/FilterCourses';
import Wrapper from 'js/components/Templates/Courses/CoursesList/Wrapper/Wrapper';
import Accreditation from 'js/components/Templates/Courses/Accreditation/AccreditationBlock/Accreditation';
import CoursesListContextProvider from 'js/components/Templates/Courses/CoursesList/context/CoursesListContext';

const CoursesTemplate = ({pageId}) => {
	const nodeRef = useRef(null);
	return (
		<main className="templateCourses" ref={nodeRef}>
			<TopBanner
				pageType={'CoursesTemplate'}
				pageId={pageId} />
			<CoursesListContextProvider>
				<FilterCourses/>
				<Wrapper/>
			</CoursesListContextProvider>
			<Accreditation pageId={pageId} />
			<Intro
				pageType={'CoursesTemplate'}
				pageId={pageId} />
		</main>
	);
};
export default CoursesTemplate;

