import React, { useState } from 'react';
import parse from 'html-react-parser';
import Heading from 'js/components/Common/Heading/Heading';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Container from 'js/components/Common/Container/Container';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';
import ContentWrapper from 'js/components/Common/ContentWrapper/ContentWrapper';

import classes from './SimpleContent.module.pcss';
import policyContentBlockFetchData from './fetchData';

const SimpleContent = ({ pageId }) => {
	const [title, setTitle] = useState<string>('');
	const [content, setContent] = useState<string>('');

	const { isLoading, error } = useFetchData({
	    query: policyContentBlockFetchData(pageId),
	    condition: !!pageId,
	    updateData: (data) => {
			setTitle(data?.page?.title);
			setContent(data?.page?.content);
	    }
	});

	return (
		<DataValidator data={!!content && !isLoading && !error}>
			<article className={classes.SimpleContent}>
				<Container>
					<Heading text={title} type="h1" />
					<ContentWrapper>
						{parse(content || '', { trim: true })}
					</ContentWrapper>
				</Container>
			</article>
		</DataValidator>
	);
};

export default SimpleContent;

