export default pageId => `
	{
      podcastsSingle(id: "${pageId}", idType: DATABASE_ID) {
          title
          link
          SeoMetadata {
            metaTitle
              metaDescription
            }
    }
  }
`;
