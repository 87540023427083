import React from 'react';

import classes from './Attributes.module.pcss';
import {AttributesType} from '../../ILTCoursesBlock/ILTCoursesDataInterface';

export interface Props {
    data: AttributesType
}

const Attributes = ({data}: Props) => {
    const {
        duration,
        host,
        hands,
        fullyCertified,
        onlineTraining,
        registrationIsRequired,
        showDuration,
        showCertified,
        showHands,
        showHost,
        showOnline,
        showRegistration
    } = data || {};
    const setAttribute = (type: 'host' | 'duration' | 'hands' | 'certified' | 'online' | 'registration'
                          , field, icon:string, showField:boolean) => {
        if (!field || !showField) {
            return '';
        }
        return (
            <div className={classes.Attribute} datatype={type}>
                {field}
            </div>
        );
    };
    if (!(showHost || showDuration || showRegistration || showCertified || showOnline || showHands)) {
        return <></>;
    }
    return (
        <div className={classes.Attributes}>
            {setAttribute('host', host, '', showHost)}
            {setAttribute('duration', duration, '', showDuration)}
            {setAttribute('registration', registrationIsRequired, '', showRegistration)}
            {setAttribute('certified', fullyCertified, '', showCertified)}
            {setAttribute('online', onlineTraining, '', showOnline)}
            {setAttribute('hands', hands, '', showHands)}
        </div>
    );
};

export default Attributes;
