import imageFragment from 'js/graphqlFragments/ImageFragment';
import thumbnailFragment from 'js/graphqlFragments/ThumbnailFragment';
import {TopBannerPageId, TopBannerPageType} from 'js/components/Common/TopBanner/TopBannerDataInterface';

export const entityFetchData = (pageId, entityId:TopBannerPageId) => {
	switch (entityId) {
		case 'Courses' : return `
		{
			page(id: "${pageId}", idType: DATABASE_ID) {
				CoursesTemplate {
					bannerTagline
					selectEntityBanner {
						... on CoursesSingle {
							id
							uri
							title
							Courses {
								coursePrice
						  	}
							...ThumbnailFragment
						}
					}
				}	
			}
			themeGeneralSettings {
			  Options {
				buyCourseButton
				startCourseButton
			  }
			}
		}
		${thumbnailFragment('CoursesSingle')}
		`;
		case 'Meetings' : return `
			{
				page(id: "${pageId}", idType: DATABASE_ID) {
					EventsTemplate {
						bannerTagline
						selectEntityBanner {
							... on MeetingsSingle {
								id
								title
								meetingID
								meetingDateID
								databaseId
								userRegisteredHash
								Meetings {
								  eventType
								  startDate
								  eventLink
								}
								...ThumbnailFragment
							}
						}
				}	
			}
			themeGeneralSettings {
			  Options {
				joinEventButton
				registerEventButton
				viewEventButton
			  }
			}
		}
		${thumbnailFragment('MeetingsSingle')}
		`;
		default : return '';
	}
};

export const customFetchData = (pageId, pageType:TopBannerPageType) => `
		{
		  page(id: "${pageId}", idType: DATABASE_ID) {
			${pageType} {
		   	bannerTopic
			bannerTagline
			bannerButtonText
			bannerImage {
				...ImageFragment
			}
			bannerButtonLink
			buttonActionType           
			}
		  }
		}
		${imageFragment()} 
    `;

export const getBannerType = (pageId, pageType:TopBannerPageType) => `
	{
	  page(id: "${pageId}", idType: DATABASE_ID) {
		${pageType} {
		  bannerType
		}
	  }
	}
`;
