const fetchData = pageId => `
{
    page(id: "${pageId}", idType: DATABASE_ID) {
      PreLoginPage {
        headingMissionStatement
        listMissionStatement {
            description
            title
        }
      }
    }
  }
`;
export default fetchData;
