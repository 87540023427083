import React from 'react';

import classes from './EventListItems.module.pcss';
import {EventsButtonsType, WrapperDataInterface, EventsListTypes} from '../Wrapper/WrapperDataInterface';
import EventListItem from '../EventListItem/EventListItem';

export interface Props {
    data: WrapperDataInterface[],
    buttons: EventsButtonsType,
    sectionType: EventsListTypes
}

const EventsListItems = ({data, buttons, sectionType}: Props) => (
    <div className={classes.eventsListItems}>
        {data?.map(item => (
            <EventListItem
                key={item?.databaseId}
                item={item}
                buttons={buttons}
                sectionType={sectionType}
            />
        ))}
    </div>
);

export default EventsListItems;
