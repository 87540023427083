import React, {useState } from 'react';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Container from 'js/components/Common/Container/Container';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';
import { ImageBlockDataInterface } from 'js/components/Common/Image/ImageBlockDataInterface';

import fetchData from './fetchData';
import classes from './TopBlock.module.pcss';
import AboutUsTopInfo from '../TopInfo/TopInfo';
import AboutUsTopImage from '../TopImage/TopImage';

type aboutUsTopInfoType = {
	firstParagraph: string,
	secondParagraph: string,
	thirdParagraph: string
}

const TopBlock = ({ pageId }) => {
	const [aboutUsTopTitle, setAboutUsTopTitle] = useState<string>('');
	const [aboutUsTopInfo, setAboutUsTopInfo] = useState<aboutUsTopInfoType>({
		firstParagraph: '',
		secondParagraph: '',
		thirdParagraph: ''
	});
	const [aboutUsTopImage, setAboutUsTopImage] = useState<ImageBlockDataInterface>({
		altText: '',
		mediaItemUrl: '',
		mediaDetails: {
			sizes: {
				name: '',
				sourceUrl: ''
			}['']
		}
	});

	const { isLoading, error } = useFetchData({
		query: `${fetchData(pageId)}`,
		condition: !!pageId,
		updateData: (data) => {
			setAboutUsTopTitle(data.page.AboutUs.headingTopBlock);
			setAboutUsTopInfo(data.page.AboutUs.infoTopBlock);
			setAboutUsTopImage(data.page.AboutUs.imageTopBlock);
		}
	});
	return (
		<DataValidator data={!isLoading && !error && !!aboutUsTopInfo}>
			<section className={classes.TopBlock}>
				<Container additionalClass={classes.TopBlockWrap}>
					<AboutUsTopInfo title={aboutUsTopTitle} info={aboutUsTopInfo} />
					<AboutUsTopImage imageData={aboutUsTopImage} />
				</Container>
			</section>
		</DataValidator>

	);
};

export default TopBlock;
