import React from 'react';
import Image from 'js/components/Common/Image/Image';

import classes from './PromoBoxMedia.module.pcss';

const StaticImage = ({ item }) => {
	const { altText, mediaDetails, mediaItemUrl } = item?.staticImage || '';
	return (
		<figure className={classes.staticImage}>
			<Image
				alt={altText || ''}
				mediaDetails={mediaDetails}
				size="free"
				mediaItemUrl={mediaItemUrl} />
		</figure>
	);
};

export default StaticImage;
