import imageFragment from 'js/graphqlFragments/ImageFragment';

const fetchData = (pageId: number): string => `
    {
    page(id: "${pageId}", idType: DATABASE_ID) {
      TrainingTemplate {
        titleOffer
        descriptionOffer
        offerings {
          courseTitle
          productsTab {
            inThisTraining {
              item
            }
            aboutThisTraining
            productName
            videoButton {
              buttonLabel
              videoLink
              videoPreview {
                ...ImageFragmentVideoPreview
              }
            }
          }
        }
      }
    }
  }
  ${imageFragment('VideoPreview')} 
`;
export default fetchData;
