import React, { useState } from 'react';
import Heading from 'js/components/Common/Heading/Heading';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Container from 'js/components/Common/Container/Container';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import fetchData from './fetchData';
import classes from './Accreditation.module.pcss';
import AccreditationItems from '../AccreditationItems/AccreditationItems';
import { AccreditationDataInterface } from './AccreditationDataInterface';

const Accreditation = ({ pageId }) => {
	const [accreditationTitle, setAccreditationTitle] = useState<string>('');
	const [companies, setCompanies] = useState<AccreditationDataInterface[]>([]);

	const {isLoading, error} = useFetchData({
		query: `${fetchData(pageId)}`,
		condition: !!pageId,
		updateData: (data) => {
			setAccreditationTitle(data.page.CoursesTemplate.headingAccreditation);
			setCompanies(data.page.CoursesTemplate.companiesAccreditation);
		}
	});

	return (
		<DataValidator data={!!companies && !isLoading && !error}>
			<section className={classes.Accreditation}>
				<Container additionalClass={classes.AccreditationContainer}>
					<Heading text={accreditationTitle} />
					<AccreditationItems items={companies} />
				</Container>
			</section>
		</DataValidator>
	);
};

export default Accreditation;
