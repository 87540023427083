import React, {useCallback, useMemo, useState} from 'react';
import 'swiper/css/swiper.css';
import Swiper from 'react-id-swiper';
import useAnchor from 'js/hooks/useAnchor/useAnchor';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';
import CarouselArrows from 'js/components/Common/Carousel/CarouselArrows/CarouselArrows';
import CarouselBullets from 'js/components/Common/Carousel/CarouselBullets/CarouselBullets';

const CarouselSlider = (
	{
		items,
		children,
		paramsProp = {},
		isColumnOfThree = false,
		useArrows = true,
		useBullets = false,
		parentID = ''
	}
) => {
	const [carousel, setCarousel] = useState(null);
	const anchor = useAnchor(parentID, 0, -100);
	const [activeButtons, setActiveButtons] = useState<{
		prev: boolean,
		next: boolean
	}>({ prev: false, next: true });

	const groupBy = useCallback((array) => {
		if (array?.length === 0) {
			return [];
		}
		let i = 0;
		return array.map((item, index) => {
			if (3 % index === 0 || index === 0) {
				const arr = [];
				const first = array[i];
				const second = array[i + 1];
				const third = array[i + 2];
				first ? arr.push(first) : false;
				second ? arr.push(second) : false;
				third ? arr.push(third) : false;
				i = i + 3;
				return arr?.length > 0 ? arr : null;
			} else {
				return null;
			}
		});
	}, []);

	const params = useMemo(() => (
		{
			on: {
				slideChangeTransitionStart() {
					if (this.activeIndex === 0) {
						setActiveButtons({ prev: false, next: true });
					} else if (this.isEnd) {
						setActiveButtons({ prev: true, next: false });
					} else {
						setActiveButtons({ prev: true, next: true });
					}
					this.updateAutoHeight();
					parentID?.length ? anchor() : '';
				}
			},
			...paramsProp
		}
	), [anchor, paramsProp, parentID.length]);
	const itemsGroup = useMemo(() => groupBy(items).filter((item => item)), [groupBy, items]);
	if (isColumnOfThree) {
		return (
			<DataValidator data={items}>
				<>
					<Swiper getSwiper={setCarousel} {...params}>
						{itemsGroup?.map((item, index) =>
							<div className="swiper-slide" key={index}>
								{
									item?.map((subItem, subIndex) =>
										React.cloneElement(children, { item: subItem, key: subIndex }))
								}
							</div>
						)}
						{useBullets && <CarouselBullets />}
					</Swiper>
					{useArrows && <CarouselArrows slider={carousel} activeButtons={activeButtons} />}
				</>
			</DataValidator>
		);
	}
	return (
		<DataValidator data={items}>
			<>
				<Swiper getSwiper={setCarousel} {...params}>
					{items?.filter(item => item).map((item, index) =>
						<div className="swiper-slide" key={item?.index || item?.id}>
							{React.cloneElement(children, { item, index })}
						</div>
					)}
				</Swiper>
				{useArrows && <CarouselArrows slider={carousel} activeButtons={activeButtons} />}
			</>
		</DataValidator>
);
};

export default CarouselSlider;
