export const incrementFetch = (id, value) => `
   {
    search {
      incrementSearchCount(id: "${id}", title: "${value}" )
    }
  }
`;

export const subtitleFetch = `
{
    themeGeneralSettings {
      Options {
        searchFormSubtitle
      }
    }
  }
`;
