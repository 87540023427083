import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {CommonContext} from 'js/context/CommonContext/CommonContext';

export const CoursesListContext = React.createContext({
        showLoader: true,
        setShowLoader: value => value,
        emptyContent: null,
        setResult: (type, value) => value,
        getQuery: value => value,
        setQuery: (type, value) => value,
        resetFilter: false,
        setResetFilter: value => value,
        popUpTrigger: false,
        setPopUpTrigger: value => value,
        popUpData: {
            title: '',
            content: {
                first: '',
                second: ''
            }
        },
        setPopUpData: value => value
    }
);
export type  CoursesListQueryTypes = 'suitableFor' | 'skills' | 'duration' | 'price';
export type  CoursesPriceCountsTypes = 'priceFree' | 'price199' | 'price100199' | 'price200299' | 'price300';
export type  CoursesListTypes = 'isEssential' | 'isPopular' | 'isPremium';
export type PriceCountsType = {
    free: string,
    price199: string,
    price100199: string,
    price200299: string,
    price300: string
}
export type PriceCountType = {
    priceCountType: CoursesPriceCountsTypes,
    value: null
}

const CoursesListContextProvider = (props) => {
    const [showLoader, setShowLoader] = useState<boolean>(true);
    const [resetFilter, setResetFilter] = useState<boolean>(false);
    const [popUpTrigger, setPopUpTrigger] = useState<boolean>(false);
    const [popUpData, setPopUpData] = useState<{
        title: string,
        content: {
            first: string,
            second: string
        }
    }>({
        title: '',
        content: {
            first: '',
            second: ''
        }
    });
    const [suitableForQuery, setSuitableForQuery] = useState<string>('');
    const [skillsQuery, setSkillsQuery] = useState<string>('');
    const [durationQuery, setDurationQuery] = useState<string>('');
    const [priceQuery, setPriceQuery] = useState<string>('');
    const [emptyContent, setEmptyContent] = useState<boolean | null>(null);
    const [coursesEssentialResults, setCoursesEssentialResults] = useState<boolean | null>(null);
    const [coursesPopularResults, setCoursesPopularResults] = useState<boolean | null>(null);
    const [coursesPremiumResults, setCoursesPremiumResults] = useState<boolean | null>(null);
    const {isCustomerLogged} = useContext(CommonContext);

    const getQueryHandler = useCallback((type:CoursesListQueryTypes)  => {
        switch (type) {
            case 'suitableFor': return suitableForQuery;
            case 'skills': return skillsQuery;
            case 'duration': return durationQuery;
            case 'price': return priceQuery;
            default : return null;
        }
    }, [durationQuery, priceQuery, skillsQuery, suitableForQuery]);

    const setQueryHandler = useCallback((type:CoursesListQueryTypes, value)  => {
        switch (type) {
            case 'suitableFor': return setSuitableForQuery(value);
            case 'skills': return setSkillsQuery(value);
            case 'duration': return setDurationQuery(value);
            case 'price': return setPriceQuery(value);
            default : return null;
        }
    }, []);

    const setResultHandler = useCallback((type:CoursesListTypes, value)  => {
        switch (type) {
            case 'isEssential': return setCoursesEssentialResults(value);
            case 'isPopular': return setCoursesPopularResults(value);
            case 'isPremium': return setCoursesPremiumResults(value);
            default : return null;
        }
    }, []);

    const isEmpty = useMemo(() => {
        const freeCoursesLoaded = coursesEssentialResults !== null && coursesPopularResults !== null;
        const allCoursesLoaded = freeCoursesLoaded && coursesPremiumResults !== null;
        const areFreeCoursesEmpty = coursesEssentialResults === false && coursesPopularResults === false;
        const areAllCoursesEmpty = areFreeCoursesEmpty && coursesPremiumResults === false;

        if (isCustomerLogged === false) {
            return freeCoursesLoaded ? areFreeCoursesEmpty : false;
        } else if (isCustomerLogged) {
            return allCoursesLoaded ? areAllCoursesEmpty : false;
        }

       return false;
    }, [coursesEssentialResults, coursesPopularResults, coursesPremiumResults, isCustomerLogged]);

    useEffect(() => {
        setEmptyContent(isEmpty);
    }, [isEmpty]);

    return (
        <CoursesListContext.Provider value={
            {
                showLoader,
                setShowLoader,
                emptyContent,
                setResult: setResultHandler,
                getQuery: getQueryHandler,
                setQuery: setQueryHandler,
                resetFilter,
                setResetFilter,
                popUpTrigger,
                setPopUpTrigger,
                popUpData,
                setPopUpData
            }
        }>
            {props.children}
        </CoursesListContext.Provider>
    );
};

export default CoursesListContextProvider;


