import React, { useState } from 'react';
import Swiper from 'react-id-swiper';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';
import { testimonialsParams } from 'js/components/Common/Carousel/CarouselParams/CarouselParams';

import classes from './TestimonialsItems.module.pcss';
import TestimonialsItem from '../TestimonialsItem/TestimoniaslItem';

const TestimonialsItems = ({ items }) => {
	const [testimonialsSlider, setTestimonialsSlider] = useState(null);

	return (
		<DataValidator data={items}>
			<Swiper
				containerClass={`swiper-container ${items?.length === 1 ? classes.disabled : ''}`}
				getSwiper={setTestimonialsSlider}
				{...testimonialsParams}>
				{items?.map((item, index) => (
					<div
						className="swiper-slide"
						key={index}>
						<TestimonialsItem item={item} testimonialsSlider={testimonialsSlider} />
					</div>
				))}
			</Swiper>
		</DataValidator>
	);
};

export default TestimonialsItems;
