import React, {useCallback, useEffect, useState} from 'react';
import useAnchor from 'js/hooks/useAnchor/useAnchor';
import Button from 'js/components/Common/Button/Button';

import classes from './ToTopArrow.module.pcss';

const ToTopArrow = () => {
    const anchor = useAnchor('root', 0, -100);
    const [active, setActive] = useState<boolean>(false);

    const scrollCallBack = useCallback(() => {
        const currentScrollTop: number = window.scrollY;
        if (currentScrollTop > 800) {
            return setActive(true);
        }
        return setActive(false);
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', scrollCallBack);
        return () => {
            window.removeEventListener('scroll', scrollCallBack);
        };
    }, [scrollCallBack]);

    return (
        <div className={`${classes.ToTopArrow} ${active ? classes.ToTopArrowActive : ''}`}>
            <Button type={'button'} handler={anchor} text={
                <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M8.70711 0.292892C8.31658 -0.0976314 7.68342 -0.0976315 7.29289 0.292892L0.928933 6.65685C0.538408 7.04738 0.538408 7.68054 0.928933 8.07107C1.31946 8.46159 1.95262 8.46159 2.34315 8.07107L8 2.41421L13.6569 8.07107C14.0474 8.46159 14.6805 8.46159 15.0711 8.07107C15.4616 7.68054 15.4616 7.04738 15.0711 6.65685L8.70711 0.292892ZM9 19L9 1L7 1L7 19L9 19Z"
                        fill="#fff"/>
                </svg>
            }/>
        </div>
    );
};

export default ToTopArrow;
