import React, {MouseEventHandler, useCallback, useMemo, useState} from 'react';
import Image from 'js/components/Common/Image/Image';

import classes from './Thumbnail.module.pcss';
import isComingSoon from '../ThumbnailNotice/ThumbnailNotice';
import InfoButton from 'js/components/Common/InfoButton/InfoButton';
import Button from 'js/components/Common/ButtonNew/Button';

export interface Props {
	item: any,
	index?: number,
	type?: 'meeting_24' | 'meeting_48' | 'course' | '',
	handler?: MouseEventHandler<HTMLDivElement>
}

const Thumbnail = ({ item, index, type = '', handler }: Props) => {
	const [loveButtonState, setLoveButtonState] = useState<boolean>(false);
	const loveButtonClick = useCallback(() => {
		setLoveButtonState(true);
	}, []);

	const label = useMemo((): boolean | string => {
		switch (type) {
			case 'meeting_24' :
				return isComingSoon(item, index, 24);
			case 'meeting_48' :
				return isComingSoon(item, index, 48);
			default:
				return '';
		}
	}, [index, item, type]);
	const {featuredImage} = item || {};
	return (
		<figure className={`${classes.Thumbnail} ${label}`}>
			<Image
				mediaDetails={featuredImage ? featuredImage?.mediaDetails : null}
				size="380x310"
				srcSet={featuredImage ? featuredImage?.srcSet : ''}
				mediaItemUrl={featuredImage ? featuredImage?.mediaItemUrl : ''}
				alt={featuredImage ? featuredImage?.altText : ''} />
			{ type === 'course' ?
				<div className={classes.LoveInfoWrap}>
					<Button
						type={loveButtonState ? 'LoveButtonActive' : 'LoveButtonInActive'}
						handler={loveButtonClick} />
					<InfoButton click={handler} />
				</div>
				: null
			}
		</figure>
	);
};

export default Thumbnail;
