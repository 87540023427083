import { ImageBlockDataInterface } from 'js/components/Common/Image/ImageBlockDataInterface';

const useCutBackground = (imageData: ImageBlockDataInterface, size: string = 'free') => {
	let { mediaItemUrl } = imageData || {};
	if (imageData?.mediaDetails) {
		const cut = imageData?.mediaDetails?.sizes?.filter(el => el.name === size)[0];
		mediaItemUrl = cut ? cut?.sourceUrl : mediaItemUrl;
	}

	return mediaItemUrl;
};

export default useCutBackground;
