import React, {useCallback, useContext, useEffect, useState} from 'react';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';
import {MyProfileContext} from 'js/context/MyProfileContext/MyProfileContext';
import CheckBoxesList from 'js/components/Common/FormComponents/Input/ChechBoxesList/CheckBoxesList';

import fetchData from './fetchData';
import classes from './Interests.module.pcss';

export type Product = {
    id: string,
    name: string,
    checked: boolean
}

interface Props {
    size?: 'MD' | 'SM',
    heading?: 'h4' | 'h5',
    prefix?: string
}

const Interests = ({size = 'MD', heading = 'h4', prefix}: Props) => {
    const {setInterests, interests} = useContext(MyProfileContext);
    const [productsList, setProductsList] = useState<Array<Product>>([]);
    const [productsSelected, setProductsSelected] = useState<Array<Product>>([]);
    const [productsFiltered, setProductsFiltered] = useState<Array<Product>>([]);
    const [productsSpinner, setProductsSpinner] = useState<boolean>(true);

    const cleanProductsForAPI = useCallback((products) => {
        if (!products?.length) {
            return '';
        }
        const filtered = products?.filter(item => item.checked)?.map(item => `${item?.name}_${item?.id}`);
        return filtered?.join(',');
    }, []);


    const { isLoading, error } = useFetchData({
        query: `${fetchData()}`,
        condition: true,
        updateData: (data) => {
            setProductsList(data?.getResponse?.getTags);
            setProductsSelected(data?.getResponse?.getTagsForCurrentUser);
        }
    });

    useEffect(() => {
        const products = productsList?.map(item => (
            {
                ...item,
                checked: productsSelected?.find(el => item?.id === el?.id) !== undefined
            }
        ));
        setInterests(cleanProductsForAPI(products));
        setProductsFiltered(products);
        setProductsSpinner(false);
    }, [cleanProductsForAPI, productsList, productsSelected, setInterests]);

    const checkDataHandler = useCallback((data) => {
        if (data?.length === 0) {
            setInterests('');
        }
        setInterests(data?.join(','));
    }, [setInterests]);

    return (
        <DataValidator data={!isLoading && !error }>
            <section className={classes.Interests}>
                <CheckBoxesList
                    size={size}
                    name={'interests'}
                    type={'checkBoxesList'}
                    checkBoxesData={productsFiltered}
                    onChange={checkDataHandler}
                    prefix={prefix}
                    heading={{
                        text: 'Your interests',
                        type: heading
                    }}
                    spinner={productsSpinner}
                    validation={{
                        errorMessage: interests?.length <= 0 ? 'Required Field' : ''
                    }}/>
            </section>
        </DataValidator>
    );
};

export default Interests;
