import React, {useCallback, useMemo, useState} from 'react';

type TimingType = {
	delay?: number,
	media?: number,
	text?: number
}

type PromoBoxContextType = {
	animationsTiming: TimingType,
	playingTrigger: boolean,
	setPlayingTrigger: Function
}

const PromoBoxContextDefaultValue: PromoBoxContextType = {
	animationsTiming: {},
	playingTrigger: true,
	setPlayingTrigger: value => value
};

export const PromoBoxContext = React.createContext(PromoBoxContextDefaultValue);

const PromoBoxContextProvider = (props) => {
	const [playingTrigger, setPlayingTrigger] = useState<boolean>(true);

	const playingTriggerHandler = useCallback((value: boolean): void => {
		setPlayingTrigger(value);
	}, []);

	const timing = useMemo((): TimingType => {
		const sliderAutoplayDelay = 5000;
		return {
			delay: sliderAutoplayDelay,
			media: sliderAutoplayDelay - 500,
			text: sliderAutoplayDelay - 1000
		};
	}, []);

	return (
		<PromoBoxContext.Provider value={{
			animationsTiming: timing,
			playingTrigger,
			setPlayingTrigger: playingTriggerHandler
		}}>
			{props.children}
		</PromoBoxContext.Provider>
	);
};

export default PromoBoxContextProvider;
