import React, {useContext} from 'react';
import Loader from 'js/components/Common/Loader/Loader';
import {CommonContext} from 'js/context/CommonContext/CommonContext';
import CoursePopUp from 'js/components/Common/CoursePopUp/CoursePopUp';
import NoDataSection from 'js/components/Common/NoDataSection/NoDataSection';

import CoursesListBlock from '../CoursesListBlock/CoursesListBlock';
import {CoursesListContext} from '../context/CoursesListContext';

const Wrapper = () => {
    const {isCustomerLogged} = useContext(CommonContext);
    const {showLoader, emptyContent} = useContext(CoursesListContext);
    const {
        popUpTrigger,
        setPopUpTrigger,
        popUpData
    } = useContext(CoursesListContext);

    return (
        <>
            {showLoader ? <Loader/> : null}
            <div id="Courses">
                <CoursesListBlock type="isEssential" shouldUseFilter buttonAction={isCustomerLogged ? 'start' : 'buy'} />
                <CoursesListBlock type="isPopular" shouldUseFilter buttonAction={isCustomerLogged ? 'start' : 'buy'} />
                {(
                    emptyContent !== null && emptyContent && !showLoader ?
                        <CoursesListBlock
                            type="isEssential"
                            buttonAction={isCustomerLogged ? 'start' : 'buy'}
                            shouldUseFilter={false} additionalMessage/> : null
                )}
                {isCustomerLogged ? <CoursesListBlock type="isPremium" shouldUseFilter buttonAction={'buy'} /> : null}
                <CoursePopUp type={'L'} popUpTrigger={popUpTrigger} setPopUpTrigger={setPopUpTrigger} popUpData={popUpData}/>
                {emptyContent === null && showLoader ? <Loader/> : null}
                {emptyContent === true && <NoDataSection
				            infoKeyProp={'noCoursesInfo'}
				            buttonTextKeyProp={'noCoursesButtonText'}
				            imageKeyProp={'noCoursesImage'}
				            buttonLinkKeyProp={'noCoursesButtonLink'}
		            />}
            </div>
        </>
    );
};

export default Wrapper;
