import React, {useCallback, useContext, useMemo} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import Button from 'js/components/Common/Button/Button';
import {CommonContext} from 'js/context/CommonContext/CommonContext';

import classes from './UserProfile.module.pcss';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';

interface Props {
    userData:any,
    logOutCallback: any,
    offsetTop: number
}

const UserProfile = ({userData, logOutCallback, offsetTop}: Props) => {
    const location = useLocation();
    const {oneLoginBaseURL} = useContext(CommonContext);

    const getNavLink = useCallback((link:string, label:string, external:boolean = false) => {
        if (external) {
            return (
                <li className={classes.UserProfileNavigationItem}>
                    <a
                        target={'_blank'}
                        href={link}
                        rel={'nofollow noopener noreferrer'}>{label}</a>
                </li>
            );
        }
        if (link === location.pathname) {
            return (
                <li className={`${classes.UserProfileNavigationItem} active`}>
                    <span className={`${classes.currentItemLink} emptyLink`}>{label}</span>
                </li>
            );
        }
        return (
            <li className={classes.UserProfileNavigationItem}>
                <NavLink exact={true} to={link}>{label}</NavLink>
            </li>
        );
    }, [location.pathname]);

    const userNameAbbreviation = useMemo(() => {
        const {firstName, lastName} = userData || {};
        const first = firstName?.substring(0, 1) || '';
        const last = lastName?.substring(0, 1) || '';
        return first + last;
    }, [userData]);

    return (
        <div className={classes.UserProfile}>
            <span className={classes.UserProfileAbbreviation}>{userNameAbbreviation}</span>
            <nav style={{marginTop: `${offsetTop}px`}} className={classes.UserProfileMenu}>
                <ul>
                    {getNavLink('/profile', 'My Profile')}
                    {getNavLink('/contact-us', 'Contact Us')}
                    {getNavLink(
                        `${oneLoginBaseURL}/myaccount`,
                        'OneLogin Account',
                        true
                    )}
                    <li className={classes.UserProfileNavigationItem}>
                        <Button type="button" buttonType={'ButtonUncolored'} text="Log out" handler={() => {
                            logOutCallback();
                            MixPanelWrapper.Reset().then((r) => {
                                console.log(r);
                            });
                        }}/>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default UserProfile;
