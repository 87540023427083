import {SubTopicDataType} from 'js/components/Taxonomies/SubTopic/SubTopic';
import {contentFetchData} from 'js/components/Taxonomies/SubTopic/fetchData';

class SubTopicService {
    sortTerms(items) {
        const filtered = items?.filter(item => item?.productLegacySubTopic?.length > 0);
        return filtered?.sort((a, b) => b?.productLegacySubTopic?.length - a?.productLegacySubTopic?.length);
    }
    fetchArticlesRecursive = async (productId:number, apiPath:string, page = 1) => {
        const response = await fetch(`${apiPath}/graphql`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                query: `${contentFetchData(productId, page)}`
            })
        });
        const responseData = await response.json();
        let count = responseData?.data?.productLegacy?.productLegacySubTopic?.length;
        if (count) {
            count = responseData?.data?.productLegacy?.productLegacySubTopic[0]?.total;
        }

        return {
            items: responseData?.data?.productLegacy?.productLegacySubTopic,
            maxPages: parseInt(count)
        };
    };

    separateArticleIntoColumns = (articles: Array<SubTopicDataType>) => {
        const column1 = [];
        const column2 = [];
        const articlesCount = articles?.length || 0;

        if (articlesCount <= 5) {
            return { column1: articles, column2: [] };
        }

        articles.forEach((article, index) => {
            if (index % 2 === 0) {
                column1.push(article);
            } else {
                column2.push(article);
            }
        });

        return { column1, column2 };
    };

    getButtonLabelByType(type: any) {
        switch (type) {
            case 'articles' : return 'Read More';
            case 'courses' : return 'Start Course';
            case 'videos' : return 'Watch Now';
            case 'manuals' : return 'Download';
            case 'presentations' : return 'Download';
            case 'meetings' : return 'View Event Page';
            default : return '';
        }
    }

}

export default new SubTopicService();
