import thumbnailFragment from 'js/graphqlFragments/ThumbnailFragment';
import imageFragment from 'js/graphqlFragments/ImageFragment';

export default queryAttributes => (
	`{
		meetingsArchive(where: {status: PUBLISH, ${queryAttributes}}, first: 1000) {
		  nodes {
			title
			meetingID
			meetingDateID
		  	databaseId
		  	userRegisteredHash
		  	ResourceVisibility {
				resourceVisibility
			}
			Meetings {
			  eventType
			  startDate
			  shortDescription
			  duration
			  eventLink
			  host {
				... on TeamSingle {
				  title
				  id
				  Team {
					positionEvents
				  }
				  featuredImage {
					  ...ImageFragmentHost
				  }
				}
			  }
			  panelists {
				... on TeamSingle {
				  title
				  id
				  Team {
					positionEvents
				  }
				  featuredImage {
					  ...ImageFragmentPanelist
				  }
				}
			  }
			}
			...ThumbnailFragment
		  }
		}
		themeGeneralSettings {
		  Options {
			joinEventButton
			registerEventButton
			viewEventButton
			dateEventsHeading
			skillBoosterEventsHeading
			learnNewEventsHeading
			inspiredEventsHeading
		  }
		}
  	}
  	${thumbnailFragment('MeetingsSingle')}
  	${imageFragment('Host')}
  	${imageFragment('Panelist')}
  	`
);
