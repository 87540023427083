import {checkVisibilityForArray} from 'js/components/Common/ResourceVisibilityValidator/fetchData';

class ResourceVisibilityService {
    filterArrayByVisibility = async (data:Array<any>, idKey: string, apiPath:string) => {
        if (!data?.length) {
            return [];
        }
        const ids = data?.map(item => item[idKey]);
        const response = await fetch(`${apiPath}/graphql`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                query: `${checkVisibilityForArray(ids)}`
            })
        });
        const responseData = await response.json();
        let filteredIds = responseData?.data?.resourceVisibility?.checkResourceVisibilityForArray;
        if (filteredIds?.length) {
            filteredIds = filteredIds?.split(',')?.map(Number);
            return data?.filter(item => filteredIds?.includes(item[idKey]));
        }
        return [];
    };
}

export default new ResourceVisibilityService();
