import React, {useState} from 'react';

export const ManualsHubContext = React.createContext({
	activeCategoryId: null,
	setActiveCategoryId: value => value,
	activeCategoryName: '',
	setActiveCategoryName: value => value,
	loading: false,
	setLoading: value => value,
	showLoadAllBtn: true,
	setShowLoadAllBtn: value => value,
	limit: '6',
	setLimit: value => value,
	totalManualsCount: 0,
	setTotalManualsCount: value => value
});

const ManualsHubContextProvider = (props) => {
	const [limit, setLimit] = useState<'6' | 'all'>('6');
	const [loading, setLoading] = useState<boolean>(false);
	const [activeCategoryId, setActiveCategoryId] = useState<number>(null);
	const [activeCategoryName, setActiveCategoryName] = useState<string>('');
	const [showLoadAllBtn, setShowLoadAllBtn] = useState<boolean>(true);
	const [totalManualsCount, setTotalManualsCount] = useState<number>(0);
	return (
		<ManualsHubContext.Provider value={{
			limit,
			loading,
			setLimit,
			setLoading,
			showLoadAllBtn,
			activeCategoryId,
			setShowLoadAllBtn,
			totalManualsCount,
			setActiveCategoryId,
			activeCategoryName,
			setTotalManualsCount,
			setActiveCategoryName
		}}>
			{props.children}
		</ManualsHubContext.Provider>
	);
};

export default ManualsHubContextProvider;
