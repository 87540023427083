import React, {useCallback, useContext, useEffect, useReducer, useMemo} from 'react';
import checkDateOffset from 'js/helpers/CheckDateOffset';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';
import {CommonContext} from 'js/context/CommonContext/CommonContext';
import {ToastMessagesContext} from 'js/context/ToastMessagesContext/ToastMessagesContext';

import fetchData from './fetchData';
import classes from './EventRegistrationWrap.module.pcss';
import useFetchCallback from 'js/hooks/useFetchCallback/useFetchCallback';

export interface Props {
    buttonType?: 'button' | 'link',
    children?: any,
    eventType: string,
    startDate: string,
    eventLink: string,
    meetingID: any,
    meetingDateID: any,
    databaseId: any,
    initialRegistrationHash: string,
    setRegistrationHashCallback?: Function,
    additionalHandlerCallback?: Function,
    buttons: {
        registerEventButton: string,
        joinEventButton: string,
        viewEventButton: string
    },
    title: string,
    eventCategory: 'Search Results' | 'Live Events' | 'Notifications' | 'Top Banner'
}

const reducer = (state, action):any => {
    switch (action.type) {
        case 'init': return action?.value;
        case 'registration': return action?.value;
        case 'join' : return action?.value;
        case 'error' : return {
            button: action?.value,
            hash: '',
            loadingState: false
        };
        default: return state;
    }
};

const EventRegistrationWrap = ({
       buttonType = 'button',
       meetingID,
       meetingDateID,
       databaseId,
       children,
       eventType,
       eventLink,
       startDate,
       initialRegistrationHash,
       setRegistrationHashCallback,
       additionalHandlerCallback,
       buttons,
       title,
       eventCategory
   }:Props) => {
    const {currentUser} = useContext(CommonContext);
    const {addToastMessage} = useContext(ToastMessagesContext);

    const sendMixPanelEvent = useCallback(async (eventName: string) => {
        await MixPanelWrapper.TrackEvent(
            eventName,
            {
                category: eventCategory,
                value: title
            });
    }, [eventCategory, title]);

    const registrationButton = useMemo(() => (
        {
            buttonText: buttons?.registerEventButton,
            buttonType: 'button',
            buttonState: 'active'
        }
    ), [buttons]);

    const joinButton = useMemo(() => (
        {
            buttonText: buttons?.joinEventButton,
            buttonType: checkDateOffset(startDate, 1) ? 'a' : 'button',
            buttonState: checkDateOffset(startDate, 1) ? 'joinActive' : 'joinDisabled'
        }
    ), [buttons, startDate]);

    const conferenceButton = useMemo(() => (
        {
            buttonText: buttons?.viewEventButton,
            buttonType: 'a',
            buttonState: 'active'
        }
    ), [buttons]);

    const changeStateOnRegistration = useCallback((caseType: 'error' | 'success', result) => {
        switch (caseType) {
            case 'error' : {
                dispatch({type: 'error', value: registrationButton});
                addToastMessage({
                    type: 'error',
                    key: 'eventRegistrationToasts'
                });
                break;
            }
            case 'success': {
                dispatch({type: 'join', value: {
                        button: joinButton,
                        hash: result?.meetingsSingle?.registerOnEvent?.request,
                        loadingState: false
                    }});
                addToastMessage({
                    type: 'success',
                    key: 'eventRegistrationToasts'
                });
                break;
            }
            default : break;
        }
    }, [addToastMessage, joinButton, registrationButton]);

    const registerUserOnEvent = useFetchCallback({
        updateData: data => data?.meetingsSingle?.registerOnEvent?.request,
        query: () => `${fetchData(databaseId, meetingID, meetingDateID)}`,
        onStart: () => {
            if (!currentUser) {
                changeStateOnRegistration('error', null);
                return;
            }
        },
        onSuccess: result => changeStateOnRegistration('success', result),
        onFailure: () => changeStateOnRegistration('error', null),
        onError: () => changeStateOnRegistration('error', null)
    });

    const [state, dispatch] = useReducer(reducer, {
        button: registrationButton,
        hash: initialRegistrationHash,
        loadingState: true
    } );

    useEffect(() => {

        if (eventType === 'Conference') {
            return dispatch({type: 'init', value: {
                    button: conferenceButton,
                    hash: '',
                    loadingState: false
                }});
        }

        if (initialRegistrationHash) {
            return dispatch({type: 'init', value: {
                    button: joinButton,
                    hash: initialRegistrationHash,
                    loadingState: false
                }});
        }

        dispatch({type: 'init', value: {
                button: registrationButton,
                hash: '',
                loadingState: false
            }});

    }, [buttons, conferenceButton, eventType,
        registrationButton, joinButton, initialRegistrationHash]);

    const clickHandler = useCallback(async () => {
        const button = eventType === 'Conference' ? conferenceButton : registrationButton;
        const additionalHandler = additionalHandlerCallback || (() => {});
        if (state?.button?.buttonType === 'a') {
            if (eventType === 'Conference') {
                await sendMixPanelEvent('View custom Event');
                additionalHandler();
                return;
            }
            await sendMixPanelEvent('Join on the Event');
            additionalHandler();
            return;
        }
        await sendMixPanelEvent('Register on the Event');

        dispatch({type: 'registration', value: {
                button,
                hash:'',
                loadingState: true
            }});
        registerUserOnEvent(null);
    }, [
        eventType,
        conferenceButton,
        registrationButton,
        additionalHandlerCallback, state, sendMixPanelEvent, registerUserOnEvent]);

    return React.cloneElement(children, {
        type: state?.button.buttonType,
        target: true,
        text: state?.button.buttonText,
        handler: clickHandler,
        link: eventType === 'Conference' ? eventLink : `${process?.env?.REACT_APP_DEMIO_REGISTRATION_LINK}${state.hash}`,
        disabled: state?.button.buttonState === 'joinDisabled',
        withSpinner: state.loadingState,
        additionalStylesClass: `${buttonType === 'button' ? classes.RegistrationButton : classes.RegistrationLink} ${classes[state?.button.buttonState]}`,
        setRegistrationHashCallback: setRegistrationHashCallback(state.hash)
    });
};

export default EventRegistrationWrap;
