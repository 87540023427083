import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useLocation} from 'react-router-dom';
import Logo from 'js/components/Common/Logo/Logo';
import Container from 'js/components/Common/Container/Container';
import { CommonContext } from 'js/context/CommonContext/CommonContext';
import SearchForm from 'js/components/Common/SearchBlock/SearchForm/SearchForm';
import AnnouncementBar from 'js/components/Common/AnnouncementBar/AnnouncementBar';

import classes from './Header.module.pcss';
import HeaderPostLogin from './HeaderPostLogin';
import HeaderPreLogin from './HeaderPreLogin';

const Header = () => {
	const [isSticky, setSticky] = useState(false);
	const headerRef = useRef(null);
	const {isCustomerLogged} = useContext(CommonContext);
	const {pathname} = useLocation();

	const scrollCallBack = useCallback(() => {
		const header: HTMLElement = document.getElementById('header');
		const sticky: number = header.offsetTop;
		window.pageYOffset > sticky ? setSticky(true) : setSticky(false);
	}, []);


	useEffect(() => {
		window.addEventListener('scroll', scrollCallBack);
		return () => {
			window.removeEventListener('scroll', scrollCallBack);
		};
	}, [scrollCallBack]);

	return (
		<header ref={headerRef} id="header" className={`${classes.Header} ${isSticky ? classes.Fixed : ''}`}>
			<AnnouncementBar/>
			<Container additionalClass={classes.TopWrap}>
				<div className={classes.HeaderWrapper}>
					<Logo footerLogo={false}/>
					{isCustomerLogged === false && <HeaderPreLogin/>}
					{isCustomerLogged === true && <HeaderPostLogin />}
				</div>
			</Container>
			{pathname !== '/homepage' && isCustomerLogged === true &&
				<SearchForm headerRef={headerRef} />}
		</header>
	);
};

export default Header;
