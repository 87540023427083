const getTaxonomyData = (name: 'productList' | 'suitableForList'):string => (
	`${name}(first: 50) {
		nodes {
		  name
		  ${name === 'productList' ? 'ShortName {shortName}' : ''}
		}
	}`
);

const getAttributes = ():string => (
	`
	attributes {
	  duration
	  hands
	  host
	  onlineTraining
	  fullyCertified
      registrationIsRequired
	  showCertified
	  showDuration
	  showHands
	  showHost
	  showOnline
	  showRegistration
	}
	`
);

export const iLTCoursesSingleFetchData = (pageId):string => (
	`
		{
			iLTCoursesSingle(id: "${pageId}", idType: DATABASE_ID) {
				  title
				  ILTCourses {
					about
					content
					location
					${getAttributes()}
				  }
				  ${getTaxonomyData('productList')}
				  ${getTaxonomyData('suitableForList')}
			}
  		}
	`
);


const iLTCoursesSectionFetchData = (pageId:number, type:'onBoarding' | 'catalogue', templateName: string):string => (
	`
		{
			page(id: "${pageId}", idType: DATABASE_ID) {
				${templateName} {
					titleIltSection
					subtitleIltSection
				}			  
			}
			iLTCoursesArchive(where: {orderby: {field: DATE, order: DESC}, courseType: "${type}"}, first: 50) {
				  nodes {
					databaseId
					title
				  }
			}
		}
	`
);
export default iLTCoursesSectionFetchData;
