import React, {useCallback} from 'react';
import NavigationItem from 'js/components/Common/Navigation/NavigationItem/NavigationItem';

import { NavigationMenuDataInterface } from '../NavigationMenu/NavigationMenuDataInterface';

export interface Props {
	menuItems: NavigationMenuDataInterface[],
	isSubMenu: boolean
}

const NavigationItems = ({ menuItems, isSubMenu }) => {
	const setSubMenu = useCallback((menuItem) => {
		const isSubMenuCondition = menuItem?.childItems && menuItem?.childItems?.nodes?.length > 0;
		if (!isSubMenuCondition) {
			return null;
		}
		return (
			<NavigationItems
				menuItems={menuItem.childItems.nodes}
				isSubMenu={isSubMenuCondition}/>
		);
	}, []);

	return (
			<ul className={isSubMenu ? 'SubMenu' : ''}>
				{
					menuItems ? menuItems?.map((menuItem) => {
						let isSubMenuClass = false;
						if (menuItem?.childItems?.nodes?.length > 0) {
							isSubMenuClass = true;
						}
						return (
							<NavigationItem
								key={menuItem.id}
								item={menuItem}
								hasSubMenuClass={isSubMenuClass}
								subMenu={setSubMenu(menuItem)}>
								{menuItem.label}
							</NavigationItem>
						);
					}
				) : null
			}
		</ul>
	);
};

export default NavigationItems;
