import React, {MouseEventHandler} from 'react';

import classes from './InfoButton.module.pcss';

export interface Props {
	click: MouseEventHandler<HTMLDivElement>
}

const InfoButton = ({click}: Props) => (
	<div className={classes.InfoButton} onClick={click}>
		<svg xmlns="http://www.w3.org/2000/svg" width="10" height="20" viewBox="0 0 10 20" fill="none">
			<path d="M3.91992 0C4.51327 0 5.09326 0.175971 5.58661 0.505615C6.07996 0.835259 6.46447 1.30375 6.69153 1.85193C6.91859 2.40011 6.978 3.00338 6.86224 3.58533C6.74649 4.16727 6.46082 4.70178 6.04126 5.12134C5.6217 5.5409 5.08713 5.82663 4.50519 5.94238C3.92324 6.05814 3.32003 5.99867 2.77185 5.77161C2.22367 5.54454 1.75512 5.1601 1.42548 4.66675C1.09583 4.1734 0.919922 3.59334 0.919922 3C0.919922 2.20435 1.23597 1.44127 1.79858 0.878662C2.36119 0.316053 3.12427 0 3.91992 0Z" fill="white"/>
			<path d="M8.72998 19.8801H1.06995C0.80473 19.8801 0.55039 19.7748 0.362854 19.5873C0.175318 19.3997 0.0699463 19.1453 0.0699463 18.8801C0.0699463 18.6149 0.175318 18.3605 0.362854 18.173C0.55039 17.9854 0.80473 17.8801 1.06995 17.8801H2.81995V11.0502H1.06995C0.786165 11.0502 0.514018 10.9374 0.313354 10.7367C0.112691 10.536 0 10.2639 0 9.9801C0 9.69632 0.112691 9.42417 0.313354 9.22351C0.514018 9.02285 0.786165 8.91016 1.06995 8.91016H7.39996C7.50075 8.91016 7.59744 8.95022 7.6687 9.02148C7.73996 9.09275 7.77997 9.18938 7.77997 9.29016V17.8801H8.77997C8.91129 17.8834 9.0407 17.9125 9.16077 17.9658C9.28084 18.0191 9.3892 18.0955 9.47974 18.1907C9.57027 18.2859 9.64119 18.3979 9.68842 18.5205C9.73564 18.6431 9.75823 18.7738 9.75494 18.9052C9.75166 19.0365 9.7226 19.1658 9.66931 19.2859C9.61602 19.406 9.53958 19.5143 9.4444 19.6049C9.34922 19.6954 9.23715 19.7664 9.11456 19.8136C8.99198 19.8608 8.8613 19.8834 8.72998 19.8801Z" fill="white"/>
		</svg>
	</div>
);

export default InfoButton;
