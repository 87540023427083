import React from 'react';
import SimpleContent from 'js/components/Common/SimpleContent/SimpleContent';

const PolicyTemplate = ({ pageId }) => (
	<main className="templatePolicy">
		<SimpleContent pageId={pageId}/>
	</main>
);

export default PolicyTemplate;
