import React from 'react';
import parse from 'html-react-parser';

import classes from './Description.module.pcss';

const Description = ({value}) => (
    <div className={classes.Description}>
        {parse(value || '')}
    </div>
);

export default Description;
