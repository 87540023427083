import React from 'react';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import classes from './ValuesItems.module.pcss';
import ValueItem from '../ValuesItem/ValueItem';

const ValuesItems = ({ items }) => (
	<DataValidator data={items}>
		<div className={classes.ValuesItems}>
			{
				items?.map
				(
					(item, index) => <ValueItem key={index} item={item} />
				)
			}
		</div>
	</DataValidator>
);

export default ValuesItems;
