export default (pageId, topic, limit) => `
{
    manualsHub {
      getManualItems(page: ${pageId}, topic: ${topic}, limit: "${limit}") {
        id
        description
        title
        publishedAgo
        product
        lastUpdated
        isPlayTechOnly
        fileURL
        downloads
        type
        owner {
          name
          photo
          position
        }
      }
      getManualsCount
    }
  }
`;
