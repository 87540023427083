import React from 'react';
import Heading from 'js/components/Common/Heading/Heading';

import classes from './OnboardingFlowItem.module.pcss';
import Advantages from './Advantages/Advantages';
import {OnBoardingFlowDataInterface} from '../OnboardingFlowBlock/OnboardingFlowDataInterface';

export interface Props {
	data: OnBoardingFlowDataInterface
}

const OnBoardingFlowItem = ({data}: Props) => (
	<div className={classes.OnBoardingFlowItem}>
		<Heading text={data?.title || ''} />
		<p>{data?.description || ''}</p>
		{data?.showAdvantages && data?.advantages ? <Advantages data={data?.advantages} /> : null}
	</div>
);

export default OnBoardingFlowItem;
