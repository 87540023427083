import React from 'react';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import classes from './MediaDuration.module.pcss';

export interface Props {
    data: string
}

const MediaDuration = ({data}) => (
    <DataValidator data={data?.length > 0}>
        <span className={classes.MediaDuration}>
        {data}
    </span>
    </DataValidator>
);

export default MediaDuration;
