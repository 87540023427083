import React from 'react';
import Container from 'js/components/Common/Container/Container';

import classes from './Loader.module.pcss';
const Loader = () => (
    <Container>
        <div className={classes.Loader} />
    </Container>
);
export default Loader;
