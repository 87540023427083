import {useCallback, useContext, useMemo, useState} from 'react';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import buildQuery from 'js/components/Filter/Helpers/buildQuery';
import moment from 'moment';

import fetchData from '../hook/fetchData';
import {
    EventsListTypes,
    EventsHeadingsType,
    WrapperDataInterface
} from '../Wrapper/WrapperDataInterface';
import {EventsListContext} from '../context/EventsListContext';

const initialButtonsValues = {
    registerEventButton: '',
    joinEventButton: '',
    viewEventButton: ''
};

const initialHeadingsValue = {
    dateSectionHeading: '',
    skillBoosterSectionHeading: '',
    learnNewSectionHeading: '',
    inspiredSectionHeading: ''
};

const useEventsList = (
    type:EventsListTypes
) => {
    const [eventsList, setEventsList] = useState<WrapperDataInterface[]>([]);
    const [eventsButtons, setEventsButtons] = useState(initialButtonsValues);
    const [eventsHeadings, setEventsHeadings] = useState<EventsHeadingsType>(initialHeadingsValue);
    const {setResult} = useContext(EventsListContext);

    const upcomingOnly = useCallback(eventsListData => (
        eventsListData?.filter((item) => {
            const date = item?.Meetings?.startDate;
            const localStartDate = moment(new Date(date)).utc(true);
            const localNowDate = moment(new Date()).utc(false);
            return localNowDate.isBefore(localStartDate);
        })
    ), []);


    const setQueryAttributesCallBack = useMemo(() =>
        buildQuery(type, null, true, true, true), [type]);

    const updateDataCallback = useCallback((data) => {
        const events = data?.meetingsArchive?.nodes;
        const options = data?.themeGeneralSettings?.Options;
        const eventsFiltered = upcomingOnly(events);

        if (!eventsFiltered || !eventsFiltered?.length) {
            setResult(type, false);
            return;
        }
        setEventsList(eventsFiltered);
        setResult(type, true);
        setEventsButtons({
            joinEventButton: options?.joinEventButton,
            registerEventButton: options?.registerEventButton,
            viewEventButton: options?.viewEventButton
        });
        setEventsHeadings({
            dateSectionHeading: options?.dateEventsHeading,
            skillBoosterSectionHeading: options?.skillBoosterEventsHeading,
            learnNewSectionHeading: options?.learnNewEventsHeading,
            inspiredSectionHeading: options?.inspiredEventsHeading
        });
    }, [setResult, type, upcomingOnly]);

    const { isLoading, error } = useFetchData({
        query: `${fetchData(setQueryAttributesCallBack)}`,
        condition: !!type,
        updateData: updateDataCallback
    });

    if (isLoading || error) {
        return {
            eventsList: [],
            eventsButtons: initialButtonsValues,
            eventsHeadings: initialHeadingsValue
        };
    }

    return {
        eventsList,
        eventsButtons,
        eventsHeadings
    };
};

export default useEventsList;
