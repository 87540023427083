const imageFragment = (name = ''): string =>
	`fragment ImageFragment${name} on MediaItem {
		  altText
		  mediaItemUrl
		  srcSet
		  mediaDetails {
		  	width
		  	height
			sizes {
			  name
			  sourceUrl
			}
	  	}
    }`;

export default imageFragment;
