import React, {useState} from 'react';
import parse from 'html-react-parser';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Container from 'js/components/Common/Container/Container';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import classes from './HowToSection.module.pcss';
import fetchData from './fetchData';

const HowToSection = ({pageId}) => {
    const [leftSide, setLeftSide] = useState<string>('');
    const [rightSide, setRightSide] = useState<string>('');

    const { isLoading, error } = useFetchData({
        query: `${fetchData(pageId)}`,
        condition: !!pageId,
        updateData: (data) => {
            setLeftSide(data?.page?.CoursesCatalogue?.leftSideHowTo || '');
            setRightSide(data?.page?.CoursesCatalogue?.rightSideHowTo || '');
        }
    });

    return (
        <DataValidator data={!isLoading && !error && (leftSide || rightSide)}>
            <section className={classes.HowToSection}>
                <Container additionalClass={classes.HowToSectionWrap}>
                    <div className={classes.HowToSectionLeft}>
                        {parse(leftSide)}
                    </div>
                    <div className={classes.HowToSectionRight}>
                        {parse(rightSide)}
                    </div>
                </Container>
            </section>
        </DataValidator>
    );
};

export default HowToSection;
