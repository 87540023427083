import navigationFragment from 'js/graphqlFragments/NavigationFragment';
const fetchData = (location, levels) => (
    `{
        menus(where: {location: ${location}} ) {
          nodes {
            id
            name
            menuId
            FooterMenuFields {
              loggedOnly
            }
            menuItems(first: 100) {
                nodes {
                  id
                  url
                  title
                  label
                  target
                  menuItemId
                  cssClasses
                  ComingSoon {
                    comingSoonLabel
                  }
                  FooterMenu {
                    usePopup
                    popupInfo
                    popupTitle
                    useButtonFooter
                    buttonTextFooter
                    popupTypeFooter
                  }
                  ${levels > 0 ? '...NavigationFragment' : ''}
                }
            }
          }
        }
    }
    ${navigationFragment(levels)}`
);

export default fetchData;
