import React from 'react';
import Heading from 'js/components/Common/Heading/Heading';

import Filter from '../SearchFilter/SearchFilter';
import classes from './SearchSidebar.module.pcss';
import FilterReset from '../SearchFilter/SearchFilterReset/SearchFilterReset';


const SearchSidebar = ({nodeRef}) => (
    <aside className={classes.SearchSidebar}>
        <div className={classes.Wrap}>
            <Heading text={'Filters'} type={'h4'} />
            <FilterReset nodeRef={nodeRef} />
        </div>
        <Filter />
    </aside>
);

export default SearchSidebar;
