import React from 'react';
import classes from './FilterItemCount.module.pcss';

const FilterItemCount = ({type, count}) => {
    if (count[type]) {
        return (
            <span className={classes.FilterItemCount}>{`(${count[type]})`}</span>
        );
    } else {
        return null;
    }
};

export default FilterItemCount;
