import React from 'react';
import useSearchReset from 'js/hooks/useSearchReset/useSearchReset';
import {observer} from 'mobx-react';

import classes from './SearchFilterReset.module.pcss';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';
import SearchStore from 'js/store/SearchStore';

const SearchFilterReset = (nodeRef) => {
    const resetClickHandler = useSearchReset(nodeRef);
    const {searchStatus, searchQuery} = SearchStore;
    return (
        <button
            onClick={async () => {
                resetClickHandler();
                await MixPanelWrapper.TrackEvent(
                    'Reset search filter',
                    {
                        value: '',
                        category: 'Search Filters'
                    }
                );
            }}
            className={`${classes.FilterReset} ${searchStatus || searchQuery.length === 0 ? classes.FilterResetActive : ''}`}>Clear All</button>
    );
};

export default observer(SearchFilterReset);
