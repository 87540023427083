const fetchData = pageId => (
    `
    {
        page(id: "${pageId}", idType: DATABASE_ID) {
          OnBoarding {
            onboardingFlowSection {
              ${section()}
            }
            secondOnboardingFlowSection {
              ${section()}
            }
            thirdOnboardingFlowSection {
              ${section()}
            }
            fourthOnboardingFlowSection {
              ${section()}
            }
            fifthOnboardingFlowSection {
              ${section()}
            }
          }
        }
    }
   
    `
);

const section = () => (
    `
          title
          showAdvantages
          description
          advantages {
            info
            icon {
              mediaItemUrl
            }
          }
    `
);

export default fetchData;
