import React, {useState} from 'react';
import Label from 'js/components/Common/Label/Label';
import Button from 'js/components/Common/Button/Button';
import Heading from 'js/components/Common/Heading/Heading';
import TeamProfile from 'js/components/Common/TeamProfile/TeamProfile';
import Description from 'js/components/Common/Description/Description';
import useFetchCallback from 'js/hooks/useFetchCallback/useFetchCallback';
import VisibilityViewerIcon from 'js/components/Common/VisibilityViewerIcon/VisibilityViewerIcon';
import ActivityAndPublishing from 'js/components/Common/ActivityAndPublishing/ActivityAndPublishing';

import classes from './ManualItem.module.pcss';
import fetchData from './fetchData';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';

export type ManualItemType = {
    id: number,
    title : string,
    description : string,
    isPlayTechOnly : boolean,
    product : string,
    type: string,
    lastUpdated : string,
    fileURL : string,
    downloads : number,
    publishedAgo : string,
    owner : {
        name: string,
        position: string,
        photo: string
    }
}

interface Props {
    itemData: ManualItemType;
}

const ManualItem = ({itemData} : Props) => {
	const {
        id,
        type,
        title,
        fileURL,
        product,
        downloads,
        lastUpdated,
        description,
        publishedAgo,
        isPlayTechOnly,
        owner
    } = itemData || {};
    const [downloadsCount, setDownloadsCount] = useState<number>(downloads);

    const fileDownloadHandler = useFetchCallback({
        updateData: data => data?.manualsSingle?.updateDownloads,
        query: () => `${fetchData(id)}`,
        onStart: () => {
            const element = document.createElement('a');
            element.setAttribute('href', fileURL);
            const ext = fileURL.split('.').pop();
            element.setAttribute('download', `${title}.${ext}`);

            element.style.display = 'block';
            element.click();
        },
        onSuccess: async () => {
            await MixPanelWrapper.TrackEvent(
                'Download Manual',
                {
                    category: 'Manual Hub',
                    value: title
            });
            setDownloadsCount(prevState => prevState + 1);
        },
        onFailure: () => {
            console.error('Error while downloading');
        },
        onError: (e) => {
            console.error(e);
        }
    });

    return (
        <div className={classes.ManualItem}>
            <div className={classes.Top}>
                <VisibilityViewerIcon id={id} additionalClass={classes.VisibilityIcon}/>
                <Label
                    additionalClass={classes.Label}
                    value={isPlayTechOnly ? 'Playtech Only' : null}
                    colorType={'Yellow'} />
                <Label value={type}
                       additionalClass={classes.Label}
                       colorType={'Blue'}
                />
                <Label value={product}
                       additionalClass={`${classes.Label} ${classes.Product}`}
                       colorType={'Red'}
                />
            </div>
            {lastUpdated ? <span className={classes.LastUpdated}>{`Last Updated ${lastUpdated}`}</span> : null}
			<Heading text={title} type={'h4'} />
            <Description data={description} linesType={'threeLines'} />
            <TeamProfile
                type={'Big'}
                additionalClass={classes.TeamProfile}
                title={owner?.name}
                position={owner?.position}
                photo={{
                    altText: owner?.name,
                    mediaItemUrl: owner?.photo
                }} />
            <Button
                type={'button'}
                text={'Download Manual'}
                additionalStylesClass={classes.Button}
                handler={fileDownloadHandler} />
            <ActivityAndPublishing
                count={downloadsCount}
                publishingDate={publishedAgo}
            />
        </div>
    );
};

export default ManualItem;
