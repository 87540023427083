import React, {useContext, useEffect, useState} from 'react';
import Heading from 'js/components/Common/Heading/Heading';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Container from 'js/components/Common/Container/Container';
import {CommonContext} from 'js/context/CommonContext/CommonContext';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';
import ResourceVisibilityService from 'js/components/Common/ResourceVisibilityValidator/ResourceVisibilityService';

import fetchData from './fetchData';
import classes from './GettingStarted.module.pcss';
import GettingStartedService from './GettingStartedService';
import GettingStartedItems, {GettingStartedItemType} from './GettingStartedItems/GettingStartedItems';
import parse from 'html-react-parser';

interface Props {
    pageId: number
}


const GettingStarted = ({pageId}: Props) => {
    const {apiPath} = useContext(CommonContext);
    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [gettingStartedDefaultData, setGettingStartedDefaultData] = useState<Array<GettingStartedItemType>>(null);
    const [gettingStartedCustomData, setGettingStartedCustomData] = useState<Array<GettingStartedItemType>>(null);
    const [gettingStartedResultData, setGettingStartedResultData] = useState<Array<GettingStartedItemType>>(null);

    useEffect(() => {
        setGettingStartedResultData(
            GettingStartedService.getMergedItems(gettingStartedDefaultData, gettingStartedCustomData));
    }, [gettingStartedDefaultData, gettingStartedCustomData]);

    const {isLoading, error} = useFetchData({
        query: `${fetchData(pageId)}`,
        condition: !!pageId,
        updateData: async (data: any) => {
            setTitle(data?.subjectMatter?.SubjectMatterGlobal?.gettingStartedTitle);
            setDescription(data?.gettingStartedCustom?.ProductLegacyFields?.gettingStartedSubtitle);

            let gettingStartedDefault = data?.gettingStartedDefault?.articles?.items;
            let gettingStartedCustom = data?.gettingStartedCustom?.ProductLegacyFields?.gettingStartedCustom;

            gettingStartedDefault = await ResourceVisibilityService.filterArrayByVisibility(gettingStartedDefault, 'id', apiPath);
            gettingStartedCustom = await ResourceVisibilityService.filterArrayByVisibility(gettingStartedCustom, 'id', apiPath);

            setGettingStartedDefaultData(gettingStartedDefault);
            setGettingStartedCustomData(gettingStartedCustom);
        }
    });

    return (
        <DataValidator data={!isLoading && !error && gettingStartedResultData?.length > 0}>
            <section className={classes.GettingStarted}>
                <Container additionalClass={classes.Wrap}>
                    <Heading text={title} />
                    <div className={classes.Description}>{parse(description || '')}</div>
                    <GettingStartedItems data={gettingStartedResultData} />
                </Container>
            </section>
        </DataValidator>
    );
};

export default GettingStarted;
