import React from 'react';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import classes from './Views.module.pcss';

export interface Props {
    data: number
}

const Views = ({data}: Props) => (
    <DataValidator data={data > 0}>
        <span className={classes.Views}>{data} {data > 1 ? 'Views' : 'View'}</span>
    </DataValidator>
);

export default Views;
