import React from 'react';
import Heading from 'js/components/Common/Heading/Heading';
import Container from 'js/components/Common/Container/Container';

import classes from './ContactBlock.module.pcss';
import ContactInfo from './ContactInfo/ContactInfo';
import ContactForm from './ContactForm/ContactForm';

const ContactBlock = ({pageId}) => (
	<div className={classes.ContactBlock}>
		<Container additionalClass={classes.Wrapper}>
			<Heading text={'Contact Us'} type={'h1'}/>
			<ContactForm/>
			<ContactInfo pageId={pageId}/>
		</Container>
	</div>
);

export default ContactBlock;

