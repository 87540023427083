import React, {useCallback, useContext} from 'react';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';
import Sidebar from 'js/components/Common/Sidebar/Sidebar';
import Container from 'js/components/Common/Container/Container';
import FilterCount from 'js/components/Common/FilterCount/FilterCount';
import {ManualsHubContext} from 'js/context/ManualsHubContext/ManualsHubContext';

import fetchData from './fetchData';
import classes from './ManualsService.module.pcss';
import ManualsResults from './../ManualsResults/ManualsResults';

const ManualsService = ({pageId}: {pageId: number}) => {
    const {
        loading,
        totalManualsCount,
        activeCategoryName,
        setActiveCategoryId,
        setActiveCategoryName,
        setLimit,
        setLoading,
        activeCategoryId,
        setShowLoadAllBtn
    } = useContext(ManualsHubContext);

    const getObjectData = useCallback((obj) => {
        if (!obj) {
            return null;
        }
        return obj?.manualsHub?.getSidebarItems;
    }, []);

    const sendMixPanelEvent = useCallback(async (label:string) => {
        await MixPanelWrapper.TrackEvent(
            'Manuals Filtering',
            {
                category: 'Manual Hub',
                value: `${label}`
            });
    }, []);

    return (
        <div id={'ManualsHub'} className={`${classes.ManualsService} ${loading ? classes.Shadow : ''}`}>
            <Container>
                <FilterCount
                    value={totalManualsCount}
                    activeCat={activeCategoryName}
                    label={'manual'}
                />
                <div className={classes.Main}>
                    <Sidebar
                        pageId={pageId}
                        fetchData={fetchData}
                        anchorId={'ManualsHub'}
                        setLimit={setLimit}
                        getObjectData={getObjectData}
                        setLoading={setLoading}
                        eventSendHandler={async value => await sendMixPanelEvent(value)}
                        activeItemId={activeCategoryId}
                        setShowLoadAllBtn={setShowLoadAllBtn}
                        setActiveItemId={setActiveCategoryId}
                        setActiveItemName={setActiveCategoryName}

                     />
                    <ManualsResults pageId={pageId} />
                </div>
            </Container>
        </div>
    );
};

export default ManualsService;
