import React, {useMemo} from 'react';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import classes from './Label.module.pcss';
import parse from 'html-react-parser';

export interface Props {
    value: string,
    colorType?: 'Red' | 'Blue' | 'Yellow' | 'White',
    additionalClass?: string,
    newClass?: string
}

const Label = ({ value, colorType = 'Red', additionalClass = '', newClass }: Props) => {

    const getClassName = useMemo(() => {
        if (newClass) return newClass;
        return `${classes.Label} ${classes[colorType]} ${additionalClass}`;
    }, [additionalClass, colorType, newClass]);

    return (
        <DataValidator data={value}>
            <span className={getClassName}>{parse(value || '')}</span>
        </DataValidator>
    );
};

export default Label;
