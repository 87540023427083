import thumbnailFragment from 'js/graphqlFragments/ThumbnailFragment';
export default getAttributesCallback => (
	`
		{
			coursesArchive(where: 
			{orderby: {field: DATE, order: DESC} 
			${getAttributesCallback}}, first: 1000) {
			  nodes {
				title
				id: databaseId
				uri
				productList {
				  nodes {
					name
				  }
        		}
				ResourceVisibility {
        			resourceVisibility
      			}
				Courses {
				  coursePrice
				  courseAudience
				  newContentLabel
				  courseDuration
				  moreInfo {
					first
					second
				  }
				}
				...ThumbnailFragment
			  }
			}
			themeGeneralSettings {
				Options {
				  startCourseButton
				  buyCourseButton
				  infoCourseButton
				  essentialCoursesHeading
				  popularCoursesHeading
				  premiumCoursesHeading
				  usefulCoursesText
				}
		  }
		}
		${thumbnailFragment('CoursesSingle')}
		`
);
