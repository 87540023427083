import React, {useCallback, useContext, useState} from 'react';
import parse from 'html-react-parser';
import Heading from 'js/components/Common/Heading/Heading';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Container from 'js/components/Common/Container/Container';
import {CommonContext} from 'js/context/CommonContext/CommonContext';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';
import {EntitiesTypesDataInterface} from 'js/containers/Pages/Search/SearchResults/Entities/EntitiesTypesDataInterface';

import fetchData from './fetchData';
import classes from './SubTopic.module.pcss';
import SubTopicService from './SubTopicService';
import SubTopicItems from './SubTopicItems/SubTopicItems';
import SubTopicData from './SubTopicData/SubTopicData';


interface Props {
    pageId: number
}

export type SubTopicItem = {
    id: string,
    databaseId: number,
    name: string
}

export type SubTopicDataType = {
    id:number,
    link: string,
    title: string,
    description: string,
    date: string,
    type: EntitiesTypesDataInterface,
    total: number
}

const SubTopic = ({pageId}: Props) => {
    const {apiPath} = useContext(CommonContext);
    const [title, setTitle] = useState<string>('');
    const [page, setPage] = useState<number>(1);
    const [maxPages, setMaxPages] = useState<number>(0);
    const [description, setDescription] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [items, setItems] = useState<Array<SubTopicItem>>(null);
    const [activeItem, setActiveItem] = useState<{
        name: string,
        id: number
    }>(null);
    const [productName, setProductName] = useState<string>('');
    const [visibleArticles, setVisibleArticles] = useState(10);
    const [articles, setArticles] = useState<Array<SubTopicDataType>>([]);

    const {isLoading: itemsLoading, error: itemsError} = useFetchData({
        query: `${fetchData(pageId)}`,
        condition: !!pageId,
        updateData: (data: any): void => {
            setItems(SubTopicService.sortTerms(data?.subTopicData?.items));
            setProductName(data?.productLegacy?.name);
            setTitle(data?.productLegacy?.ProductLegacyFields?.subTopicSection?.title);
            setDescription(data?.productLegacy?.ProductLegacyFields?.subTopicSection?.description);
        }
    });

    const subTopicItemClickHandler = useCallback(async (activeItemId: number, activeItemName: string) => {
        if (activeItemId === activeItem?.id) {
            return;
        }
        setVisibleArticles(10);
        setIsLoading(true);
        try {
            const result = await SubTopicService.fetchArticlesRecursive(activeItemId, apiPath, 1);

            setArticles(result?.items);
            setPage(1);
            setMaxPages(result?.maxPages);
            setActiveItem({
                id: activeItemId,
                name: activeItemName
            });
        } catch (error) {
            console.error('Error fetching articles:', error);
        } finally {
            setIsLoading(false);
        }
    }, [activeItem, apiPath]);

    const showMoreClickHandler = useCallback(async () => {
        setVisibleArticles((prev:number) => prev + 10);
        setIsLoading(true);
        try {
            const result =
                await SubTopicService.fetchArticlesRecursive(activeItem?.id, apiPath, page + 1);
            setArticles(prevState => [...prevState, ...result?.items]);
            setPage(prevState => prevState + 1);
        } catch (error) {
            console.error('Error fetching articles:', error);
        } finally {
            setIsLoading(false);
        }
    }, [activeItem, apiPath, page]);

    return (
        <DataValidator data={!itemsLoading && !itemsError && !!items?.length}>
            <section className={classes.SubTopic}>
                <Container>
                    <Heading text={title} />
                    <div className={classes.Description}>{parse(description || '')}</div>
                    <SubTopicItems
                        items={items}
                        activeItem={activeItem?.id}
                        parentName={productName}
                        itemClickHandler={subTopicItemClickHandler} />
                    <SubTopicData
                        isLoading={isLoading}
                        visibleArticles={visibleArticles}
                        title={activeItem?.name}
                        articles={articles}
                        currentPage={page}
                        maxPages={maxPages}
                        showMoreHandler={showMoreClickHandler}
                        />
                </Container>
            </section>
        </DataValidator>
    );
};

export default SubTopic;
