export default `
   {
        themeGeneralSettings {
          Options {
            pilotTitle
            pilotInfo
            pilotsubtitle
          }
        }
  }
`;
