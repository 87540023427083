import React, {CSSProperties} from 'react';

import classes from './SpinnerLoader.module.pcss';

export interface Props {
    active: boolean,
    additionalStyles?: CSSProperties
}

const SpinnerLoader = ({active, additionalStyles}: Props) => (
    <div className={classes.SpinnerLoader} style={{
        visibility: active ? 'visible' : 'hidden',
        ...additionalStyles
    }}>
        <svg className={classes.SpinnerLoaderCircular} viewBox="25 25 50 50">
            <circle
                className={classes.SpinnerLoaderPath}
                cx="50"
                cy="50"
                r="20"
                fill="none"
                strokeWidth="5"
                strokeMiterlimit="10"/>
        </svg>
    </div>
);

export default SpinnerLoader;
