import React from 'react';

import classes from './TopicItems.module.pcss';
import TopicItem from '../TopicItem/TopicItem';

const TopicItems = ({ data }) => (
	<div className={classes.TopicItems}>
		{data?.map((item, index) => <TopicItem key={index} item={item} />)}
	</div>
);

export default TopicItems;
