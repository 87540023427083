const fetchData = (pageId: number): string => `
    {
    page(id: "${pageId}", idType: DATABASE_ID) {
      TrainingTemplate {
        titleLearningPath
        learningPathDescription
        learningPathSlider {
          step
          info
        }
      }
    }
  }
`;
export default fetchData;
