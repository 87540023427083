import React, {useContext, useCallback, useMemo, useEffect} from 'react';
import {useHistory} from 'react-router';
import {useLocation} from 'react-router-dom';
import { CommonContext } from 'js/context/CommonContext/CommonContext';

import HeaderButtons from './HeaderButtons/HeaderButtons';

const HeaderPreLogin = () => {
	const history = useHistory();
	const {pathname, search} = useLocation();
	const {isCustomerLogged, apiPath} = useContext(CommonContext);

	const searchParams = useMemo(() => new URLSearchParams(search), [search]);

	useEffect(() => {
		if (isCustomerLogged && searchParams.get('sourcePage')) {
			searchParams.delete('sourcePage');
			history.replace({
				search: searchParams.toString()
			});
		}
	}, [isCustomerLogged, searchParams, history]);

	const logInHandler = useCallback(() => {
		if (pathname === '/') {
			const sourcePage = searchParams.get('sourcePage');
			return window.location.href = sourcePage ? `${apiPath}/auth?sourcePage=${sourcePage}` : `${apiPath}/auth`;
		}
		return window.location.href = `${apiPath}/auth?sourcePage=${pathname}`;
	}, [apiPath, pathname, searchParams]);

	return (
		<HeaderButtons logInCallback={logInHandler}/>
	);
};

export default HeaderPreLogin;
