export default pageId => `
{
    page(id: "${pageId}", idType: DATABASE_ID) {
      HelpTemplate {
        helpArea {
          cases {
            caseBody
            caseHeader
          }
        }
      }
    }
  }
`;
