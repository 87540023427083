import React from 'react';

import classes from './Container.module.pcss';

export interface Props {
	additionalClass?: string,
	children?: any
}

const Container = (props: Props) => (
	<div className={`${classes.Container} ${props?.additionalClass || ''}`}>
		{props?.children}
	</div>
);

export default Container;
