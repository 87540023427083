import React, { useState } from 'react';
import parse from 'html-react-parser';
import Heading from 'js/components/Common/Heading/Heading';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Container from 'js/components/Common/Container/Container';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import fetchData from './fetchData';
import classes from './BrandsBlock.module.pcss';
import BrandsItems from '../BrandsItems/BrandsItems';
import { BrandsBlockDataInterface } from './BrandsBlockDataInterface';

const BrandsBlock = ({ pageId }) => {
	const [brandsTitle, setBrandsTitle] = useState<string>('');
	const [brandsData, setBrandsData] = useState<BrandsBlockDataInterface[]>([]);

	const { isLoading, error } = useFetchData({
		query: `${fetchData(pageId)}`,
		condition: !!pageId,
		updateData: (data) => {
			setBrandsTitle(data?.page?.AboutUs?.headingBrands);
			setBrandsData(data?.page?.AboutUs?.brands);
		}
	});

	return (
		<DataValidator data={!!brandsData && !isLoading && !error}>
			<section className={classes.Brands}>
				<Container>
					<Heading text={brandsTitle ? parse(brandsTitle) : ''} thin={true} />
					<BrandsItems data={brandsData} />
				</Container>
			</section>
		</DataValidator>
	);
};

export default BrandsBlock;
