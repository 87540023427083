import {PageType} from './Overview';

export default (pageId: number, type:  PageType) =>  {
    const productTitleQuery:string = `
        product {
                ProductGlobal {
                  overviewTitleProduct
                }
            }
    `;
    const subjectMatterTitleQuery:string = `
        subjectMatter {
          SubjectMatterGlobal {
            overviewTitleSm
          }
        }
    `;
    return (
        `
        {
            productLegacy(id: "${pageId}", idType: DATABASE_ID) {
              ProductLegacyFields {
                overviewDescription
              }
            }
            ${type === 'Product' ? productTitleQuery : subjectMatterTitleQuery}
        }
        `
    );
};
