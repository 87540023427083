import React from 'react';
import BeforeYouLeave from 'js/components/Common/BeforeYouLeave/BeforeYouLeave';
import WelcomeSection from 'js/components/Templates/Onboarding/WelcomeSection/WelcomeSection';
import MadeEasyBlock from 'js/components/Templates/Onboarding/MadeEasySection/MadeEasyBlock/MadeEasyBlock';
import OnBoardingFlowBlock from 'js/components/Templates/Onboarding/OnboardingFlow/OnboardingFlowBlock/OnboardingFlowBlock';

const OnBoardingTemplate = ({ pageId }) => (
	<main className="templateOnBoarding">
		<WelcomeSection pageId={pageId} />
		<MadeEasyBlock pageId={pageId}/>
		<OnBoardingFlowBlock pageId={pageId} />
		<BeforeYouLeave pageId={pageId} templateName={'OnBoarding'} />
	</main>
);

export default OnBoardingTemplate;
