import React, { useState } from 'react';
import Heading from 'js/components/Common/Heading/Heading';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Container from 'js/components/Common/Container/Container';
import SubHeading from 'js/components/Common/SubHeading/SubHeading';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import fetchData from './fetchData';
import classes from './ValuesBlock.module.pcss';
import ValuesItems from '../ValuesItems/ValuesItems';
import { ValuesBlockDataInterface } from './ValuesBlockDataInterface';

const ValuesBlock = ({ pageId }) => {
	const [valuesTitle, setValuesTitle] = useState<string>('');
	const [valuesSubTitle, setValuesSubTitle] = useState<string>('');
	const [valuesItems, setValuesItems] = useState<ValuesBlockDataInterface[]>([]);

	const {error, isLoading} = useFetchData({
		query: `${fetchData(pageId)}`,
		condition: !!pageId,
		updateData: (data) => {
			setValuesTitle(data.page.AboutUs.headingValues);
			setValuesSubTitle(data.page.AboutUs.subtitleValues);
			setValuesItems(data.page.AboutUs.valuesList);
		}
	});

	return (
		<DataValidator data={!!valuesItems && !error && !isLoading}>
			<section className={classes.Values}>
				<Container additionalClass={classes.ValuesWrap}>
					<Heading text={valuesTitle} />
					<SubHeading text={valuesSubTitle} />
					<ValuesItems items={valuesItems} />
				</Container>
			</section>
		</DataValidator>
	);
};

export default ValuesBlock;
