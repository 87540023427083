import React, {useContext, useState, useMemo, useEffect, useCallback} from 'react';
import { Route, useLocation } from 'react-router';
import SeoHelmet from 'js/containers/SEO/SeoHelmet';
import { CommonContext } from 'js/context/CommonContext/CommonContext';
import SingleVideos from 'js/containers/PostTypes/Videos/SingleVideos';
import SingleArticles from 'js/containers/PostTypes/Articles/SingleArticles';
import SinglePodcasts from 'js/containers/PostTypes/Podcasts/SinglePodcasts';
import ResourceVisibilityValidator from 'js/components/Common/ResourceVisibilityValidator/ResourceVisibilityValidator';

import fetchData from './fetchData';
import { SinglePostTypeRouterDataInterface } from './SinglePostRouterDataInterface';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';

type TypesType = 'articles' | 'videos' | 'podcasts';

const useSinglePostRouter = () => {
    const location = useLocation();
    const types:Array<TypesType> = useMemo(() => ['articles', 'videos', 'podcasts'], []);
    const {isCustomerLogged, apiPath} = useContext(CommonContext);
    const [currentType, setCurrentType] = useState<TypesType>(null);
    const [currentUri, setCurrentUri] = useState<string>('');
    const [post, setPost] = useState<SinglePostTypeRouterDataInterface>(null);

    useEffect(() => {
        if (!types.length) {
            setPost(null);
            setCurrentType(null);
            setCurrentUri('');
            return;
        }

        const path: string = location.pathname;
        for (let i = 0; i < types.length; i++ ) {
            const type = types[i];
            const regexp: RegExp = new RegExp(`^/${type}/`);
            if (regexp.test(path)) {
                const uri = path.replace(regexp, '').replace('/', '');
                setCurrentType(type);
                setCurrentUri(uri);
            }
        }
    }, [location, types]);

    useEffect(() => {
        if (!currentType) {
            return;
        }
        let isCanceled = false;
        (async () => {
            const response = await fetch(`${apiPath}/graphql`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    query: `${fetchData(currentType, currentUri)}`
                })
            });
            if (isCanceled) { return; }
            const { data } = await response.json();
            setPost(data[`${currentType}Single`]);
        })();
        return () => {
            isCanceled = true;
        };
    }, [apiPath, currentType, currentUri]);

    const getComponentByType = useCallback(() => {
        switch (currentType) {
            case 'articles' : return <SingleArticles pageId={post?.databaseId}/>;
            case 'podcasts' : return <SinglePodcasts pageId={post?.databaseId}/>;
            case 'videos' : return <SingleVideos pageId={post?.databaseId}/>;
            default : return null;
        }
    }, [currentType, post]);

    const {INIT_MIX_PANEL} = useMemo(() => MixPanelWrapper, []);

    useEffect(() => {
        if (!post) {
            return;
        }
        (async () => {
            await MixPanelWrapper.TrackPageView({
                page: post?.title
            });
        })();
    }, [post, INIT_MIX_PANEL]);

    return (
        !!post?.databaseId && isCustomerLogged &&
        <ResourceVisibilityValidator redirect={true} postID={post?.databaseId}>
            <Route
                    exact
                    key={post?.databaseId}
                    path={`/${currentType}/:slug`}
                    render={
                () => (
                    <>
                        <SeoHelmet
                            title={post?.SeoMetadata?.metaTitle || post?.title}
                            description={ post?.SeoMetadata?.metaDescription ?? ''}
                            canonical={post?.link} />
                        {getComponentByType()}
                    </>
                )
            }/>
	    </ResourceVisibilityValidator>
    );
};

export default useSinglePostRouter;
