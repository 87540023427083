import React, {useMemo, useRef, useState} from 'react';
import Swiper from 'react-id-swiper';
import {Link} from 'react-router-dom';
import Heading from 'js/components/Common/Heading/Heading';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Container from 'js/components/Common/Container/Container';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import fetchData from './fetchData';
import parse from 'html-react-parser';
import {slides, params} from './helpers';
import classes from './SubjectMatterList.module.pcss';

interface Props {
	pageId: number
}
export type SubjectMatterListType = {
	name: string,
	uri: string,
	id: string
}
const SubjectMatterList = ({ pageId }: Props) => {
	const swiperRef = useRef(null);
	const [title, setTitle] = useState<string>('');
	const [description, setDescription] = useState<string>('');
	const [itemsPerSlide, setItemsPerSlide] = useState<number>(18);
	const [subjectMatterList, setSubjectMatterList] = useState<Array<SubjectMatterListType>>(null);

	const { isLoading, error } = useFetchData({
	    query: `${fetchData(pageId)}`,
	    condition: !!pageId,
	    updateData: (data: any):void => {
			setTitle(data?.product?.ProductGlobal?.subjectMatterTitleProduct);
			setDescription(data?.productLegacy?.ProductLegacyFields?.subjectMatterDescription);
			setItemsPerSlide(data?.productLegacy?.ProductLegacyFields?.subjectMatterItemsPerSlide);
			setSubjectMatterList(data?.productLegacyList?.nodes?.reverse());
		}
	});

	const list:SubjectMatterListType[][] = useMemo(() =>
		slides(subjectMatterList, itemsPerSlide), [subjectMatterList, itemsPerSlide]);

	return (
		<DataValidator data={!isLoading && !error && title && description && subjectMatterList}>
			<section className={classes.SubjectMatterList}>
				<Container additionalClass={classes.Wrap}>
					<div className={classes.Info}>
						<Heading text={title} type={'h2'}/>
						{parse(description || '', {trim: true})}
					</div>
					<div className={classes.SliderWrap}>
						<div ref={swiperRef} className={classes.Slider}>
							<Swiper {...params}>
								{list?.map((slide, index) => (
									<div className={`${classes.Slide} swiper-slide`} key={index}>
										{slide?.map((item: { id: React.Key; uri: any; name: any; }) => (
											<div className={classes.Item} key={item.id}>
												<Link to={`/${item.uri}`}>
													<span>{parse(item?.name || '')}</span>
													<span>{parse(item?.name || '')}</span>
												</Link>
											</div>
										))}
									</div>
								))}
							</Swiper>
						</div>
						<div className={`${classes.Bullets}`}></div>
					</div>
				</Container>
			</section>
		</DataValidator>
	);
};

export default SubjectMatterList;
