import {useState, useEffect, useContext, useRef, useMemo} from 'react';
import {CommonContext} from 'js/context/CommonContext/CommonContext';
import axios from 'axios';

interface Props {
	query?: string | Function,
	condition: boolean,
	updateData: Function,
	path?: string,
	responseVar?: string,
	additionalDep?: any
}

const useFetchData = ({
  	query,
  	condition,
  	updateData,
  	path = '',
  	responseVar = 'data',
	additionalDep = null
}: Props) => {
	const {apiPath} = useContext(CommonContext);
	const [resultData, setResultData] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const inputPath = useMemo(() => path || `${apiPath}/graphql`, [path, apiPath]);
	const updateDataRef = useRef(updateData);

	useEffect(() => {
		if (!condition) {
			setIsLoading(false);
			return;
		}

		let isCanceled = false;
		setIsLoading(true);
			axios({
				method: 'post',
				url: inputPath,
				data: {
					query: `${query}`
				},
				headers: {
					'Content-Type': 'application/json'
				}
			})
				.then( (response) => {
				if (isCanceled) {
					setIsLoading(false);
					return;
				}
				const {data} = response || {};
				if (!data) {
					setIsLoading(false);
					return;
				}
				const result = responseVar ? data[responseVar] : data;
				setIsLoading(false);
				setResultData(result);
				return true;
			})
			.catch( (er) => {
				setError(er);
				console.log(er);
				setIsLoading(false);
				return true;
			});

		return () => {
			isCanceled = true;
		};
	}, [condition, inputPath, query, responseVar, additionalDep]);


	useEffect(() => {
		if (resultData) {
			updateDataRef?.current(resultData);
		}
	}, [resultData, updateDataRef]);

	return { isLoading, error };
};

export default useFetchData;

