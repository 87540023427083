import React from 'react';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import classes from './MissionStatementItems.module.pcss';
import MissionStatementItem from '../MissionStatementItem/MissionStatementItem';
import { MissionStatementDataInterface } from '../MissionStatementBlock/MissionStatementDataInterface';

export interface Props {
	items: MissionStatementDataInterface[]
}

const MissionStatementItems = ({ items }: Props) => (
	<DataValidator data={items}>
		<div className={classes.MissionStatementItems}>
			{
				items ? items.map(
					(item, index) => <MissionStatementItem key={index} item={item} index={index} />
				) : null
			}
		</div>
	</DataValidator>
);

export default MissionStatementItems;
