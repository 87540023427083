export default pageId => `
{
      page(id: "${pageId}", idType: DATABASE_ID) {
        AboutUs {
            headingLocation
            subtitleLocation
            locations {
              title
              subtitle
              image {
                altText
                    mediaItemUrl
                    mediaDetails {
                      sizes {
                        name
                        sourceUrl
                      }
                    }
              }
              background {
                mediaItemUrl
              }
            }
          }
      }
    }
`;
