import React from 'react';
import parse from 'html-react-parser';

import classes from './RequiredMessage.module.pcss';

export interface Props {
    text: string
}

const RequiredMessage = ({text}: Props) => <span className={classes.RequiredMessage}>{parse(text || '')}</span>;

export default RequiredMessage;
