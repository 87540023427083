import React, { useState } from 'react';
import Heading from 'js/components/Common/Heading/Heading';
import Container from 'js/components/Common/Container/Container';
import SubHeading from 'js/components/Common/SubHeading/SubHeading';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import fetchData from './fetchData';
import classes from './Location.module.pcss';
import LocationItems from '../LocationItems/LocationItems';
import { LocationDataInterface } from './LocationDataInterface';
import useFetchData from 'js/hooks/useFetchData/useFetchData';

const Location = ({ pageId }) => {
	const [LocationTitle, setLocationTitle] = useState<string>('');
	const [LocationSubTitle, setLocationSubTitle] = useState<string>('');
	const [LocationData, setLocationData] = useState<LocationDataInterface[]>([]);

	const {isLoading, error} = useFetchData({
		query: `${fetchData(pageId)}`,
		condition: !!pageId,
		updateData: (data) => {
			setLocationData(data?.page.AboutUs.locations);
			setLocationTitle(data?.page.AboutUs.headingLocation);
			setLocationSubTitle(data?.page.AboutUs.subtitleLocation);
		}
	});

	return (
		<DataValidator data={!!LocationData && !isLoading && !error}>
			<section className={classes.Location}>
				<Container>
					<Heading text={LocationTitle} />
					<SubHeading text={LocationSubTitle} />
				</Container>
				<LocationItems items={LocationData} />
			</section>
		</DataValidator>
	);
};

export default Location;
