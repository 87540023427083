import React from 'react';
import parse from 'html-react-parser';

import {ItemsType} from './../Sidebar';
import classes from './SidebarItem.module.pcss';

interface Props {
	data: ItemsType,
	activeItemId: number,
	itemClickHandler: Function

}
const SidebarItem = (
	{
		data,
		activeItemId,
		itemClickHandler
	}: Props) => {
	const {name, id} = data || {};

    return (
        <div
			onClick={() => itemClickHandler(id, name)}
			className={`${classes.SidebarItem} ${activeItemId === id ? classes.Active : ''}`}>{parse(name || '')}</div>
    );
};

export default SidebarItem;
