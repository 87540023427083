import React, {useContext, useCallback} from 'react';
import {useLocation} from 'react-router-dom';
import UserProfile from './UserProfile/UserProfile';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';
import Notifications from 'js/components/Notifications/Notifications';
import { CommonContext } from 'js/context/CommonContext/CommonContext';
import SearchButton from 'js/components/Common/SearchBlock/SearchButton/SearchButton';
import NavigationMenu from 'js/components/Common/Navigation/NavigationMenu/NavigationMenu';
import {observer} from 'mobx-react';

import classes from './Header.module.pcss';
import SearchStore from 'js/store/SearchStore';

const HeaderPostLogin = () => {
	const {showSearch} = SearchStore;
	const {pathname, search} = useLocation();
	const {currentUser} = useContext(CommonContext);

	const logOut = useCallback(async () => {
		const symbol = search ? '&' : '?';
		document.location.href = `${document.location.href}${symbol}logout`;
		await MixPanelWrapper.TrackEvent(
			'The Logout button is clicked',
			{
				category: 'Login'
			});
	}, [search]);

	const searchIconClickHandler = useCallback(() => {
		SearchStore.setShowSearch(!showSearch);
	}, [showSearch]);

	return (
		<>
			<NavigationMenu location={'MAIN_MENU'} isMobile={true} levels={2} />
			<div className={classes.HeaderUserWrapper}>
				{pathname !== '/homepage' && <SearchButton handler={searchIconClickHandler} />}
				<Notifications offsetTop={4} />
				<UserProfile offsetTop={15} userData={currentUser} logOutCallback={logOut}/>
			</div>
		</>
	);
};

export default observer(HeaderPostLogin);
