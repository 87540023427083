const fetchData = pageId => (
    `
    {
        page(id: "${pageId}", idType: DATABASE_ID) {
          OnBoarding {
                headingWelcome
                subheadingWelcome
                welcomeImage {
                  mediaItemUrl
                }
          }
        }
    }
        
    `
);

export default fetchData;
