import thumbnailFragment from 'js/graphqlFragments/ThumbnailFragment';
import imageFragment from 'js/graphqlFragments/ImageFragment';

const carouselFetchData = pageId => `
    	{
            page(id: "${pageId}", idType: DATABASE_ID) {
              PreLoginPage {
                headingSessionsCarousel
                buttonTextSessionsCarousel
                sessionsCarousel {
                  ... on MeetingsSingle {
                    title
                    id
                    meetingID
                    ResourceVisibility {
                        resourceVisibility
                    },
                    Meetings {
                      shortDescription
                      startDate
                      eventLink
                      duration
                      eventType
                      hosts {
                        ... on TeamSingle {
                          title
                          id
                          Team {
                            positionEvents
                          }
                          featuredImage {
                              ...ImageFragmentHost
                          }
                        }
                      }
                    }
                    ...ThumbnailFragment
                  }
                }
              }
            }
          }
          ${thumbnailFragment('MeetingsSingle')}
          ${imageFragment('Host')}
    `;

export default carouselFetchData;
