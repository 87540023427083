import React, {useCallback, useContext, useEffect, useState} from 'react';
import parse from 'html-react-parser';
import {useCookies, withCookies} from 'react-cookie';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Container from 'js/components/Common/Container/Container';
import {CommonContext} from 'js/context/CommonContext/CommonContext';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import fetchData from './fetchData';
import classes from './AnnouncementBar.module.pcss';

const AnnouncementBar = () => {
    const [cookies, setCookie, removeCookie] = useCookies([]);
    const {REACT_APP_USER_AFTER_LOGIN_COOKIE, REACT_APP_ANN_BAR_COOKIE} = process.env || {};
    const {isCustomerLogged, setIsAnnouncementBar} = useContext(CommonContext);

    const [info, setInfo] = useState<string>('');
    const [version, setVersion] = useState<number>(null);
    const [isSticky, setIsSticky] = useState<boolean>(false);
    const [isExpired, setIsExpired] = useState<boolean>(false);
    const [barCookies, setBarCookies] = useState<string>('');
    const [loginCookies, setLoginCookies] = useState<boolean | undefined>(false);
    const [hideBar, setHideBar] = useState<boolean>(false);

    useEffect(() => {
        setBarCookies(cookies[REACT_APP_ANN_BAR_COOKIE]);
        setLoginCookies(cookies[REACT_APP_USER_AFTER_LOGIN_COOKIE]);
        removeCookie(REACT_APP_USER_AFTER_LOGIN_COOKIE);
    }, [cookies, REACT_APP_ANN_BAR_COOKIE, REACT_APP_USER_AFTER_LOGIN_COOKIE, removeCookie]);

    const { isLoading, error } = useFetchData({
        query: `${fetchData}`,
        condition: isCustomerLogged === true,
        updateData: (data) => {
            const result = data?.announcementBar?.AnnouncementBar;
            if (!result) {
                return;
            }
            setInfo(result?.announcementBody);
            setIsSticky(result?.stickyAnnouncement);
            setVersion(result?.announcementVersion);
            setIsExpired(result?.annExpiryDateInUtc === 'true');
            setIsAnnouncementBar(true);
        }
    });

    const closeClickHandler = useCallback(() => {
        setHideBar(true);
        setIsAnnouncementBar(false);
        if (!isSticky) {
            setCookie(REACT_APP_ANN_BAR_COOKIE, version,
                {
                    path: '/',
                    domain: `.${location.hostname}`,
                    maxAge: 315360000
                }
            );
        }
    }, [REACT_APP_ANN_BAR_COOKIE, isSticky, setCookie, setIsAnnouncementBar, version]);

    if (
        isExpired ||
        !loginCookies ||
        ((parseInt(barCookies) === version) && !isSticky) ||
        isCustomerLogged !== true
    ) {
        return <></>;
    }

    return (
        <DataValidator data={!!info && !isLoading && !error}>
            <div
                 className={`${classes.AnnouncementBar} ${hideBar ? classes.Hide : ''}`}>
                <Container>
                    <div>{parse(info || '')}</div>
                </Container>
                <span onClick={closeClickHandler} className={classes.Cross}/>
            </div>
        </DataValidator>
    );
};

export default withCookies(AnnouncementBar);
