import {PageType} from './TopSection';

export type FetchResultType = {
    title: string,
    parent: {
        uri: string,
        name?: string,
        title?: string
    }
}
const productFetchData = (pageId: any):string => `
{
    productLegacy(id: "${pageId}", idType: DATABASE_ID) {
        name
        parent {
            uri
            name
      }
    }
  }
`;

const pageFetchData = (pageId: any):string => `
{
    page(id: "${pageId}", idType: DATABASE_ID) {
        title
    }
  }
`;

export const getFetchResult = (data: any, type: PageType): FetchResultType => {
    switch (type) {
        case 'product':  {
            const {name, parent} = data?.productLegacy;
            return {
                title: name,
                parent
            };
        }
        case 'page':  {
            const {title} = data?.page;
            return {
                title,
                parent: null
            };
        }
        default: return {
            title: '',
            parent: null
        };
    }
};

export const fetchData = (type: PageType, pageId: number):string => {
    switch (type) {
        case 'product':
            return productFetchData(pageId);
        case 'page':
            return pageFetchData(pageId);
        default:
            return '';
    }
};
export default fetchData;
