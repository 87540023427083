import React, {useContext} from 'react';
import {ToastMessagesContext} from 'js/context/ToastMessagesContext/ToastMessagesContext';

import classes from './ToastMessage.module.pcss';

const ToastMessageContainer = (props) => {
    const {toastContainerIsActive} = useContext(ToastMessagesContext);
    return (
        <div className={`${classes.ToastMessageContainer} ${toastContainerIsActive ? classes.ActiveContainer : ''}`}>
            {props.children}
        </div>
    );
};

export default ToastMessageContainer;
