import React, {useCallback, useContext, useEffect, useState} from 'react';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';
import {NotificationsContext} from 'js/context/NotificationsContext/NotificationsContext';

import Bell from './Bell/Bell';
import Frame from './Frame/Frame';
import notificationsFetch from './NotificationsFetchData';

interface Props {
    offsetTop: number
}

const Notifications = ({offsetTop}: Props) => {
    const {
        notifications,
        customNotifications,
        setNotifications,
        setCustomNotifications,
        frameOpened,
        setFrameOpened,
        updateTrigger
    } = useContext(NotificationsContext);
    const [count, setCount] = useState<number>(0);
    const [joinEventButton, setJoinEventButton] = useState<string>('');
    const [viewEventButton, setViewEventButton] = useState<string>('');
    const [registerEventButton, setRegisterEventButton] = useState<string>('');

    const { isLoading, error } = useFetchData({
        query: notificationsFetch(),
        condition: true,
        additionalDep: updateTrigger,
        updateData: (data) => {
            const agNotificationsData = data?.notifications?.getNotificationsForCurrentUser;
            const customNotificationsData = data?.notifications?.getCustomNotificationsForCurrentUser;
            const options = data?.themeGeneralSettings?.Options;

            if (!agNotificationsData && !customNotificationsData) {
                return;
            }

            setJoinEventButton(options?.joinEventButton);
            setViewEventButton(options?.viewEventButton);
            setRegisterEventButton(options?.registerEventButton);

            setNotifications(agNotificationsData);
            setCustomNotifications(customNotificationsData);
        }
    });

    const bellClickCallback = useCallback(async () => {
        if (!customNotifications?.length && !notifications?.length) {
            setFrameOpened(false);
            return;
        }
        await MixPanelWrapper.TrackEvent(
            'The Notification bell is clicked',
            {
                category: 'Notifications'
            });
        const availableCustomNotification = customNotifications.find(element => element?.status !== 'removed');
        const availableAGNotifications = notifications.find(element => element?.status !== 'removed');
        if (!availableCustomNotification && !availableAGNotifications) {
            setFrameOpened(false);
            return;
        }
        setFrameOpened(!frameOpened);
    }, [customNotifications, frameOpened, notifications, setFrameOpened]);

    const closeFrame = useCallback((e) => {
        if (!e.target.closest('[data-notification]')) {
            setFrameOpened(false);
        }
    }, [setFrameOpened]);

    useEffect(() => {
        window.addEventListener('click', closeFrame);
        window.addEventListener('scroll', () => setFrameOpened(false));
        return () => {
            window.removeEventListener('click', closeFrame);
            window.removeEventListener('scroll', () => setFrameOpened(false));
        };
    }, [closeFrame, setFrameOpened]);

    useEffect(() => {
        const notificationsCount = notifications?.filter(item => item?.status === 'unread')?.length;
        const customNotificationsCount = customNotifications?.filter(item => item?.status === 'unread')?.length;
        setCount(notificationsCount + customNotificationsCount);
    }, [customNotifications, notifications]);
    return (
        <DataValidator data={!isLoading && !error}>
            <div data-notification={true}>
                <Bell count={count} click={bellClickCallback} />
                <DataValidator data={notifications?.length > 0 || customNotifications?.length > 0}>
                    <Frame
                        status={frameOpened}
                        closeFrame={() => setFrameOpened(false)}
                        offsetTop={offsetTop}
                        eventButtons={{
                            joinEventButton,
                            viewEventButton,
                            registerEventButton
                        }} />
                </DataValidator>
            </div>
        </DataValidator>
    );
};
export default Notifications;

