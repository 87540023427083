import React, {CSSProperties} from 'react';
import Downloads from 'js/components/Common/ActivityCounter/Downloads';
import PublishingDate from 'js/components/Common/PublishingDate/PublishingDate';

import classes from './ActivityAndPublishing.module.pcss';

export interface Props {
	count: number,
	publishingDate: string,
	style?: CSSProperties
}

const ActivityAndPublishing = ({
	count,
   	publishingDate,
   	style
}: Props) => (
	<span className={classes.ActivityAndPublishing} style={style}>
		<Downloads count={count}/>
		<PublishingDate data={publishingDate}/>
	</span>
);

export default ActivityAndPublishing;
