import React, {useContext, useState, useRef} from 'react';
import Button from 'js/components/Common/Button/Button';
import Form from 'js/components/Common/FormComponents/Form/Form';
import Input from 'js/components/Common/FormComponents/Input/Input';
import TextArea from 'js/components/Common/FormComponents/TextArea/TextArea';
import ReCaptcha from 'js/components/Common/FormComponents/ReCaptcha/ReCaptcha';
import {ToastMessagesContext} from 'js/context/ToastMessagesContext/ToastMessagesContext';
import RequiredMessage from 'js/components/Common/FormComponents/RequiredMessage/RequiredMessage';

import classes from './ContactForm.module.pcss';
import useFetchCallback from 'js/hooks/useFetchCallback/useFetchCallback';
import fetchData from './fetchData';

const ContactForm = () => {
	const name = 'ContactFormName';
	const email = 'ContactFormEmail';
	const subject = 'ContactFormSubject';
	const message = 'ContactFormMessage';
	const recaptcha = 'ReCaptcha';

	const recaptchaRef = useRef(null);
	const {addToastMessage} = useContext(ToastMessagesContext);
	const [formIsValid, setFormIsValid] = useState<boolean>(false);
	const [isSpinner, setIsSpinner] = useState<boolean>(false);

	const submitHandler = useFetchCallback({
		updateData: data => data?.contactForm?.contactFormResult,
		query: inputData => `${fetchData(inputData, name, email, subject, message, recaptcha)}`,
		onStart: () => setIsSpinner(true),
		onSuccess: () => {
			recaptchaRef.current.reset();
			setFormIsValid(false);
			setIsSpinner(false);
			addToastMessage({
				type: 'success',
				key: 'contactFormToasts'
			});
		},
		onFailure: () => {
			recaptchaRef.current.reset();
			setFormIsValid(false);
			setIsSpinner(false);
			addToastMessage({
				type: 'error',
				key: 'contactFormToasts'
			});
		},
		onError: () => {
			setIsSpinner(false);
			addToastMessage({
				type: 'error',
				key: 'contactFormToasts'
			});
		}
	});

	return (
		<div className={classes.ContactForm}>
			<Form onSubmit={submitHandler} resetAfterSubmit={true} onValidForm={setFormIsValid}>
				<RequiredMessage text={'*All fields are required'} />
				<Input
					key={name}
					type={'text'}
					name={name}
					id={name}
					maxLength={25}
					autoComplete={'name'}
					withMargin={true}
					floatingLabel={'Name'}
					validation={{
						rules: [
							{ type: 'required', message: 'Required Field' },
							{ type: 'name', message: 'Invalid Name' }
						]
					}}
				/>
				<Input
					key={email}
					type={'text'}
					name={email}
					id={email}
					maxLength={50}
					autoComplete={'email'}
					withMargin={true}
					floatingLabel={'Email'}
					validation={{
						rules: [
							{ type: 'required', message: 'Required Field' },
							{ type: 'email', message: 'Invalid Email' }
						]
					}}
				/>
				<Input
					key={subject}
					type={'text'}
					name={subject}
					id={subject}
					maxLength={50}
					autoComplete={'off'}
					floatingLabel={'Subject'}
					withMargin={true}
					validation={{
						rules: [
							{ type: 'required', message: 'Required Field' }
						]
					}}
				/>

				<TextArea
					type={'text'}
					key={message}
					id={message}
					withMargin={true}
					floatingLabel={'Message us here...'}
					height={160}
					name={message}
					maxLength={10000}
					validation={{
						rules: [
							{ type: 'required', message: 'Required Field' }
						]
					}}
				/>

				<ReCaptcha
					type={recaptcha}
					recaptchaRef={recaptchaRef}
					name={recaptcha}
					size={'normal'}
					validation={{
						rules: [
							{ type: 'required', message: 'Required Field' }
						]
					}}/>

				<div className={classes.ButtonWrap}>
					<Button withSpinner={isSpinner} text={'Send'} type={'button'} disabled={!formIsValid} />
				</div>
			</Form>
		</div>
	);
};

export default ContactForm;

