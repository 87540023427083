import React, {useState} from 'react';
import parse from 'html-react-parser';
import useFetchCallback from 'js/hooks/useFetchCallback/useFetchCallback';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import fetchData from './fetchData';
import classes from './Manual.module.pcss';

export interface Props {
	id: number,
	title: string,
	url: string,
	buttonText: string
}

const Manual = ({id, title, url, buttonText}: Props) => {
	const [downloadStatus, setDownloadStatus] = useState<'loading' | 'noChanges'>();

	const fileDownloadHandler = useFetchCallback({
		updateData: data => data?.manualsSingle?.updateDownloads,
		query: () => `${fetchData(id)}`,
		onStart: () => {
			setDownloadStatus('loading');
			const element = document.createElement('a');
			element.setAttribute('href', url);
			const ext = url.split('.').pop();
			element.setAttribute('download', `${title}.${ext}`);

			element.style.display = 'block';
			element.click();

		},
		onSuccess: () => setDownloadStatus('noChanges'),
		onFailure: () => {
			console.error('Error while downloading');
		},
		onError: (e) => {
			console.error(e);
		}
	});

	return (
        <DataValidator data={buttonText && url}>
			<div className={classes.Manual}>
				<strong>{parse(title || '')}</strong>
				<span onClick={fileDownloadHandler}>{downloadStatus === 'loading' ? 'Loading...' : buttonText}</span>
			</div>
		</DataValidator>
    );
};

export default Manual;
