import React, {useMemo} from 'react';
import moment from 'moment';
import Moment from 'react-moment';

export interface Props {
	date: string, // UTC format must be used
	format?: string,
	className?: string,
	fromNow?: boolean,
	reminder?: 'tomorrow' | 'hour' | ''
}

const DateByTimezone = ({ date, className = '', format, fromNow = false, reminder = '' }: Props) => {
	const localDate = useMemo(() => moment(new Date(date)).utc(true), [date]);

	const dateVal = useMemo(() => {
		let local = localDate?.format();
		const now = moment();
		let result = '';

		if (reminder === 'tomorrow') {
			local = moment().startOf('day')
				.format();
		}

		let seconds = now.diff(local, 'seconds');
		let minutes = now.diff(local, 'minutes');
		const hours = now.diff(local, 'hours');
		const days = now.diff(local, 'days');
		const weeks = now.diff(local, 'weeks');
		const months = now.diff(local, 'months');

		if (reminder === 'hour') {

			if (Math.abs(minutes) < 59 && hours === 0) {
				minutes = 60 + minutes - 1;

			} else if (seconds && Math.abs(minutes) >= 59) {
				seconds = (60 * 60) + seconds;
			}
		}

		if (months) {
			result += months + (months === 1 ? ' month ' : ' months ');
		} else if (weeks && months === 0) {
			result += weeks + (weeks === 1 ? ' week ' : ' weeks ');
		} else if (days && weeks === 0) {
			result += days + (days === 1 ? ' day' : ' days ');
		} else if (hours && days === 0) {
			result += hours + (hours === 1 ? ' hour ' : ' hours ');
		} else if (minutes && (minutes !== -59) && hours === 0) {
			result += minutes + (minutes === 1 ? ' minute ' : ' minutes ');
		} else if (seconds && (minutes === 0 || minutes <= 0)) {
			result += seconds + (seconds === 1 ? ' second ' : ' seconds ');
		}
		return result;
	}, [localDate, reminder]);

	if (!fromNow) {
		return (
			<strong className={`${className}`}>
				<Moment local format={format}>{localDate?.format()}</Moment>
			</strong>
		);
	} else {
		return (
			<strong className={`${className}`}>
				<time>{dateVal} ago</time>
			</strong>
		);
	}

};

export default DateByTimezone;
