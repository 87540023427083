const fetchData = pageId => `
{
    page(id: "${pageId}", idType: DATABASE_ID) {
      OnBoarding {
            madeEasyInfo
            madeEasyFlow {
              abbreviation
              step
              title
              subtitle
            }
      }
    }
}
`;

export default fetchData;
