import React, {useMemo} from 'react';
import Heading from 'js/components/Common/Heading/Heading';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import classes from './TopicItem.module.pcss';
import TopicItemList from './TopicItemList/TopicItemList';
import {TopicDataInterface} from '../TopicBlock/TopicDataInterface';

export interface Props {
	item?: TopicDataInterface
}

const TopicItem = ({ item }: Props) => {
	const backgroundStyle = useMemo(() => (
		{
			backgroundImage: `url('${item?.images?.background?.mediaItemUrl}')`
		}
	), [item]);
	const { picture } = item?.images;
	const { info } = item;
	return (
			<div className={classes.TopicItem}>
				<div className={classes.TopicItemInfo}>
					<div className={classes.TopicItemInfoWrap}>
						<Heading text={info.title} type={'h3'} />
						<p>{info?.text}</p>
						<DataValidator data={info.list}>
							<TopicItemList data={info.list}/>
						</DataValidator>
					</div>
				</div>
				<div className={classes.TopicItemImages} style={backgroundStyle}>
					{
						picture ?
								<figure
										style={{
											width: picture?.mediaDetails?.width
										}}
										className={classes.TopicItemImagesPicture}>
									<img
											src={picture?.mediaItemUrl}
											alt={picture?.altText}/>
								</figure> : null
					}
				</div>
		</div>
	);
};

export default TopicItem;
