import React, {useCallback} from 'react';
import Button from 'js/components/Common/Button/Button';

import classes from './PromoBoxItem.module.pcss';
import Video from './PromoBoxMedia/PromoBoxMediaTypes/Video';
import StaticImage from './PromoBoxMedia/PromoBoxMediaTypes/StaticImage';
import AnimatedImage from './PromoBoxMedia/PromoBoxMediaTypes/AniamtedImage';
import { PromoDataBoxInterface } from '../PromoBoxBlock/PromoBoxDataInterface';

export interface Props {
	item: PromoDataBoxInterface,
	index?: number,
	slider: object | null
}

const PromoBoxItem = ({ item, slider }: Props) => {
	const getSelectedMediaBlock = useCallback((field) => {
		switch (field) {
			case 'animated_image' :
				return <AnimatedImage item={item?.animatedImage} />;
			case 'static_image' :
				return <StaticImage item={item} />;
			case 'video' :
				if (item?.videoFile && item?.videoFile.mediaItemUrl) {
					return <Video
						item={item}
						slider={slider} />;
				} else {
					return <AnimatedImage item={item?.animatedImage} />;
				}
			default :
				return <AnimatedImage item={item?.animatedImage} />;
		}
	}, [item, slider]);

	const divideHeadingByParts = useCallback((headingParts: string) => {
		if (!headingParts) {
			return '';
		}
		const { heading } = item;
		const lastElement = heading?.split(' ')?.pop();
		return heading?.split(' ')?.map((stringPart, headingIndex) => {
			const space = stringPart !== lastElement ? '\xa0' : '';
			return (
				<div key={headingIndex}>
						<span>
							{stringPart + space}
						</span>
				</div>
			);
		});
	}, [item]);

	return (
		<div className={classes.PromoBoxItem}>
			<div className={classes.PromoBoxItemInfo}>
				<h2 className={classes.Heading}
				>{item?.subheading}
					<div className="animatedHeading"
					     style={{ color: `${item?.headingColor}` }}> {divideHeadingByParts(item?.heading)}</div>
				</h2>
				<Button
					type="a"
					buttonType="ButtonColored"
					text={item?.button ? item?.button?.title : ''}
					link={item?.button ? item?.button?.url : ''}
					target={item?.button ? item?.button?.target : ''}
				/>
			</div>
			<div className={`${classes.PromoBoxItemMedia} PromoBoxItemMedia`}>
				{getSelectedMediaBlock(item?.contentType)}
			</div>
		</div>
	);
};

export default PromoBoxItem;
