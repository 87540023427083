import React, {useCallback, useEffect, useState, useRef} from 'react';
import classes from './FilterSelect.module.pcss';
import FilterSelectItem from './FilterSelectItem/FilterSelectItem';
import {FilterSelectInterface, FilterPriceRange} from '../FilterDataInterface';
import Input from 'js/components/Common/FormComponents/Input/Input';
import rangeValidateValue from '../Helpers/rangeValidateValue';
import sumObjectsByKey from '../Helpers/sumObjectsByKey';

const FilterSelect = ({
     name, list,
     filterDataType, columns,
     width, opened, click, counts = {},
     setQuery, setShowLoader,
     resetFilter, setResetFilter, closeSelect
}: FilterSelectInterface) => {
    const currentSelect = useRef(null);
    const [activeSelect, setActiveSelect] = useState<boolean>(false);
    const [selectQuery, setSelectQuery] = useState<Array<string>>([]);
    const [rangeValueQuery, setRangeValueQuery] = useState<string>('');
    const [throttling, setThrottling] = useState<any>(0);
    const [rangeValue, setRangeValue] = useState<FilterPriceRange>({min: '', max: ''});
    const setSelectAdditionalStyles = () => ({columnCount: columns, width: `${width}px`});

    const closeAllSelects = useCallback((e) => {
        if (!e.target.closest(`[data-courses-select].${classes.Opened}`)) {
            closeSelect();
        }
    }, [closeSelect]);
    useEffect(() => {
        window.addEventListener('click', closeAllSelects);
        return () => {
            window.removeEventListener('click', closeAllSelects);
        };
    }, [closeAllSelects]);

    const resetSelectCallback = useCallback(() => {
        const selectNode:Element = currentSelect?.current;
        setQuery(filterDataType, '');
        selectQuery.forEach((item) => {
            const el:NodeListOf<any> = selectNode.querySelectorAll(`input[name="${item}"]`);
            el[0].checked = false;
        });
        setSelectQuery([]);
        setRangeValue({min: '', max: ''});
    }, [selectQuery, setQuery, filterDataType]);

    useEffect(() => {
        if (!selectQuery?.length && (!rangeValue.min && !rangeValue.max)) {
            return setActiveSelect(false);
        }
        return setActiveSelect(true);
    }, [filterDataType, selectQuery, rangeValue]);

    const resetButtonHandler = useCallback(() => {
        resetSelectCallback();
    }, [resetSelectCallback]);

    const selectDataHandler = useCallback((e) => {
        setShowLoader(true);
        const {checked} = e.target;
        const targetName = e.target.name;
        const selectQueryCloned = [...selectQuery];
        if (checked) {
            setSelectQuery([...selectQueryCloned, targetName]);
        } else {
            const index = selectQueryCloned.indexOf(targetName);
            if (index > -1) {
                selectQueryCloned.splice(index, 1);
                setSelectQuery(selectQueryCloned);
            }
        }
    }, [selectQuery, setShowLoader]);

    const setRangeValueQueryCallback = useCallback((value) => {
        const min = value?.min && value?.max ? value?.min : '';
        const max = value?.min && value?.max ? value?.max : '';
        if (value.min === '' || value?.max === '') {
            setRangeValueQuery('');
            clearTimeout(throttling);
            return;
        }

        if (throttling) {
            clearTimeout(throttling);
        }

        setThrottling(
            setTimeout(() => {
                const dash = min && max ? '-' : '';
                const result = `${min}${dash}${max}`;
                setRangeValueQuery(result);
            }, 800)
        );

    }, [throttling]);

    const priceRangeMinHandler = useCallback((e) => {
        const priceRangeMinData = rangeValidateValue(e, 'min', rangeValue);
        if (!priceRangeMinData) {
            return;
        }
        setRangeValue(priceRangeMinData);
        setRangeValueQueryCallback(priceRangeMinData);
    }, [rangeValue, setRangeValue, setRangeValueQueryCallback]);

    const priceRangeMaxHandler = useCallback((e) => {
        const priceRangeMaxData = rangeValidateValue(e, 'max', rangeValue);
        if (!priceRangeMaxData) {
            return;
        }
        setRangeValue(priceRangeMaxData);
        setRangeValueQueryCallback(priceRangeMaxData);
    }, [rangeValue, setRangeValue, setRangeValueQueryCallback]);

    const setLastSelectElement = () => {
        if (filterDataType === 'price') {
            return (
                <div className={classes.FilterSelectRange}>
                    <span>Enter custom price range</span>
                    <div className={classes.FilterSelectRangeWrap}>
                        <Input
                            type="text"
                            value={ rangeValue?.min || ''}
                            placeholder={'£ Min'}
                            id={'PriceRangeMin'}
                            maxLength={7}
                            onChange={priceRangeMinHandler} />
                        <Input
                            type="text"
                            value={rangeValue?.max || ''}
                            placeholder={'£ Max'}
                            id={'PriceRangeMax'}
                            maxLength={7}
                            onChange={priceRangeMaxHandler} />
                    </div>
                </div>
            );
        }
        return (
            <li className={classes.FilterSelectReset}>
                <Input
                    type="reset"
                    name={'Clear All'}
                    click={resetButtonHandler} />
            </li>
        );
    };

    useEffect(() => {

        let isCancelled = false;

        if (isCancelled) { return; }

        let resultQuery = [...selectQuery];

        if (filterDataType === 'price') {
            resultQuery = rangeValueQuery ? [...selectQuery, rangeValueQuery] : resultQuery;
        }
        setQuery(filterDataType, resultQuery?.join(','));

        return () => {
            isCancelled = true;
        };
    }, [
        rangeValueQuery,
        setQuery,
        filterDataType,
        selectQuery
    ]);

    useEffect(() => {
        if (resetFilter) {
            resetSelectCallback();
            setResetFilter(false);
        }
    }, [resetFilter, resetSelectCallback, setResetFilter]);

    return (
        <div
            ref={currentSelect}
            data-courses-select={true}
            id={`${filterDataType}FilterSelect`}
            className={`${classes.FilterSelect} ${activeSelect ? classes.Active : ''} ${opened ? classes.Opened : ''}`}>
            <span
                className={classes.FilterSelectName}
                onClick={() => click(filterDataType)}>{name}</span>
            <ul style={setSelectAdditionalStyles()} className={`${classes.FilterSelectItems}`}>
                {list?.map((item, index) => {
                    const {priceCountsForEssential, priceCountsForPopular, priceCountsForPremium} = counts || {};
                    return (
                        <FilterSelectItem
                            key={index}
                            item={item}
                            countSum={
                                sumObjectsByKey(priceCountsForEssential, priceCountsForPopular, priceCountsForPremium)
                            }
                            changeHandler={selectDataHandler}/>
                    );
                })}
                    {setLastSelectElement()}
            </ul>
        </div>
    );
};

export default FilterSelect;
