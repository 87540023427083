import React from 'react';
import Image from 'js/components/Common/Image/Image';

import classes from './BrandsItem.module.pcss';

const BrandsItem = ({ item }) => {
	const { altText, mediaItemUrl, mediaDetails } = item?.featuredImage || {};
	if (item?.featuredImage) {
		return (
			<div className={classes.BrandsItem}>
				<Image
					mediaItemUrl={mediaItemUrl}
					mediaDetails={mediaDetails}
					alt={altText || item.title}
					size="270x140"
				/>
			</div>
		);
	} else {
		return null;
	}
};

export default BrandsItem;
