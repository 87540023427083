import React from 'react';

import classes from './PublishingDate.module.pcss';

export interface Props {
    data: string
}

const PublishingDate = ({data}) => (
    <span className={classes.PublishingDate}>
        Published {data}
    </span>
);

export default PublishingDate;
