import React from 'react';
import './index.css';
import App from 'App';
import * as serviceWorker from './serviceWorker.js';
import { createRoot } from 'react-dom/client';

if (!new class { x:any; }().hasOwnProperty('x')) throw new Error('Transpiler is not configured correctly');
if ('serviceWorker' in navigator) {
    // Register a service worker hosted at the root of the
    // site using the default scope.
    navigator.serviceWorker.register('/gr_sw_main.js').then(() => {
        console.log('Service worker registration succeeded');
    }, /* catch */ (error) => {
        console.log('Service worker registration failed:', error);
    });
} else {
    console.log('Service workers are not supported.');
}
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
