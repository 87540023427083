import React, {useCallback} from 'react';
import parse from 'html-react-parser';
import useDebounce from 'js/hooks/useDebounce/useDebounce';
import {slideToggle, slideUp} from 'js/helpers/toggleSlide';

import classes from './ExpandableSection.module.pcss';


interface Props {
    nodeRef: any,
    text: string,
    isOpened?: boolean,
    withTypeLabel?: boolean
}

const ExpandableSectionHeading = (
    {nodeRef, text, withTypeLabel = false, isOpened = false} : Props) => {
    const timeouts = {
        slide: 200,
        expandDebounce: 100
    };

    const expandCallback = useCallback((currentEl, currentContentBlock, expandedHeadings, expandedBlocks) => {
        expandedHeadings?.forEach((item) => {
            if ((item !== currentEl) && item.classList.contains('Active')) {
                item.classList.remove('Active');
            }
        });
        expandedBlocks?.forEach((item) => {
            if ((item !== currentContentBlock) && item.classList.contains('ShowExpandSection')) {
                slideUp(item, 'ShowExpandSection', timeouts.slide, currentEl);
            }
        });
        slideToggle(currentContentBlock, 'ShowExpandSection', timeouts.slide, currentEl);
    }, [timeouts.slide]);

    const debouncedExpandCallback = useDebounce(expandCallback, timeouts.expandDebounce);

    const expandableHeadingHandler = useCallback((e) => {
        const currentEl = e?.currentTarget;
        let currentContentBlock = e?.currentTarget?.nextSibling;
        if (withTypeLabel) {
            currentContentBlock = currentContentBlock?.nextSibling;
        }
        const expandedHeadings = nodeRef?.current?.querySelectorAll(`h4.${classes.ExpandableSectionHeading}`);
        const expandedBlocks = nodeRef?.current?.querySelectorAll(`.${classes.ExpandableSectionInfo}`);
        if (!currentContentBlock || !nodeRef) {
            return;
        }
        debouncedExpandCallback(currentEl, currentContentBlock, expandedHeadings, expandedBlocks);
    }, [nodeRef, debouncedExpandCallback, withTypeLabel]);

    return (
        <h4
            title={`${parse(text || '')}`}
            onClick={e => expandableHeadingHandler(e)}
            id={''}
            className={`${classes.ExpandableSectionHeading} ${isOpened ? 'Active' : ''}`}>
            {parse(text || '')}
        </h4>
    );
};

export default ExpandableSectionHeading;
