import {Type} from './FAQ';

export default (pageId: number, type: Type, fieldGroupName: string) =>  {
    const productTitleQuery:string = `
        product {
          ProductGlobal {
            faqTitleProduct
          }
        }
    `;
    const subjectMatterTitleQuery:string = `
        subjectMatter {
          SubjectMatterGlobal {
            faqTitleSm
          }
        }
    `;
    if (type === 'Product' || type === 'SubjectMatter') {
        return `{
        productLegacy(id: "${pageId}", idType: DATABASE_ID) {
          ProductLegacyFields {
            faqProductLegacy {
              caseHeader
              caseBody
            }
          }
        }
        ${type === 'Product' ? productTitleQuery : subjectMatterTitleQuery}
        }`;
    }

    if (type === 'Page') {
        return `{
            page(id: "${pageId}", idType: DATABASE_ID) {
              ${fieldGroupName} {
                titleFaq
                faq {
                  caseHeader
                  caseBody
                }
              }
            }
          }`;
    }

};
