import React from 'react';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import classes from './LocationItems.module.pcss';
import LocationItem from '../LocationItem/LocationItem';

const LocationItems = ({ items }) => (
	<DataValidator data={items}>
		<div className={classes.LocationItems}>
			{
				items?.map
				(
					(item, index) => Object?.values(item).find(el => el !== '' && el !== null) ?
						<LocationItem key={index} item={item} /> : null
				)
			}
		</div>
	</DataValidator>
);

export default LocationItems;
