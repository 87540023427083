import React, {useState} from 'react';
import Image from 'js/components/Common/Image/Image';
import Heading from 'js/components/Common/Heading/Heading';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Container from 'js/components/Common/Container/Container';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';
import AnimatedMouse from 'js/components/Common/AnimatedMouse/AnimatedMouse';

import fetchData from './fetchData';
import classes from './WelcomeSection.module.pcss';

const WelcomeSection = ({pageId}) => {
    const [title, setTitle] = useState<string>('');
    const [image, setImage] = useState<string>('');
    const [subTitle, setSubTitle] = useState<string>('');

    const { isLoading, error } = useFetchData({
        query: `${fetchData(pageId)}`,
        condition: !!pageId,
        updateData: (data) => {
            const result = data?.page?.OnBoarding;
            setTitle(result?.headingWelcome || '');
            setSubTitle(result?.subheadingWelcome || '');
            setImage(result?.welcomeImage?.mediaItemUrl || '');
        }
    });

    return (
        <DataValidator data={!!title && !!subTitle && !!image && !isLoading && !error }>
            <section className={classes.WelcomeSection} style={{minHeight: 'calc(100vh - 60px)'}}>
                <Container additionalClass={classes.WelcomeSectionWrap}>
                    <div className={classes.WelcomeSectionInfo}>
                        <Heading text={title} type={'h1'} />
                        <Heading text={subTitle} type={'h2'} />
                    </div>
                    <figure className={classes.WelcomeSectionImage}>
                        <Image mediaItemUrl={image} alt={title} />
                    </figure>
                    <AnimatedMouse />
                </Container>
            </section>
        </DataValidator>
    );
};

export default WelcomeSection;
