import React from 'react';

import classes from './IntroDescription.module.pcss';

const IntroDescription = ({ data }) => (
	<div className={classes.IntroDescription}>
		<p>{data}</p>
	</div>
);

export default IntroDescription;
