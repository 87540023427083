import React, {useState, useCallback} from 'react';
import useAnchor from 'js/hooks/useAnchor/useAnchor';
import useFetchData from 'js/hooks/useFetchData/useFetchData';

import DataValidator from 'js/components/Common/DataValidator/DataValidator';
import classes from './Sidebar.module.pcss';
import SidebarItem from './SidebarItem/SidebarItem';

export type ItemsType = {
    id: number,
    name: string
}

interface Props {
    pageId: number,
    anchorId: string,
    activeItemId: number,
    setLimit?: (value) => {},
    setLoading: (value) => {},
    setActiveItemId: (value) => {},
    eventSendHandler: (value) => {},
    setActiveItemName: (value) => {},
    setShowLoadAllBtn?: (value) => {},
    fetchData: (pageId: number) => {},
    getObjectData: (val) => Array<ItemsType>
}

const Sidebar = (
    {
        pageId,
        setLimit,
        anchorId,
        fetchData,
        setLoading,
        activeItemId,
        setActiveItemId,
        eventSendHandler,
        setActiveItemName,
        setShowLoadAllBtn,
        getObjectData
    }: Props) => {
    const [items, setItems] = useState<Array<ItemsType>>([]);
    const anchor = useAnchor(anchorId, 0, -150);
    const [sidebarItemsLoaded, setSidebarItemsLoaded] = useState<boolean>(false);

    useFetchData({
        query: `${fetchData(pageId)}`,
        condition: pageId && !sidebarItemsLoaded,
        updateData: (data) => {
            const result = getObjectData(data);
            if (!result || sidebarItemsLoaded) {
                return;
            }
            setItems(result);
            setActiveItemId(result[0].id);
            setActiveItemName(result[0].name);
            setSidebarItemsLoaded(true);
        }
    });

    const itemClickHandler = useCallback(async (id:number, name:string) => {
        setActiveItemId((prev:number) => {
            if (prev !== id) {
                if (setLimit) {
                    setLimit('6');
                }
                if (setShowLoadAllBtn) {
                    setShowLoadAllBtn(true);
                }
                setLoading(true);
                anchor();
            }
            return id;
        });
        eventSendHandler(name);
        setActiveItemName(name);

    }, [
        anchor,
        eventSendHandler,
        setActiveItemId,
        setActiveItemName, setLimit, setLoading, setShowLoadAllBtn]);

    return (
        <DataValidator data={items?.length > 0}>
            <div className={classes.Sidebar}>
                <div className={classes.SidebarItems}>
                    { items?.map(item =>
                        <SidebarItem
                            data={item}
                            key={item?.id}
                            activeItemId={activeItemId}
                            itemClickHandler={itemClickHandler}
                        />) }
                </div>
            </div>
        </DataValidator>
    );
};

export default Sidebar;
