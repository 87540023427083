const navigationFragment = (level: number) => {
	const getSubMenu = (): Function | string => {
		let query: string = '';
		if (level > 0) {
			for (let i = 0; i < level; i++) {
				query = `${query}fragment NavigationFragment${i === 0 ? '' : i} on MenuItem {
			    childItems {
		          nodes {
		            id
			          menuItemId
			          title
			          url
			          cssClasses
			          label
			          target
			          ${(i + 1) < level ? `...NavigationFragment${i + 1}` : ''}
		          }
		        }
				}
		`;
			}
		}
		return query;
	};
	return getSubMenu();
};

export default navigationFragment;
