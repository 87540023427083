import React from 'react';

import classes from './ErrorMessage.module.pcss';

export interface Props {
	errorMessage: string
}

const ErrorMessage = ({errorMessage}: Props) => <span className={classes.ErrorMessage}>{errorMessage}</span>;

export default ErrorMessage;
