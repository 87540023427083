import mixpanel from 'mixpanel-browser';
import {UserType} from 'js/hooks/useGetUser/useGetUser';
import parse from 'html-react-parser';
import Cookies from 'universal-cookie';


type EventDataType = {
    category: string,
    type?: string,
    value?: string,
    selected?:string,
    words?: number,
    characters?: number,
    domain?: string,
    title?: string,
    link?: string
}

type PageDataType = {
    page: string,
    domain?: string
}

class MixPanelWrapper {
    static REACT_APP_MIXPANEL_TOKEN:Object = process?.env?.REACT_APP_MIXPANEL_TOKEN || '';
    static REACT_APP_MIX_PANEL_INIT_COOKIE:string = process?.env?.REACT_APP_MIX_PANEL_INIT_COOKIE || '';
    INIT_MIX_PANEL:boolean = false;

    async Init(userData: UserType, isLoggedIn: boolean, debugMode: boolean = false) {
        const CookiesList = new Cookies(null);
        const isMixPanelAccepted:boolean = CookiesList.get(MixPanelWrapper.REACT_APP_MIX_PANEL_INIT_COOKIE) === 'true';

        if (!isLoggedIn || !isMixPanelAccepted) {
            return;
        }
        mixpanel.init(MixPanelWrapper.REACT_APP_MIXPANEL_TOKEN, {debug: debugMode});
        const {
            uuid,
            position,
            company,
            playtechUser
        } = userData || {};

        mixpanel.identify(uuid);
        this.INIT_MIX_PANEL = true;
        return await mixpanel.people.set_once({
            Role: position,
            Company: company,
            'Playtech User': `${playtechUser ? 'Yes' : 'No'}`
        });
    }
    async TrackEvent(eventName: string, eventData: EventDataType) {
        if (!eventName || !eventData || !this.INIT_MIX_PANEL) {
            return;
        }
        if (eventData?.value) {
            eventData.value = `${parse(eventData.value)}`;
        }
        eventData.domain = window?.location?.origin;
        return await mixpanel.track(eventName, eventData);
    }
    async TrackPageView(data: PageDataType) {
        if (!data?.page || !this.INIT_MIX_PANEL) {
            return;
        }
        data.page = `${parse(data.page)}`;
        data.domain = window?.location?.origin;
        return mixpanel.track_pageview(data);
    }

    async Reset() {
        if (!this.INIT_MIX_PANEL) {
            return;
        }
        return mixpanel.reset();
    }
}

export default new MixPanelWrapper();
