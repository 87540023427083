import React from 'react';

import classes from './Tabs.module.pcss';
import {ContentType} from '../ProductContent';
import Button from 'js/components/Common/Button/Button';


export type TabType = {
    label: string,
    name: ContentType
}

const Tabs = ({tabList, setActiveTabHandler, activeTab}) => (
    <div className={classes.Tabs}>
        {tabList?.map(item => (
            <Button
                type={'button'}
                buttonColorType={'Blue'}
                key={item?.name}
                text={item?.label}
                handler={() => activeTab !== item?.name && setActiveTabHandler(item?.name)}
                additionalStylesClass={`${classes.Item} ${activeTab === item?.name && classes.Active}`}
                buttonType={'ButtonUncolored'} />
        ))}
    </div>
);

export default Tabs;
