import React from 'react';
import {observer} from 'mobx-react';
import SearchStore from 'js/store/SearchStore';

import classes from './SearchFilter.module.pcss';
import SearchFilterItem from './SearchFilterItem/SearchFilterItem';


const SearchFilter = () => {
        const { filterTaxData} = SearchStore;
        return (
            <div className={classes.SearchFilter} data-search-filter={true}>
                    <SearchFilterItem
                        type={'taxonomy'}
                        taxonomyName={'productLegacy'}
                        heading={'Product'}
                    />
                    {filterTaxData?.productLegacy ?
                    <SearchFilterItem
                        type={'taxonomy'}
                        taxonomyName={'subjectMatter'}
                        parentId={filterTaxData?.productLegacy}
                        heading={'Subject Matter'}
                    /> : null}
                    <SearchFilterItem
                        type={'entities'}
                        heading={'Content Type'}
                    />
                    <SearchFilterItem
                        type={'taxonomy'}
                        taxonomyName={'articleType'}
                        heading={'Article Type'}
                    />
                    <SearchFilterItem
                        type={'taxonomy'}
                        taxonomyName={'videoType'}
                        heading={'Video Type'}
                    />
                    <SearchFilterItem
                        type={'taxonomy'}
                        taxonomyName={'courseType'}
                        heading={'Course Type'}
                    />
                    <SearchFilterItem
                        type={'taxonomy'}
                        taxonomyName={'manualsType'}
                        heading={'Manual Type'}
                    />
                    <SearchFilterItem
                        type={'taxonomy'}
                        taxonomyName={'skills'}
                        heading={'Topic'}
                    />
                    <SearchFilterItem
                        type={'taxonomy'}
                        taxonomyName={'suitableFor'}
                        heading={'Suitable For'}
                    />
                    <SearchFilterItem
                        type={'taxonomy'}
                        taxonomyName={'language'}
                        heading={'Language'}
                    />
            </div>
        );
};

export default observer(SearchFilter);
