import React, {useMemo, useState} from 'react';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Container from 'js/components/Common/Container/Container';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import fetchData from './fetchData';
import classes from './TestimonialsBlock.module.pcss';
import TestimonialsItems from '../TestimonialsItems/TestimonialsItems';
import { TestimonialsBlockDataInterface } from './TestimonialsBlockDataInterface';

const TestimonialsBlock = ({ additionalStyles = false }) => {
	const [testimonialsData, setTestimonialsData] = useState<TestimonialsBlockDataInterface[]>([]);
	const setAdditionalStyles = useMemo(() => additionalStyles ? {
		background: '#EBF0FF'
	} : null, [additionalStyles]);

	const { isLoading, error } = useFetchData({
	    query: fetchData,
	    condition: true,
	    updateData: data => setTestimonialsData(data.themeGeneralSettings.Options.testimonialsSlider)
	});

	return (
		<DataValidator data={testimonialsData && !isLoading && !error}>
			<div className={classes.Testimonials} style={setAdditionalStyles}>
				<Container additionalClass={`${classes.TestimonialsContainer}`}>
					<TestimonialsItems items={testimonialsData} />
				</Container>
			</div>
		</DataValidator>
	);

};

export default TestimonialsBlock;
