import React from 'react';
import classes from './AwardsItem.module.pcss';
import Heading from 'js/components/Common/Heading/Heading';
import Image from 'js/components/Common/Image/Image';

const AwardsItem = ({ item }) => {
	const { title, name } = item;
	const { altText, mediaItemUrl, mediaDetails } = item?.image || {};
	return (
		<div className={classes.AwardsItem}>
			<Image
				mediaItemUrl={mediaItemUrl}
				mediaDetails={mediaDetails}
				size="240x240"
				alt={altText || title} />
			<Heading text={title} thin={true} />
			<p>{name}</p>
		</div>
	);
};

export default AwardsItem;
