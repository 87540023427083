import React from 'react';
import MissionStatement
	from 'js/components/Templates/PreLoginPage/MissionStatement/MissionStatementBlock/MissionStatement';
import SessionsCarouselBlock
	from 'js/components/Templates/PreLoginPage/SessionsCarousel/SessionCarouselBlock/SessionsCarouselBlock';
import TestimonialsBlock from 'js/components/Common/Testimonials/TestimonialsBlock/TestimonialsBlock';
import IndustryTalksCarouselBlock
	from 'js/components/Templates/PreLoginPage/IndustryTalksCarousel/IndustryTalksCarouselBlock/IndustryTalksCarouselBlock';
import MasterSkillsCarouselBlock
	from 'js/components/Templates/PreLoginPage/MasterSkillsCarousel/MasterSkillsCarouselBlock/MasterSkillsCarouselBlock';
import PromoBox from 'js/components/Templates/PreLoginPage/PromoBox/PromoBoxBlock/PromoBox';
import Topic from 'js/components/Templates/PreLoginPage/Topic/TopicBlock/Topic';

const PreLoginPageTemplate = ({ pageId }) => (
	<main className="templatePreLogin">
		<PromoBox pageId={pageId} />
		<MissionStatement pageId={pageId} />
		<TestimonialsBlock />
		<SessionsCarouselBlock pageId={pageId} />
		<IndustryTalksCarouselBlock pageId={pageId} />
		<MasterSkillsCarouselBlock pageId={pageId} />
		<Topic pageId={pageId} />
	</main>
);

export default PreLoginPageTemplate;
