import React from 'react';
import parse from 'html-react-parser';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import classes from './MadeEasyInfo.module.pcss';

export interface Props {
    data: string
}

const MadeEasyInfo = ({data}: Props) => (
    <DataValidator data={!!data}>
        <div className={classes.MadeEasyInfo}>
            {parse(data)}
        </div>
    </DataValidator>

);

export default MadeEasyInfo;
