export default pageId => (
	`
	{
		page(id: "${pageId}", idType: DATABASE_ID) {
			  CoursesCatalogue {
				leftSideHowTo
				rightSideHowTo
			}
		}
	}
		
	`
);
