import React, {useState} from 'react';
import parse from 'html-react-parser';
import Image from 'js/components/Common/Image/Image';
import Button from 'js/components/Common/Button/Button';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Container from 'js/components/Common/Container/Container';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';
import {ImageBlock} from 'js/components/Common/Image/ImageBlockDataInterface';

import fetchData from './fetchData';
import classes from './NoDataSection.module.pcss';

export interface Props {
    infoKeyProp: string,
    imageKeyProp: string,
    buttonLinkKeyProp:string,
    buttonTextKeyProp: string
}

const NoDataSection = ({infoKeyProp, buttonTextKeyProp, imageKeyProp, buttonLinkKeyProp}: Props) => {
    const [info, setInfo] = useState<string>('');
    const [placeholderImage, setPlaceholderImage] = useState<ImageBlock>(null);
    const [buttonInfo, setButtonInfo] = useState<{
        text: string,
        link: string
    }>({
        text: '',
        link: ''
    });

    const { isLoading, error } = useFetchData({
        query: fetchData(buttonLinkKeyProp, buttonTextKeyProp, infoKeyProp, imageKeyProp),
        condition: true,
        updateData: (data) => {
            const result = data?.themeGeneralSettings?.Options;
            if (!result) {
                return;
            }
            const {infoKey, buttonTextKey, imageKey, buttonLinkKey} = result || {};
            setInfo(infoKey || '');
            setPlaceholderImage(imageKey);
            setButtonInfo({
                link: buttonLinkKey?.link,
                text: buttonTextKey
            });
        }
    });

    return (
        <DataValidator data={!!info && !isLoading && !error}>
            <section className={classes.NoDataSection}>
                <Container>
                    {parse(info || '')}
                    <Button
                        additionalStylesClass={classes.NoDataSectionButton}
                        type={'a'}
                        text={buttonInfo?.text} link={buttonInfo?.link}/>
                    <Image
                        mediaDetails={placeholderImage?.mediaDetails}
                        mediaItemUrl={placeholderImage?.mediaItemUrl}
                        alt={placeholderImage?.altText} />
                </Container>
            </section>
        </DataValidator>
    );
};

export default NoDataSection;
