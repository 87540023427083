import React from 'react';
import Heading from 'js/components/Common/Heading/Heading';

import classes from './TopInfo.module.pcss';

interface Props {
	title: string,
	info: {
		firstParagraph: string,
		secondParagraph: string,
		thirdParagraph: string
	}
}

const TopInfo = ({ title, info }: Props) => {
	const { firstParagraph, secondParagraph, thirdParagraph } = info || {};
	return (
		<article className={classes.TopInfo}>
			<Heading type="h1" text={title} />
			{firstParagraph ? <p>{firstParagraph}</p> : null}
			{secondParagraph ? <p>{secondParagraph}</p> : null}
			{thirdParagraph ? <p>{thirdParagraph}</p> : null}
		</article>
	);
};

export default TopInfo;
