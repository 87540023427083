import React, { useContext, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { SwiperInstance } from 'react-id-swiper';
import { PromoBoxContext } from 'js/context/PromoBoxContext';

import classes from './PromoBoxMedia.module.pcss';
import { promoMediaFadeOut, promoTextFadeOut } from '../PromoBoxFunctions/PromoBoxFuntions';

export interface Props {
	item: any,
	slider: SwiperInstance
}

const Video = ({ item, slider }: Props) => {
	const Context = useContext(PromoBoxContext);
	const [playing, setPlaying] = useState(false);
	const { playingTrigger } = Context;

	useEffect(() => {
		setPlaying(slider && playingTrigger && (slider?.activeIndex === item?.index));
	}, [playingTrigger, slider, item]);

	return (
		<div className={`${classes.promoVideo} VideoPlayerWrapper`}>
			<ReactPlayer
				className='reactPlayer'
				url={item && item?.videoFile && item?.videoFile?.mediaItemUrl ? item?.videoFile?.mediaItemUrl : ''}
				config={{
					file: {
						forceVideo: true
					}
				}}
				muted={true}
				playing={playing}
				width='100%'
				height='100%'
				onEnded={() => {
					promoTextFadeOut(slider, Context?.animationsTiming?.text, true);
					promoMediaFadeOut(slider, Context?.animationsTiming?.media, true);
					setPlaying(false);
					Context.setPlayingTrigger(false);
				}}
			/>
		</div>
	);
};

export default Video;
