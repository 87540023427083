import React, {useCallback, useMemo, useState} from 'react';
import parse from 'html-react-parser';
import PopUp from 'js/components/Common/PopUp/PopUp';
import Button from 'js/components/Common/Button/Button';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';
import EmbedVideo from 'js/components/Common/EmbedVideo/EmbedVideo';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import {ProductType} from '../../Offerings';
import classes from './CourseInfo.module.pcss';
import {ImageBlockDataInterface} from 'js/components/Common/Image/ImageBlockDataInterface';


interface Props {
    activeCourseInfo: ProductType
}

const CourseInfo = ({activeCourseInfo}: Props) => {
    const [popUpTrigger, setPopUpTrigger] = useState<any>(false);
    const [videPreview, setVideoPreview] =
        useState<ImageBlockDataInterface>(null);
    const [videoLink, setVideoLink] = useState<string>('');
    const video = useMemo(() =>
        activeCourseInfo?.videoButton, [activeCourseInfo]);
    const isPopup = useMemo(() =>
            video?.videoLink &&
            video?.videoPreview?.mediaItemUrl,
        [video]);

    const sendMixPanelPlayEvent = useCallback(async (title:string) => (
        await MixPanelWrapper.TrackEvent(
            'Play Video',
            {
                category: 'Training Page',
                value: title
            })
    ), []);

    return (
        <DataValidator data={activeCourseInfo}>
            <article className={classes.CourseInfo}>
                <div className={classes.ContentWrap}>
                    <div className={classes.CourseInfoSection}>
                        <strong>In this Training</strong>
                        <ul>
                            {activeCourseInfo?.inThisTraining?.map((item, index) =>
                                <li key={index}><span>{parse(item?.item || '')}</span></li>
                            )}
                        </ul>
                    </div>
                    <div className={classes.CourseInfoSection}>
                        <strong>About This Training</strong>
                        <p className={classes.CourseInfoDescription}>
                            {parse(activeCourseInfo?.aboutThisTraining || '')}
                        </p>
                    </div>
                </div>
                <div className={classes.ButtonsWrap}>
                    <Button
                        additionalStylesClass={classes.Button}
                        type={'button'}
                        text={parse(`<span>${activeCourseInfo?.videoButton?.buttonLabel || 'Trailer'}</span>`)}
                        disabled={!isPopup}
                        handler={() => {
                            setVideoPreview(video?.videoPreview);
                            setVideoLink(video?.videoLink);
                            setPopUpTrigger(true);
                        }}
                        buttonType={'ButtonColored'} buttonColorType={'Pink'}/>
                    <Button type={'button'}
                            additionalStylesClass={classes.Button}
                            text={'Guided Self Learning'}
                            disabled={true}
                            buttonColorType={'Pink'}
                            buttonType={'ButtonUncolored'}/>
                    {isPopup && videoLink && videPreview ?
                        <PopUp
                            type={'S'}
                            additionalClass={classes.Popup}
                            popUpTrigger={popUpTrigger}
                            popUpClose={() => {
                                setPopUpTrigger(false);
                                setVideoLink('');
                                setVideoPreview(null);
                            }}>
                            <EmbedVideo
                                title={activeCourseInfo?.productName}
                                customVideoSize={{
                                    width: '100%',
                                    height: '388'
                                }}
                                sendEventHandler={async () =>
                                    await sendMixPanelPlayEvent(activeCourseInfo?.productName)}
                                link={videoLink}
                                preview={videPreview} />
                        </PopUp> : null}
                </div>
            </article>
        </DataValidator>
    );
};

export default CourseInfo;
