import React from 'react';
import Heading from 'js/components/Common/Heading/Heading';

import classes from './MissionStatementItem.module.pcss';

export interface Props {
	item: {
		description?: string,
		title?: string
	},
	index: number
}

const MissionStatementItem = ({ item, index }: Props) => (
	<div className={classes.MissionStatementItem}>
		<span className={classes.position}>{index + 1}</span>
		<Heading text={item?.title} type="h4" />
		<p>{item?.description}</p>
	</div>
);

export default MissionStatementItem;
