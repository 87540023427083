import React from 'react';
import Button from 'js/components/Common/Button/Button';

import classes from './HeaderButtons.module.pcss';

const HeaderButtons = ({logInCallback}) => (
	<div className={classes.HeaderButtons}>
		<Button type="button" text="Log in" handler={logInCallback}/>
	</div>
);

export default HeaderButtons;
