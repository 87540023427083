import React from 'react';
import ContactBlock from 'js/components/Templates/ContactUs/ContactBlock/ContactBlock';
import ContactTopInfo from 'js/components/Templates/ContactUs/ContactTopInfo/ContactTopInfo';

const ContactUsTemplate = ({pageId}) => (
    <main className="templateContactUs">
        <ContactTopInfo pageId={pageId} />
        <ContactBlock pageId={pageId} />
    </main>
);

export default ContactUsTemplate;
