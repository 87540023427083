import React, {useContext, useMemo} from 'react';
import CourseItemBox from 'js/components/Common/CourseItemBox/CourseItemBox';
import CourseTransitionContainer from 'js/components/Common/CourseItemBox/CourseTransitionContainer ';

import classes from './CoursesListItems.module.pcss';
import {CoursesListContext} from '../context/CoursesListContext';

const CoursesListItems = ({listType, courseListData, buttonAction}) => {
    const {coursesList, coursesButtons} = courseListData;
    const {setPopUpTrigger, setPopUpData} = useContext(CoursesListContext);

    const actionButtonText = useMemo(() => {
        switch (buttonAction) {
            case 'start': return coursesButtons?.startCourseButton;
            case 'buy': return coursesButtons?.buyCourseButton;
            default : return null;
        }
    }, [buttonAction, coursesButtons]);
    return (
        <div className={classes.CoursesListItems}>
            {coursesList?.filter(item => item)?.map((item, index) =>
                <CourseTransitionContainer key={item?.id} index={index}>
                    <CourseItemBox
                        init={false}
                        key={item?.id}
                        item={item}
                        animation
                        buttonAction={buttonAction}
                        buttonText={actionButtonText}
                        secondButtonText={coursesButtons?.infoCourseButton}
                        additionalClass={classes.CoursesListItem}
                        setPopUpTrigger={setPopUpTrigger}
                        setPopUpData={setPopUpData}
                        type={listType}
                    />
                </CourseTransitionContainer>
            )}
        </div>
    );
};

export default CoursesListItems;
