import React from 'react';
import parse from 'html-react-parser';

import {SubTopicItem} from '../SubTopic';
import classes from './SubTopicItems.module.pcss';

interface Props {
    items: Array<SubTopicItem>,
    activeItem: number,
    parentName: string,
    itemClickHandler: any
}

const SubTopicItems = ({items, activeItem, parentName, itemClickHandler}: Props) => (
    <div className={classes.SubTopicItems}>
        {items?.map(item => (
            <div
                className={`${classes.Item} ${activeItem === item?.databaseId && classes.Active}`}
                key={item?.id}
                onClick={() => itemClickHandler(item?.databaseId, `${parentName} - ${item?.name}`)}>
                <span>{parse(`${parentName} - ${item?.name}` || '')}</span>
                <span>{parse(`${parentName} - ${item?.name}` || '')}</span>
            </div>
        ))}
    </div>
);

export default SubTopicItems;
