import React, {useState} from 'react';
import parse from 'html-react-parser';
import useFetchCallback from 'js/hooks/useFetchCallback/useFetchCallback';
import {iLTCoursesSingleFetchData} from 'js/components/Common/ILTCourses/ILTCoursesBlock/fetchData';

import classes from './ILTCoursesList.module.pcss';
import {
	ILTCoursesDataInterface,
	ILTCoursesPopUpDataType
} from '../ILTCoursesBlock/ILTCoursesDataInterface';
import ILTCoursesPopUp from '../ILTCoursesPopUp/ILTCoursesPopUp';

export interface Props {
	listData: ILTCoursesDataInterface[],
	centered: boolean
}

const ILTCoursesList = ({listData, centered}: Props) => {
	const [popUpTrigger, setPopUpTrigger] = useState<boolean>(false);
	const [popUpData, setPopUpData] = useState<ILTCoursesPopUpDataType>(null);

	const itemClickHandler = useFetchCallback({
	    updateData: data => data?.iLTCoursesSingle,
	    query: id => iLTCoursesSingleFetchData(id),
	    onSuccess: (data) => {
			const result = data?.iLTCoursesSingle;
			const attributes = result?.ILTCourses?.attributes || [];

			setPopUpData({
				title: result?.title || '',
				about: result?.ILTCourses?.about || '',
				content: result?.ILTCourses?.content || '',
				location: result?.ILTCourses?.location || '',
				attributes: {
					hands: attributes?.hands || '',
					host: attributes?.host || '',
					duration: attributes?.duration || '',
					fullyCertified: attributes?.fullyCertified || '',
					onlineTraining : attributes?.onlineTraining || '',
					registrationIsRequired : attributes?.registrationIsRequired || '',
					showCertified: attributes?.showCertified || false,
					showHands: attributes?.showHands || false,
					showHost: attributes?.showHost || false,
					showOnline: attributes?.showOnline || false,
					showRegistration: attributes?.showRegistration || false,
					showDuration: attributes?.showDuration || false
				},
				productList: result?.productList?.nodes || [],
				suitableForList: result?.suitableForList?.nodes || []
			});
			setPopUpTrigger(true);
		}
	});

	if (!listData.length) {
		return <></>;
	}
    return (
        <div className={`${classes.ILTCoursesListWrap} ${centered ? classes.Centered : ''}`}>
			<ul className={classes.ILTCoursesList}>
				{listData?.map(item => (
					<li
						className={classes.ILTCoursesListItem}
						key={item.databaseId}>
						<span onClick={() => itemClickHandler(item.databaseId)}>
							{parse(item.title)}
						</span>
					</li>
				))}
			</ul>
			<ILTCoursesPopUp
				popUpTrigger={popUpTrigger}
				setPopUpTrigger={setPopUpTrigger}
				popUpData={popUpData}
			/>
		</div>
    );
};

export default ILTCoursesList;
