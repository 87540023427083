import React, {useMemo} from 'react';
import Heading from 'js/components/Common/Heading/Heading';
import Container from 'js/components/Common/Container/Container';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import useEventsList from '../hook/useEventsList';
import classes from './EventsListBlock.module.pcss';
import EventsListItems from '../EventsListItems/EventsListItems';
import {EventsListTypes} from '../Wrapper/WrapperDataInterface';

export interface Props {
    type: EventsListTypes
}

const EventsListBlock = ({type}: Props) => {
    const eventsListData = useEventsList(type);
    const setSectionHeading = useMemo(() => {
        const {eventsHeadings} = eventsListData;
        switch (type) {
            case 'upcomingByDate': return eventsHeadings?.dateSectionHeading;
            case 'skillBooster': return eventsHeadings?.skillBoosterSectionHeading;
            case 'learnNew': return eventsHeadings?.learnNewSectionHeading;
            case 'inspired': return eventsHeadings?.inspiredSectionHeading;
            default: return '';
        }
    }, [eventsListData, type]);

    return (
        <DataValidator data={eventsListData?.eventsList}>
            <section className={classes.EventsList}>
                <Container>
                    <Heading text={setSectionHeading}/>
                    <EventsListItems
                        data={eventsListData?.eventsList}
                        buttons={eventsListData?.eventsButtons}
                        sectionType={type} />
                </Container>
            </section>
        </DataValidator>
    );
};

export default EventsListBlock;
