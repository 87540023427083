import React, {useCallback} from 'react';

const DataValidator = (props) => {
	const { data, children } = props;

	const validateObject = useCallback((object) => {
		if (object) {
			return Object?.values(object)?.find(el => el !== '' && el !== null) || null;
		}
		return null;
	}, []);

	// Boolean
	if (typeof data === 'boolean' && data) {
		return children;
	}
	// String
	if (typeof data === 'string' && data?.length > 0) {
		return children;
	}
	// Number
	if (typeof data === 'number' && data) {
		return children;
	}
	// Object
	if (typeof data === 'object' && data) {
		if (Array.isArray(data)) {
			const notEmptyData = data.filter(
				(element) => {
					if (validateObject(element)) {
						return element;
					}
				}
			);
			return notEmptyData.length > 0 ? children : <></>;
		} else {
			return children;
		}
	}
	return <></>;
};

export default DataValidator;
