import React, {useState} from 'react';
import {stateType} from 'js/containers/Pages/Profile/Preferences/Preferences';

type MyProfileContextType = {
    interests: string,
    preferences: stateType,
    setInterests: Function,
    setPreferences: Function
}
export const initialState: stateType = {
    email: {
        slug: '',
        label: '',
        status: false
    },
    messages: {
        slug: '',
        label: '',
        status: false
    },
    notifications: {
        slug: '',
        label: '',
        status: false
    },
    disableAll: {
        slug: '',
        label: '',
        status: false
    },
    loaded: false
};

const MyProfileContextDefaultValue: MyProfileContextType = {
    interests: '',
    preferences: initialState,
    setInterests: value => value,
    setPreferences: value => value
};

export const MyProfileContext = React.createContext(MyProfileContextDefaultValue);

const MyProfileContextProvider = (props) => {
    const [preferences, setPreferences] = useState<stateType>(initialState);
    const [interests, setInterests] = useState<string>('');
    return (
        <MyProfileContext.Provider value={{
            interests,
            preferences,
            setInterests,
            setPreferences
        }}>
            {props.children}
        </MyProfileContext.Provider>
    );
};

export default MyProfileContextProvider;
