const fetchData = (pageId: number): string => `
    {
    productLabels {
      productLabelsList(pageId: ${pageId}) {
        name
        count
      }
    }
  }
`;
export default fetchData;
