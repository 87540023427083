import {FilterTaxDataType} from 'js/store/SearchStore';

const fetchData = (entities: string, filterTax:FilterTaxDataType) => {
        const setSingleTaxQuery = (tax, data) => {
                if (data?.length === 0) {
                        return '';
                }
                return `${tax}: "${data}",`;
        };
        const getTaxQuery = () => {
                const {
                        skills,
                        productLegacy,
                        subjectMatter,
                        language,
                        eventType,
                        videoType,
                        courseType,
                        suitableFor,
                        manualsType,
                        articleType,
                        podcastType,
                        presentationType
                } = filterTax || {};
                const suitableForData = setSingleTaxQuery('suitableFor', suitableFor);
                const skillsData = setSingleTaxQuery('skills', skills);
                const productData = setSingleTaxQuery('productLegacy', productLegacy);
                const subjectMatterData = setSingleTaxQuery('subjectMatter', subjectMatter);
                const languageData = setSingleTaxQuery('language', language);
                const articleTypeData = setSingleTaxQuery('articleType', articleType);
                const eventTypeData = setSingleTaxQuery('eventType', eventType);
                const videoTypeData = setSingleTaxQuery('videoType', videoType);
                const courseTypeData = setSingleTaxQuery('courseType', courseType);
                const manualTypeData = setSingleTaxQuery('manualsType', manualsType);
                const podcastTypeData = setSingleTaxQuery('podcastType', podcastType);
                const presentationTypeData = setSingleTaxQuery('presentationType', presentationType);
                if (
                    !suitableForData &&
                    !subjectMatterData &&
                    !skillsData &&
                    !productData &&
                    !languageData &&
                    !articleTypeData &&
                    !eventTypeData &&
                    !videoTypeData &&
                    !courseTypeData &&
                    !manualTypeData &&
                    !podcastTypeData &&
                    !presentationTypeData
                ) {
                        return '';
                }
                return (`
                taxonomies(
                ${subjectMatterData}
                ${suitableForData} 
                ${skillsData} 
                ${productData} 
                ${languageData} 
                ${articleTypeData} 
                ${eventTypeData} 
                ${videoTypeData} 
                ${courseTypeData} 
                ${manualTypeData} 
                ${podcastTypeData} 
                ${presentationTypeData}) 
                { result }
                `);
        };
        return (
            `
        searchFilter {
            entity(value: "${entities}") {
                result
             }
             ${getTaxQuery()}
        }
        `
        );
};

export default fetchData;
