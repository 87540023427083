export default pageId => (
    `
    {
        page(id: "${pageId}", idType: DATABASE_ID) {
          CoursesCatalogue {
            buttonText
            headingWelcome
            subheadingWelcome
            welcomeImage {
              mediaItemUrl
            }
          }
        }
    }
    `
);
