import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import classes from './FilterCourses.module.pcss';
import Container from 'js/components/Common/Container/Container';
import FilterSelect from '../../FilterSelect/FilterSelect';
import {CommonContext} from 'js/context/CommonContext/CommonContext';
import {durationList, priceList} from './FilterCustomData';
import {FilterCoursesDataInterface, PriceCountsType} from '../../FilterDataInterface';
import Input from 'js/components/Common/FormComponents/Input/Input';
import {CoursesListContext} from 'js/components/Templates/Courses/CoursesList/context/CoursesListContext';
import buildQuery from 'js/components/Filter/Helpers/buildQuery';

const FilterCourses = () => {
    const controllerRef = useRef<AbortController | null>();
    const [filterDataList, setFilterDataList] = useState<FilterCoursesDataInterface>({
        suitableFor: null,
        skills: null,
        duration: null,
        price: null
    });
    const [openedSelect, setOpenedSelect] = useState<string>('');
    const [priceCountsForEssential, setPriceCountsForEssential] = useState<PriceCountsType>({
        free: null,
        price199: null,
        price100199: null,
        price200299: null,
        price300: null
    });
    const [priceCountsForPopular, setPriceCountsForPopular] = useState<PriceCountsType>({
        free: null,
        price199: null,
        price100199: null,
        price200299: null,
        price300: null
    });
    const {apiPath, isCustomerLogged} = useContext(CommonContext);
    const {
        setResetFilter,
        resetFilter,
        getQuery,
        setQuery,
        setShowLoader
    } = useContext(CoursesListContext);

    useEffect(() => {
        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        controllerRef.current = new AbortController();
        let isCanceled = false;
        (async () => {
            try {
                const response = await fetch(`${apiPath}/graphql`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    signal: controllerRef.current?.signal,
                    body: JSON.stringify({
                        query: `
                    {
                        suitableForList(where: {orderby: TERM_ID}, first: 50) {
                          nodes {
                            id
                            name
                            slug
                          }
                        }
                        skillsList(where: {orderby: TERM_ID}, first: 50) {
                          nodes {
                            id
                            name
                            slug
                          }
                        }
                    }
				`})
                });
                if (isCanceled) { return; }
                const {data} = await response.json();

                setFilterDataList({
                    suitableFor: data.suitableForList.nodes,
                    skills: data.skillsList.nodes,
                    duration: durationList,
                    price: priceList
                });
            } catch (e) {

            }
        })();
        return () => {
            if (controllerRef.current) {
                controllerRef.current.abort();
                controllerRef.current = null;
            }
            isCanceled = true;
        };
    }, [apiPath]);

    const setQueryAttributesCallBack = useCallback((type): string => (
        buildQuery(type, getQuery, false, true)
    ), [getQuery]);

    const setPriceCounts = useCallback((limit, type) => {
        let isCanceled = false;
        (async () => {
            const response = await fetch(`${apiPath}/graphql`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    query: `
					{
				        coursesArchive(where: 
				        {orderby: {field: DATE, order: DESC} 
				        ${setQueryAttributesCallBack(type)}}, 
				        ${limit ? `first: ${limit}` : ''}) {
                            pageInfo {
                                free
                                total
                                from100to199
                                from1to99
                                from200to299
                                upto300
                              }
                        }
					  }
                `
                })
            });
            if (isCanceled) { return; }
            const {data} = await response.json();
            switch (type) {
                case 'isEssential' : setPriceCountsForEssential(data?.coursesArchive?.pageInfo); break;
                case 'isPopular' : setPriceCountsForPopular(data?.coursesArchive?.pageInfo); break;
                default : break;
            }
        })();
        return () => {
            isCanceled = true;
        };
    }, [setQueryAttributesCallBack, apiPath]);

    useEffect(() => {
        if (isCustomerLogged) {
            return;
        }
        setPriceCounts(1000, 'isEssential');
        setPriceCounts(1000, 'isPopular');

    }, [setPriceCounts, isCustomerLogged]);

    const selectClickHandler = (name):void => {
        if (openedSelect === name) {
            setOpenedSelect('');
        } else {
            setOpenedSelect(name);
        }
    };

    const resetFilterHandler = useCallback(() => {
        if (!resetFilter) {
            setResetFilter(!resetFilter);
        }
    }, [resetFilter, setResetFilter]);

    return (
        <div className={classes.CoursesListFilter}>
            <Container additionalClass={classes.CoursesListFilterWrap}>
                <FilterSelect
                    name="Suitable For"
                    filterDataType="suitableFor"
                    list={filterDataList?.suitableFor}
                    width={694}
                    columns={3}
                    opened={openedSelect === 'suitableFor'}
                    click={selectClickHandler}
                    resetFilter={resetFilter}
                    setResetFilter={setResetFilter}
                    getQuery={getQuery}
                    setQuery={setQuery}
                    setShowLoader={setShowLoader}
                    closeSelect={() => setOpenedSelect('')}
                />
                <FilterSelect
                    name="Topic"
                    filterDataType="skills"
                    list={filterDataList?.skills}
                    width={500}
                    columns={3}
                    opened={openedSelect === 'skills'}
                    click={selectClickHandler}
                    resetFilter={resetFilter}
                    setResetFilter={setResetFilter}
                    getQuery={getQuery}
                    setQuery={setQuery}
                    setShowLoader={setShowLoader}
                    closeSelect={() => setOpenedSelect('')}
                />
                <FilterSelect
                    name="Duration"
                    filterDataType="duration"
                    list={filterDataList?.duration}
                    width={234}
                    columns={1}
                    opened={openedSelect === 'duration'}
                    click={selectClickHandler}
                    resetFilter={resetFilter}
                    setResetFilter={setResetFilter}
                    getQuery={getQuery}
                    setQuery={setQuery}
                    setShowLoader={setShowLoader}
                    closeSelect={() => setOpenedSelect('')}
                />
                {!isCustomerLogged ? <FilterSelect
                    name="Price"
                    filterDataType="price"
                    list={filterDataList?.price}
                    width={234}
                    columns={1}
                    counts={{
                        priceCountsForEssential,
                        priceCountsForPopular
                    }}
                    opened={openedSelect === 'price'}
                    click={selectClickHandler}
                    resetFilter={resetFilter}
                    setResetFilter={setResetFilter}
                    getQuery={getQuery}
                    setQuery={setQuery}
                    setShowLoader={setShowLoader}
                    closeSelect={() => setOpenedSelect('')}
                /> : null}
                <div className={classes.resetCoursesFilter}>
                    <Input
                        type="reset"
                        name={'Reset Filters'}
                        click={resetFilterHandler} />
                </div>
            </Container>
        </div>
    );
};

export default FilterCourses;
