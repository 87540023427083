import React, {useState} from 'react';
import parse from 'html-react-parser';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import fetchData from './fetchData';
import classes from './MoreInfoBlock.module.pcss';

export interface Props {
    type: 'manualDownloadInfo' | 'furtherAssistanceInfo' | 'attachedVideoInfo',
    border?: boolean
}

type InfoType = {
    title: string,
    description: string
}

const MoreInfoBlock = ({ type, border }: Props) => {
    const [info, setInfo] = useState<InfoType>({
        title: '',
        description: ''
    });

    const { isLoading, error } = useFetchData({
        query: `${fetchData(type)}`,
        condition: true,
        updateData: data => setInfo(data?.themeGeneralSettings?.Options[type])
    });

    return (
        <DataValidator data={!isLoading && !error }>
            <div className={`${classes.MoreInfoBlock} ${border ? classes.Bordered : ''}`}>
                { info?.title ? <h2>{info?.title}</h2> : '' }
                { info?.description ? parse(info?.description) : '' }
            </div>
        </DataValidator>
    );
};

export default MoreInfoBlock;
