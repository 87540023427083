import React, {useRef} from 'react';
import {CSSTransition} from 'react-transition-group';

import classes from './ToastMessage.module.pcss';
import {toastMessageType} from './ToastMessageDataInterface';

interface Props {
    data: toastMessageType
}

const ToastMessage = ({data}: Props) => {
    const { message, type, show } = data || {};
    const nodeRef = useRef(null);

    return (
        <CSSTransition
            in={show}
            timeout={10}
            appear
            exit
            nodeRef={nodeRef}
            classNames={classes.ToastMessage}
        >
            <div
                ref={nodeRef}
                datatype={type}
                className={classes.ToastMessage}>
                <span>{message}</span>
            </div>
        </CSSTransition>
    );
};

export default ToastMessage;
