import React from 'react';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';
import {observer} from 'mobx-react';

import SearchSorting from '../SearchSorting/SearchSorting';
import classes from './SearchResults.module.pcss';
import SearchPagination from './SearchPagination/SearchPagination';
import SearchResultsItem from './SearchResultsItem/SearchResultsItem';
import SearchResultsCount from './SearchResultsCount/SearchResultsCount';
import SearchStore from 'js/store/SearchStore';


const SearchResults = ({data, count, pages, pageChange}) => {
    const {searchStatus, searchQuery} = SearchStore;
    return (
        <div id={'SearchResults'} className={classes.SearchResults}>
            <div className={classes.SearchResultsBoxWrap}>
                <SearchResultsCount
                    query={searchQuery}
                    count={count}
                    countPerPage={data?.length}
                    isActive={searchStatus}
                />
                <SearchSorting />
            </div>
            <DataValidator data={data?.length > 0}>
                <div className={classes.SearchResultsBox}>
                    <div className={`${classes.SearchResultItems} ${searchStatus ? classes.SearchResultItemsActive : ''}`}>
                        {data?.map(item =>
                            <SearchResultsItem
                                key={item?.id}
                                type={item?.type}
                                data={item}
                                isPromoted={item?.isPromoted}/>
                        )}
                    </div>
                    {pages > 0 ? <SearchPagination pages={pages} pageChange={pageChange} /> : null}
                </div>
            </DataValidator>
        </div>
    );
};

export default observer(SearchResults);
