export default pageId => `
    {
        page(id: "${pageId}", idType: DATABASE_ID) {
          EventsTemplate {
            expandableDisclaimer {
              title
              info
            }
          }
        }
    }
`;
