const globalFields:string = `
    subjectMatter {
        SubjectMatterGlobal {
          gettingStartedTitle
        }
    }
`;


const articleFields:string = `
      id: databaseId
      title
      date
      link
      Articles {
        shortDescription
      }
`;

const fetchData = (pageId: number): string => `
     {
    gettingStartedDefault: productLegacy(id: "${pageId}", idType: DATABASE_ID) {
      articles : articlesArchive(where: {status: PUBLISH, isGettingStarted: true, orderby: {field: DATE, order: DESC}}, first: 4) {
        items: nodes {
          ${articleFields}
        }
      }
    }  
    gettingStartedCustom: productLegacy(id: "${pageId}", idType: DATABASE_ID) {
      ProductLegacyFields {
        gettingStartedSubtitle
        gettingStartedCustom {
            ... on ArticlesSingle {
              ${articleFields}
            }
          }
      }
    }
    ${globalFields}
  }
`;

export default fetchData;
