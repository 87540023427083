import React from 'react';
import Image from 'js/components/Common/Image/Image';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';
import {ImageBlockDataInterface} from 'js/components/Common/Image/ImageBlockDataInterface';

import classes from './TeamProfile.module.pcss';

export interface Props {
    type: 'Big' | 'Small',
    title: string,
    position: string,
    additionalClass?: string,
    photo: ImageBlockDataInterface
}

const TeamProfile = ({type, title, position, photo, additionalClass}: Props) => (
    <DataValidator data={title && photo?.mediaItemUrl}>
        <div className={`${classes.Profile} ${classes[type]} ${additionalClass}`}>
            <figure className={classes.ProfileImage}>
                <Image
                    mediaItemUrl={photo?.mediaItemUrl}
                    alt={title}
                    size={'thumbnail'}
                    mediaDetails={photo?.mediaDetails} />
            </figure>
            <div className={classes.ProfileInfo}>
                <strong>{title}</strong>
                <span>{position}</span>
            </div>
        </div>
    </DataValidator>
);

export default TeamProfile;
