import React from 'react';
import Awards from 'js/components/Templates/AboutUs/Awards/AwardsBlock/Awards';
import AboutUsTopBlock from 'js/components/Templates/AboutUs/Top/TopBlock/TopBlock';
import BrandsBlock from 'js/components/Templates/AboutUs/Brands/BrandsBlock/BrandsBlock';
import Location from 'js/components/Templates/AboutUs/Location/LocationBlock/Location';
import ValuesBlock from 'js/components/Templates/AboutUs/Values/ValuesBlock/ValuesBlock';
import GetToKnowBlock from 'js/components/Templates/AboutUs/GetToKnow/GetToKnowBlock/GetToKnowBlock';
import TestimonialsBlock from 'js/components/Common/Testimonials/TestimonialsBlock/TestimonialsBlock';

const AboutUsPageTemplate = ({ pageId }) => (
	<main className="templateAboutUs">
		<AboutUsTopBlock pageId={pageId} />
		<BrandsBlock pageId={pageId} />
		<TestimonialsBlock additionalStyles />
		<ValuesBlock pageId={pageId} />
		<GetToKnowBlock pageId={pageId} />
		<Location pageId={pageId} />
		<Awards pageId={pageId} />
	</main>
);

export default AboutUsPageTemplate;
