export default pageId => `
{
    page(id: "${pageId}", idType: DATABASE_ID) {
      HelpTemplate {
        announcementTitle
        announcementButton {
          url
          title
        }
      }
    }
  }
`;
