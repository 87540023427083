export default (type: 'manualDownloadInfo' | 'furtherAssistanceInfo' | 'attachedVideoInfo') => `
    {
    themeGeneralSettings {
      Options {
        ${type} {
          title
          description
        }
      }
    }
  }
`;
