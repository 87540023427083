import React, {useContext, useState, useMemo, useEffect} from 'react';
import { Route, useLocation } from 'react-router';
import SeoHelmet from 'js/containers/SEO/SeoHelmet';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Product from 'js/containers/Taxonomies/Product/Product';
import { CommonContext } from 'js/context/CommonContext/CommonContext';
import SubjectMatter from 'js/containers/Taxonomies/SubjectMatter/SubjectMatter';

import fetchData from './fetchData';
import getTypeByURL from './Helper';
import {TaxonomyRouterDataInterface, TaxType, TaxTypes} from './TaxonomyRouterDataInterface';

const useTaxonomyRouter = () => {
    const location = useLocation();
    const {INIT_MIX_PANEL} = useMemo(() => MixPanelWrapper, []);
    const {isCustomerLogged} = useContext(CommonContext);
    const types:TaxTypes = useMemo(() => [{
        queryName: 'productLegacy',
        slug: 'product-legacy'
    }], []);
    const [currentType, setCurrentType] = useState<TaxType>(null);
    const [term, setTerm] = useState<TaxonomyRouterDataInterface>(null);
    const [response, setResponse] = useState(null);

    useEffect(() => {
        setCurrentType(null);
        setTerm(null);
        setResponse(null);
    }, [location]);

    const {transformPath, matchingType} = useMemo(() => {
        const path: string = location?.pathname;
        return getTypeByURL(path, types);
    }, [types, location]) || {};

    const {databaseId, name, SeoMetadata, link} = useMemo(() =>  term || {}, [term]) || {};

    useEffect(() => {
        if (!transformPath || !matchingType) {
            return;
        }
        setCurrentType({ uri: transformPath, ...matchingType });
    }, [matchingType, transformPath]);

    const { isLoading, error } = useFetchData({
        query: `${fetchData(currentType?.queryName, currentType?.uri)}`,
        condition: !!currentType?.queryName && !!currentType?.uri,
        updateData: (data:any) => setResponse(data)
    });

    useEffect(() => {
        if (!response || !currentType?.queryName) {
            return;
        }
        const item:any = response[currentType?.queryName];
        (async () => {
            await MixPanelWrapper.TrackPageView({
                page: item?.name
            });
        })();
        setTerm(item);
    }, [currentType, response, INIT_MIX_PANEL]);

    const SEO = useMemo(() => {
        const {metaTitle, metaDescription} = SeoMetadata || {};
        return (
            <SeoHelmet
                title={metaTitle || name}
                description={metaDescription ?? ''}
                canonical={link} />
        );
    }, [SeoMetadata, link, name]);

    return (
        !!databaseId && isCustomerLogged && !isLoading && !error && !!currentType &&
        <>
            <Route
                exact
                path={`/${currentType?.slug}/:term`}
                render={() => {
                    switch (currentType?.slug) {
                        case 'product-legacy' : return (
                            <>
                                {SEO}
                                <Product pageId={databaseId} />
                            </>
                        );
                        default : return null;
                    }
                }}/>
            <Route
                exact
                path={`/${currentType?.slug}/:term/:subTerm`}
                render={() => {
                    switch (currentType?.slug) {
                        case 'product-legacy' : return (
                            <>
                                {SEO}
                                <SubjectMatter pageId={databaseId} />
                            </>
                        );
                        default : return null;
                    }
                }}/>
        </>
    );
};

export default useTaxonomyRouter;
