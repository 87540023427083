import React, {useState, useMemo, CSSProperties} from 'react';
import ReactPlayer from 'react-player';

import classes from './VideoBackground.module.pcss';

export type AssetsType = {
	mp4: {
		mediaItemUrl: string
	},
	placeholder: {
		mediaItemUrl: string
	},
	muteButtonColor: string,
	overlay: boolean,
	overlayOpacity: number,
	soundButton?: boolean
}

type Props = {
	children?: any,
	assets: AssetsType
}

const VideoBackground = (props: Props) => {
	const { children, assets } = props || {};
	const videoBg = assets?.mp4?.mediaItemUrl || '';
	const placeholder = assets?.placeholder?.mediaItemUrl || '';
	const [isMuted, setIsMuted] = useState(true);
	const style:CSSProperties = useMemo(() => (
		{
			background: `rgba(0,0,0, ${assets?.overlayOpacity})`
		}
	), [assets]);
	return (
		<div
			className={classes.VideoBackground}
			style={
				{
					minHeight: 'calc(100vh - 60px)',
					backgroundImage: `url('${placeholder}')`
				}
			}>
			{assets?.overlay ? <div style={style} className={classes.Overlay} /> : null}
			{videoBg ?
				<>
					<ReactPlayer
						url={videoBg}
						playing
						loop
						muted={isMuted}
						width="100%"
						height="100%"
						style={{
							position: 'absolute',
							top: 0,
							bottom: 0,
							left: 0,
							right: 0,
							width: '100%'
						}}
					/>
					{assets?.muteButtonColor && assets?.soundButton ? <div
						onClick={() => setIsMuted(prev => !prev)}
						style={{
							border: `2px solid ${assets?.muteButtonColor}`
						}}
						className={`${classes.MuteButton} ${!isMuted ? classes.Active : ''}`}>
						<svg fill={assets?.muteButtonColor}
							 xmlns="http://www.w3.org/2000/svg"
							 xmlnsXlink="http://www.w3.org/1999/xlink"
							 viewBox="0 0 50 50"
							 width="50px"
							 height="50px">
							{isMuted ? <path xmlns="http://www.w3.org/2000/svg" d="M49.9,48.3l-48-48c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l48,48c0.199,0.2,0.5,0.3,0.699,0.3c0.201,0,0.5-0.1,0.701-0.3C50.299,49.3,50.299,48.7,49.9,48.3z"/> : ''}
							<path d="M7.01,31.754V18.247c0-0.658,0.143-1.494,0.477-2.287H3c-1.676,0-3.04,1.364-3.04,3.04v12c0,1.676,1.364,3.04,3.04,3.04h4.481C7.151,33.253,7.01,32.415,7.01,31.754z"/>
							<path d="M24.16,46.992c1.667,0,2.831-1.513,2.831-3.678V6.327c0-2.278-1.456-3.316-2.808-3.316c-0.91,0-1.838,0.433-2.607,1.213l-11.631,11.6c-0.64,0.566-0.934,1.648-0.934,2.422v13.507c0,0.767,0.293,1.841,0.881,2.359L21.51,45.695C22.565,46.767,23.533,46.992,24.16,46.992z"/>
							<path id={classes.WaveFirst} className={classes.Wave} fill="none" stroke={assets?.muteButtonColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M30.688,19.417C33.167,20.064,35,22.32,35,25c0,2.68-1.833,4.936-4.313,5.584"/>
							<path id={classes.WaveSecond} className={classes.Wave} fill="none" stroke={assets?.muteButtonColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M34.92,13.142C39.137,15.417,42,19.873,42,25c0,5.111-2.85,9.557-7.045,11.835"/>
							<path id={classes.WaveThird} className={classes.Wave} fill="none" stroke={assets?.muteButtonColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M38.498,7.82c6.275,3.167,10.579,9.668,10.579,17.18c0,7.512-4.304,14.014-10.579,17.18"/>
						</svg>
					</div> : null}
				</>
				: null}
			{children}
		</div>
	);
};

export default VideoBackground;
