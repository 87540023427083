import React, {useState} from 'react';
import Button from 'js/components/Common/Button/Button';
import Heading from 'js/components/Common/Heading/Heading';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Container from 'js/components/Common/Container/Container';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import fetchData from './fetchData';
import classes from './Announcement.module.pcss';

export interface Props {
	pageId: number
}

const Announcement = ({pageId}: Props) => {
	const [title, setTitle] = useState<string>('');
	const [buttonData, setButtonData] = useState<{
		url: '',
		title: '',
		target: ''
	}>(null);

	const { isLoading, error } = useFetchData({
		query: `${fetchData(pageId)}`,
		condition: !!pageId,
		updateData: (data) => {
			const result = data?.page?.HelpTemplate;
			setTitle(result?.announcementTitle);
			setButtonData(result?.announcementButton);

		}
	});

    return (
        <DataValidator data={(title && buttonData?.url) && !isLoading && !error }>
			<section className={classes.Announcement}>
				<Container additionalClass={classes.Wrap}>
					<Heading text={title} type={'h2'} />
					<Button
						type="a"
						buttonType="ButtonColored"
						text={buttonData?.title || ''}
						link={buttonData?.url || ''}
					/>
				</Container>
			</section>
		</DataValidator>
    );
};

export default Announcement;
