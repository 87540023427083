import React from 'react';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import classes from './AccreditationItems.module.pcss';
import AccreditationItem from '../AccreditationItem/AccreditationItem';

const AccreditationItems = ({ items }) => (
	<DataValidator data={items}>
		<div className={classes.AccreditationItems}>
			{
				items?.map(
					(item, index) => <AccreditationItem key={index} item={item} />
				)
			}
		</div>
	</DataValidator>
);

export default AccreditationItems;
