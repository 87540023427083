export type toastMessagesTypes = 'success' | 'error' | '';

export type toastMessageType = {
    type: toastMessagesTypes,
    message: string,
    show: boolean
}
export type ToastIdsType =
    'feedbackToasts' |
    'articleVoteToasts' |
    'contactFormToasts' |
    'saveProfileToasts' |
    'eventRegistrationToasts' |
    '';

type KeysType = Array<{
    id: ToastIdsType,
    isErrorMessage: boolean
}>

export const keys:KeysType = [
    {
        id: 'feedbackToasts',
        isErrorMessage: true
    },
    {
        id: 'articleVoteToasts',
        isErrorMessage: false
    },
    {
        id: 'contactFormToasts',
        isErrorMessage: true
    },
    {
        id: 'saveProfileToasts',
        isErrorMessage: true
    },
    {
        id: 'eventRegistrationToasts',
        isErrorMessage: true
    }
];
