import React from 'react';

import classes from './OfferingsTabs.module.pcss';
import {CourseType} from '../Offerings';
import parse from 'html-react-parser';

interface Props {
    tabs: Array<CourseType>,
    activeTab: number,
    tabClickHandler: Function
}

const OfferingsTabs = ({tabs, activeTab, tabClickHandler}: Props) => (
    <div className={classes.OfferingsTabs}>{tabs?.map((item, index) =>
        <strong
            className={`${classes.OfferingsTab} ${activeTab === item?.id ? classes.Active : ''}`}
            onClick={() => tabClickHandler(item?.id)}
            key={index}>{parse(item?.courseTitle || '')}</strong>)}</div>
);

export default OfferingsTabs;
