import React, {useState, useContext, useCallback} from 'react';
import Swiper, { SwiperInstance } from 'react-id-swiper';
import { PromoBoxContext } from 'js/context/PromoBoxContext';
import Container from 'js/components/Common/Container/Container';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';
import AnimatedMouse from 'js/components/Common/AnimatedMouse/AnimatedMouse';
import { PromoBoxParams } from 'js/components/Common/Carousel/CarouselParams/CarouselParams';

import classes from './PromoBoxItems.module.pcss';
import PromoBoxItem from '../PromoBoxItem/PromoBoxItem';
import {
	clearAnimations,
	promoMediaFadeIn,
	promoMediaFadeOut,
	promoTextFadeIn,
	promoTextFadeOut
} from '../PromoBoxItem/PromoBoxMedia/PromoBoxFunctions/PromoBoxFuntions';
import { PromoDataBoxInterface } from '../PromoBoxBlock/PromoBoxDataInterface';
import PromoBoxItemBackground from '../PromoBoxItem/PromoBoxItemBackground/PromoBoxItemBackground';

export interface Props {
	items: PromoDataBoxInterface[]
}

const PromoBoxItems = ({ items }: Props) => {
	const [promoBoxSlider, setPromoBoxSlider] = useState<SwiperInstance>(null);
	const [activeSlide, setActiveSlide] = useState<number>(0);
	const Context = useContext(PromoBoxContext);
	const setActiveSlideHandler = useCallback((slider: SwiperInstance): void => {
		setActiveSlide(slider.realIndex);
	}, []);
	const setAutoplayDelay = useCallback( (slider: SwiperInstance): void => {
        slider.params.autoplay = {
            delay: Context?.animationsTiming?.delay,
            disableOnInteraction: true
        };
    }, [Context.animationsTiming.delay]);
	const animationStack = useCallback((slider: SwiperInstance) => {
		const validateSlidesCount = (func: Function): Function | boolean => {
			if (slider?.slides.length > 1) {
				return func();
			} else {
				return false;
			}
		};
		validateSlidesCount(() => setAutoplayDelay(slider));
		validateSlidesCount(() => clearAnimations(slider));
		validateSlidesCount(() => setActiveSlideHandler(slider));
		validateSlidesCount(() => slider?.autoplay?.stop());
		validateSlidesCount(() => promoTextFadeOut(slider, Context?.animationsTiming?.text));
		promoTextFadeIn(slider);
		promoMediaFadeIn(slider, Context);
		validateSlidesCount(() => promoMediaFadeOut(slider, Context?.animationsTiming?.media));
	}, [Context, setActiveSlideHandler, setAutoplayDelay]);

	const promoSliderAdditionalParams = {
		on: {
			init() {
				animationStack(this);
			},
			transitionStart() {
				animationStack(this);
			}
		}
	};

	return (
		<DataValidator data={items}>
			<div className={classes.PromoBoxItems}>
				<div className={classes.PromoBoxItemBackgrounds}>
					{items?.map((item, index) => {
						let prevSlide = activeSlide === index + 1;
						if ((activeSlide - 1) === index) {
							prevSlide = true;
						}

						return <PromoBoxItemBackground
							key={index}
							item={item}
							active={activeSlide === index}
							previous={prevSlide}
							first={index === 0}
							zIndex={index} />;
					})}
				</div>
				<Swiper
					containerClass={`swiper-container ${classes.PromoBoxSlider}`}
					getSwiper={setPromoBoxSlider}
					{...PromoBoxParams} {...promoSliderAdditionalParams}>
					{items?.map((item, index) => {
						item.index = index;
						return (
							<div className="swiper-slide" key={index}>
								<Container>
									<PromoBoxItem item={item} slider={promoBoxSlider || null} />
								</Container>
							</div>
						);
					})}
				</Swiper>
				<Container>
					<AnimatedMouse />
				</Container>
			</div>
		</DataValidator>
	);
};

export default PromoBoxItems;
