import thumbnailFragment from 'js/graphqlFragments/ThumbnailFragment';
export default pageId => `
{
    page(id: "${pageId}", idType: DATABASE_ID) {
      AboutUs {
        headingBrands
        brands {
            ... on BrandsSingle {
              id
              title
              ...ThumbnailFragment
          }
        }
      }
    }
  }
  ${thumbnailFragment('BrandsSingle')}
`;
