import React from 'react';
import classes from './Breadcrumbs.module.pcss';
import {Link} from 'react-router-dom';
export type PageType = 'product' | 'training'

interface Props {
	title: string,
	parent: {
		name?: string,
		title?: string,
		uri: string
	}
}

const Breadcrumbs = ({ title, parent }: Props) => (
	<div className={classes.Breadcrumbs}>
		<ul>
			<li><Link to={'/homepage'}>Home page</Link></li>
			{parent && <li><Link to={`/${parent?.uri}`}>{parent?.title || parent?.name}</Link></li>}
			<li><span>{title}</span></li>
		</ul>
	</div>
);

export default Breadcrumbs;
