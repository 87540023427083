import React, {useCallback, useMemo, useState} from 'react';

import classes from './PromoBoxMedia.module.pcss';
import PromoBoxImages from '../PromoBoxImages/PromoBoxImages';

export interface Props {
	item: string
}

const AnimatedImage = ({ item }: Props) => {
	const [coordinates, setCoordinates] = useState<{
		x: number,
		y: number
	}>({ x: 0, y: 0 });
	const [backgroundPosition, setBackgroundPosition] = useState<{} | null>(null);

	const currentImg = useMemo(() => PromoBoxImages.filter(
		PromoBoxImagesItem => PromoBoxImagesItem.id === parseInt(item)
	)[0], [item]);

	const background = useMemo((): string => {
		if (!currentImg) {
			return '';
		}
		return `url('${currentImg.firstSrc}'), url('${currentImg.secondSrc}'), url('${currentImg.thirdSrc}')`;
	}, [currentImg]);

	const sortBackgroundPositions = useCallback((deps: Array<string>): Array<string> => {
		const result: Array<string> = [];
		currentImg?.bgPosition?.forEach((currentImgItem: number): void => {
			result?.push(deps[currentImgItem - 1]);
		});
		return result;
	}, [currentImg]);

	const parallaxCallback = useCallback((e) => {
		const width = window?.innerWidth;
		const height = window?.innerHeight;
		setCoordinates({
			x: e?.clientX,
			y: e?.clientY
		});
		const mouseX = coordinates.x;
		const mouseY = coordinates.y;
		const depth1 = `${100 + (mouseX - width) * 0.02}% ${50 + (mouseY + height) * 0.02}%`;
		const depth2 = `${100 + (mouseX - width) * 0.02}% ${50 + (mouseY + height) * 0.02}%`;
		const depth3 = '100% 50%';

		const deps: Array<string> = [depth1, depth2, depth3];

		setBackgroundPosition(sortBackgroundPositions(deps));
	}, [coordinates, sortBackgroundPositions]);

	const styles: any = useMemo(() => (
		{
			backgroundImage: background,
			backgroundPosition
		}
	), [background, backgroundPosition]);

	return (
		<div className={`${classes.AnimatedImageWrap} animatedImage`}>
			<div
				className={classes.AnimatedImage}
				onMouseMove={parallaxCallback}
				style={styles}
			></div>
		</div>
	);
};

export default AnimatedImage;
