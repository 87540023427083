import React, {useCallback, useContext, useMemo, useRef, useState} from 'react';
import parse from 'html-react-parser';
import {slideToggle} from 'js/helpers/toggleSlide';
import Button from 'js/components/Common/Button/Button';
import useDebounce from 'js/hooks/useDebounce/useDebounce';
import Form from 'js/components/Common/FormComponents/Form/Form';
import clearGraphQlStringValue from 'js/helpers/clearGraphQlStringValue';
import useFetchCallback from 'js/hooks/useFetchCallback/useFetchCallback';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';
import TextArea from 'js/components/Common/FormComponents/TextArea/TextArea';
import ReCaptcha from 'js/components/Common/FormComponents/ReCaptcha/ReCaptcha';
import {ToastMessagesContext} from 'js/context/ToastMessagesContext/ToastMessagesContext';

import fetchData from './fetchData';
import classes from './Feedback.module.pcss';

export interface Props {
    title: string,
    id: number,
    link: string,
    label: any,
    eventHandler: any
}

const Feedback = ({label, id, title = '', eventHandler, link = 'https://paytech.academy.com'}:  Props) => {
    const message = 'FeedbackMessage';
    const recaptcha = 'ReCaptcha';
    const recaptchaRef = useRef(null);
    const [formIsValid, setFormIsValid] = useState<boolean>(false);
    const [isSpinner, setIsSpinner] = useState<boolean>(false);
    const nodeRef = useRef(null);
    const {addToastMessage} = useContext(ToastMessagesContext);
    const timeouts = useMemo(() => (
        {
            slide: 200,
            expandDebounce: 100
        }
    ), []);

    const feedbackSubmitHandler = useFetchCallback({
        updateData: data => data?.monday?.sendFeedBack,
        query: (resultData) => {
            const token = resultData.find(item => item.name === recaptcha)?.value;
            const feedback = resultData.find(item => item.name === message)?.value;
            return `${fetchData(
                id,
                link,
                parse(title),
                clearGraphQlStringValue(feedback || ''),
                token
            )}`;
        },
        onStart: () => setIsSpinner(true),
        onSuccess: () => {
            addToastMessage({
                type: 'success',
                key: 'feedbackToasts'
            });
            setFormIsValid(false);
            setIsSpinner(false);
            recaptchaRef.current.reset();
            eventHandler(title);
        },
        onFailure: () => {
            addToastMessage({
                type: 'error',
                key: 'feedbackToasts'
            });
            setFormIsValid(false);
            setIsSpinner(false);
            recaptchaRef.current.reset();
        },
        onError: () => {
            addToastMessage({
                type: 'error',
                key: 'feedbackToasts'
            });
            setFormIsValid(false);
            setIsSpinner(false);
            recaptchaRef.current.reset();
        }
    });

    const expandCallback = useCallback((currentEl, currentContentBlock) => {
        slideToggle(currentContentBlock, 'ShowExpandSection', timeouts.slide, currentEl);
    }, [timeouts.slide]);

    const debouncedExpandCallback = useDebounce(expandCallback, timeouts.expandDebounce);

    const expandableHeadingHandler = useCallback((e) => {
        const currentEl = e.currentTarget;
        const currentContentBlock = e.currentTarget.nextSibling;
        if (!currentContentBlock || !nodeRef) {
            return;
        }
        debouncedExpandCallback(currentEl, currentContentBlock);
    }, [nodeRef, debouncedExpandCallback]);

    return (
        <DataValidator data={!!(label?.length > 0 && id)}>
            <div className={classes.Feedback} ref={nodeRef}>
                <span onClick={expandableHeadingHandler} className={classes.FeedbackLabel}>{parse(label || '')}</span>
                <div className={classes.FeedbackForm}>
                    <Form onSubmit={feedbackSubmitHandler} resetAfterSubmit={true} onValidForm={setFormIsValid}>
                        <TextArea
                            height={160}
                            id={message}
                            key={message}
                            name={message}
                            type={message}
                            maxLength={1000}
                            withMargin={true}
                            placeholder={'Up to 1000 characters...'}
                            validation={{
                                rules: [
                                    { type: 'required', message: 'Required Field' }
                                ]
                            }}
                        />
                        <ReCaptcha
                            size={'normal'}
                            type={recaptcha}
                            name={recaptcha}
                            recaptchaRef={recaptchaRef}
                            validation={{
                                rules: [
                                    { type: 'required', message: 'Required Field' }
                                ]
                            }}/>
                        <div className={classes.ButtonWrap}>
                            <Button withSpinner={isSpinner} text={'Submit'} type={'button'} disabled={!formIsValid} />
                        </div>
                    </Form>
                </div>
            </div>
        </DataValidator>
    );
};

export default Feedback;
