import React from 'react';
import Button from 'js/components/Common/Button/Button';
import Heading from 'js/components/Common/Heading/Heading';
import DateByTimezone from 'js/components/Common/DateByTimezone/DateByTimezone';
import Thumbnail from 'js/components/Common/ItemThumbnail/Thumbnail/Thumbnail';
import PriceContainer from 'js/components/Common/Carousel/PriceContainer/PriceContainer';
import CarouselItemDescription from 'js/components/Common/Carousel/CarouselItemDescription/CarouselItemDescription';

import classes from '../IndustryTalksCarouselBlock/IndustryTalksCarouselBlock.module.pcss';
import { IndustryTalksCarouselDataInterface } from '../IndustryTalksCarouselBlock/IndustryTalksCarouselDataInterface';

export interface Props {
	buttonText: string,
	item?: IndustryTalksCarouselDataInterface
}

const IndustryTalksCarouselItem = ({ buttonText, item }: Props) => (
	<div className={classes.BoxItem}>
		<div className="CarouselItemWrapper">
			<Thumbnail item={item} index={0} type="meeting_48" />
			<PriceContainer showPrice={true} heading={item?.Meetings?.category} price={item?.Meetings?.price} />
			<Heading text={item?.title} type="h3" />
			<CarouselItemDescription description={item?.Meetings?.shortDescription} />
		</div>
		<div className="CarouselItemWrapper">
			<DateByTimezone className={classes?.DateByTimezone} date={item?.Meetings?.startDate} format={'DD MMMM YYYY, HH:mm '} />
			<Button type="button" text={buttonText} />
		</div>
	</div>
);

export default IndustryTalksCarouselItem;
