import React, {useCallback, useEffect, useRef, useState} from 'react';
import parse from 'html-react-parser';
import useAnchor from 'js/hooks/useAnchor/useAnchor';
import Button from 'js/components/Common/Button/Button';
import {useLocation} from 'react-router-dom';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Container from 'js/components/Common/Container/Container';
import Input from 'js/components/Common/FormComponents/Input/Input';
import useFetchCallback from 'js/hooks/useFetchCallback/useFetchCallback';
import SpinnerLoader from 'js/components/Common/SpinnerLoader/SpinnerLoader';
import AutoComplete from 'js/components/Common/SearchBlock/AutoComplete/AutoComplete';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';
import {observer} from 'mobx-react';

import classes from './SearchForm.module.pcss';
import {incrementFetch, subtitleFetch} from './fetchData';
import useSearchReset from 'js/hooks/useSearchReset/useSearchReset';
import SearchStore from 'js/store/SearchStore';
import {useHistory} from 'react-router';

const SearchForm = ({headerRef, isStatic = false}) => {
    const history = useHistory();
    const {searchStatus, searchQuery, showSearch, autoCompleteSpinnerStatus} = SearchStore;
    const location = useLocation();
    const inputRef = useRef(null);
    const autoCompleteRef = useRef(null);
    const resetSearch = useSearchReset({
        nodeRef: headerRef,
        isLocalRef: false
    });
    const [typingTimeOut, setTypingTimeout] = useState<any>(0);
    const [searchCountId, setSearchCountId] = useState<number>(0);
    const [searchSubtitle, setSearchSubtitle] = useState<string>('');
    const [autoCompleteQuery, setAutoCompleteQuery] = useState<string>('');
    const anchor = useAnchor('SearchResults', 0, -120);
    const [autoCompleteShow, setAutoCompleteShow] = useState<boolean>(false);
    const [focusAutocompleteItem, setFocusAutocompleteItem] = useState<number | null>(-1);

    useEffect(() => {
        if (showSearch) {
            inputRef.current.focus();
        }
    }, [showSearch]);

   useEffect(() => {
       if (location.pathname !== '/search') {
           inputRef.current.value = '';
           SearchStore.setShowSearch(false);
           SearchStore.setFilterEntitiesData('');
           SearchStore.setFilterTaxData({
               suitableFor: '',
               skills: '',
               productLegacy: '',
               subjectMatter: '',
               language: '',
               articleType: '',
               courseType: '',
               eventType: '',
               videoType: '',
               podcastType: '',
               presentationType: '',
               manualsType: ''
           });
           SearchStore.setSearchStatus(false);
       }
   }, [
       location,
       searchQuery
   ]);

    useEffect(() => {
        const handlePopstate = (e) => {
            if (!e?.state?.state && e.currentTarget?.location?.pathname === '/search') {
                SearchStore.setSearchStatus(false);
                window.location.reload();
            }
        };

        window.addEventListener('popstate', handlePopstate);

        return () => {
            window.removeEventListener('popstate', handlePopstate);
        };
    }, []);

    const incrementSearchCount = useFetchCallback({
        updateData: () => {},
        query: e => `${incrementFetch(searchCountId, e)}`,
        onError: (e) => {
            console.error(e);
        }
    });

    useFetchData({
        query: subtitleFetch,
        condition: true,
        updateData: (data) => {
            setSearchSubtitle(data?.themeGeneralSettings?.Options?.searchFormSubtitle);
        }
    });

    const countWords = useCallback((str) => {
        let result = str.replace(/(^\s*)|(\s*$)/gi, '');
        result = result.replace(/[ ]{2,}/gi, ' ');
        result = result.replace(/\n /, '\n');
        return result.split(' ').length;
    }, []);

    const sendMixPanelEvent = useCallback(async (acValue:string) => {
        const wordsCount = countWords(acValue);
        if (!acValue?.length) {
            return;
        }
        await MixPanelWrapper.TrackEvent(
            'Submit the search',
            {
                category: 'Search Input',
                value: acValue,
                words: wordsCount,
                characters: acValue.length
            });
    }, [countWords]);

    const searchCallback = useCallback( () => {
        incrementSearchCount(inputRef?.current.value);
        setSearchCountId(0);
        setAutoCompleteQuery('');
        SearchStore.setSearchQuery(inputRef?.current.value);
        SearchStore.setSearchPage(1);
        if (location.pathname !== '/search' && inputRef?.current?.value) {
            history?.push('/search');
        }
        if (location.pathname === '/search') {
            anchor();
        }
    }, [incrementSearchCount, location, history, anchor]);


    const searchSubmitHandler = useCallback(async (e) => {
        e.preventDefault();
        resetSearch();
        SearchStore.setAtoCompleteSpinnerStatus(false);
        SearchStore.setShowSearch(false);
        const inputValue = e?.target?.children[0]?.children[0]?.value;
        if (!inputValue) {
            SearchStore.setEntitiesCounts([]);
            SearchStore.setTaxCounts(
                {
                    suitableFor: [],
                    subjectMatter: [],
                    skills: [],
                    product: [],
                    language: [],
                    articleType: [],
                    courseType: [],
                    eventType: [],
                    videoType: [],
                    podcastType: [],
                    presentationType: [],
                    manualsType: []
                }
            );
            return;
        }
        searchCallback();
        await sendMixPanelEvent(inputRef?.current?.value);
        SearchStore.setSearchStatus(false);
        if (typingTimeOut) {
            clearTimeout(typingTimeOut);
        }
    }, [
        sendMixPanelEvent,
        resetSearch,
        typingTimeOut,
        searchCallback
    ]);

    const searchInputChangeHandler = useCallback((e) => {
        e.persist();
        if (typingTimeOut) {
            SearchStore.setAtoCompleteSpinnerStatus(false);
            clearTimeout(typingTimeOut);
        }
       setTypingTimeout(
           setTimeout( () =>  {
               setAutoCompleteShow(false);
               if (!e?.target?.value?.length) {
                   setAutoCompleteQuery('');
                   SearchStore.setAtoCompleteSpinnerStatus(false);
                   setFocusAutocompleteItem(-1);
                   setAutoCompleteShow(false);
                   return;
               }
               SearchStore.setAtoCompleteSpinnerStatus(true);
               const filteredValue = e.target.value.replace(/\W/g, '');
               if (filteredValue) {
                   setAutoCompleteQuery(e.target.value);
                   setFocusAutocompleteItem(-1);
                   setAutoCompleteShow(true);
                   return;
               }
               SearchStore.setAtoCompleteSpinnerStatus(false);
               setAutoCompleteQuery('');
               setAutoCompleteShow(false);
               setFocusAutocompleteItem(-1);
           }, 800)
       );
    }, [typingTimeOut]);

    const autoCompleteClickHandler = useCallback(async (e, id) => {
        resetSearch();
        inputRef.current.value = e?.currentTarget?.textContent || '';
        searchCallback();
        setSearchCountId(id);
        SearchStore.setTaxCounts(
            {
                suitableFor: [],
                skills: [],
                productLegacy: [],
                subjectMatter: [],
                language: [],
                articleType: [],
                courseType: [],
                eventType: [],
                videoType: [],
                podcastType: [],
                presentationType: [],
                manualsType: []
            }
        );
        SearchStore.setEntitiesCounts([]);
        SearchStore.setAtoCompleteSpinnerStatus(false);
        await sendMixPanelEvent(inputRef?.current?.value);
        if (typingTimeOut) {
            clearTimeout(typingTimeOut);
        }
    }, [
        resetSearch,
        sendMixPanelEvent,
        typingTimeOut,
        searchCallback
    ]);

    const autoCompleteChangeHandler = useCallback((e, id) => {
        if (focusAutocompleteItem >= 0) {
            setSearchCountId(id);
            inputRef.current.value = e?.textContent;
            inputRef.current.focus();
        }
    }, [focusAutocompleteItem]);

    const keyUpHandler = useCallback((e) => {
        e.persist();

        if (!autoCompleteRef?.current) {
            return;
        }
        const autoCompleteItemsIndexCount = autoCompleteRef.current.children[1].childElementCount - 1;
        const {key} = e || {};
        if (key === 'ArrowDown') {
            if (focusAutocompleteItem === autoCompleteItemsIndexCount) {
                setFocusAutocompleteItem(0);
            }
            if (focusAutocompleteItem < autoCompleteItemsIndexCount) {
                setFocusAutocompleteItem(focusAutocompleteItem + 1);
            }
        }
        if (key === 'ArrowUp') {
            if (focusAutocompleteItem === -1 || focusAutocompleteItem === 0) {
                setFocusAutocompleteItem(autoCompleteItemsIndexCount);
            }
            if (focusAutocompleteItem > 0 && focusAutocompleteItem < 6) {
                setFocusAutocompleteItem(focusAutocompleteItem - 1);
            }
        }
    }, [focusAutocompleteItem]);

    const closeAutoComplete = useCallback((e) => {
        if (!e.target.closest('[data-search]')) {
            setAutoCompleteShow(false);
            SearchStore.setAtoCompleteSpinnerStatus(false);
            if (typingTimeOut) {
                clearTimeout(typingTimeOut);
            }
        }

    }, [typingTimeOut]);

    const closeSearch = useCallback((e) => {
        if (!e.target.closest('[data-show-search-form]')) {
            setAutoCompleteShow(false);
            SearchStore.setAtoCompleteSpinnerStatus(false);
            SearchStore.setShowSearch(false);
            if (typingTimeOut) {
                clearTimeout(typingTimeOut);
            }
        }
    }, [typingTimeOut]);

    useEffect(() => {
        window.addEventListener('click', closeAutoComplete);
        window.addEventListener('click', closeSearch);
        return () => {
            window.removeEventListener('click', closeAutoComplete);
            window.removeEventListener('click', closeSearch);
        };
    }, [closeAutoComplete, closeSearch]);

    useEffect(() => {
        if (searchStatus) {
            setAutoCompleteShow(false);
        }
    }, [searchStatus]);

    return (
        <div data-show-search-form={true} className={`${classes.SearchForm} ${showSearch ? classes.Active : ''} ${isStatic ? classes.Static : ''}`}>
            <Container additionalClass={classes.SearchFormWrap}>
                <form data-search={true} className={classes.SearchFormInner} onSubmit={searchSubmitHandler}>
                    <Input
                        type="text"
                        onKeyUp={e => keyUpHandler(e)}
                        addRef={inputRef}
                        onChange={searchInputChangeHandler}
                        name = {'globalSearch'}
                        id = {'globalSearch'}
                        minLength={1}
                        placeholder={'Type here...'}
                        autoComplete={'off'}
                        customStylesClass={classes.SearchInputField}
                    />
                    <SpinnerLoader active={autoCompleteSpinnerStatus} additionalStyles={{
                        width: '35px',
                        right: '85px',
                        top: '23px'
                    }}/>
                    <Button
                        type={'input'}
                        text={'Search'}
                        customStylesClass={classes.SearchButton}/>
                    <div className={classes.Subtitle}>{parse(searchSubtitle || '')}</div>
                    <AutoComplete
                        itemRef={autoCompleteRef}
                        show={autoCompleteShow}
                        query={autoCompleteQuery}
                        focus={focusAutocompleteItem}
                        itemClick={autoCompleteClickHandler}
                        itemChange={autoCompleteChangeHandler}
                    />
                </form>
                <span className={classes.Close} onClick={() => SearchStore.setShowSearch(false)} />
            </Container>
        </div>
    );
};

export default observer(SearchForm);
