import React from 'react';
import SimpleContent from 'js/components/Common/SimpleContent/SimpleContent';

const DefaultTemplate = ({ pageId }) => (
	<main className="templateDefault">
		<SimpleContent pageId={pageId} />
	</main>
);

export default DefaultTemplate;
