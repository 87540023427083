import React, { useState } from 'react';
import Heading from 'js/components/Common/Heading/Heading';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Container from 'js/components/Common/Container/Container';
import SubHeading from 'js/components/Common/SubHeading/SubHeading';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import fetchData from './fetchData';
import classes from './GetToKnowBlock.module.pcss';
import GetToKnowItems from '../GetToKnowItems/GetToKnowItems';
import { GetToKnowBlockDataInterface } from './GetToKnowBlockDataInterface';

const GetToKnowBlock = ({ pageId }) => {
	const [getToKnowTitle, setGetToKnowTitle] = useState<string>('');
	const [getToKnowSubTitle, setGetToKnowSubTitle] = useState<string>('');
	const [getToKnowSubItems, setGetToKnowItems] = useState<GetToKnowBlockDataInterface[]>([]);

	const {error, isLoading} = useFetchData({
		query: `${fetchData(pageId)}`,
		condition: !!pageId,
		updateData: (data) => {
			setGetToKnowItems(data?.page?.AboutUs?.teamGetToKnow);
			setGetToKnowTitle(data?.page?.AboutUs?.headingGetToKnow);
			setGetToKnowSubTitle(data?.page?.AboutUs?.subtitleGetToKnow);
		}
	});

	return (
		<DataValidator data={!!getToKnowSubItems && !error && !isLoading}>
			<section className={classes.GetToKnow}>
				<Container additionalClass={classes.GetToKnowWrap}>
					<Heading text={getToKnowTitle} />
					<SubHeading text={getToKnowSubTitle} />
					<GetToKnowItems items={getToKnowSubItems} />
				</Container>
			</section>
		</DataValidator>
	);
};

export default GetToKnowBlock;
