import imageFragment from 'js/graphqlFragments/ImageFragment';
export default `
    {
        themeGeneralSettings {
            Options {
              testimonialsSlider {
              client {
                company
                location
                name
                photo {
                  ...ImageFragment
                }
              }
              info
            }
            }
      }
    }
    ${imageFragment()}
`;

