import React, { useState } from 'react';
import parse from 'html-react-parser';
import Heading from 'js/components/Common/Heading/Heading';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Container from 'js/components/Common/Container/Container';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import fetchData from './fetchData';
import classes from './Topic.module.pcss';
import TopicItems from '../TopicItems/TopicItems';
import { TopicDataInterface } from './TopicDataInterface';

const Topic = ({ pageId }) => {
	const [topicTitle, setTopicTitle] = useState<string>('');
	const [topicData, setTopicData] = useState<TopicDataInterface[]>([]);

	const { isLoading, error } = useFetchData({
	    query: `${fetchData(pageId)}`,
	    condition: !!pageId,
	    updateData: (data) => {
			setTopicTitle(data?.page?.PreLoginPage?.headingTopic);
			setTopicData(data?.page?.PreLoginPage?.topics);
	    }
	});

	return (
		<DataValidator data={!!topicData?.length && !isLoading && !error}>
			<section className={classes.Topic}>
				<Container>
					<Heading text={topicTitle ? parse(topicTitle) : ''} thin={true} />
				</Container>
				<TopicItems data={topicData} />
			</section>
		</DataValidator>
	);
};

export default Topic;
