import React from 'react';
import TeamProfile from 'js/components/Common/TeamProfile/TeamProfile';
import classes from 'js/components/Templates/Events/EventsList/EventListItem/EventListItem.module.pcss';

const Participants = ({eventType, host, hosts, panelists}) => {
    switch (eventType) {
        case 'Conference' : {
            return <></>;
        }
        case 'Classroom' : {
            return (
                <>
                    <TeamProfile type={'Big'} title={host?.title} position={host?.Team?.positionEvents} photo={host?.featuredImage} />
                </>
            );
        }
        case 'Panel' : {
            return (
                <>
                    <TeamProfile type={'Big'} title={host?.title} position={host?.Team?.positionEvents} photo={host?.featuredImage} />
                    <div className={classes.Panelists}>
                        {panelists?.map(panelist => (
                            <TeamProfile
                                key={panelist?.id}
                                type={'Small'}
                                title={panelist?.title}
                                position={panelist?.Team?.positionEvents}
                                additionalClass={classes.PanelistsItem}
                                photo={panelist?.featuredImage} />
                        ))}
                    </div>
                </>
            );
        }
        case 'Webinar' : {
            if (hosts?.length === 1) {
                return <TeamProfile
                    type={'Big'}
                    title={hosts[0]?.title}
                    position={hosts[0]?.Team?.positionEvents}
                    photo={hosts[0]?.featuredImage} />;
            }
            if (hosts?.length > 1) {
                return (
                    <div className={classes.ParticipantsList}>
                        {hosts?.map(hostItem => (
                            <TeamProfile
                                key={hostItem?.id}
                                type={'Small'}
                                title={hostItem?.title}
                                position={hostItem?.Team?.positionEvents}
                                additionalClass={classes.ParticipantsListItem}
                                photo={hostItem?.featuredImage} />
                        ))}
                    </div>
                );
            }
            return <></>;
        }
        default : return <></>;
    }
};

export default Participants;
