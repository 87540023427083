import React, {useCallback} from 'react';
import Image from 'js/components/Common/Image/Image';

import classes from './TestimonialItem.module.pcss';

const TestimonialItem = ({ item, testimonialsSlider }) => {
	const { info, client } = item;
	const { photo, name, company, location } = client;

	const onHoverSlideStart = useCallback(() => {
		if (testimonialsSlider) {
			testimonialsSlider.autoplay ? testimonialsSlider.autoplay.start() : false;
		}
	}, [testimonialsSlider]);

	const onHoverSlideStop = useCallback(() => {
		if (testimonialsSlider) {
			testimonialsSlider.autoplay ? testimonialsSlider.autoplay.stop() : false;
		}
	}, [testimonialsSlider]);

	return (
		<div className={classes.TestimonialItem}>
			<blockquote
				className={classes.TestimonialItemInfo}
				onMouseOver={onHoverSlideStop}
				onMouseLeave={onHoverSlideStart}
			>
				<p>{info}</p>
			</blockquote>
			<div className={classes.TestimonialItemAuthor}
			     onMouseOver={onHoverSlideStop}
			     onMouseLeave={onHoverSlideStart}
			>
				<div className={`${classes.TestimonialItemAuthorWrap}`}>
					<figure className={classes.TestimonialItemAuthorPhoto}>
						<Image
							mediaItemUrl={photo?.mediaItemUrl}
							mediaDetails={photo?.mediaDetails}
							size="thumbnail"
							alt={photo?.altText} />
					</figure>
					<div className={classes.TestimonialItemAuthorInfo}>
						<strong className={classes.name}>{name}</strong>
						<span className={classes.company}>{company}</span>
						<span className={classes.location}>{location}</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TestimonialItem;
