import Input from 'js/components/Common/FormComponents/Input/Input';
import React, {useEffect, useMemo, useState} from 'react';

import classes from './Checkboxes.module.pcss';
import {CookieDescriptionsType} from '../SecondStage/SecondStage';
import Heading from 'js/components/Common/Heading/Heading';
import parse from 'html-react-parser';

interface Props {
    sendDataToParent: Function,
    descriptions: CookieDescriptionsType
}

const Checkboxes = ({sendDataToParent, descriptions}: Props) => {
    const [mixPanelVal, setMixPanelVal] = useState<boolean>(false);
    const {
        analyticsCookiesInfo,
        analyticsCookiesTitle,
        essentialCookiesInfo,
        essentialCookiesTitle
    } = useMemo(() => descriptions, [descriptions]);
    useEffect(() => {
        sendDataToParent(mixPanelVal);
    }, [mixPanelVal, sendDataToParent]);

    return (
        <div className={classes.Checkboxes}>
            <div className={classes.Info}>
                <Heading text={essentialCookiesTitle} type={'h6'}/>
                {parse(essentialCookiesInfo || '')}
            </div>
            <Input
                type="checkbox"
                withLabel={true}
                slideCheckbox={true}
                slideCheckboxType={'Big'}
                slideCheckboxColorType={'Pink'}
                id={'logged_in'}
                slug={'logged_in'}
                name={'logged_in'}
                checked={true}
                labelText={'Wordpress logged in'}
                disabled={true}
                additionalClass={classes.Item}
            />
            <Input
                type="checkbox"
                withLabel={true}
                slideCheckbox={true}
                slideCheckboxType={'Big'}
                slideCheckboxColorType={'Pink'}
                id={'wordpressTestCookie'}
                slug={'wordpressTestCookie'}
                name={'wordpressTestCookie'}
                checked={true}
                labelText={'Wordpress Test Cookie'}
                disabled={true}
                additionalClass={classes.Item}
            />
            <div className={classes.Info}>
                <Heading text={analyticsCookiesTitle} type={'h6'}/>
                {parse(analyticsCookiesInfo || '')}
            </div>
            <Input
                type="checkbox"
                withLabel={true}
                slideCheckbox={true}
                slideCheckboxType={'Big'}
                slideCheckboxColorType={'Pink'}
                id={'mixpanel'}
                slug={'mixpanel'}
                name={'mixpanel'}
                checked={mixPanelVal}
                labelText={'MixPanel'}
                additionalClass={classes.Item}
                onChange={() => setMixPanelVal(prevState => !prevState)}
            />
        </div>
    );
};
export default Checkboxes;
