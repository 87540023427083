import React, { useEffect, useState } from 'react';
import Heading from 'js/components/Common/Heading/Heading';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Container from 'js/components/Common/Container/Container';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';
import CarouselSlider from 'js/components/Common/Carousel/CarouselSlider/CarouselSlider';
import EventListItem from 'js/components/Templates/Events/EventsList/EventListItem/EventListItem';
import { filterMeetingByStartDate, sortMeetingsByStartDate } from 'js/components/Common/Carousel/CarouselFilters/CarouselFilters';
import { sessionCarouselParams } from 'js/components/Common/Carousel/CarouselParams/CarouselParams';

import carouselFetchData from './fetchData';
import classes from './SessionsCarouselBlock.module.pcss';
import { SessionsCarouselBlockDataInterface } from './SessionsCarouselBlockDataInterface';

const SessionsCarouselBlock = ({ pageId }) => {
	const [sessionCarouselTitle, setSessionCarouselTitle] = useState<string>('');
	const [sessionCarouselButtonsText, setSessionCarouselButtonsText] = useState<string>('');
	const [sessionCarouselData, setSessionCarouselData] = useState<SessionsCarouselBlockDataInterface[]>([]);
	const [dataFiltered, setDataFiltered] = useState<SessionsCarouselBlockDataInterface[]>([]);

	const { isLoading, error } = useFetchData({
	    query: `${carouselFetchData(pageId)}`,
	    condition: !!pageId,
	    updateData: (data) => {
			const carouselData = data?.page?.PreLoginPage;
			setSessionCarouselData(carouselData?.sessionsCarousel);
			setSessionCarouselTitle(carouselData?.headingSessionsCarousel);
			setSessionCarouselButtonsText(carouselData?.buttonTextSessionsCarousel);
	    }
	});

	useEffect(() => {
		let filteredData = filterMeetingByStartDate(sessionCarouselData);
		filteredData = sortMeetingsByStartDate(filteredData);
		setDataFiltered(filteredData);
	}, [sessionCarouselData]);

	return (
		<DataValidator data={!!dataFiltered?.length && !isLoading && !error}>
			<section id={'SessionsCarousel'} className={classes.SessionsCarousel}>
				<Container additionalClass={`${classes.SessionsCarouselContainer}`}>
					<Heading text={sessionCarouselTitle} />
					<CarouselSlider
						parentID={'SessionsCarousel'}
						useArrows={false}
						useBullets={true}
						isColumnOfThree={true}
						paramsProp={sessionCarouselParams}
						items={dataFiltered}>
						<EventListItem
							buttons={{
							joinEventButton: '',
							registerEventButton: '',
							viewEventButton: sessionCarouselButtonsText
						}} sectionType={'webinar'} />
					</CarouselSlider>
				</Container>
			</section>
		</DataValidator>
	);
};

export default SessionsCarouselBlock;
