import React, {useState} from 'react';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Container from 'js/components/Common/Container/Container';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import fetchData from './fetchData';
import classes from './ContactTopInfo.module.pcss';
import parse from 'html-react-parser';

const ContactTopInfo = ({pageId}) => {
	const [info, setInfo] = useState<string>('');
	const { isLoading, error } = useFetchData({
	    query: fetchData(pageId),
	    condition: !!pageId,
	    updateData: (data) => {
	        setInfo(data?.page?.ContactUs?.contactUsInfo);
	    }
	});
    return (
        <DataValidator data={!isLoading && !error && !!info}>
			<section className={classes.ContactTopInfo}>
				<Container additionalClass={classes.Wrap}>
					{ parse(info || '') }
				</Container>
			</section>
		</DataValidator>
    );
};

export default ContactTopInfo;
