import React, {useState} from 'react';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';
import VideoBackground, {AssetsType} from 'js/components/Common/VideoBackground/VideoBackground';

import classes from './HomeBackground.module.pcss';
import fetchData from './fetchData';

const HomeBackground = (props) => {
	const { children, pageId } = props || {};
	const [videoData, setVideoData] = useState<AssetsType>({
		mp4: {
			mediaItemUrl: ''
		},
		placeholder: {
			mediaItemUrl: ''
		},
		muteButtonColor: '',
		overlay: false,
		overlayOpacity: 0,
		soundButton: true
	});

	const { isLoading, error } = useFetchData({
	    query: `${fetchData(pageId)}`,
	    condition: !!pageId,
	    updateData: (data) => {
			setVideoData(data?.page?.PostLoginPage?.videoData);
	    }
	});

    return (
		<DataValidator data={!!videoData && !isLoading && !error}>
			<div className={classes.HomeBackground}>
				<VideoBackground assets={videoData}>
					{children}
				</VideoBackground>
			</div>
		</DataValidator>
    );
};

export default HomeBackground;
