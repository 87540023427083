import React from 'react';
import Image from 'js/components/Common/Image/Image';
import Heading from 'js/components/Common/Heading/Heading';

import classes from './ValueItem.module.pcss';

const ValueItem = ({ item }) => {
	const { title, info } = item;
	const { mimeType, altText, mediaItemUrl, mediaDetails } = item?.image || {};
	return (
		<div className={classes.ValuesItem}>
			<Image
				mediaItemUrl={mediaItemUrl}
				mimeType={mimeType}
				size="thumbnail"
				mediaDetails={mediaDetails}
				alt={altText || title} />
			<Heading text={title} type="h3" thin={true} />
			<p>{info}</p>
		</div>
	);
};

export default ValueItem;

