import React, { useState } from 'react';
import Logo from 'js/components/Common/Logo/Logo';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Container from 'js/components/Common/Container/Container';
import SocialMedia from 'js/components/Common/SocialMedia/SocialMedia';
import FooterInfo from 'js/components/Common/Footer/FooterInfo/FooterInfo';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';
import NavigationMenu from 'js/components/Common/Navigation/NavigationMenu/NavigationMenu';

import fetchData from './fetchData';
import classes from './Footer.module.pcss';
import { FooterDataInterface } from './FooterDataInterface';

const Footer = () => {
	const [footerData, setFooterData] = useState<FooterDataInterface>({});

	const { isLoading, error } = useFetchData({
	    query: `${fetchData()}`,
	    condition: true,
	    updateData: data => setFooterData(data?.themeGeneralSettings?.Options)
	});

	return (
		<DataValidator data={!isLoading && !error}>
			<footer id="footer" className={classes.Footer}>
				<Container>
					<div className={classes.FooterWrapper}>
						<div className={classes.FooterInfoWrap}>
							<Logo footerLogo={true} />
							<FooterInfo data={footerData?.footerInfo} />
							<SocialMedia data={footerData?.socialMedia} />
						</div>
						<div className={classes.FooterNavigationWrap}>
							<NavigationMenu location="FIRST_FOOTER_MENU" heading={true} />
							<NavigationMenu location="SECOND_FOOTER_MENU" heading={true} />
							<NavigationMenu location="THIRD_FOOTER_MENU" heading={true} />
							<NavigationMenu location="FOURTH_FOOTER_MENU" heading={true} />
						</div>
					</div>
				</Container>
			</footer>
		</DataValidator>
	);
};

export default Footer;
