import React, {useMemo} from 'react';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import Manual from './Manual/Manual';
import classes from './AttachedManuals.module.pcss';
import MoreInfoBlock from '../MoreInfoBlock/MoreInfoBlock';

export type ManualDataType = {
    title: string,
    manual: {
        databaseId: number,
        title: string,
        Manuals: {
            manual: {
                mediaItemUrl: string
            }
        }
    }
};

interface Props {
    manuals: Array<ManualDataType>
}

const AttachedManuals = ({manuals}: Props) => {
    const isNonExpiredExist = useMemo(() => !!manuals?.find(item => item?.manual), [manuals]);
    return (
        <DataValidator data={manuals?.length > 0 && isNonExpiredExist}>
            <div className={classes.AttachedManuals}>
                <MoreInfoBlock type={'manualDownloadInfo'} />
                <div className={classes.AttachedManualsWrap}>
                    { manuals?.map(manual =>
                        <Manual
                            key={manual?.manual?.databaseId}
                            id={manual?.manual?.databaseId}
                            title={manual?.title}
                            url={manual?.manual?.Manuals?.manual?.mediaItemUrl}
                            buttonText={'Download'} />)
                    }
                </div>
            </div>
        </DataValidator>
    );
};

export default AttachedManuals;
