import React, {useCallback, useState, useEffect, useMemo} from 'react';

import useGetUser, {userInitialData, UserType} from 'js/hooks/useGetUser/useGetUser';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';

interface CommonContextInterface {
	apiPath: string,
	oneLoginBaseURL: string,
	currentUser: UserType,
	isCustomerLogged: boolean | null,
	activeMenuItemId: number,
	setActiveMenuItemId: Function,
	showGDPR: 'show' | 'toggle' | 'hide' | 'none',
	setShowGDPR: Function,
	isAnnouncementBar: boolean,
	setIsAnnouncementBar: Function
}

export const CommonContext = React.createContext<CommonContextInterface>({
		apiPath: '',
		oneLoginBaseURL: '',
		currentUser: userInitialData,
		isCustomerLogged: null,
		activeMenuItemId: null,
		setActiveMenuItemId: value => value,
		showGDPR: 'hide',
		setShowGDPR: value => value,
		isAnnouncementBar: false,
		setIsAnnouncementBar: value => value
	}
);

const CommonContextProvider = (props) => {
	const {
		NODE_ENV,
		REACT_APP_LOCAL_LINK,
		REACT_APP_PROD_LINK,
		REACT_APP_ONE_LOGIN_PROD_BASE_URL,
		REACT_APP_ONE_LOGIN_DEV_BASE_URL
	} = process.env || {};

	const [activeMenuItemId, setActiveMenuItemId] = useState<number>(null);
	const [isAnnouncementBar, setIsAnnouncementBar] = useState<boolean>(false);
	const [showGDPR, setShowGDPR] = useState<'show' | 'toggle' | 'hide' | 'none'>('show');

	const getApiPath = useCallback(():string => {
		if (NODE_ENV === 'development') {
			return REACT_APP_LOCAL_LINK;
		}
		return window.location.origin;
	}, [NODE_ENV, REACT_APP_LOCAL_LINK]);

	const getOneLoginBaseURL = useCallback(() => {
		if (window.location.origin === REACT_APP_PROD_LINK) {
			return REACT_APP_ONE_LOGIN_PROD_BASE_URL;
		}
		return REACT_APP_ONE_LOGIN_DEV_BASE_URL;
	}, [
		REACT_APP_PROD_LINK,
		REACT_APP_ONE_LOGIN_PROD_BASE_URL,
		REACT_APP_ONE_LOGIN_DEV_BASE_URL
	]);

	const currentUserData = useGetUser(getApiPath());
	const {currentUser, isCustomerLogged}  = useMemo(() => currentUserData || {
		currentUser: null,
		isCustomerLogged: false
	}, [currentUserData]);

	useEffect(() => {
		(async () =>
			MixPanelWrapper.Init(currentUser, isCustomerLogged, window?.location?.origin !== REACT_APP_PROD_LINK)
		)();
	}, [currentUser, isCustomerLogged, REACT_APP_PROD_LINK]);

	return (
		<CommonContext.Provider
			value={
				{
					showGDPR,
					setShowGDPR,
					activeMenuItemId,
					isCustomerLogged,
					isAnnouncementBar,
					setActiveMenuItemId,
					setIsAnnouncementBar,
					apiPath: getApiPath(),
					oneLoginBaseURL: getOneLoginBaseURL(),
					currentUser: currentUserData?.currentUser
				}
			}>
			{props.children}
		</CommonContext.Provider>
	);
};

export default CommonContextProvider;
