import React from 'react';

import classes from './Spinner.module.pcss';
type Props = {
    size?: 'small' | 'medium' | 'large',
    type?: 'white' | 'blue'
}
const Spinner = ({size = 'small', type = 'white'}:Props) => <div className={`${classes.Spinner} ${classes[type]} ${classes[size]}`} />;

export default Spinner;
