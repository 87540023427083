import React, {useRef} from 'react';
import TopBanner from 'js/components/Common/TopBanner/TopBanner';
import Wrapper from 'js/components/Templates/Events/EventsList/Wrapper/Wrapper';
import EventsListContextProvider from 'js/components/Templates/Events/EventsList/context/EventsListContext';


const EventsTemplate = ({pageId}) => {
	const nodeRef = useRef(null);
	return (
		<main className="templateEvents" ref={nodeRef}>
			<TopBanner
				pageType={'EventsTemplate'}
				pageId={pageId}/>
			<EventsListContextProvider>
				<Wrapper nodeRef={nodeRef} pageId={pageId} />
			</EventsListContextProvider>
		</main>
	);
};

export default EventsTemplate;
