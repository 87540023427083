import React, {useCallback, useEffect, useMemo} from 'react';
import classes from 'js/containers/Pages/Search/SearchFilter/SearchFilterItem/SearchFilterItem.module.pcss';
import Input from 'js/components/Common/FormComponents/Input/Input';
import SearchFilterService from 'js/containers/Pages/Search/SearchFilter/SearchFilterService';
import useAnchor from 'js/hooks/useAnchor/useAnchor';
import { observer } from 'mobx-react';
import SearchStore from 'js/store/SearchStore';

const SearchFilterItemElement = ({ item, taxonomyName, type, heading, parentId }) => {
    const anchor = useAnchor('Search', 0, -150);
    const {parent, id, count, value, name, label} = useMemo(() => item, [item]) || {};
    const {
        filterTaxData,
        filterEntitiesData,
        entityTypes,
        typesTaxSections
    } = SearchStore;

    const subjectMatterItemConditionVar = useMemo(() =>
             parentId && parseInt(filterTaxData?.productLegacy) !== parseInt(parent)
        , [filterTaxData, parent, parentId]);

    const typeTaxItemConditionVar = useMemo(() =>
            typesTaxSections?.includes(taxonomyName) && !filterEntitiesData.includes(entityTypes[taxonomyName])
        , [typesTaxSections, taxonomyName, filterEntitiesData, entityTypes]);

    useEffect(() => {
        if (!count || subjectMatterItemConditionVar || typeTaxItemConditionVar) {
            SearchFilterService.removeItemFromState(taxonomyName, id, type);
        }
    }, [count, id, type, subjectMatterItemConditionVar, taxonomyName, typeTaxItemConditionVar]);

    const inputCheckHandler = useCallback(async (e) => {
        await SearchFilterService.filterItemClickHandler(e, taxonomyName, anchor, type, heading);
    }, [anchor, heading, taxonomyName, type]);

    if (!count || subjectMatterItemConditionVar || typeTaxItemConditionVar) {
        return null;
    }
    return (
        <div
            key={id}
            datatype={value}
            className={classes.FilterSectionItem}>
            {taxonomyName === 'productLegacy' ?
                <Input
                    type={'radio'}
                    withLabel={true}
                    name={name || ''}
                    slug={taxonomyName}
                    labelText={label || ''}
                    value={`${id}`}
                    id={id?.toString()}
                    checked={SearchStore.filterTaxData[taxonomyName]?.includes(`${id}`)}
                    onChange={e => inputCheckHandler(e)}
                /> :
                <Input
                    type={'checkbox'}
                    withLabel={true}
                    checked={type === 'taxonomy' ? SearchStore.filterTaxData[taxonomyName]?.includes(`${id}`) : SearchStore.filterEntitiesData?.includes(`${value}`)}
                    name={name || ''}
                    slug={type === 'taxonomy' ? `${id}` : value || ''}
                    labelText={label || ''}
                    id={id?.toString()}
                    onChange={e => inputCheckHandler(e)}
                />}
        </div>
    );
};

export default observer(SearchFilterItemElement);
