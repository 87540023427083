import React from 'react';
import BeforeYouLeave from 'js/components/Common/BeforeYouLeave/BeforeYouLeave';
import ILTCourses from 'js/components/Common/ILTCourses/ILTCoursesBlock/ILTCourses';
import HowToSection from 'js/components/Templates/CoursesCatalogue/HowToSection/HowToSection';
import WelcomeSection from 'js/components/Templates/CoursesCatalogue/WelcomeSection/WelcomeSection';

const CoursesCatalogueTemplate = ({ pageId }) => (
	<main className="templateCoursesCatalogue">
		<WelcomeSection pageId={pageId} />
		<HowToSection pageId={pageId}/>
		<div id={'Catalogue'}>
			<ILTCourses
				pageId={pageId}
				type={'catalogue'}
				templateName={'CoursesCatalogue'}
				centered={true}
				useContainer={true}
			/>
		</div>
		<BeforeYouLeave
			pageId={pageId}
			templateName={'CoursesCatalogue'} />
	</main>
);

export default CoursesCatalogueTemplate;

