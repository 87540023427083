import React from 'react';
import Heading from 'js/components/Common/Heading/Heading';
import Description from 'js/components/Common/Description/Description';
import DateByTimezone from 'js/components/Common/DateByTimezone/DateByTimezone';
import ProductLabelsList from 'js/components/Common/ProductLabelList/ProductLabelsList';
import VisibilityViewerIcon from 'js/components/Common/VisibilityViewerIcon/VisibilityViewerIcon';

import classes from './Meeting.module.pcss';
import Label from '../../components/Label/Label';
import Thumbnail from '../../components/Thumbnail/Thumbnail';
import commonClasses from '../../../SearchResultsItem/SearchResultsItem.module.pcss';

export interface Props {
    data: {
        id: number,
        title: string,
        subtitle: string,
        featuredImage: {
            url: string,
            altText: string
        },
        type: 'meetings',
        eventType: 'Classroom' | 'Panel' | 'Conference',
        link: string,
        meetingID: string,
        meetingDateID: number,
        databaseId: number,
        initRegistrationHash: string,
        date: string,
        registrationButton: string,
        joinButton: string,
        viewButton: string
    },
    isPromoted: boolean
}

const Meeting = ({data, isPromoted}:Props) => {
    const {
        title,
        link,
        featuredImage,
        subtitle
    } = data || {};
    return (
        <div className={`${commonClasses.SearchResultItem} ${classes.Meeting} ${isPromoted && commonClasses.Promoted}`}>
            <Thumbnail thumbnail={featuredImage} />
            <span className={commonClasses.SearchResultItemInfo}>
                <a href={link} target={'_blank'} rel={'noopener noreferrer'}>
                    <Heading text={`${title}`} type={'h5'}/>
                </a>
                <Heading text={`${title}`} type={'h5'}/>
                <Description data={subtitle}/>
                <div className={commonClasses.Wrap}>
                    <Label type={'meetings'}/>
                    <DateByTimezone className={commonClasses.SearchDate} date={data?.date} format={'DD MMMM YYYY, HH:mm '} />
                    <ProductLabelsList pageId={data?.id} />
                </div>
                <div className={commonClasses.Wrap}>
                    <VisibilityViewerIcon id={data?.id}/>
                </div>
            </span>
        </div>
    );
};

export default Meeting;
