import React from 'react';
import parse from 'html-react-parser';
import {Link} from 'react-router-dom';
import PopUp from 'js/components/Common/PopUp/PopUp';

import classes from './CoursePopUp.module.pcss';

export interface Props {
    popUpTrigger: boolean,
    setPopUpTrigger: Function,
    type?: 'L' | 'M' | 'S' | 'XL',
    popUpData: {
        title: string,
        content: {
            first: string,
            second: string
        }
    }
}

const CoursePopUp = ({
     popUpTrigger,
     setPopUpTrigger,
     popUpData,
    type
    }:Props) => (
    <div className={classes.CoursesPopUp}>
        <PopUp
            fixedInfo={
                <p className={classes.Contact}>*If you need more info, please <Link to={'/contact-us'}>contact us</Link></p>
            }
            heading={popUpData?.title}
            type={type}
            additionalClass={classes.CoursesMoreInfoPopUp}
            popUpTrigger={popUpTrigger}
            popUpClose={() => setPopUpTrigger(false)}>
            <div
                className={
                    `${classes.CoursesMoreInfoPopUpContent} 
                            ${popUpData?.content?.first && popUpData?.content?.second ? classes.CoursesMoreInfoPopUpContentFlex : ''}`
                }>
                {popUpData?.content?.first ?
                    <div className={classes.CoursesMoreInfoPopUpContentFirst}>
                        {parse(popUpData?.content?.first || '', {trim: true})}
                    </div>
                    : null}
                {popUpData?.content?.second ?
                    <div className={classes.CoursesMoreInfoPopUpContentSecond}>
                        {parse(popUpData?.content?.second || '', {trim: true})}
                    </div>
                    : null}
            </div>
        </PopUp>
    </div>
);

export default CoursePopUp;
