export default pageId => `
{
		page(id: "${pageId}", idType: DATABASE_ID) {
		  AboutUs {
			  headingTopBlock
			  infoTopBlock {
				firstParagraph
				secondParagraph
				thirdParagraph
			  }
			  imageTopBlock {
				altText
				  mediaItemUrl
				  mediaDetails {
					sizes {
					  name
					  sourceUrl
					}
				  }
			  }
		  }
		}
	  }
`;
