import React from 'react';
import EmbedVideo from 'js/components/Common/EmbedVideo/EmbedVideo';
import {ImageBlockDataInterface} from 'js/components/Common/Image/ImageBlockDataInterface';
import MoreInfoBlock from 'js/containers/PostTypes/Articles/Content/MoreInfoBlock/MoreInfoBlock';

import classes from './AttachedVideo.module.pcss';

interface Props {
    title: string,
    link: string,
    preview: ImageBlockDataInterface,
    customClass?: string,
    withoutInfo?: boolean,
    sendEventHandler: any,
    customVideoSize?: {
        width: string,
        height: string
    }
}

const AttachedVideo = ({title, link, preview, sendEventHandler, customClass = '', withoutInfo = false, customVideoSize = null}: Props) => (
    <section className={`${classes.AttachedVideo} ${customClass?.length > 0 ? customClass : ''}`}>
        { !withoutInfo ? <MoreInfoBlock type={'attachedVideoInfo'} /> : '' }
        <EmbedVideo
            title={title}
            customVideoSize={customVideoSize}
            sendEventHandler={sendEventHandler}
            link={link}
            preview={preview} />
    </section>
);

export default AttachedVideo;
