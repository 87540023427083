import React, {useState,  useMemo} from 'react';
import HeaderLogoImg from 'images/logo-text.svg';
import Image from 'js/components/Common/Image/Image';
import {useLocation, NavLink} from 'react-router-dom';
import FooterLogoImg from 'images/footer-logo.svg';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import {ImageBlockDataInterface} from 'js/components/Common/Image/ImageBlockDataInterface';

import fetchData from './fetchData';
import classes from './Logo.module.pcss';

export interface Props {
	footerLogo: boolean
}

const Logo = ({footerLogo}: Props) => {
	const location = useLocation();
	const [headerLogo, setHeaderLogo] = useState<ImageBlockDataInterface>(null);

	useFetchData({
	    query: fetchData,
	    condition: true,
	    updateData: data => setHeaderLogo(data?.themeGeneralSettings?.Options?.headerLogo)
	});

	const setLogoData = useMemo(() =>
		footerLogo ?
			<figure>
				<img src={`${footerLogo ? FooterLogoImg : HeaderLogoImg}`} alt="Playtech Academy"/>
			</figure> : headerLogo ?
			<Image
				mediaItemUrl={headerLogo?.mediaItemUrl}
				mediaDetails={headerLogo?.mediaDetails}
			alt={'Playtech Academy'} />
			: null, [footerLogo, headerLogo]);

	const setLogo = useMemo(() =>
		<div className={`${classes.Logo} ${footerLogo ? classes.footerLogo : classes.HeaderLogo}`}>
			{setLogoData}
		</div>, [footerLogo, setLogoData]);

	if (footerLogo) return setLogo;

	return location?.pathname === '/' ? setLogo
	: (
		<NavLink
			exact={true}
			to="/"
			className={`${classes.Logo} ${classes.HeaderLogo}`}>
			{setLogoData}
		</NavLink>
	);
};

export default Logo;
