import React, {useCallback, useContext, useState} from 'react';
import Heading from 'js/components/Common/Heading/Heading';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';
import {CommonContext} from 'js/context/CommonContext/CommonContext';
import {NotificationsContext} from 'js/context/NotificationsContext/NotificationsContext';
import {NotificationDataType} from 'js/components/Notifications/NotificationsDataInterface';

import classes from './Frame.module.pcss';
import removeNotifications from './fetchData';
import NotificationItem from '../NotificationItem/NotificationItem';

interface Props {
	status: boolean,
	offsetTop: number,
	closeFrame: Function,
	eventButtons: {
		registerEventButton: string,
		joinEventButton: string,
		viewEventButton: string
	}
}

const Frame = ({ status, offsetTop, closeFrame, eventButtons }:Props) => {
	const {
		notifications,
		setNotifications,
		customNotifications,
		setCustomNotifications,
		setBlockUpdateTimer
	} = useContext(NotificationsContext);
	const [activeFilterItemIndex, setActiveFilterItemIndex] = useState<1 | 2>(1);
	const [visibilityType, setVisibilityType] = useState<Array<'read' | 'unread' | 'removed'>>(['read', 'unread', 'removed']);
	const {apiPath} = useContext(CommonContext);

	const frameItemClickHandler = useCallback((e, type: 'all' | 'unread' | 'removed') => {
		switch (type) {
			case 'all': {
				if (activeFilterItemIndex === 1) {
					return;
				}
				setVisibilityType(['read', 'unread', 'removed']);
				setActiveFilterItemIndex(1);
				break;
			}
			case 'unread': {
				if (activeFilterItemIndex === 2) {
					return;
				}
				setVisibilityType(['unread', 'removed']);
				setActiveFilterItemIndex(2);
				break;
			}
			default : return;
		}
	}, [activeFilterItemIndex]);

	const setQueryItemsForDeleting = useCallback((type: 'custom' | 'autogenerated') => {
		const items:Array<NotificationDataType> = type === 'custom' ? [...customNotifications] : [...notifications];
		let index:number = 0;
		let query:string = '';
		let allCleared = true;
		for (const i in items) {
			if (!items[i].blockClearing) {
				if (items[i].status != 'removed') {
					items[i].status = 'removed';
					query += `${items[i].id}-${items[i].type},`;
				}
			} else {
				allCleared = false;
			}
			index = index + 1;
		}
		query = query.slice(0, -1);
		return {
			items,
			query,
			allCleared
		};
	}, [customNotifications, notifications]);

	const clearAllHandler = useCallback(async () => {
		await MixPanelWrapper.TrackEvent(
			'All notifications cleared',
			{
				category: 'Notifications'
			});
		let queryString = '';
		const customNotificationsFilteredData = setQueryItemsForDeleting('custom');
		const notificationsFilteredData = setQueryItemsForDeleting('autogenerated');

		const {allCleared:clearedCustom, items:itemsCustom, query:queryCustom} = customNotificationsFilteredData || {};
		const {allCleared, items, query} = notificationsFilteredData || {};

		queryString += queryCustom;
		queryString += queryString?.length > 0 ? `,${query}` : query;

		setCustomNotifications(itemsCustom);
		setNotifications(items);

		if (!queryString) {
			return;
		}

		if (clearedCustom && allCleared) {
			closeFrame();
		}

		setBlockUpdateTimer(true);

		(async () => {
			await fetch(`${apiPath}/graphql`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					query: `${removeNotifications(queryString)}`
				})
			});
			setBlockUpdateTimer(false);
		})();

	}, [
		apiPath,
		closeFrame,
		setBlockUpdateTimer,
		setCustomNotifications,
		setNotifications,
		setQueryItemsForDeleting
	]);

	return (
		<div
			style={{ marginTop: `${offsetTop}px` }}
			className={`${classes.Frame} ${status ? classes.Active : ''}`}>
			<div className={classes.FrameFilter}>
				<div className={classes.FrameFilterTop}>
					<Heading text={'Notifications'} type={'h6'} />
					<button onClick={clearAllHandler} className={classes.Clear}>Clear All</button>
				</div>
				<div className={classes.FrameFilterBottom}>
					<button
						onClick={e => frameItemClickHandler(e, 'all')}
						className={`${classes.FrameFilterItem} ${activeFilterItemIndex === 1 ? classes.FrameFilterItemActive : ''}`}>All</button>
					<button
						onClick={e => frameItemClickHandler(e, 'unread')}
						className={`${classes.FrameFilterItem} ${activeFilterItemIndex === 2 ? classes.FrameFilterItemActive : ''}`}>Unread</button>
				</div>
			</div>
			<div className={classes.FrameItems}>
				{customNotifications
					?.filter(item => visibilityType?.includes(item?.status))
					?.map(item => (<NotificationItem
						key={item?.id}
						data={item}
						closeFrame={closeFrame}
						eventButtons={eventButtons} />))}
				{notifications
					?.filter(item => visibilityType?.includes(item?.status))
					?.map(item => (<NotificationItem
						key={item?.id}
						data={item}
						closeFrame={closeFrame}
						eventButtons={eventButtons} />))}
			</div>
		</div>
	);
};

export default Frame;
