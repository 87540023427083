import React, {useEffect, useState, useCallback, useMemo} from 'react';

import classes from './AnimatedMouse.module.pcss';

const AnimatedMouse = () => {
	const [mouseOpacity, setMouseOpacity] = useState<number>(1);
	const scrollCallBack = useCallback((): void => {
		setMouseOpacity((500 - window.pageYOffset) / 1000 * 2);
	}, []);
	useEffect(() => {
		window.addEventListener('scroll', scrollCallBack);
		return (): void => {
			window.removeEventListener('scroll', scrollCallBack);
		};
	}, [scrollCallBack]);
	const style = useMemo(() => ({ opacity: mouseOpacity }), [mouseOpacity]);

	return (
		<div style={style} className={classes.AnimatedMouse}>
			<span className={classes.Text}>Scroll</span>
			<div className={classes.Mouse} />
		</div>
	);
};

export default AnimatedMouse;
