import {TaxType, TaxTypes} from './TaxonomyRouterDataInterface';

const getTypeByURL = (path: string, types: TaxTypes) => {
   if (!types?.length) {
      return null;
   }
   const matchingType:TaxType = types?.find(item => path?.startsWith(`/${item?.slug}/`));

   if (matchingType) {
      const parts = path?.split('/');
      return {
         transformPath: `/${parts[1]}/${parts[parts.length - 1]}`,
         matchingType
      };
   }
   return null;
};

export default getTypeByURL;
