const searchResultCountData =
    `
      pagesCount
      postsCount
       entitiesCount {
        name
        count
      }
      languageCount {
        count
        name
      }
      productCount {
        count
        name
      }
      skillsCount {
        count
        name
      }
      suitableForCount {
        count
        name
      }
      subjectMatterCount {
        count
        name
      }
      articleTypeCount {
        count
        name
      }
      eventTypeCount {
        count
        name
      }
      podcastTypeCount {
        count
        name
    }
      presentationTypeCount {
        count
        name
    }
      videoTypeCount {
        count
        name
    }
      courseTypeCount {
        count
        name
        }
      manualTypeCount {
        count
        name
        }
    `;
export default searchResultCountData;
