export const clearAnimations = (slider) => {
	const sliderObject: HTMLElement = slider?.$el[0];
	const promoMediaFadeIn: HTMLElement = sliderObject?.querySelector('.swiper-slide .promoMediaFadeIn');
	const promoMediaFadeOut: HTMLElement = sliderObject?.querySelector('.swiper-slide .promoMediaFadeOut');
	const promoTextFadeIn: HTMLElement = sliderObject?.querySelector('.swiper-slide .promoTextFadeIn');
	const promoTextFadeOut: HTMLElement = sliderObject?.querySelector('.swiper-slide .promoTextFadeOut');
	const promoBgTranslateX: HTMLElement = sliderObject?.querySelector('.swiper-slide .promoBgTranslateX');
	const animatedHeading: HTMLElement = sliderObject?.querySelector('.swiper-slide .animatedHeading');

	promoMediaFadeIn?.classList.remove('promoMediaFadeIn');
	promoMediaFadeOut?.classList.remove('promoMediaFadeOut');
	promoTextFadeIn?.classList.remove('promoTextFadeIn');
	promoTextFadeOut?.classList.remove('promoTextFadeOut');
	promoBgTranslateX?.classList.remove('promoBgTranslateX');
	animatedHeading?.classList.remove('active');
};
export const promoMediaFadeIn = (slider: any, context): void => {
	const video: HTMLElement = slider?.slides[slider?.activeIndex]?.querySelector('.VideoPlayerWrapper');
	if (slider?.slides) {
		if (video) {
			context?.setPlayingTrigger(true);
		}
		const slide: HTMLElement =
			slider?.slides[slider?.activeIndex]?.children[0]?.children[0]?.children[1]?.children[0];
		slide.classList.remove('promoMediaFadeOut');
		slide.classList.add('promoMediaFadeIn');
	}
};
export const promoMediaFadeOut = (slider, timeout: number, forced: boolean = false): void => {
	const video = slider?.slides[slider?.activeIndex]?.querySelector('.VideoPlayerWrapper');
	const fadeOut = (): void => {
		setTimeout(() => {
			if (!slider?.destroyed) {
				const activeElement: HTMLElement = slider.$el[0].querySelector('.promoMediaFadeIn');
				if (activeElement) {
					activeElement?.classList?.remove('promoMediaFadeIn');
					activeElement?.classList?.add('promoMediaFadeOut');
				}
			}
		}, timeout);
		slider?.autoplay?.start();
	};
	if (slider?.slides) {
		if (!forced && !video) {
			fadeOut();
		}
		if (forced) {
			fadeOut();
		}
	}
};
export const promoTextFadeIn = (slider: any): void => {
	const animatedHeading = slider?.slides[slider?.activeIndex]?.querySelector('.animatedHeading');
	const firstChild = animatedHeading?.firstElementChild;
	const { children } = animatedHeading;

	firstChild?.classList.remove('promoTextFadeOut');
	firstChild?.classList.add('promoTextFadeIn');

	for (let i = 0; i < children.length; i++) {
		children[i].onanimationend = () => {
			const sibling = children[i]?.nextElementSibling;
			if (sibling) {
				sibling?.classList?.remove('promoTextFadeOut');
				sibling?.classList?.add('promoTextFadeIn');
			}
		};
	}
};
export const promoTextFadeOut = (slider, timeout, forced = false) => {
	const animatedHeading = slider?.slides[slider?.activeIndex]?.querySelector('.animatedHeading');
	const lastChild = animatedHeading?.lastElementChild;
	const { children } = animatedHeading;
	const video: HTMLElement = slider?.slides[slider?.activeIndex]?.querySelector('.VideoPlayerWrapper');

	const fadeOut = (): void => {
		setTimeout(() => {
			lastChild?.classList?.remove('promoTextFadeIn');
			lastChild?.classList?.add('promoTextFadeOut');

			for (let i = children?.length; i--;) {
				children[i].onanimationend = () => {
					const sibling = children[i]?.previousElementSibling;
					if (sibling) {
						sibling?.classList?.remove('promoTextFadeIn');
						sibling?.classList?.add('promoTextFadeOut');
					}
				};
			}
		}, timeout);
	};

	if (slider?.slides) {
		if (!forced && !video) {
			fadeOut();
		}
		if (forced) {
			fadeOut();
		}
	}
};
