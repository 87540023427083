import clearGraphQlStringValue from 'js/helpers/clearGraphQlStringValue';
export default (resultData, name, email, subject, message, recaptcha) => {
    const nameFieldValue = resultData.find(item => item.name === name).value;
    const emailFieldValue = resultData.find(item => item.name === email).value;
    const subjectFieldValue = resultData.find(item => item.name === subject).value;
    const messageFieldValue = resultData.find(item => item.name === message).value;
    const token = resultData.find(item => item.name === recaptcha).value;
    return `
    {
        contactForm {
          contactFormResult(
            name: "${clearGraphQlStringValue(nameFieldValue)}",
            email: "${clearGraphQlStringValue(emailFieldValue)}",
            message: "${clearGraphQlStringValue(messageFieldValue)}",
            subject: "${clearGraphQlStringValue(subjectFieldValue)}",
            token: "${token}",
          )
        }
    }
`;
};
