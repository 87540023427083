import React from 'react';
import FAQ from 'js/components/Taxonomies/FAQ/FAQ';
import Overview from 'js/components/Common/Overview/Overview';
import TopSection from 'js/components/Common/TopSection/TopSection';
import SubjectMatterList from 'js/components/Taxonomies/SubjectMatterList/SubjectMatterList';
import ProductContent from 'js/components/Taxonomies/ProductContent/ProductContent';

const Product = ({ pageId }) => (
	<main className="productLegacy">
		<TopSection pageId={pageId} pageType={'product'} />
		<Overview pageId={pageId} pageType={'Product'} />
		<SubjectMatterList pageId={pageId} />
		<ProductContent pageId={pageId} />
		<FAQ pageId={pageId} pageType={'Product'} />
	</main>
);

export default Product;
