import classes from './SubjectMatterList.module.pcss';
import {SubjectMatterListType} from './SubjectMatterList';
import { Navigation, Pagination } from 'swiper/js/swiper.esm';

export const slides =
    (subjectMatterList: Array<SubjectMatterListType>, count: number):Array<Array<SubjectMatterListType>>  => {
    if (!subjectMatterList) return [];
    const slidesArray = [];
    for (let i = 0; i < subjectMatterList?.length; i += count) {
        slidesArray.push(subjectMatterList?.slice(i, i + count));
    }
    return slidesArray;
};
export const params = {
    slidesPerView: 1,
    noSwiping: true,
    speed: 500,
    autoHeight: true,
    watchOverflow: true,
    direction: 'horizontal',
    shouldSwiperUpdate: true,
    watchSlidesVisibility: true,
    containerModifierClass: 'swiper-container-wrap ',
    spaceBetween: 16,
    modules: [Navigation, Pagination],
    pagination: {
        el: `.${classes.Slider} + .${classes.Bullets}`,
        clickable: true
    },
    on: {
        slideChangeTransitionStart() {
            this.updateAutoHeight();
        }
    }
};
