import React, {useCallback, useContext, useMemo, useState} from 'react';
import Button from 'js/components/Common/Button/Button';
import Heading from 'js/components/Common/Heading/Heading';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Container from 'js/components/Common/Container/Container';
import {CommonContext} from 'js/context/CommonContext/CommonContext';
import useCutBackground from 'js/hooks/useCutBackground/useCutBackground';
import {ImageBlockDataInterface} from 'js/components/Common/Image/ImageBlockDataInterface';

import classes from '../TopBanner.module.pcss';
import {entityFetchData} from '../fetchData';
import {getPageEntityId, setBannerStyle} from '../TopBannerHelpers';

export interface Props {
	pageId: number,
	location: Location
}

const Course = ({pageId, location}: Props) => {
	const { isCustomerLogged } = useContext(CommonContext);
	const [bannerTagline, setBannerTagline] = useState<string>('');
	const [bannerTopic, setBannerTopic] = useState<string>('');
	const [bannerButtonUri, setBannerButtonUri] = useState<string>('');
	const [startButton, setStartButton] = useState<string>('');
	const [buyButton, setBuyButton] = useState<string>('');
	const [coursePrice, setCoursePrice] = useState<number>(null);
	const [bannerImage, setBannerImage] = useState<ImageBlockDataInterface>({});
	const bg = useCutBackground(bannerImage);

	const { isLoading, error } = useFetchData({
	    query: entityFetchData(pageId, getPageEntityId('CoursesTemplate')),
	    condition: !!pageId,
	    updateData: (data) => {
			const result = data?.page?.CoursesTemplate;
			const {selectEntityBanner} = result || {};
			if (!result) {
				return;
			}
			setStartButton(data?.themeGeneralSettings?.Options?.startCourseButton);
			setBuyButton(data?.themeGeneralSettings?.Options?.buyCourseButton);
			setBannerTagline(result?.bannerTagline);
			setBannerTopic(result?.selectEntityBanner?.title);
			setBannerButtonUri(result?.selectEntityBanner?.uri);
			setBannerImage(selectEntityBanner?.featuredImage);
			setCoursePrice(selectEntityBanner?.Courses?.coursePrice);
	    }
	});

	const sendMixPanelEvent = useCallback(async () => {
		await MixPanelWrapper.TrackEvent(
			'Course Start',
			{
				category: 'Top Banner',
				value: `${bannerTopic}`
			});
	}, [bannerTopic]);

	const setButtonType = useMemo(() => {
		if (isCustomerLogged === null && !coursePrice) {
			return null;
		}
		if (isCustomerLogged === false || coursePrice > 0) {
			return <Button
				text={buyButton}
				type="button"
				handler={() => sendMixPanelEvent()}/>;
		}
		if (isCustomerLogged === true) {
			return <Button
				text={startButton}
				type="a"
				link={`${bannerButtonUri}?source=${location?.pathname}&sourceName=courses`}
				handler={() => sendMixPanelEvent()} />;
		}
	}, [
		isCustomerLogged,
		location,
		bannerButtonUri,
		sendMixPanelEvent,
		coursePrice,
		startButton,
		buyButton
	]);
	return (
		(bannerTopic && bannerImage && !isLoading && !error) ?
			<section className={classes.TopBanner} style={setBannerStyle(bg)}>
				<Container>
					<strong className={classes.TopBannerTagline}>{bannerTagline}</strong>
					<Heading type="h1" text={bannerTopic} />
					{setButtonType}
				</Container>
			</section> : null
	);
};

export default Course;
