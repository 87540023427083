import React from 'react';

import Video from '../Entities/Entity/Video/Video';
import Course from '../Entities/Entity/Course/Course';
import Manual from '../Entities/Entity/Manual/Manual';
import Article from '../Entities/Entity/Article/Article';
import Meeting from '../Entities/Entity/Meeting/Meeting';
import Podcast from '../Entities/Entity/Podcast/Podcast';
import Presentation from '../Entities/Entity/Presentation/Presentation';


const SearchResultsItem = ({type, data, isPromoted}) => {
    switch (type) {
        case 'articles' :  return <Article data={data} isPromoted={isPromoted}/>;
        case 'manuals' :  return <Manual data={data} isPromoted={isPromoted}/>;
        case 'courses' :  return <Course data={data} isPromoted={isPromoted}/>;
        case 'meetings' :  return <Meeting data={data} isPromoted={isPromoted}/>;
        case 'presentations' :  return <Presentation data={data} isPromoted={isPromoted}/>;
        case 'podcasts' :  return <Podcast data={data} isPromoted={isPromoted}/>;
        case 'videos' :  return <Video data={data} isPromoted={isPromoted}/>;
        default : return null;
    }
};

export default SearchResultsItem;
