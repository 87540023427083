import React, { useState } from 'react';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import fetchData from './fetchData';
import { SinglePodcastsDataInterface } from './SinglePodcastsDataInterface';

const SinglePodcasts = ({ pageId }) => {
	const [postData, setPostData] = useState<SinglePodcastsDataInterface>({});

	const { isLoading, error } = useFetchData({
		query: `${fetchData(pageId)}`,
		condition: !!pageId,
		updateData: data => setPostData(data?.podcastsSingle)
	});

	return (
		<DataValidator data={!isLoading && !error }>
			<main className="SinglePodcast">
				{postData ? <h1>{postData.title}</h1> : null}
			</main>
		</DataValidator>
	);
};

export default SinglePodcasts;
