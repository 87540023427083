import React, {useCallback, useEffect, useState} from 'react';
import parse, { domToReact } from 'html-react-parser';
import useAnchor from 'js/hooks/useAnchor/useAnchor';

import classes from '../SingleArticles.module.pcss';
import ExpandableSection from 'js/components/Common/ExpandableSection/ExpandableSection';

export interface Props {
	data: string,
	nodeRef: any
}

const Content = ({data, nodeRef}: Props) => {
	const [anchorElementId, setAnchorElementId] = useState<string>('');
	const anchor = useAnchor(anchorElementId, 0, 30);

	const anchorClickHandler = useCallback(
		(anchorName) => {
			setAnchorElementId(anchorName.replace('#', ''));
		},
		[setAnchorElementId]
	);

	useEffect(() => {
		anchor();
		setAnchorElementId('');
	}, [anchorElementId, anchor]);

	const contentFiltering = useCallback((domNode) => {
		const isParagraph = domNode.name === 'p';
		const hasImage = domNode?.children?.find(el => el.name === 'img');
		const hasComment = domNode?.children?.find(item => item.type === 'comment');
		const hasEmptySpace = domNode?.data === '\n';
		const isLink = domNode?.name === 'a';
		const hasAnchor = isLink ? domNode?.attribs?.href?.indexOf('#') !== -1 : false;
		const paragraphWithSpaceOnly = isParagraph && domNode?.children[0]?.name === 'br' ||  isParagraph && domNode?.children[0]?.data && /^\s+$/m.test(domNode?.children[0]?.data);

		if (isLink && hasAnchor) {
			return <span
				className={classes.Anchor}
				onClick={() => anchorClickHandler(domNode?.attribs?.href)}>{domNode?.children[0]?.data}</span>;
		}

		if (hasEmptySpace || paragraphWithSpaceOnly) {
			return <></>;
		}

		if (isParagraph) {
			if (hasImage) {
				domNode.attribs.class = classes.ImgWrap;
			}
			if (hasComment) {
				return <></>;
			}
		}

		if (domNode?.attribs?.class === 'ExpandableHeading') {
			const nextNode = domNode?.next?.next;
			const expandableContent = nextNode?.children?.find(item => item?.name === 'div');
			return <ExpandableSection
				title={domNode?.children[0]?.data}
				info={domToReact([expandableContent])}
				nodeRef={nodeRef} />;
		}
		if (domNode?.attribs?.class === 'ExpandableContent') {
			return <></>;
		}
	}, [anchorClickHandler, nodeRef]);


    return (
		<div className={classes.ArticleContentMain}>
			{data ? parse(data || '', {
				trim : false,
				htmlparser2: {
					xmlMode: true

				},
				replace: domNode => contentFiltering(domNode)
			}) : ''}
		</div>
    );
};

export default Content;
