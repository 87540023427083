import {GettingStartedItemType} from './GettingStartedItems/GettingStartedItems';
import classes from './GettingStartedItems/GettingStartedItems.module.pcss';

class GettingStartedService {
    getWideClass(length: number, key: number):string {
        if ((length === 3 && key === 2) || length === 1) {
            return  classes.Wide;
        }
        return '';
    }
    getMergedItems(defaultItems: Array<GettingStartedItemType>, customItems: Array<GettingStartedItemType>) {
        let mergedArray = [];

        if (!defaultItems && !customItems) {
            return null;
        }

        if (customItems) {
            mergedArray = defaultItems?.map((item, index) => customItems[index] || item);
        } else {
            mergedArray = [...defaultItems];
        }


        if (customItems) {
            customItems.forEach((item, index) => {
                if (index >= defaultItems?.length) {
                    mergedArray.push(item);
                }
            });
        }

        const seenIds = new Set();
        const uniqueArray = [];

        mergedArray?.forEach((item) => {
            if (!seenIds?.has(item?.id)) {
                uniqueArray?.push(item);
                seenIds?.add(item?.id);
            }
        });

        return uniqueArray?.slice(0, 4);
    }
}

export default new GettingStartedService();
