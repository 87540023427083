import React from 'react';
import parse from 'html-react-parser';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import classes from './SubHeading.module.pcss';

export interface Props {
	text: string
}

const SubHeading = ({ text }: Props) => (
	<DataValidator data={text}>
		<div className={`${classes.SubHeading}`}>
			{parse(text || '')}
		</div>
	</DataValidator>
);
export default SubHeading;
