import React, {useCallback, useState} from 'react';
import Heading from 'js/components/Common/Heading/Heading';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Container from 'js/components/Common/Container/Container';
import {ILTCoursesDataInterface} from './ILTCoursesDataInterface';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import classes from './ILTCourses.module.pcss';
import iLTCoursesSectionFetchData from './fetchData';
import ILTCoursesList from '../ILTCoursesList/ILTCoursesList';

export interface Props {
	pageId: number,
	type: 'onBoarding' | 'catalogue',
	templateName: string,
	centered: boolean,
	useContainer: boolean
}

const ILTCourses = ({pageId, type, templateName, centered, useContainer = true} : Props) => {
	const [title, setTitle] = useState<string>('');
	const [subTitle, setSubTitle] = useState<string>('');
	const [coursesList, setCoursesList] = useState<ILTCoursesDataInterface[]>([]);

	const { isLoading, error } = useFetchData({
	    query: iLTCoursesSectionFetchData(pageId, type, templateName),
	    condition: !!pageId,
	    updateData: (data) => {
			const sectionData = data?.page[templateName];
			const coursesData = data?.iLTCoursesArchive?.nodes;
			if (!sectionData && !coursesData) {
				return;
			}
			setTitle(sectionData?.titleIltSection || '');
			setSubTitle(sectionData?.subtitleIltSection || '');
			setCoursesList(coursesData || []);
	    }
	});

	const returnMainData = useCallback(() => {
		if (useContainer) {
			return (
				<Container additionalClass={classes.ILTCoursesWrap}>
					<div className={classes.ILTCoursesBox}>
						<Heading text={title} />
						{subTitle ? <p>{subTitle}</p> : ''}
						<ILTCoursesList listData={coursesList} centered={centered} />
					</div>
				</Container>
			);
		}
		return (
			<div className={classes.ILTCoursesWrap}>
				<div className={classes.ILTCoursesBox}>
					<Heading text={title} />
					{subTitle ? <p>{subTitle}</p> : ''}
					<ILTCoursesList listData={coursesList} centered={centered} />
				</div>
			</div>
		);
	}, [useContainer, centered, coursesList, title, subTitle]);

    return (
        <DataValidator data={!!coursesList?.length && !isLoading && !error}>
			<section className={`${classes.ILTCourses} ${centered ? classes.Centered : ''}`}>
				{returnMainData()}
			</section>
		</DataValidator>
    );
};

export default ILTCourses;

