import React, {useCallback, useEffect, useMemo, useState} from 'react';
import PopUp from 'js/components/Common/PopUp/PopUp';
import {useCookies, withCookies} from 'react-cookie';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import FetchData from './fetchData';
import FirstStage from './FirstStage/FirstStage';
import SecondStage, {CookieDescriptionsType} from './SecondStage/SecondStage';
import classes from './CookiePolicyPopup.module.pcss';

const CookiePolicyPopup = () => {
    const [cookies, setCookie] = useCookies([]);
    const {REACT_APP_ACCEPTED_POLICY_COOKIE, REACT_APP_MIX_PANEL_INIT_COOKIE} = process.env || {};
    const [mainTitle, setMainTitle] = useState<string>('');
    const [secondaryTitle, setSecondaryTitle] = useState<string>('');
    const [mainInfo, setMainInfo] = useState<string>('');
    const [secondaryInfo, setSecondaryInfo] = useState<string>('');
    const [contentStage, setContentStage] = useState<1 | 2>(1);
    const [policyCookies, setPolicyCookies] = useState<boolean | undefined>(false);
    const [mixPanelCookies, setMixPanelCookies] = useState<boolean | undefined>(false);
    const [cookiesDescriptions, setCookiesDescriptions] = useState<CookieDescriptionsType>({
        analyticsCookiesInfo: '',
        analyticsCookiesTitle: '',
        essentialCookiesInfo: '',
        essentialCookiesTitle: ''
    });

    const contentConditionVar = useMemo(() => (
        mainTitle?.length && mainInfo?.length && secondaryTitle?.length && secondaryInfo?.length
    ), [mainInfo, mainTitle, secondaryTitle, secondaryInfo]);

    const cookieOption = useMemo(() => (
        {
            path: '/',
            domain: `.${location.hostname}`,
            maxAge: 315360000
        }
    ), []);

    useEffect(() => {
        const cookiePolicy = cookies[REACT_APP_ACCEPTED_POLICY_COOKIE];
        setPolicyCookies(cookiePolicy);
    }, [cookies, REACT_APP_ACCEPTED_POLICY_COOKIE]);

    const { isLoading, error } = useFetchData({
        query: FetchData,
        condition: true,
        updateData: (data) => {
            const result = data?.cookiePolicy?.CookiePolicyPopup;
            if (!result) {
                return;
            }
            setMainTitle(result?.titleCookiePolicy);
            setMainInfo(result?.mainInfoCookiePolicy);
            setSecondaryInfo(result?.secondaryInfoCookiePolicy);
            setSecondaryTitle(result?.secondaryTitleCookiePolicy);
            const {
                analyticsCookiesInfo,
                analyticsCookiesTitle,
                essentialCookiesInfo,
                essentialCookiesTitle
            } = result?.cookiesDescriptions;
            setCookiesDescriptions({
                analyticsCookiesInfo,
                analyticsCookiesTitle,
                essentialCookiesInfo,
                essentialCookiesTitle
            });
        }
    });

    const setPolicyCookieCallback = useCallback(() => {
        setCookie(REACT_APP_ACCEPTED_POLICY_COOKIE, true, {...cookieOption});
        setPolicyCookies(true);
    }, [REACT_APP_ACCEPTED_POLICY_COOKIE, cookieOption, setCookie]);

    const setMixPanelCookieCallback = useCallback((value) => {
        setCookie(REACT_APP_MIX_PANEL_INIT_COOKIE, value, {...cookieOption});
    }, [REACT_APP_MIX_PANEL_INIT_COOKIE, cookieOption, setCookie]);

    const setAllCookiesHandler = useCallback((val: boolean) => {
        setPolicyCookieCallback();
        setMixPanelCookieCallback(val);
    }, [setPolicyCookieCallback, setMixPanelCookieCallback]);

    const confirmChoiceHandler = useCallback(() => {
        setPolicyCookieCallback();
        setMixPanelCookieCallback(mixPanelCookies);
    }, [mixPanelCookies, setMixPanelCookieCallback, setPolicyCookieCallback]);

    const checkboxHandler = useCallback((value: boolean) => {
        setMixPanelCookies(value);
    }, []);

    const popupStyles = useMemo(() => ({
            padding: '24px 17px 24px 32px',
            flexDirection: 'row'
    }), []);

    return (
        <DataValidator data={contentConditionVar && !policyCookies && !isLoading && !error}>
            <PopUp
                showCloseButton={false}
                type={'S'}
                additionalStyles={popupStyles}
                popUpTrigger={!cookies[REACT_APP_ACCEPTED_POLICY_COOKIE]}
                headingType={'h4'}
                popUpClose={setAllCookiesHandler}
                closeOnEsc={false}
                closeMaskOnClick={false}
                additionalClass={classes.CookiePolicyPopup}>
                {contentStage === 1 && <FirstStage
                    title={mainTitle}
                    info={mainInfo}
                    setContentStage={setContentStage}
                    setAllCookiesHandler={setAllCookiesHandler} />}
                {contentStage === 2 && <SecondStage
                    title={secondaryTitle}
                    info={secondaryInfo}
                    descriptions={cookiesDescriptions}
                    checkboxHandler={checkboxHandler}
                    confirmChoiceHandler={confirmChoiceHandler}
                    setAllCookiesHandler={setAllCookiesHandler} />}
            </PopUp>
        </DataValidator>
    );
};

export default withCookies(CookiePolicyPopup);
