import {useContext, useCallback, useEffect, useState} from 'react';
import { CommonContext } from 'js/context/CommonContext/CommonContext';

interface Props {
	updateData: any,
	query: Function,
	onStart?: Function,
	onSuccess?: Function,
	onFailure?: Function,
	onError?: Function
}

const useFetchCallback = ({updateData, query, onStart, onSuccess, onFailure, onError}: Props) => {
	const { apiPath } = useContext(CommonContext);
	const [data, setData] = useState(null);

	const fetchCallbackData = useCallback( (el) => {
		(async () => {
			onStart?.(el);
			try {
				const response = await fetch(`${apiPath}/graphql`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						query: `${query(el)}`
					})
				});

				const responseData = await response.json();
				if (!responseData || responseData === 'false' || responseData?.errors) {
					onFailure?.(responseData?.errors);
					return;
				}
				setData(responseData.data);
				onSuccess?.(responseData.data);
				return true;
			} catch (e) {
				onError?.(e);
				return true;
			}
		})();
	}, [apiPath, onError, onFailure, onStart, onSuccess, query]);

	useEffect(() => {
		if (data) {
			updateData(data);
		}
	}, [data, updateData]);

	return fetchCallbackData;
};

export default useFetchCallback;
