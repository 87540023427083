import React from 'react';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import classes from './GetToKnowItems.module.pcss';
import GetToKnowItem from '../GetToKnowItem/GetToKnowItem';

const GetToKnowItems = ({ items }) => (
	<DataValidator data={items}>
		<div className={classes.GetToKnowItems}>
			{
				items?.map
				(
					(item, index) => <GetToKnowItem key={index} item={item} />
				)
			}
		</div>
	</DataValidator>
);

export default GetToKnowItems;
