import React, {useState } from 'react';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import PromoBoxContextProvider from 'js/context/PromoBoxContext';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import fetchData from './fetchData';
import classes from './PromoBox.module.pcss';
import PromoBoxItems from '../PromoBoxItems/PromoBoxItems';
import { PromoDataBoxInterface } from './PromoBoxDataInterface';

const PromoBox = ({ pageId }) => {
	const [promoBoxData, setPromoBoxData] = useState<PromoDataBoxInterface[]>([]);

    const { isLoading, error } = useFetchData({
        query: `${fetchData(pageId)}`,
        condition: !!pageId,
        updateData: (data) => {
            setPromoBoxData(data?.page?.PreLoginPage?.promoBoxSlider);
        }
    });

    return (
        <PromoBoxContextProvider>
            <DataValidator data={!!promoBoxData && !isLoading && !error}>
                <section
                    className={classes.PromoBox}
                    style={{minHeight: 'calc(-60px + 100vh)'}}>
                    <PromoBoxItems items={promoBoxData}/>
                </section>
            </DataValidator>
        </PromoBoxContextProvider>
    );
};

export default PromoBox;
