import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Heading from 'js/components/Common/Heading/Heading';
import Spinner from 'js/components/Common/Spinner/Spinner';
import {ValidationRulesType} from 'js/helpers/validationRules';
import Input from 'js/components/Common/FormComponents/Input/Input';
import ErrorMessage from 'js/components/Common/FormComponents/ErrorMessage/ErrorMessage';

import classes from './CheckBoxesList.module.pcss';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

type CheckBoxesDataType = {
	id: string,
	name: string,
	checked: boolean
}[]

export interface Props {
	spinner?: boolean,
	onChange?: any,
	type?: 'checkBoxesList',
	heading?: {
		type: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p',
		text: string
	},
	size?: 'SM' | 'MD',
	name?: string,
	value?: string,
	prefix?: string,
	checkBoxesData: CheckBoxesDataType,
	validation?: {
		rules?: Array<ValidationRulesType>,
		isValid?: boolean,
		errorMessage?: string
	}
}

const CheckBoxesList = (
	{
		value,
		spinner,
		heading,
		onChange,
		validation,
		size = 'SM',
		prefix = '',
		checkBoxesData,
		type = 'checkBoxesList',
		name = 'checkBoxesList'
	}: Props
) => {
	const [checkedData, setCheckedData] = useState<CheckBoxesDataType>([]);

	useEffect(() => {
		setCheckedData(checkBoxesData);
	}, [checkBoxesData]);

	const changeCheckboxHandler = useCallback((e) => {
		const checkBoxValue = e?.target?.checked;
		const targetName = e?.target?.name;
		const clonedCheckedData = [...checkedData];

		const targetIndex = clonedCheckedData.findIndex(item => `${prefix}${item?.name}_${item?.id}` === targetName);

		clonedCheckedData[targetIndex] = {
			id: clonedCheckedData[targetIndex]?.id,
			name: clonedCheckedData[targetIndex]?.name,
			checked: checkBoxValue
		};

		setCheckedData(clonedCheckedData);

		const listOfCheckedLabels = clonedCheckedData?.filter(el => el?.checked)?.map(item => `${item?.name}_${item?.id}`);
		onChange && onChange(listOfCheckedLabels);

	}, [checkedData, onChange, prefix]);

	const setHeading = useMemo(() => {
		const {type:headingType, text} = heading || {};
		if (!text) {
			return '';
		}
		if (headingType === 'p') {
			return <p className={classes.Label}>{text}</p>;
		}
		return <Heading colored={true} text={text} type={headingType} />;
	}, [heading]);

	if (spinner) {
		return <div className={`${classes.Wrap} ${classes.Spinner}`}><Spinner type={'blue'} size={'medium'}/></div>;
	}
	return (
		<DataValidator data={!!checkBoxesData && checkBoxesData?.length > 0}>
			<div
				data-value={value}
				className={`${classes.CheckBoxesList} ${classes[size]}`}
				itemType={type}
				data-name={name}>
				{setHeading}
				<div className={classes.Wrap}>
					{checkedData?.map((item, index) =>
						(
							<Input
								checked={item?.checked}
								key={index}
								type="checkbox"
								withLabel={true}
								name={item.id}
								slug={`${prefix}${item.name}_${item.id}` || ''}
								labelText={item.name || ''}
								onChange={changeCheckboxHandler}
								id={'CheckBoxProduct'}
							/>
						))}
					{validation?.errorMessage && <ErrorMessage errorMessage={validation?.errorMessage} />}
				</div>
			</div>
		</DataValidator>
	);
};

export default CheckBoxesList;
