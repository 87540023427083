import React, {useCallback} from 'react';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Heading from 'js/components/Common/Heading/Heading';

import classes from './PopUp.module.pcss';

export interface Props {
	type?: 'S' | 'M' | 'L' | 'XL',
	additionalStyles?: any,
	additionalClass?: string,
	popUpTrigger: boolean,
	popUpClose: any,
	children: any,
	animation?: string,
	heading?: string | '',
	headingType?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6',
	fixedInfo?: any,
	onAnimationEnd?: any,
	showCloseButton?: boolean,
	closeMaskOnClick?:boolean,
	closeOnEsc?:boolean,
	subtitle?:string
}

const PopUp = ({
   type:popupType = 'M',
   additionalClass,
   additionalStyles,
   popUpTrigger,
   animation,
   popUpClose,
   children,
   heading,
   fixedInfo = false,
   onAnimationEnd,
   showCloseButton,
   headingType,
   closeOnEsc = true,
   closeMaskOnClick = true,
   subtitle
}: Props) => {
	const setPopupWidthByType = useCallback(() => {
		switch (popupType) {
			case 'S': return 544;
			case 'M': return 700;
			case 'L': return 960;
			case 'XL': return 1360;
			default : return 700;
		}
	}, [popupType]);

	return (
		<Rodal
			onAnimationEnd={onAnimationEnd}
			showCloseButton={showCloseButton}
			width={setPopupWidthByType()}
			className={`${classes.PopUp} ${popupType} ${additionalClass}`}
			customStyles={additionalStyles}
			visible={popUpTrigger}
			animation={animation || 'zoom'}
			onClose={popUpClose}
			closeOnEsc={closeOnEsc}
			closeMaskOnClick={closeMaskOnClick}
		>
			{heading ?
				<div className={classes.PopUpHeading}>
					<Heading text={heading} type={headingType || 'h3'}/>
				</div> : null
			}
			{subtitle ? <div className={classes.PopUpSubTitle}><p>{subtitle}</p></div> : null}
			<div
				className={classes.PopUpInfo}>
				{children}
			</div>
			{fixedInfo ? <div className={classes.PopUpInfoFixed}>
				{fixedInfo}
			</div> : null}
		</Rodal>
	);
};

export default PopUp;
