import React from 'react';

import classes from './ReadingTime.module.pcss';

export interface Props {
    data: number,
    additionalClass?: string
}

const ReadingTime = ({data, additionalClass}: Props) => (
    <span className={`${classes.ReadTime} ${additionalClass}`}>{`${data} ${data > 1 ? 'Mins' : 'Min'}`}</span>
);

export default ReadingTime;
