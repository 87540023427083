import React, {useState, useEffect, useCallback, useMemo} from 'react';
import {observer} from 'mobx-react';
import SearchStore from 'js/store/SearchStore';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';

import classes from './SearchPagination.module.pcss';

export interface Props {
    pages: number,
    pageChange: Function
}

const SearchPagination = ({pages, pageChange}: Props) => {
    const step:number = 1;
    const {searchPage} = SearchStore;
    const [paginationMapping, setPaginationMapping] = useState([]);

    const pagination = useMemo(() => {
        const current:number = searchPage;
        const last:number = pages;
        const delta:number = 2;
        const left:number = current - delta;
        const right:number = current + delta + 1;
        const range = [];
        const rangeWithDots = [];
        let l:number;

        for (let i = 1; i <= last; i++) {
            if (i == 1 || i == last || i >= left && i < right) {
                range.push(i);
            }
        }

        for (const i of range) {
            if (l) {
                if (i - l === 2) {
                    rangeWithDots.push(l + 1);
                } else if (i - l !== 1) {
                    rangeWithDots.push('...');
                }
            }
            rangeWithDots.push(i);
            l = i;
        }

        return rangeWithDots;
    }, [pages, searchPage]);

    const sendMixPanelPageClickEvent = useCallback(async (item: number) => {
        await MixPanelWrapper.TrackEvent(
            'Nav Page',
            {
                category: 'Search Nav',
                value: `${item}`
            });
    }, []);

    const sendMixPanelArrowClickEvent = useCallback(async (eventName: 'Nav Prev' | 'Nav Next', eventValue: number) => {
        await MixPanelWrapper.TrackEvent(
            eventName,
            {
                category: 'Search Nav',
                value: `${eventValue}`
            });
    }, []);

    const previousButtonHandler = useCallback(async () => {
        const prev = searchPage - step;
        pageChange(prev);
        await sendMixPanelArrowClickEvent('Nav Prev',  prev);
    }, [pageChange, searchPage, sendMixPanelArrowClickEvent]);

    const nextButtonHandler = useCallback(async () => {
        const next = searchPage + step;
        pageChange(next);
        await sendMixPanelArrowClickEvent('Nav Next',  next);
    }, [pageChange, searchPage, sendMixPanelArrowClickEvent]);

    const setActiveClass = useCallback((currentPage: number) => {
        if (searchPage === currentPage) {
            return classes.SearchPaginationItemActive;
        }
    }, [searchPage]);


    useEffect(() => {
        setPaginationMapping(pagination);
    }, [setPaginationMapping, pagination]);

    if (pages < 2) {
        return null;
    }
    return (
        <ul data-search-pagination={true} className={classes.SearchPagination} id="SearchPagination">
            {searchPage > 1 ? <li data-pagination-prev={true} className={`${classes.SearchPaginationItem} ${classes.SearchPaginationItemArrow} ${classes.Previous}`} onClick={previousButtonHandler}></li> : null}
            {paginationMapping?.map((item, index) => {
                if (typeof item === 'number') {
                    return (
                        <li
                            data-pagination-page={true}
                            className={`${classes.SearchPaginationItem} ${setActiveClass(item) || ''}`}
                            key={index}
                            onClick={async () => {
                                await pageChange(item);
                                await sendMixPanelPageClickEvent(item);
                            }}>
                            {item}</li>
                    );
                }
                return (
                    <li
                        key={index}
                        className={`${classes.SearchPaginationItem} ${classes.SearchPaginationDots}`}><span>...</span></li>
                );
            })}
            {searchPage !== pages ? <li data-pagination-next={true} className={`${classes.SearchPaginationItem} ${classes.SearchPaginationItemArrow} ${classes.Next}`} onClick={nextButtonHandler}></li> : null}
        </ul>
    );
};

export default observer(SearchPagination);
