import React, {useCallback, useState} from 'react';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';
import Heading from 'js/components/Common/Heading/Heading';
import Downloads from 'js/components/Common/ActivityCounter/Downloads';
import Description from 'js/components/Common/Description/Description';
import useFetchCallback from 'js/hooks/useFetchCallback/useFetchCallback';
import PublishingDate from 'js/components/Common/PublishingDate/PublishingDate';
import ProductLabelsList from 'js/components/Common/ProductLabelList/ProductLabelsList';
import VisibilityViewerIcon from 'js/components/Common/VisibilityViewerIcon/VisibilityViewerIcon';

import Label from '../../components/Label/Label';
import Thumbnail from '../../components/Thumbnail/Thumbnail';
import {updateDownloadsQueryPresentation} from './PresentationFetchData';
import commonClasses from '../../../SearchResultsItem/SearchResultsItem.module.pcss';


export interface Props {
    data: {
        id: number,
        file: string,
        title: string,
        thumbnail: string,
        downloads: number,
        description: string,
        type: 'presentations',
        publishingDate: string
    },
    isPromoted: boolean
}

const Presentation = ({data, isPromoted}: Props) => {
    const {title, description, publishingDate, downloads, id, file, thumbnail} = data || {};
    const [itemDownloads, setItemDownloads] = useState<number>(downloads);

    const sendMixPanelEvent = useCallback(async () => {
        await MixPanelWrapper.TrackEvent(
            'Download Presentation',
            {
                category: 'Search Results',
                value: data?.title
            });
    }, [data]);

    const fileDownloadHandler = useFetchCallback({
        updateData: val => val?.presentationsSingle?.updateDownloads,
        query: () => updateDownloadsQueryPresentation(id),
        onStart: async () => {
            const element = document.createElement('a');
            element.setAttribute('href', file);
            const ext = file.split('.').pop();
            element.setAttribute('download', `${title}.${ext}`);

            element.style.display = 'block';
            element.click();
            setItemDownloads(prev => prev + 1);
            await sendMixPanelEvent();
        },
        onFailure: () => {
            console.error('Error while downloading');
        },
        onError: (e) => {
            console.error(e);
        }
    });
    return (
        <div className={`${commonClasses.SearchResultItem} ${isPromoted && commonClasses.Promoted}`}>
            <Thumbnail thumbnail={{
                url: thumbnail,
                altText: title
            }} alt={title}/>
            <span className={commonClasses.SearchResultItemInfo}>
                <div className={commonClasses.DownloadButton} onClick={fileDownloadHandler}>
                    <Heading text={`${title}`} type={'h5'}/>
                </div>
                <Description data={description}/>
                <div className={commonClasses.Wrap}>
                    <Label type={'presentations'}/>
                    <Downloads count={itemDownloads}/>
		            <PublishingDate data={publishingDate}/>
                    <ProductLabelsList pageId={data?.id} />
                </div>
                <div className={commonClasses.Wrap}>
                    <VisibilityViewerIcon id={data?.id}/>
                </div>
            </span>
        </div>
    );
};

export default Presentation;
