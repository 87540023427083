import React, {useMemo} from 'react';
import classes from './Rating.module.pcss';

export interface Props {
    data: {
        rating: number
    }

}

const Rating = ({rating}) => {
    const style = useMemo(() => ({
        width: `${Math.round(rating) * 20}%`
    }), [rating]);
    return (
        <div className={classes.Rating}>
            <div className={classes.RatingInner} style={style} />
        </div>
    );
};

export default Rating;
