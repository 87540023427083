import React, {useCallback, useState, useEffect} from 'react';
import {NotificationDataType} from 'js/components/Notifications/NotificationsDataInterface';

export const NotificationsContext = React.createContext({
	updateTrigger: 0,
	notifications: [],
	frameOpened: false,
	customNotifications: [],
	setFrameOpened: value => value,
	setNotifications: value => value,
	setUpdateTrigger: value => value,
	setBlockUpdateTimer: value => value,
	setCustomNotifications: value => value,
	setStatus: (id, status, isCustom) => isCustom
});

const NotificationsContextProvider = (props) => {
	const delay = 300;
	const [updateTrigger, setUpdateTrigger] = useState<number>(0);
	const [frameOpened, setFrameOpened] = useState<boolean>(false);
	const [blockUpdateTimer, setBlockUpdateTimer] = useState(false);
	const [notifications, setNotifications] = useState<Array<NotificationDataType>>(null);
	const [customNotifications, setCustomNotifications] = useState<Array<NotificationDataType>>(null);

	const setStatus = useCallback((id, status, isCustom = false) => {
		if (isCustom) {
			const notificationCloned = [...customNotifications];
			const foundIndex = notificationCloned.findIndex(x => x.id == id);
			notificationCloned[foundIndex].status = status;
			setCustomNotifications(notificationCloned);
			return;
		}
		const notificationCloned = [...notifications];
		const foundIndex = notificationCloned.findIndex(x => x.id == id);
		notificationCloned[foundIndex].status = status;
		setNotifications(notificationCloned);
	}, [customNotifications, notifications]);

	// Auto update notifications
	useEffect(() => {
		if (blockUpdateTimer) {
			return;
		}
		const timer = setTimeout(() => {
			setUpdateTrigger(Math.random());
		}, delay * 1000);
		return () => {
			clearTimeout(timer);
		};
	}, [blockUpdateTimer, updateTrigger]);

	return (
		<NotificationsContext.Provider value={{
			setStatus,
			frameOpened,
			updateTrigger,
			notifications,
			setFrameOpened,
			setNotifications,
			setUpdateTrigger,
			customNotifications,
			setBlockUpdateTimer,
			setCustomNotifications
		}}>
			{props.children}
		</NotificationsContext.Provider>
	);
};

export default NotificationsContextProvider;
