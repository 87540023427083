export default postID => `
   {
    resourceVisibility {
      checkResourceVisibility(postId: ${postID})
    }
  }
`;

export const checkVisibilityForArray = (ids:Array<number>) => {
    if (!ids?.length) {
        return '';
    }
    const idsString = ids?.join(',');
    return `
        {
            resourceVisibility {
              checkResourceVisibilityForArray(postIds: "${idsString}")
            }
        }
    `;
};
