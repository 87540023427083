export default pageId => (
	`{
			page(id: "${pageId}", idType: DATABASE_ID) {
			PostLoginPage {
				videoData {
					mp4 {
						mediaItemUrl
					}
					placeholder {
						mediaItemUrl
					}
					muteButtonColor
					overlay
					overlayOpacity
					soundButton
				}
			}
		}
	}`
);
