import imageFragment from 'js/graphqlFragments/ImageFragment';

export default `
   {
        themeGeneralSettings {
          Options {
            headerLogo {
                ...ImageFragmentLogoImage
            }
          }
        }
  }
   ${imageFragment('LogoImage')}
`;
