import React, {useRef} from 'react';
import TopInfo from 'js/components/Templates/PostLoginPage/TopInfo/TopInfo';
import HomeBackground from 'js/components/Templates/PostLoginPage/HomeBackground/HomeBackground';
import SearchForm from 'js/components/Common/SearchBlock/SearchForm/SearchForm';
const PostLoginPageTemplate = ({pageId}) => {
	const formRef = useRef(null);
	return (
		<main className="templatePostLogin" ref={formRef}>
			<HomeBackground pageId={pageId}>
				<div style={{
					zIndex: 100,
					position: 'relative'
				}}>
					<TopInfo pageId={pageId}/>
					<SearchForm headerRef={formRef} isStatic={true} />
				</div>
			</HomeBackground>
		</main>
	);
};

export default PostLoginPageTemplate;
