import React, {useEffect, useMemo} from 'react';
import SeoHelmet from 'js/containers/SEO/SeoHelmet';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';
import Heading from 'js/components/Common/Heading/Heading';
import Container from 'js/components/Common/Container/Container';

const Page404Template = () => {
    const {INIT_MIX_PANEL} = useMemo(() => MixPanelWrapper, []);
    useEffect(() => {
        (async () => {
            await MixPanelWrapper.TrackPageView({
                page: 'Page 404'
            });
        })();
    }, [INIT_MIX_PANEL]);
    return (
        <section className="404PageTemplate">
            <Container>
                <Heading text={'404'} type={'h1'}/>
                <SeoHelmet title={'404 page'} description={'Data not found'} canonical={'/404'}/>
            </Container>
        </section>
    );
};
export default Page404Template;
