import React, {useState} from 'react';

export const HelpPageContext = React.createContext({
	activeItemId: null,
	setActiveItemId: value => value,
	activeItemName: '',
	setActiveItemName: value => value,
	loading: false,
	setLoading: value => value,
	totalCount: 0,
	setTotalCount: value => value
});

const HelpPageContextProvider = (props) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [activeItemId, setActiveItemId] = useState<number>(null);
	const [activeItemName, setActiveItemName] = useState<string>('');
	const [totalCount, setTotalCount] = useState<number>(0);
	return (
		<HelpPageContext.Provider value={{
			loading,
			setLoading,
			activeItemId,
			totalCount,
			setActiveItemId,
			activeItemName,
			setTotalCount,
			setActiveItemName
		}}>
			{props.children}
		</HelpPageContext.Provider>
	);
};

export default HelpPageContextProvider;
