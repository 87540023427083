export const fetchData = pageId => `
{
    page(id: "${pageId}", idType: DATABASE_ID) {
      HelpTemplate {
        helpArea {
          name: heading
        }
      }
    }
  }
`;


export const subtitleFetchData = pageId => `
{
    page(id: "${pageId}", idType: DATABASE_ID) {
      HelpTemplate {
        subtitleHelp
      }
    }
  }
`;
