import React, {useMemo, useState} from 'react';
import Holder from 'images/holder.png';
import Label from 'js/components/Common/Label/Label';
import checkDateOffset from 'js/helpers/CheckDateOffset';
import Button from 'js/components/Common/Button/Button';
import Heading from 'js/components/Common/Heading/Heading';
import useCutBackground from 'js/hooks/useCutBackground/useCutBackground';
import PostDuration from 'js/components/Common/PostDuration/PostDuration';
import DateByTimezone from 'js/components/Common/DateByTimezone/DateByTimezone';
import VisibilityViewerIcon from 'js/components/Common/VisibilityViewerIcon/VisibilityViewerIcon';
import EventRegistrationWrap from 'js/components/Common/EventRegistrationWrap/EventRegistrationWrap';

import Participants from './Participants';
import classes from './EventListItem.module.pcss';
import {EventsButtonsType, WrapperDataInterface, EventsListTypes} from '../Wrapper/WrapperDataInterface';


export interface Props {
    item?: WrapperDataInterface,
    buttons?: EventsButtonsType,
    sectionType?: EventsListTypes
}

const EventListItem = ({item, buttons, sectionType}: Props) => {
    const {
        eventType,
        startDate,
        shortDescription,
        duration,
        eventLink,
        host,
        hosts,
        panelists
    } = item?.Meetings || {};

    const bg = useCutBackground(item?.featuredImage, '380x310');
    const {meetingID, meetingDateID, databaseId} = item || {};
    const [registrationHash, setRegistrationHash] = useState<string | null>(item?.userRegisteredHash);

    const labelByType = useMemo(() => {
        switch (eventType) {
            case 'Conference' : return 'Conference';
            case 'Panel' : return 'Panel Discussion';
            default : return null;
        }
    }, [eventType]);

    return (
        <div className={classes.EventListItem} >
            <div className={classes.Thumbnail} style={{backgroundImage: `url('${bg || Holder}')`}} />
            <div className={classes.FirstInfo}>
                <VisibilityViewerIcon id={item?.databaseId} additionalClass={classes.VisibilityIcon} />
                <Label value={ checkDateOffset(startDate, 120) ? 'Last Chance' : '' }
                       additionalClass={classes.Label} />
                <Label value={labelByType}
                       additionalClass={classes.Label} />
                <Label value={item?.ResourceVisibility?.resourceVisibility === 'playtech' ? 'Playtech Only' : null}
                       additionalClass={classes.Label}
                       colorType={'Yellow'} />
                <Label value={registrationHash && 'Registered'}
                       additionalClass={classes.Label}
                       colorType={'Blue'} />
                <Heading text={item?.title} type={'h5'} />
                {shortDescription && <p className={classes.Description}>{shortDescription}</p>}
                <Participants eventType={eventType} host={host} hosts={hosts} panelists={panelists} />
            </div>
            <div className={classes.SecondInfo}>
                <PostDuration duration={duration} />
                <DateByTimezone className={classes.Date}  date={startDate} format={'MMM Do, HH:mm'} />
                {sectionType !== 'webinar' ? <EventRegistrationWrap
                    meetingID={meetingID}
                    meetingDateID={meetingDateID}
                    databaseId={databaseId}
                    eventLink={eventLink}
                    eventType={eventType}
                    initialRegistrationHash={registrationHash}
                    startDate={startDate}
                    buttons={buttons}
                    title={item?.title}
                    eventCategory={'Live Events'}
                    setRegistrationHashCallback={setRegistrationHash}
                >
                    <Button />
                </EventRegistrationWrap> :
                    <Button
                    type={'a'}
                    target={'_blank'}
                    link={eventLink}
                    text={buttons?.viewEventButton} />}
            </div>
        </div>
    );
};

export default EventListItem;
