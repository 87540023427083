const tagsType = `{
			id
			name
			slug
		  }`;
const fetchData = () => (
	`
	{
		getResponse {
		  getTags ${tagsType}
		  getTagsForCurrentUser ${tagsType}
		}
  	}
	`
);

export default fetchData;
