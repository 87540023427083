import thumbnailFragment from 'js/graphqlFragments/ThumbnailFragment';

const fetchData = pageId => `
{
    page(id: "${pageId}", idType: DATABASE_ID) {
      PreLoginPage {
        headingIndustryTalks
          subheadingIndustryTalks
          buttonTextIndustryTalks
        industryTalksCarousel {
          ... on MeetingsSingle {
            title
            id
            meetingID
            Meetings {
              shortDescription
              startDate
              price
              category
            }
            ...ThumbnailFragment
          }
        }
      }
    }
  }
  ${thumbnailFragment('MeetingsSingle')}
`;
export default fetchData;
