import React, { useState } from 'react';
import Heading from 'js/components/Common/Heading/Heading';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Container from 'js/components/Common/Container/Container';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import fetchData from './fetchData';
import classes from './MissionStatement.module.pcss';
import { MissionStatementDataInterface } from './MissionStatementDataInterface';
import MissionStatementItems from '../MissionStatementItems/MissionStatementItems';

const MissionStatement = ({ pageId }) => {
	const [missionStatementTitle, setMissionStatementTitle] = useState<string>('');
	const [missionStatementItems, setMissionStatementItems] = useState<MissionStatementDataInterface[]>([]);

	const { isLoading, error } = useFetchData({
	    query: `${fetchData(pageId)}`,
	    condition: !!pageId,
	    updateData: (data) => {
			setMissionStatementTitle(data.page.PreLoginPage.headingMissionStatement);
			setMissionStatementItems(data.page.PreLoginPage.listMissionStatement);
	    }
	});

	return (
		<DataValidator data={!!missionStatementItems && !isLoading && !error }>
			<section className={classes.MissionStatement}>
				<Container>
					<div className={classes.heading_wrapper}>
						<Heading text={missionStatementTitle} thin={true} />
					</div>
					<MissionStatementItems items={missionStatementItems} />
				</Container>
			</section>
		</DataValidator>
	);
};

export default MissionStatement;
