import React, {useContext, useMemo, useState} from 'react';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import {CommonContext} from 'js/context/CommonContext/CommonContext';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import fetchData from './fetchData';
import classes from './Information.module.pcss';
import InformationItem from './InformationItem/InformationItem';

const Information = () => {
    const {currentUser} = useContext(CommonContext);
    const {firstName, lastName, email, company, position, username} = currentUser || {};
    const [headings, setHeadings] = useState<{
        leftInfoTitleProfile: string,
        rightInfoTitleProfile: string
    }>({
        leftInfoTitleProfile: '',
        rightInfoTitleProfile: ''
    });

    const leftInfo = useMemo(() => [
        { label: 'First Name', value: firstName },
        { label: 'Last Name', value: lastName },
        { label: 'Username', value: username }
    ], [firstName, lastName, username]);

    const rightInfo = useMemo(() => [
        { label: 'Email', value: email },
        { label: 'Role', value: position },
        { label: 'Company', value: company }
    ], [company, email, position]);

    const { isLoading, error } = useFetchData({
        query: `${fetchData}`,
        condition: true,
        updateData: (data) => {
            setHeadings(data?.themeGeneralSettings?.Options);
        }
    });

    return (
        <DataValidator data={!isLoading && !error}>
            <section className={classes.Information}>
                <InformationItem heading={headings?.leftInfoTitleProfile} data={leftInfo}/>
                <InformationItem heading={headings?.rightInfoTitleProfile} data={rightInfo}/>
            </section>
        </DataValidator>
    );
};

export default Information;
