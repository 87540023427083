import React, { useState } from 'react';
import Heading from 'js/components/Common/Heading/Heading';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Container from 'js/components/Common/Container/Container';
import SubHeading from 'js/components/Common/SubHeading/SubHeading';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import fetchData from './fetchData';
import classes from './Awards.module.pcss';
import AwardsItems from '../AwardsItems/AwardsItems';
import { AwardsDataInterface } from './AwardsDataInterface';

const Awards = ({ pageId }) => {
	const [AwardsTitle, setAwardsTitle] = useState<string>('');
	const [AwardsSubTitle, setAwardsSubTitle] = useState<string>('');
	const [AwardsData, setAwardsData] = useState<AwardsDataInterface[]>([]);

	const { isLoading, error } = useFetchData({
		query: `${fetchData(pageId)}`,
		condition: !!pageId,
		updateData: (data) => {
			setAwardsTitle(data?.page?.AboutUs?.headingAwards);
			setAwardsSubTitle(data?.page.AboutUs?.subtitleAwards);
			setAwardsData(data?.page?.AboutUs?.awardsRecognitions);
		}
	});

	return (
		<DataValidator data={!!AwardsItems && !isLoading && !error}>
			<section className={classes.Awards}>
				<Container>
					<Heading text={AwardsTitle} />
					<SubHeading text={AwardsSubTitle} />
					<AwardsItems items={AwardsData} />
				</Container>
			</section>
		</DataValidator>
	);
};

export default Awards;
