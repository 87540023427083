import {templateNameType} from './BeforeYouLeaveDataInterface';

const fetchData = (pageType: templateNameType, pageId: number) => (
	`{
			page(id: "${pageId}", idType: DATABASE_ID) {
			   ${pageType} {
					titleBeforeYouLeave
					infoBeforeYouLeave
					fingerDirection
					buttonLinkBeforeYouLeave {
						url
						title
						target
					}
				}
			}
		}
		
	`
);

export default fetchData;
