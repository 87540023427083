import React from 'react';
import Heading from 'js/components/Common/Heading/Heading';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import classes from './PriceContainer.module.pcss';

export interface Props {
	heading: string,
	price: number | string,
	showPrice?: boolean
}

const PriceContainer = ({heading, price, showPrice}: Props) => (
		<DataValidator data={heading}>
			<div className={`${classes.PriceContainer}`}>
				<Heading text={heading} type={'h6'} />
				{price && showPrice ? <span>£{price}</span> : null}
			</div>
		</DataValidator>
);
export default PriceContainer;
