import {keys} from 'js/components/Common/ToastMessage/ToastMessageDataInterface';

const getChunks = ():string => {
    let query:string = '';
    keys?.forEach((item) => {
        query += `${item?.id} { 
        success
        ${item?.isErrorMessage ? 'error' : ''}
     }`;
    });
    return query;
};

const getQuery = ():string => `
        {
            toastMessages {
              ToastMessages {
                ${getChunks()}
              }
            }
          }
    `;

export default getQuery;

