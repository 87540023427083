import React, {useMemo} from 'react';
import parse from 'html-react-parser';
import Image from 'js/components/Common/Image/Image';
import Button from 'js/components/Common/Button/Button';
import Heading from 'js/components/Common/Heading/Heading';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';
import useFetchCallback from 'js/hooks/useFetchCallback/useFetchCallback';

import classes from '../Items.module.pcss';
import {CommonContentItemType} from '../DataTypes';
import {downloadManualFetchData} from '../../fetchData';
import ProductLabelsList from 'js/components/Common/ProductLabelList/ProductLabelsList';

interface Props {
    activeItem: CommonContentItemType,
    isFocus?: boolean
}

const Item = ({activeItem, isFocus = false}: Props) => {
    const {databaseId, link, title, uri, type, buttonLabel, description, image} = activeItem || {};

    const fileDownloadHandler = useFetchCallback({
        updateData: data => data?.manualsSingle?.updateDownloads,
        query: () => `${downloadManualFetchData(databaseId)}`,
        onStart: () => {
            const element = document.createElement('a');
            element.setAttribute('href', link);
            const ext = link.split('.').pop();
            element.setAttribute('download', `${title}.${ext}`);

            element.style.display = 'block';
            element.click();
        },
        onSuccess: async () => {
            await MixPanelWrapper.TrackEvent(
                'Download Manual',
                {
                    category: 'Product Page',
                    value: title
                });
        },
        onFailure: () => {
            console.error('Error while downloading');
        },
        onError: (e) => {
            console.error(e);
        }
    });

    const button = useMemo(() => {
        if (type === 'manual') {
            return <Button
                additionalStylesClass={classes.Button}
                type={'button'}
                handler={fileDownloadHandler}
                text={isFocus ? buttonLabel : ''} />;
        }
        return <Button
            additionalStylesClass={classes.Button}
            type={'a'}
            buttonType={type === 'article' ? 'ArticleButton' : 'ButtonColored'}
            link={`/${uri}`}
            text={isFocus ? buttonLabel : ''} />;
    }, [isFocus, buttonLabel, uri, type, fileDownloadHandler]);

    return (
        <div key={activeItem?.databaseId} className={`${classes.Item} ${isFocus && classes.OnFocusItem}`}>
            <div className={classes.Top}>
                <div className={classes.Image}>
                    <Image mediaItemUrl={image} alt={title}/>
                </div>
                <div className={classes.Info}>
                    <Heading text={title} type={'h4'}/>
                    <ProductLabelsList pageId={activeItem?.databaseId} />
                    <div className={classes.Description}>{parse(description || '')}</div>
                </div>
            </div>
            {button}
        </div>
    );
};

export default Item;
