import React from 'react';
import parse from 'html-react-parser';
import { Helmet } from 'react-helmet-async';

export interface Props {
	title: string,
	description: string,
	canonical: string
}

const SeoHelmet = ({ title, description, canonical }: Props) => (
	<Helmet>
		<title>{parse(title || '')}</title>
		{description ? <meta name="description" content={description} /> : null}
		<link rel="canonical" href={canonical} />
	</Helmet>
);

export default SeoHelmet;
