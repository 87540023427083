import React, {useCallback, useContext, useEffect, useState} from 'react';
import Button from 'js/components/Common/Button/Button';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';
import {ManualsHubContext} from 'js/context/ManualsHubContext/ManualsHubContext';

import fetchData from './fetchData';
import ManualItem from '../ManualItem/ManualItem';
import classes from './ManualsResults.module.pcss';

const ManualsResults = ({pageId}: {pageId: number}) => {
	const {
		limit,
		loading,
		setLimit,
		setLoading,
		showLoadAllBtn,
		activeCategoryId,
		setShowLoadAllBtn,
		totalManualsCount,
		setTotalManualsCount
	} = useContext(ManualsHubContext);
	const [manuals, setManuals] = useState([]);
	const [count, setCount] = useState(0);

	const { isLoading, error } = useFetchData({
	    query: `${fetchData(pageId, activeCategoryId, limit)}`,
	    condition: !!pageId,
	    updateData: (data) => {
			setManuals(data?.manualsHub?.getManualItems);
			setCount(data?.manualsHub?.getManualsCount);
			setLoading(false);
	    }
	});

	useEffect(() => {
		setTotalManualsCount(count);
	}, [count, setTotalManualsCount]);

	const showAllButtonHandler = useCallback(() => {
		setLoading(true);
		setLimit('all');
		setShowLoadAllBtn(false);
	}, [setLimit, setLoading, setShowLoadAllBtn]);

	return (
		<DataValidator data={!!manuals && !isLoading && !error}>
			<article className={classes.ManualsResults}>
				<div className={classes.ManualsResultsItems}>
					{manuals?.map(item => <ManualItem key={item?.id} itemData={item} />)}
				</div>
				{
					(showLoadAllBtn && totalManualsCount > 0) && (totalManualsCount !== manuals?.length) ?
				<Button
					handler={showAllButtonHandler}
					type={'button'}
					text={'Show All'}
					withSpinner={loading}
					spinnerType={'blue'}
					buttonType={'ButtonUncolored'}
				/> : null}
			</article>
		</DataValidator>
	);
};

export default ManualsResults;
