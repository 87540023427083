import React, {useMemo, useState} from 'react';
import Swiper from 'react-id-swiper';
import parse from 'html-react-parser';
import { Navigation, Pagination } from 'swiper/js/swiper.esm';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import fetchData from './fetchData';

import Container from 'js/components/Common/Container/Container';
import Heading from 'js/components/Common/Heading/Heading';

import classes from './LearningPath.module.pcss';

interface Props {
    pageId: number
}

const LearningPath = ({pageId}: Props) => {
    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [learningPathSlider, setLearningPathSlider] = useState<Array<{
        step: string,
        info: string
    }>>(null);

    const {isLoading, error} = useFetchData({
        query: `${fetchData(pageId)}`,
        condition: !!pageId,
        updateData: (data: any): void => {
            setTitle(data?.page?.TrainingTemplate?.titleLearningPath);
            setDescription(data?.page?.TrainingTemplate?.learningPathDescription);
            setLearningPathSlider(data?.page?.TrainingTemplate?.learningPathSlider);
        }
    });

    const params = useMemo(() => ({
        spaceBetween: 30,
        noSwiping: true,
        speed: 500,
        autoHeight: false,
        watchOverflow: true,
        modules: [Navigation, Pagination],
        pagination: {
            el: `.${classes.Description} + .${classes.Bullets}`,
            clickable: true,
            renderBullet: (index, className) => `<div class="${  className  }"><span><strong>${  index + 1  }</strong></span><span>${learningPathSlider[index]?.step}</span></div>`
        }
    }), [learningPathSlider]);

    return (
        <DataValidator data={!isLoading && !error && learningPathSlider?.length > 0}>
            <section className={classes.LearningPath}>
                <Container additionalClass={classes.Wrap}>
                    <Heading text={title}/>
                    <div className={classes.Description}>{parse(description || '')}</div>
                    <div className={`${classes.Bullets}`}></div>
                    <div className={classes.Slider}>
                        <Swiper {...params}>
                            {learningPathSlider?.map((slide, index) => (
                                <div className={`${classes.Slide} 'swiper-slide'`} key={index}>
                                    <Heading text={`0${index + 1} ${slide?.step}`} type={'h4'} />
                                    <div className={classes.SlideInfo}>{parse(slide?.info)}</div>
                                </div>
                            ))}
                        </Swiper>
                    </div>
                </Container>
            </section>
        </DataValidator>
    );
};

export default LearningPath;
