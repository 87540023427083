import React from 'react';
import AwardsItem from '../AwardsItem/AwardsItem';
import classes from './AwardsItems.module.pcss';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

const AwardsItems = ({ items }) => (
	<DataValidator data={items}>
		<div className={classes.AwardsItems}>
			{
				items?.map
				(
					(item, index) => <AwardsItem key={index} item={item} />
				)
			}
		</div>
	</DataValidator>
);

export default AwardsItems;
