import React, {useState} from 'react';
import Heading from 'js/components/Common/Heading/Heading';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Container from 'js/components/Common/Container/Container';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import fetchData, {FetchResultType, getFetchResult} from './fetchData';
import classes from './TopSection.module.pcss';
import Breadcrumbs from './Breadcrumbs/Breadcrumbs';

export type PageType = 'product' | 'page'
interface Props {
	pageId: number,
	pageType: PageType
}
const TopSection = ({ pageId, pageType }: Props) => {
	const [title, setTitle] = useState<string>('');
	const [parent, setParent] = useState<{
		uri: string,
		name?: string,
		title?: string
	}>({
		name: '',
		title: '',
		uri: ''
	});
	const { isLoading, error } = useFetchData({
	    query: `${fetchData(pageType, pageId)}`,
	    condition: !!pageId,
	    updateData: (data: any):void => {
			const result:FetchResultType = getFetchResult(data, pageType);
			setTitle(result?.title);
			setParent(result?.parent);
		}
	});
	return (
		<DataValidator data={!isLoading && !error && title}>
			<section className={classes.TopSection}>
				<Container>
					<Heading text={title} type={'h1'}/>
					<Breadcrumbs title={title} parent={parent} />
				</Container>
			</section>
		</DataValidator>
	);
};

export default TopSection;
