import React, {useCallback, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import useFetchData from 'js/hooks/useFetchData/useFetchData';

import Event from './Event/Event';
import Course from './Course/Course';
import Custom from './Custom/Custom';
import {getBannerType} from './fetchData';
import {TopBannerPageType} from './TopBannerDataInterface';

export interface Props {
  pageId: number,
  pageType: TopBannerPageType
}

const TopBanner = ({ pageId, pageType}: Props) => {
    const [bannerDataType, setBannerDataType] = useState<'custom' | 'course' | 'event' | 'video'>('custom');
    const location = useLocation();

    const checkRequestDataType = useCallback((data) => {
        if (!data?.page) {
            return;
        }
        const {bannerType} = data?.page[pageType] || {};

        if (!bannerType) {
            return;
        }

        switch (pageType) {
            case 'CoursesTemplate' : return bannerType === 'entity_data' ? setBannerDataType('course') : setBannerDataType('custom');
            case 'EventsTemplate' : return bannerType === 'entity_data' ? setBannerDataType('event') : setBannerDataType('custom');
            case 'HelpTemplate' :
            case 'ManualsHub' : return setBannerDataType('custom');
            default : return null;
        }
    }, [pageType]);

    useFetchData({
        query: getBannerType(pageId, pageType),
        condition: !!pageId,
        updateData: data => checkRequestDataType(data)
    });

    return useMemo(() => {
        switch (bannerDataType) {
            case 'course': return <Course pageId={pageId} location={location} />;
            case 'event': return <Event pageId={pageId} changeBannerDataType={
                () => setBannerDataType('custom')
            } />;
            default : {
                return <Custom pageId={pageId} pageType={pageType} />;
            }
        }
    }, [bannerDataType, location, pageId, pageType]);
};

export default TopBanner;
