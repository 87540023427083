export default `
   {
    interestsPopup {
      InterestsPopup {
        infoInterestsPopup
        titleInterestsPopup
      }
    }
  }
`;
