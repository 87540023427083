import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import {ValidationRulesType} from 'js/helpers/validationRules';

import ErrorMessage from '../ErrorMessage/ErrorMessage';


export interface PropsType {
    children?: any,
    onChange?: Function,
    value?: string,
    name: string,
    type: 'ReCaptcha',
    recaptchaRef?: any,
    size?:any,
    validation?: {
        rules: Array<ValidationRulesType>,
        isValid?: boolean,
        errorMessage?: string
    }
}


const ReCaptcha = ({type, onChange, validation, recaptchaRef, size}:PropsType) => {
    const {REACT_APP_GOOGLE_RECAPTCHA_KEY} = process.env || {};
    if (!REACT_APP_GOOGLE_RECAPTCHA_KEY) {
        return <></>;
    }
    return (
        <div
            data-el-type={type}
            className={'ReCaptcha'}>
            <ReCAPTCHA
                size={size}
                ref={recaptchaRef}
                onChange={onChange}
                type={'image'}

                sitekey={REACT_APP_GOOGLE_RECAPTCHA_KEY}
            />
            {validation?.errorMessage && <ErrorMessage errorMessage={validation?.errorMessage} />}
        </div>
    );
};

export default ReCaptcha;
