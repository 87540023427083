import React from 'react';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import classes from './FooterInfo.module.pcss';

const FooterInfo = ({ data }) => (
	<DataValidator data={data}>
		<div className={classes.FooterInfo}>
			<p>{data}</p>
		</div>
	</DataValidator>

);

export default FooterInfo;
