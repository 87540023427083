import casinoChips from 'images/promo/casino-chips.png';
import casinoCharacter from 'images/promo/casino-character.png';
import casinoSecondary from 'images/promo/casino-secondary.png';

import paymentStool from 'images/promo/stool.png';
import paymentLaptop from 'images/promo/po-laptop.png';
import paymentCharacter from 'images/promo/character.png';

import fraudLaptop from 'images/promo/fraud-laptop.png';
import fraudCharacter from 'images/promo/fraud-character.png';
import fraudSecondary from 'images/promo/fraud-secondary.png';

import marketeerConfetti from 'images/promo/marketeer-confetti.png';
import marketeerCharacter from 'images/promo/marketeer-character.png';
import marketeerSecondary from 'images/promo/marketeer-secondary.png';

type PromoBoxImagesType = {
	id: number,
	firstSrc: string,
	secondSrc: string,
	thirdSrc: string,
	bgPosition: Array<number>
}[]

const PromoBoxImages: PromoBoxImagesType = [
	{
		id: 4,
		firstSrc: casinoCharacter,
		secondSrc: casinoChips,
		thirdSrc: casinoSecondary,
		bgPosition: [3, 2, 1]
	},
	{
		id: 2,
		firstSrc: paymentCharacter,
		secondSrc: paymentLaptop,
		thirdSrc: paymentStool,
		bgPosition: [3, 2, 1]
	},
	{
		id: 3,
		firstSrc: fraudLaptop,
		secondSrc: fraudSecondary,
		thirdSrc: fraudCharacter,
		bgPosition: [1, 2, 3]
	},
	{
		id: 1,
		firstSrc: marketeerCharacter,
		secondSrc: marketeerSecondary,
		thirdSrc: marketeerConfetti,
		bgPosition: [3, 2, 1]
	}
];

export default PromoBoxImages;
export type { PromoBoxImagesType };
