import React, {useContext, useMemo} from 'react';
import Intro from 'js/components/Common/Intro/Intro';
import Loader from 'js/components/Common/Loader/Loader';
import NoDataSection from 'js/components/Common/NoDataSection/NoDataSection';

import EventsListBlock from '../EventsListBlock/EventsListBlock';
import {EventsListContext} from '../context/EventsListContext';
import ExpandableDisclaimer from '../../ExpandableDisclaimer/ExpandableDisclaimer';

const Wrapper = ({pageId, nodeRef}) => {
    const {emptyContent} = useContext(EventsListContext);
    const additionalCondition = useMemo(() => emptyContent === null || emptyContent === true, [emptyContent]);

    return (
        <>
            <div id="Meetings">
                <EventsListBlock type="upcomingByDate" />
                <EventsListBlock type="skillBooster" />
                <EventsListBlock type="learnNew" />
                <EventsListBlock type="inspired" />
            </div>
            <ExpandableDisclaimer
                additionalCondition={additionalCondition}
                pageId={pageId}
                nodeRef={nodeRef} />
            <Intro
                additionalCondition={additionalCondition}
                pageType={'EventsTemplate'}
                pageId={pageId} />

            {emptyContent === null ? <Loader/> : null}
            {emptyContent === true && <NoDataSection
                infoKeyProp={'noEventsInfo'}
                buttonTextKeyProp={'noEventsButtonText'}
                imageKeyProp={'noEventsImage'}
                buttonLinkKeyProp={'noEventsButtonLink'}
            />}
        </>
    );
};

export default Wrapper;
