import React, {useCallback, useContext, useEffect, useReducer, useState} from 'react';
import Heading from 'js/components/Common/Heading/Heading';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Input from 'js/components/Common/FormComponents/Input/Input';
import {CommonContext} from 'js/context/CommonContext/CommonContext';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';
import {initialState, MyProfileContext} from 'js/context/MyProfileContext/MyProfileContext';

import fetchData from './fetchData';
import classes from './Preferences.module.pcss';

type stateTypeItem = {
    slug: string,
    label: string,
    status: boolean
}

export type stateType = {
    email: stateTypeItem,
    messages: stateTypeItem,
    notifications: stateTypeItem,
    disableAll: stateTypeItem,
    loaded: boolean
}

const Preferences = () => {
    const {currentUser} = useContext(CommonContext);
    const { setPreferences } = useContext(MyProfileContext);
    const [title, setTitle] = useState<string>('');
    const [preferencesLabelsData, setPreferencesLabelsData] = useState(null);

    const reducer = (state: stateType, action): any => {
        switch (action?.type) {
            case 'init' : {
                return {...action?.value};
            }
            case 'change' : {
                return {...action?.value};
            }
            default :
                break;
        }
    };
    const [state, dispatch] = useReducer(reducer, initialState);

    const { isLoading, error } = useFetchData({
        query: `${fetchData}`,
        condition: true,
        updateData: (data) => {
            const result = data?.themeGeneralSettings?.Options;
            setPreferencesLabelsData(result?.preferencesLabels);
            setTitle(result?.preferencesTitle || '');
        }
    });

    useEffect(() => {
        const isDisableAll = currentUser?.disableAllMarketing;
        const value: stateType = {
            email: {
                slug: 'promotionalEmails',
                label: preferencesLabelsData?.emails,
                status: !isDisableAll && currentUser?.promotionalEmails
            },
            messages: {
                slug: 'promotionalTextMessages',
                label: preferencesLabelsData?.messages,
                status: !isDisableAll && currentUser?.promotionalMessages
            },
            notifications: {
                slug: 'browserNotifications',
                label: preferencesLabelsData?.notifications,
                status: !isDisableAll && currentUser?.browserNotifications
            },
            disableAll: {
                slug: 'disableAll',
                label: preferencesLabelsData?.disableMarketing,
                status: currentUser?.disableAllMarketing
            },
            loaded: true
        };

        dispatch({type: 'init', value});
        setPreferences(value);
    }, [currentUser, setPreferences, preferencesLabelsData]);

    const checkBoxChangeHandler = useCallback((key, stateClone) => {

        const value: stateType = {
            ...stateClone,
            [key]: {
                ...stateClone[key],
                status: !stateClone[key]?.status
            },
            disableAll: {
                ...stateClone?.disableAll,
                status: false
            }
        };

        if (!currentUser?.playtechUser) {
            dispatch({ type: 'change', value });
            setPreferences(value);
        }
    }, [currentUser, setPreferences]);

    const checkBoxDisableChangeHandler = useCallback((stateClone) => {
        const value: stateType = {
            ...stateClone,
            email: {
                ...stateClone?.email,
                status: false
            },
            messages: {
                ...stateClone?.messages,
                status: false
            },
            notifications: {
                ...stateClone?.notifications,
                status: false
            },
            disableAll: {
                ...stateClone?.disableAll,
                status: !stateClone?.disableAll?.status
            }
        };

        if (!currentUser?.playtechUser) {
            dispatch({ type: 'change', value });
            setPreferences(value);
        }
    }, [currentUser, setPreferences]);
    return (
        <DataValidator data={!isLoading && !error}>
            {
                state?.loaded && <div className={classes.Preferences}>
                    <Heading colored={true} type={'h4'} text={title}/>
                    {state?.email?.label && <Input
                        type="checkbox"
                        withLabel={true}
                        slideCheckbox={true}
                        id={state?.email?.slug}
                        name={state?.email?.slug}
                        slug={state?.email?.slug}
                        checked={state?.email?.status}
                        labelText={state?.email?.label}
                        additionalClass={`${classes.PreferencesItem} ${currentUser?.playtechUser ? classes.Disabled : ''}`}
                        onChange={() => checkBoxChangeHandler('email', {...state})}
				            />}
                    {state?.messages?.label && <Input
                        type="checkbox"
                        withLabel={true}
                        slideCheckbox={true}
                        id={state?.messages?.slug}
                        slug={state?.messages?.slug}
                        name={state?.messages?.slug}
                        checked={state?.messages?.status}
                        labelText={state?.messages?.label}
                        additionalClass={`${classes.PreferencesItem} ${currentUser?.playtechUser ? classes.Disabled : ''}`}
                        onChange={() => checkBoxChangeHandler('messages', {...state})}
				            />}
                    {state?.notifications?.label && <Input
                        type="checkbox"
                        withLabel={true}
                        slideCheckbox={true}
                        id={state?.notifications?.slug}
                        name={state?.notifications?.slug}
                        slug={state?.notifications?.slug}
                        checked={state?.notifications?.status}
                        labelText={state?.notifications?.label}
                        additionalClass={`${classes.PreferencesItem} ${currentUser?.playtechUser ? classes.Disabled : ''}`}
                        onChange={() => checkBoxChangeHandler('notifications', {...state})}
				            />}
                    {state?.disableAll?.label && <Input
                        type="checkbox"
                        withLabel={true}
                        slideCheckbox={true}
                        id={state?.disableAll?.slug}
                        name={state?.disableAll?.slug}
                        checked={state?.disableAll?.status}
                        slug={state?.disableAll?.slug}
                        labelText={state?.disableAll?.label}
                        additionalClass={`${classes.PreferencesItem} ${currentUser?.playtechUser ? classes.Disabled : ''}`}
                        onChange={() => checkBoxDisableChangeHandler({...state})}
				            /> }
		            </div>
            }
        </DataValidator>
    );
};

export default Preferences;
