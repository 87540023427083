import React, {CSSProperties, useMemo} from 'react';

import classes from './Button.module.pcss';

type ButtonType = 'Filled' | 'Unfilled' | 'LoveButtonActive' | 'LoveButtonInActive'
interface Props {
    content?: string,
    type: ButtonType,
    disabled?:boolean,
    customClass?:string,
    handler?: (e: any) => void,
    additionalStyles?:CSSProperties
}

const Button = (
    {
        content,
        customClass,
        type = 'Filled',
        disabled = false,
        additionalStyles = {},
        handler = () => {}
    }: Partial<Props>) => {
    const mainClass = useMemo(():string => {
        if (['LoveButtonActive', 'LoveButtonInActive'].includes(type)) {
            return classes[type];
        }
        return `${customClass || (`${classes.Button} ${classes[type]}`)}`;
    }, [type, customClass]);
    return (
        <button
            disabled={disabled}
            style={additionalStyles}
            className={mainClass}
            onClick={e => handler(e)}>
            {content}
        </button>
    );
};

export default Button;
