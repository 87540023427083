import bulletClass from 'js/components/Common/Carousel/CarouselBullets/CarouselBullets.module.pcss';

export const defaultCarouselParams: object = {
	direction: 'horizontal',
	shouldSwiperUpdate: true,
	spaceBetween: 15,
	slidesPerView: 3,
	slidesPerGroup: 1,
	noSwiping: true,
	speed: 1000,
	watchOverflow: true,
	watchSlidesVisibility: true,
	containerModifierClass: 'swiper-container-overlay '
};

export const sessionCarouselParams: object = {
	slidesPerView: 1,
	noSwiping: true,
	speed: 1000,
	autoHeight: true,
	watchOverflow: true,
	direction: 'horizontal',
	shouldSwiperUpdate: true,
	watchSlidesVisibility: true,
	containerModifierClass: 'swiper-container-wrap ',
	pagination: {
		el: `.${bulletClass.CarouselBullets}`,
		clickable: true
	}
};

export const testimonialsParams: object = {
	direction: 'horizontal',
	shouldSwiperUpdate: true,
	spaceBetween: 5,
	slidesPerView: 1,
	noSwiping: true,
	loop: true,
	speed: 1000,
	autoplay: {
		delay: 10000,
		disableOnInteraction: false
	},
	watchOverflow: true
};

export const PromoBoxParams: object = {
	direction: 'horizontal',
	shouldSwiperUpdate: true,
	initialSlide: 0,
	normalizeSlideIndex: true,
	spaceBetween: 5,
	slidesPerView: 1,
	effect: 'fade',
	fadeEffect: {
		crossFade: true
	},
	noSwiping: true,
	loop: false,
	speed: 300,
	watchOverflow: true
};
