import React from 'react';
import Label from 'js/containers/Pages/Search/SearchResults/Entities/components/Label/Label';
import {EntitiesTypesDataInterface} from 'js/containers/Pages/Search/SearchResults/Entities/EntitiesTypesDataInterface';

import classes from './ExpandableSection.module.pcss';
import ExpandableSectionHeading from './ExpandableSectionHeading';
import ExpandableSectionInfo from './ExpandableSectionInfo';


interface Props {
    title: string,
    info: string | JSX.Element | JSX.Element[],
    nodeRef: any,
    withoutLine?: boolean,
    withTypeLabel?: boolean,
    isOpened?: boolean,
    entityType?: EntitiesTypesDataInterface
}

const ExpandableSection = ({title, info, isOpened = false, withTypeLabel = false, entityType = 'articles', nodeRef, withoutLine = false}: Props) => (
    <section className={`${classes.ExpandableSection} ${withoutLine ? classes.WithoutLine : ''}`}>
        <ExpandableSectionHeading
            text={title}
            nodeRef={nodeRef}
            isOpened={isOpened}
            withTypeLabel={withTypeLabel}
        />
        {withTypeLabel ? <Label additionalStyles={{marginRight: '10px'}} type={entityType} /> : ''}
        <ExpandableSectionInfo data={info} isOpened={isOpened}/>
    </section>
);

export default ExpandableSection;
