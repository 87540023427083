import React, {useMemo} from 'react';
import Heading from 'js/components/Common/Heading/Heading';
import Container from 'js/components/Common/Container/Container';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';
import {CoursesListTypes} from 'js/components/Common/CourseItemBox/CourseItemBoxDataInterface';

import useCoursesList from '../hook/useCoursesList';
import classes from './CoursesListBlock.module.pcss';
import CoursesListItems from '../CoursesListItems/CoursesListItems';

export interface Props {
    type: CoursesListTypes,
    buttonAction: 'start' | 'buy',
    shouldUseFilter?: boolean,
    additionalMessage?: boolean
}

const CoursesListBlock = ({type, buttonAction, shouldUseFilter, additionalMessage}: Props) => {
    const CourseListData = useCoursesList(type, shouldUseFilter);
    const sectionHeading = useMemo(() => {
        const {coursesHeadings} = CourseListData;
        switch (type) {
            case 'isEssential': return coursesHeadings.essentialHeading;
            case 'isPopular': return coursesHeadings.popularHeading;
            case 'isPremium': return coursesHeadings.premiumHeading;
            default: return '';
        }
    }, [CourseListData, type]);
    return (
        <DataValidator data={CourseListData?.coursesList}>
            <section className={classes.CoursesList}>
                <Container>
                    {additionalMessage ?
                        <p className={classes.AdditionalMessage}>{CourseListData?.usefulCoursesText}</p> :
                        <Heading text={sectionHeading}/> }
                    <CoursesListItems listType={type} courseListData={CourseListData} buttonAction={buttonAction}/>
                </Container>
            </section>
        </DataValidator>
    );
};

export default CoursesListBlock;
