import thumbnailFragment from 'js/graphqlFragments/ThumbnailFragment';

const fetchData = pageId => `
{
      page(id: "${pageId}", idType: DATABASE_ID) {
        PreLoginPage {
          headingMasterSkills
            introTextMasterSkills
              topButtonMasterSkills {
                title
                url
                target
              }
          masterSkillsCarousel {
            ... on CoursesSingle {
              title
              id
              uri
              productList {
                nodes {
                  name
                }
              }
              ResourceVisibility {
                  resourceVisibility
              }
              Courses {
                coursePrice
                courseAudience
                newContentLabel
                courseDuration
                moreInfo {
                  first
                  second
                }
              }
              ...ThumbnailFragment
            }
          }
        }
      }
      themeGeneralSettings {
          Options {
            buyCourseButton
            infoCourseButton
          }
        }
    }
    ${thumbnailFragment('CoursesSingle')}
`;
export default fetchData;
