import React, {useMemo} from 'react';
import parse from 'html-react-parser';
import Image from 'js/components/Common/Image/Image';
import Heading from 'js/components/Common/Heading/Heading';

import classes from './LocationItem.module.pcss';

const LocationItem = ({ item }) => {
	const { title, subtitle } = item;
	const { altText, mediaItemUrl, mediaDetails } = item?.image || {};
	const backgroundUrl = item.background ? item.background.mediaItemUrl : null;
	const style = useMemo(() => (
		{
			backgroundImage: `url(${backgroundUrl})`
		}
	), [backgroundUrl]);
	return (
		<div className={classes.LocationItem}>
			<div className={classes.LocationItemInfo}>
				<div className={classes.LocationItemInfoWrap}>
					<Heading text={title} type="h2" thin={true} />
					<p>{subtitle ? parse(subtitle) : ''}</p>
				</div>
			</div>
			<div className={classes.LocationItemImages} style={style}>
				<figure className={classes.LocationItemImagesWrap}>
					{mediaItemUrl ?
						<Image mediaDetails={mediaDetails} mediaItemUrl={mediaItemUrl} alt={altText || title} />
						: null}
				</figure>
			</div>
		</div>
	);
};

export default LocationItem;
