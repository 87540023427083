import React, {Ref, useCallback, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import Label from 'js/components/Common/Label/Label';
import Button from 'js/components/Common/Button/Button';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';
import Heading from 'js/components/Common/Heading/Heading';
import PostDuration from 'js/components/Common/PostDuration/PostDuration';
import Thumbnail from 'js/components/Common/ItemThumbnail/Thumbnail/Thumbnail';
import VisibilityViewerIcon from 'js/components/Common/VisibilityViewerIcon/VisibilityViewerIcon';
import CarouselItemDescription from 'js/components/Common/Carousel/CarouselItemDescription/CarouselItemDescription';

import classes from './CourseItemBox.module.pcss';
import {CourseItemBoxInterface, CoursesListTypes} from './CourseItemBoxDataInterface';

export interface Props {
    buttonText: string,
    secondButtonText: string,
    item?: CourseItemBoxInterface,
    additionalClass?: boolean | string,
    init?: boolean,
    showPrice?: boolean,
    buttonAction: 'start' | 'buy',
    animation?: boolean,
    setPopUpTrigger: Function,
    setPopUpData: Function,
    type?: CoursesListTypes
}

const CourseItemBox = React.forwardRef((props: Props, ref: Ref<any>) => {
    const location = useLocation();
    const [courseTypeForFilter, setCourseTypeForFilter] = useState<'Ess' | 'Pop' | 'Prem' | ''>('');
    const {
        buttonText,
        item,
        additionalClass = false,
        init = true,
        animation = false,
        setPopUpData,
        setPopUpTrigger,
        type
    } = props;

    if (!item) {
        return null;
    }

    const {coursePrice, courseAudience, courseDuration} = item?.Courses || {};

    const buttonMoreInfoHandler = useCallback(async () => {
        setPopUpData({
            title: item?.title,
            content: item?.Courses?.moreInfo || ''
        });
        setPopUpTrigger(true);

        if (courseTypeForFilter) {
            await MixPanelWrapper.TrackEvent(
                'Course More Info',
                {
                    category: 'Course',
                    value: `${item.title}`
            });
        }

    }, [setPopUpTrigger, setPopUpData, item, courseTypeForFilter]);

    const buttonStartHandler = useCallback(async () => {
        if (courseTypeForFilter) {
            await MixPanelWrapper.TrackEvent(
                'Course Start',
                {
                    category: 'Course',
                    value: `${item.title}`
                });
        }
    }, [courseTypeForFilter, item]);

    useEffect(() => {
        switch (type) {
            case 'isEssential': return setCourseTypeForFilter('Ess');
            case 'isPopular': return setCourseTypeForFilter('Pop');
            case 'isPremium': return setCourseTypeForFilter('Prem');
            default : break;
        }
    }, [type]);

    if (!init) {
        return null;
    }
    return (
        <div ref={ref} className={`${classes.CourseItemBox} BoxItem ${animation ? 'BoxItemAnimation' : ''} ${additionalClass || ''}`}>
            <div className={classes.CourseItemBoxWrapper}>
                <Thumbnail item={item} type={'course'} handler={buttonMoreInfoHandler}/>
                <div className={classes.Labels}>
                    <Label value={item?.productList?.nodes?.length > 0 ? item?.productList?.nodes[0].name : ''}
                           additionalClass={classes.Label}
                    />
                    <Label value={item?.Courses?.newContentLabel ? 'New Content' : ''}
                           colorType={'Blue'}
                           additionalClass={classes.Label}
                    />
                    <Label value={item?.ResourceVisibility?.resourceVisibility === 'playtech' ? 'Playtech Only' : ''}
                           colorType={'Yellow'}
                           additionalClass={classes.Label}
                    />
                </div>
                <VisibilityViewerIcon id={item?.id} />
                <Heading text={item?.title} type={'h6'} />
            </div>
            <div className={classes.CourseItemBoxWrapper}>
                <CarouselItemDescription
                    description={courseAudience}
                    cut={true} />
                <PostDuration duration={courseDuration} price={coursePrice} showPrice={true}/>
                <div className={classes.CourseItemBoxButtonsWrapper}>
                    <Button handler={buttonStartHandler} type={'a'} link={`${item.uri}?source=${location.pathname}&sourceName=courses`} text={buttonText} />
                </div>
            </div>
        </div>
    );
});

export default CourseItemBox;
