import imageFragment from 'js/graphqlFragments/ImageFragment';
export default (buttonLinkKey, buttonTextKey, infoKey, imageKey) =>  `
    {
        themeGeneralSettings {
		  Options {
            buttonLinkKey: ${buttonLinkKey} {
              ... on Page {
                link
              }
              ... on ArticlesSingle {
                link
              }
              ... on CoursesSingle {
                link
              }
              ... on VideosSingle {
                link
              }
              ... on PodcastsSingle {
                link
              }
            }
            buttonTextKey: ${buttonTextKey}
            infoKey: ${infoKey}
            imageKey: ${imageKey} {
                ...ImageFragment
            }
		  }
		}
    }
    ${imageFragment()} 
`;
