import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Pilot from 'js/components/Common/Pilot/Pilot';
import Header from 'js/components/Common/Header/Header';
import Footer from 'js/components/Common/Footer/Footer';
import Router from 'js/containers/Routers/Router/Router';
import ToTopArrow from 'js/components/Common/ToTopArrow/ToTopArrow';
import CommonContextProvider from 'js/context/CommonContext/CommonContext';
import InterestsPopup from 'js/components/Common/InterestsPopup/InterestsPopup';
import MyProfileContextProvider from 'js/context/MyProfileContext/MyProfileContext';
import CookiePolicyPopup from 'js/components/Common/CookiePolicyPopup/CookiePolicyPopup';
import NotificationsContextProvider from 'js/context/NotificationsContext/NotificationsContext';
import ToastMessagesContextProvider from 'js/context/ToastMessagesContext/ToastMessagesContext';

export interface Window {
    location: object;
}

const App = () => (
    <BrowserRouter basename="/">
        <HelmetProvider>
            <div className="App">
                <CookiesProvider>
                    <CommonContextProvider>
                        <ToastMessagesContextProvider>
                            <div className="content">
                                <NotificationsContextProvider>
                                    <Header/>
                                </NotificationsContextProvider>
                                <MyProfileContextProvider>
                                    <Router/>
                                    <InterestsPopup/>
                                </MyProfileContextProvider>
                                <Pilot/>
                                <ToTopArrow/>
                                <CookiePolicyPopup/>
                            </div>
                            <Footer/>
                        </ToastMessagesContextProvider>
                    </CommonContextProvider>
                </CookiesProvider>
            </div>
        </HelmetProvider>
    </BrowserRouter>
);

export default App;
