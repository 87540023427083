export default pageId => `
{
    page(id: "${pageId}", idType: DATABASE_ID) {
      AboutUs {
          headingAwards
          subtitleAwards
          awardsRecognitions {
            name
            title
            image {
              altText
                  mediaItemUrl
                  mediaDetails {
                    sizes {
                      name
                      sourceUrl
                    }
                  }
            }
          }
        }
    }
  }
`;
