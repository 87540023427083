const simpleContentBlockFetchData = (pageId):string => `
    	{
            page(id: "${pageId}", idType: DATABASE_ID) {
              title
              content
            }
        }
    `;

export default simpleContentBlockFetchData;
