import React from 'react';
import TopSection from 'js/components/Common/TopSection/TopSection';
import LearningPath from 'js/components/Templates/Training/LearningPath/LearningPath';
import FAQ from 'js/components/Taxonomies/FAQ/FAQ';
import Offerings from 'js/components/Templates/Training/Offerings/Offerings';

const TrainingTemplate = ({ pageId }) => (
	<main className="templateTraining">
		<TopSection pageId={pageId} pageType={'page'} />
		<LearningPath pageId={pageId} />
		<Offerings pageId={pageId} />
		<FAQ pageId={pageId} pageType={'Page'} fieldGroupName={'TrainingTemplate'} />
	</main>
);

export default TrainingTemplate;
