import React, {useState} from 'react';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Container from 'js/components/Common/Container/Container';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';
import ILTCourses from 'js/components/Common/ILTCourses/ILTCoursesBlock/ILTCourses';

import fetchData from './fetchData';
import classes from './OnboardingFlowBlock.module.pcss';
import {OnBoardingFlowDataInterface} from './OnboardingFlowDataInterface';
import OnBoardingFlowItem from '../OnboardingFlowItem/OnboardingFlowItem';

const OnBoardingFlowBlock = ({pageId}) => {
    const [onBoardingFlowSection, setOnBoardingFlowSection] = useState<OnBoardingFlowDataInterface>({
        title: '',
        advantages: [],
        showAdvantages: false,
        description: ''
    });
    const [secondOnBoardingFlowSection, setSecondOnBoardingFlowSection] = useState<OnBoardingFlowDataInterface>({
        title: '',
        advantages: [],
        showAdvantages: false,
        description: ''
    });
    const [thirdOnBoardingFlowSection, setThirdOnBoardingFlowSection] = useState<OnBoardingFlowDataInterface>({
        title: '',
        advantages: [],
        showAdvantages: false,
        description: ''
    });
    const [fourthOnBoardingFlowSection, setFourthOnBoardingFlowSection] = useState<OnBoardingFlowDataInterface>({
        title: '',
        advantages: [],
        showAdvantages: false,
        description: ''
    });
    const [fifthOnBoardingFlowSection, setFifthOnBoardingFlowSection] = useState<OnBoardingFlowDataInterface>({
        title: '',
        advantages: [],
        showAdvantages: false,
        description: ''
    });

    const { isLoading, error } = useFetchData({
        query: `${fetchData(pageId)}`,
        condition: !!pageId,
        updateData: (data) => {
            const result = data?.page?.OnBoarding;
            setOnBoardingFlowSection(result?.onboardingFlowSection);
            setSecondOnBoardingFlowSection(result?.secondOnboardingFlowSection);
            setThirdOnBoardingFlowSection(result?.thirdOnboardingFlowSection);
            setFourthOnBoardingFlowSection(result?.fourthOnboardingFlowSection);
            setFifthOnBoardingFlowSection(result?.fifthOnboardingFlowSection);
        }
    });

    return (
        <DataValidator data={
            onBoardingFlowSection?.description && secondOnBoardingFlowSection?.description &&
            thirdOnBoardingFlowSection?.description &&
            fourthOnBoardingFlowSection?.description && fifthOnBoardingFlowSection?.description
            && !isLoading && !error
        }>
            <section className={classes.OnBoardingFlowBlock}>
                <Container>
                    <OnBoardingFlowItem data={onBoardingFlowSection} />
                    <OnBoardingFlowItem data={secondOnBoardingFlowSection} />
                    <OnBoardingFlowItem data={thirdOnBoardingFlowSection} />
                    <ILTCourses
                        pageId={pageId}
                        type={'onBoarding'}
                        templateName={'OnBoarding'}
                        centered={false}
                        useContainer={false}
                    />
                    <OnBoardingFlowItem data={fourthOnBoardingFlowSection} />
                    <OnBoardingFlowItem data={fifthOnBoardingFlowSection} />
                </Container>
            </section>
        </DataValidator>
    );
};

export default OnBoardingFlowBlock;
