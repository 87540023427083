const buildQuery = (
    type,
    getQuery,
    shouldUsePrice:boolean = true,
    shouldUseFilter?:boolean,
    isEvent: boolean = false
): string => {
    let query = '';
    const suitableFor = getQuery ? getQuery('suitableFor') : '';
    const skills = getQuery ? getQuery('skills') : '';
    const duration = getQuery ? getQuery('duration') : '';
    const price = getQuery ? getQuery('price') : '';
    query = type ? `${type}: true ` : query;
    if (shouldUseFilter) {
        query = suitableFor ? `${query} suitableForQuery: "${suitableFor}" ` : query;
        query = skills ? `${query} skillsQuery: "${skills}" ` : query;
        query = duration ? `${query} courseDuration: "${duration}" ` : query;
        query = price && shouldUsePrice ? `${query} coursePrice: "${price}" ` : query;
    }
    query = isEvent ? `${query} isNotWebinar: true` : query;
    return query;
};

export default buildQuery;
