import React from 'react';
import classes from './FilterSelectItem.module.pcss';
import {ItemCheckbox} from '../../FilterDataInterface';
import Input from 'js/components/Common/FormComponents/Input/Input';
import FilterItemCount from './FilterItemCount/FilterItemCount';

export type PriceCountsType = {
    free: number,
    price199: number,
    price100199: number,
    price200299: number,
    price300: number
}

export interface Props {
    item: ItemCheckbox,
    changeHandler: any,
    countSum?: PriceCountsType
}

const FilterSelectItem = ({item, changeHandler, countSum}:Props) => (
    <li className={classes.FilterSelectItem}>
        <Input
            type="checkbox"
            id={item.id}
            name={item.name}
            slug={item.slug}
            labelText={item.name}
            onChange={changeHandler}
            withLabel={true}/>
        {countSum ? <FilterItemCount type={item.id} count={countSum}/> : null}
    </li>
);

export default FilterSelectItem;
