import React, {useReducer, useState, useCallback} from 'react';
import useDebounce from 'js/hooks/useDebounce/useDebounce';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import ToastMessage from 'js/components/Common/ToastMessage/ToastMessage';
import ToastMessageContainer from 'js/components/Common/ToastMessage/ToastMessageContainer';
import {toastMessageType, toastMessagesTypes, ToastIdsType} from 'js/components/Common/ToastMessage/ToastMessageDataInterface';

import getQuery from './getQuery';

export type ToastMessageFunctionType = {
    type: toastMessagesTypes,
    key: ToastIdsType
};

interface ToastMessagesContextInterface {
    addToastMessage: (obj: ToastMessageFunctionType) => {},
    toastContainerIsActive: boolean
}

const initializer = (value):Array<toastMessageType> => value;

export const ToastMessagesContext = React.createContext<ToastMessagesContextInterface>({
    addToastMessage: obj => obj,
    toastContainerIsActive: false
});


const ToastMessagesContextProvider = (props) => {
    const [messages, setMessages] = useState<Array<any>>([]);
    const [toastContainerIsActive, setToastContainerIsActive] = useState<boolean>(false);

    useFetchData({
        query: getQuery(),
        condition: true,
        updateData: data => setMessages(data?.toastMessages?.ToastMessages)
    });

    const reducer = (state: Array<toastMessageType>, action): any => {
        switch (action?.type) {
            case 'fadeIn' : {
                let toasts = [...state];
                const toastsLength = toasts?.length;
                if (toasts.filter(e => e.show === false).length === toastsLength) {
                    toasts = [];
                }
                if (toasts?.length === 5) {
                    toasts?.shift();
                }
                toasts.push(action?.val);
                return toasts;
            }
            case 'fadeOut' : {
                const toasts = [...state];
                toasts.forEach((part, index, theArray) => {
                    theArray[index]['show'] = false;
                });
                return initializer(toasts);
            }
            default : break;
        }
    };
    const [state, dispatch] = useReducer(reducer, [], initializer);

    const resetToastsCallback = useCallback(() => {
        dispatch({type: 'fadeOut'});
        setToastContainerIsActive(false);
    }, []);
    const debouncedResetCallback = useDebounce(resetToastsCallback, 3000);

    const addToastMessage = useCallback((value) => {
        const {type, key} = value || {};
        const val = {
            type,
            message: '',
            show: true
        };
        if (!messages) {
            return val;
        }
        val.message = messages[key][type];
        dispatch({type: 'fadeIn', val});
        setToastContainerIsActive(true);
        debouncedResetCallback();
    }, [debouncedResetCallback, messages]);

    return (
        <ToastMessagesContext.Provider
            value={ { addToastMessage, toastContainerIsActive } }>
            {props.children}
            <ToastMessageContainer>
                {state?.map((item, key) => <ToastMessage key={key} data={item}/>)}
            </ToastMessageContainer>
        </ToastMessagesContext.Provider>
    );
};

export default ToastMessagesContextProvider;

