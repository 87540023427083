import {TopBannerPageId, TopBannerPageType} from './TopBannerDataInterface';

export const switchBetweenTypes = ((pageType: TopBannerPageType, ...args:Array<string | Function>) => {
	switch (pageType) {
		case 'CoursesTemplate' : return typeof args[0] === 'function' ? args[0]() : args[0];
		case 'EventsTemplate' : return typeof args[1] === 'function' ? args[1]() : args[1];
		case 'VideosTemplate' : return typeof args[2] === 'function' ? args[2]() : args[2];
		case 'ManualsHub' : return typeof args[3] === 'function' ? args[3]() : args[3];
		case 'HelpTemplate' : return typeof args[4] === 'function' ? args[4]() : args[4];
		default : return '';
	}
});

export const getPageEntityId = (pageType:TopBannerPageType):TopBannerPageId => (
	switchBetweenTypes(pageType, 'Courses', 'Meetings', 'Videos', 'ManualsHub', 'Help')
);

export const setBannerStyle = bg => (
	{ backgroundImage: `url('${bg || ''}` }
);
