import thumbnailFragment from 'js/graphqlFragments/ThumbnailFragment';
export default pageId => `
{
    page(id: "${pageId}", idType: DATABASE_ID) {
      AboutUs {
        headingGetToKnow
        subtitleGetToKnow
        teamGetToKnow {
          ... on TeamSingle {
            id
            title
            ...ThumbnailFragment
            Team {
              position
            }
          }
        }
      }
    }
  }
  ${thumbnailFragment('TeamSingle')}
`;
