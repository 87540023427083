import React from 'react';

import classes from './BrandsItems.module.pcss';
import BrandsItem from '../BrandsItem/BrandsItem';

const BrandsItems = ({ data }) => (
	<figure className={classes.BrandsItems}>
		{data?.map((item, index) => <BrandsItem key={index} item={item} />)}
	</figure>
);

export default BrandsItems;
