import React, {useCallback} from 'react';
import {useLocation} from 'react-router-dom';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';
import Heading from 'js/components/Common/Heading/Heading';
import Description from 'js/components/Common/Description/Description';
import PostDuration from 'js/components/Common/PostDuration/PostDuration';
import VisibilityViewerIcon from 'js/components/Common/VisibilityViewerIcon/VisibilityViewerIcon';

import Label from '../../components/Label/Label';
import Thumbnail from '../../components/Thumbnail/Thumbnail';
import commonClasses from '../../../SearchResultsItem/SearchResultsItem.module.pcss';
import ProductLabelsList from 'js/components/Common/ProductLabelList/ProductLabelsList';

export interface Props {
    data: {
        id: number,
        title: string,
        link: string,
        featuredImage: {
            url: string,
            altText: string
        },
        type: 'courses',
        duration: number,
        description: string,
        isPremium: boolean
    },
    isPromoted: boolean
}

const Course = ({data, isPromoted}:Props) => {
    const location = useLocation();

    const sendMixPanelEvent = useCallback(async () => {
        await MixPanelWrapper.TrackEvent(
            'Start Course',
            {
                category: 'Search Results',
                value: data?.title
            });
    }, [data]);

    return (
        <div className={`${commonClasses.SearchResultItem} ${isPromoted && commonClasses.Promoted}`}>
            <Thumbnail thumbnail={data?.featuredImage} />
            <div className={commonClasses.SearchResultItemInfo}>
                <a onClick={sendMixPanelEvent}
                   href={`/courses/${data?.link}?source=${location?.pathname}&sourceName=search`}
                   target={'_blank'} rel={'noopener noreferrer'}>
                    <Heading text={`${data?.title}`} type={'h5'}/>
                </a>
                <Description data={data?.description}/>
                <div className={commonClasses.Wrap}>
                    <Label type={'courses'}/>
                    <PostDuration showIcon={false} duration={data?.duration}/>
                    <ProductLabelsList pageId={data?.id} />
                </div>
                <div className={commonClasses.Wrap}>
                    <VisibilityViewerIcon id={data?.id}/>
                </div>
            </div>
        </div>
);
};

export default Course;
