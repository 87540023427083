const fetchData = pageId => `
    	{
			page(id: "${pageId}", idType: DATABASE_ID) {
			  PostLoginPage {
				  topSection
				}
			}
		}
    `;

export default fetchData;

