import classes from './ThumbnailNotice.module.pcss';

const isComingSoon = (item, index, time: number): boolean | string => {
	const currentDate: any = new Date();
	const startDate: any = new Date(item.Meetings.startDate);
	const oneDay: number = 1000 * 60 * 60 * time;
	const result = (startDate - currentDate) < oneDay;

	return ((index === 0) && result ? classes.comingSoon : '');
};
export default isComingSoon;
