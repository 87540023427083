export default pageId => `
{
    page(id: "${pageId}", idType: DATABASE_ID) {
      AboutUs {
        headingValues
        subtitleValues
        valuesList {
          title
          info
          image {
            mimeType
            altText
                  mediaItemUrl
                  mediaDetails {
                    sizes {
                      name
                      sourceUrl
                    }
                  }
          }
        }
      }
    }
  }
`;
