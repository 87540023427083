const fetchData = (pageId: any):string => `
{
    productLegacyList(where: {parent: ${pageId}, orderby: COUNT, hideEmpty: true}, first: 50) {
      nodes {
        id
        name
        uri
      }
    }
    product {
      ProductGlobal {
        subjectMatterTitleProduct
      }
    }
    productLegacy(id: "${pageId}", idType: DATABASE_ID) {
      ProductLegacyFields {
        subjectMatterDescription
        subjectMatterItemsPerSlide
      }
    }
  }
`;
export default fetchData;
