import React, {useState} from 'react';
import parse from 'html-react-parser';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';
import SocialMedia, {SocialMediaType} from 'js/components/Common/SocialMedia/SocialMedia';

import fetchData from './fetchData';
import classes from './ContactInfo.module.pcss';
import useFetchData from 'js/hooks/useFetchData/useFetchData';

const ContactInfo = ({pageId}) => {
	const [info, setInfo] = useState<string>('');
	const [socialMedia, setSocialMedia] = useState<SocialMediaType>({
		youtube: '',
		linkedin: ''
	});

	const {isLoading, error} = useFetchData({
		query: `${fetchData(pageId)}`,
		condition: !!pageId,
		updateData: (data) => {
			setInfo(data?.page?.content);
			setSocialMedia(data?.themeGeneralSettings?.Options?.socialMedia);
		}
	});

    return (
        <DataValidator data={!!info && !isLoading && !error}>
			<section className={classes.ContactInfo}>
				<div className={classes.ContactInfoText}>
					{parse(info || '')}
				</div>
				{socialMedia?.linkedin || socialMedia?.youtube ? <SocialMedia data={socialMedia} heading={'Follow Us'} /> : ''}
			</section>
		</DataValidator>
    );
};

export default ContactInfo;
