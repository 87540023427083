import React from 'react';
import parse from 'html-react-parser';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import classes from './Description.module.pcss';

export interface Props {
    data: string,
    linesType?: 'twoLines' | 'threeLines' | 'full'
}

const Description = ({data, linesType = 'threeLines'}:Props) => (
    <DataValidator data={data}>
        <div className={`${classes.Description} ${classes[linesType]}`}>
            {parse(data || '', { trim: true })}
        </div>
    </DataValidator>
);

export default Description;
