import React, {useState} from 'react';
import Heading from 'js/components/Common/Heading/Heading';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Container from 'js/components/Common/Container/Container';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import fetchData from './fetchData';
import classes from './Overview.module.pcss';
import parse from 'html-react-parser';


export type PageType = 'Product' | 'SubjectMatter';
interface Props {
    pageId: number,
    pageType: PageType
}
const Overview = ({pageId, pageType}: Props) => {
    const [title, setTitle] = useState<string>('');
    const [info, setInfo] = useState<string>('');
    const { isLoading, error } = useFetchData({
        query: `${fetchData(pageId, pageType)}`,
        condition: !!pageId,
        updateData: (data:any) => {
            setInfo(data?.productLegacy?.ProductLegacyFields?.overviewDescription);
            if (pageType === 'Product') {
                setTitle(data?.product?.ProductGlobal?.overviewTitleProduct);
            } else {
                setTitle(data?.subjectMatter?.SubjectMatterGlobal?.overviewTitleSm);
            }
        }
    });
    return (
        <DataValidator data={!!info && !!title && !isLoading && !error}>
            <section className={classes.Overview}>
                <Container additionalClass={classes.Wrap}>
                    <Heading text={title} type={'h2'} />
                    {parse(info || '', { trim: true })}
                </Container>
            </section>
        </DataValidator>
    );
};

export default Overview;
