import React from 'react';
import Image from 'js/components/Common/Image/Image';

import classes from './AccreditationItem.module.pcss';

const AccreditationItem = ({ item }) => {
	const { mediaItemUrl, altText } = item?.logo || {};
	return (
		<a
			href={item.link}
			target="_blank" rel="nofollow noopener noreferrer"
			className={classes.AccreditationItem}>
			<Image mediaItemUrl={mediaItemUrl} alt={altText || ''} />
		</a>
	);
};

export default AccreditationItem;
