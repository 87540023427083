import React from 'react';

import classes from './TopicItemList.module.pcss';

const TopicItemList = ({ data }) => (
	<ul className={classes.TopicItemList}>
		{data ? data?.map((item, index) =>
			(
				<li key={index}>
					<img src={item?.icon?.mediaItemUrl} alt={item?.icon?.altText} />
					<span>{item?.text}</span>
				</li>
			)
		) : null}
	</ul>
);

export default TopicItemList;
