import React from 'react';
import Image from 'js/components/Common/Image/Image';

import classes from './TopImage.module.pcss';

const TopImage = ({ imageData }) => {
	const { altText, mediaItemUrl, mediaDetails } = imageData || {};
	return (
		<figure className={classes.TopImage}>
			<Image mediaItemUrl={mediaItemUrl} mediaDetails={mediaDetails} alt={altText} />
		</figure>
	);
};

export default TopImage;
