import React from 'react';
import Image from 'js/components/Common/Image/Image';

import classes from './Advantages.module.pcss';
import {AdvantagesType} from '../../OnboardingFlowBlock/OnboardingFlowDataInterface';

export interface Props {
    data: AdvantagesType;
}

const Advantages = ({data}: Props) => (
    <div className={classes.Advantages}>
        {data?.map((item, key) => (
            <div key={key} className={classes.AdvantagesItem}>
                <Image mediaItemUrl={item?.icon?.mediaItemUrl || ''} alt={'icon'} />
                <span className={classes.AdvantagesItemInfo}>{item?.info || ''}</span>
            </div>
        ))}
    </div>
);

export default Advantages;

