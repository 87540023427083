import React, {useCallback} from 'react';

import classes from './CarouselArrows.module.pcss';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

export interface Props {
	slider: {
		allowSlideNext: () => void,
		allowSlidePrev: () => void,
		slideNext: () => void,
		slidePrev: () => void
	},
	activeButtons: {
		prev: boolean,
		next: boolean
	}
}

const CarouselArrows = ({ slider, activeButtons }: Props) => {
	const goNext = useCallback(() => slider.slideNext(), [slider]);
	const goPrev = useCallback(() => slider.slidePrev(), [slider]);
	return (
		<DataValidator data={!!slider && !!slider.allowSlideNext && !!slider.allowSlidePrev}>
			<div className={classes.CarouselArrows}>
				<button className={`${classes.CarouselArrowsButtonPrev}`} data-active={activeButtons.prev}
						onClick={goPrev} />
				<button className={`${classes.CarouselArrowsButtonNext}`} data-active={activeButtons.next}
						onClick={goNext} />
			</div>
		</DataValidator>
	);
};

export default CarouselArrows;
