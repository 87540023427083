import {useContext, useState, useCallback, useMemo} from 'react';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import {
	CourseItemBoxDataInterface,
	coursesButtonsType,
	CoursesListTypes
} from 'js/components/Common/CourseItemBox/CourseItemBoxDataInterface';
import buildQuery from 'js/components/Filter/Helpers/buildQuery';

import fetchData from '../hook/fetchData';
import {CoursesListContext} from '../context/CoursesListContext';

export type CoursesHeadingsType = {
    essentialHeading: string,
    popularHeading: string,
    premiumHeading: string
}

const initialButtonsValues = {
    startCourseButton: '',
    buyCourseButton: '',
    infoCourseButton: ''
};
const initialHeadingsValue = {
    essentialHeading: '',
    popularHeading: '',
    premiumHeading: ''
};

const useCoursesList = (
    type:CoursesListTypes,
    shouldUseFilter: boolean
) => {
    const {getQuery, setResult, setShowLoader} = useContext(CoursesListContext);
    const [coursesList, setCoursesList] = useState<CourseItemBoxDataInterface[]>([]);
    const [usefulCoursesText, setUsefulCoursesText] = useState<string>('');
    const [coursesButtons, setCoursesButtons] = useState<coursesButtonsType>(initialButtonsValues);
    const [coursesHeadings, setCoursesHeadings] = useState<CoursesHeadingsType>(initialHeadingsValue);

    const setQueryAttributesCallBack = useMemo(() =>
        buildQuery(type, getQuery, true, shouldUseFilter, false), [getQuery, shouldUseFilter, type]);

    const sortCourses = useCallback(courses =>
        courses.sort((a, b) => {
            const first = a?.Courses?.newContentLabel;
            const second = b?.Courses?.newContentLabel;
            if (first > second) return -1;
            if (first < second) return 1;
            return 0;
        }), []);

    const updateDataCallback = useCallback((data) => {
        let courses = data?.coursesArchive.nodes;
        const options = data?.themeGeneralSettings?.Options;
        courses = sortCourses(courses);
        setCoursesList(courses);
        courses.length > 0 && shouldUseFilter ? setResult(type, true) : setResult(type, false);
        setCoursesButtons({
            startCourseButton: options?.startCourseButton,
            buyCourseButton: options?.buyCourseButton,
            infoCourseButton: options?.infoCourseButton
        });
        setCoursesHeadings({
            essentialHeading: options?.essentialCoursesHeading,
            popularHeading: options?.popularCoursesHeading,
            premiumHeading: options?.premiumCoursesHeading
        });
        setUsefulCoursesText(options?.usefulCoursesText);
        if (courses) {
            setShowLoader(false);
        }
    }, [setResult, setShowLoader, shouldUseFilter, sortCourses, type]);

    const {isLoading, error} = useFetchData({
        query: `${fetchData(setQueryAttributesCallBack)}`,
        condition: !!type,
        updateData: updateDataCallback
    });

    if (isLoading || error) {
        return {
            coursesList: [],
            coursesButtons: initialButtonsValues,
            coursesHeadings: initialHeadingsValue,
            usefulCoursesText: ''
        };
    }

    return {
        coursesList,
        coursesButtons,
        coursesHeadings,
        usefulCoursesText
    };
};

export default useCoursesList;
