import React, {useState} from 'react';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Container from 'js/components/Common/Container/Container';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import fetchData from './fetchData';
import classes from './MadeEasyBlock.module.pcss';
import MadeEasyInfo from '../MadeEasyInfo/MadeEasyInfo';
import MadeEasyFlow from '../MadeEasyFlow/MadeEasyFlow';
import {MadeEasyFlowDataInterface} from './MadeEasyFlowDataInterface';

const MadeEasyBlock = ({pageId}) => {
    const [info, setInfo] = useState<string>('');
    const [flow, setFlow] = useState<MadeEasyFlowDataInterface[]>([]);

    const { isLoading, error } = useFetchData({
        query: `${fetchData(pageId)}`,
        condition: !!pageId,
        updateData: (data) => {
            const result = data?.page?.OnBoarding;
            setInfo(result?.madeEasyInfo);
            setFlow(result?.madeEasyFlow);
        }
    });

    return (
        <DataValidator data={!!info && !!flow && !isLoading && !error}>
            <section className={classes.MadeEasySection}>
                <Container additionalClass={classes.MadeEasySectionWrap}>
                    <MadeEasyInfo data={info} />
                    <MadeEasyFlow data={flow} />
                </Container>
            </section>
        </DataValidator>
    );
};

export default MadeEasyBlock;
