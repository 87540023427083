
export const updateAPIFetchData = (
    products,
    marketingEmails,
    marketingTextMessages,
    browserNotifications,
    disableMarketing) => {
    const productsListQuery = products ? `, productsList: "${products}` : '';
    const browserNotificationsQuery = browserNotifications !== false ? `browserNotificationsQuery: ${browserNotifications}` : '';
    const disableMarketingQuery = disableMarketing !== false ? `, disableMarketingQuery: ${disableMarketing},` : '';
    const marketingEmailsQuery = marketingEmails !== false ? `marketingEmailsQuery: ${marketingEmails},` : '';
    const marketingTextMessagesQuery = marketingTextMessages !== false ? `marketingTextMessagesQuery: ${marketingTextMessages},` : '';
    return `
    {
        getResponse {
          updateProfileAPIData(${browserNotificationsQuery} ${disableMarketingQuery} ${marketingEmailsQuery} ${marketingTextMessagesQuery} ${productsListQuery}")
        }
    }
    `;
};

export default updateAPIFetchData;


