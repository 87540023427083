import React, {useCallback, useRef} from 'react';
import {ValidationRulesType} from 'js/helpers/validationRules';

import classes from './TextArea.module.pcss';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

export interface TextAreaDataType {
    id?: string,
    value?: string,
    placeholder?: string,
    height: number,
    withMargin?: boolean,
    onChange?: any,
    blur?: any,
    floatingLabel?: string,
    name: string,
    type: string,
    maxLength?: number,
    validation?: {
        rules: Array<ValidationRulesType>,
        isValid?: boolean,
        errorMessage?: string
    }
}

const TextArea = ({
      id,
      value,
      name,
      placeholder,
      height,
      withMargin = false,
      blur,
      onChange,
      maxLength,
      floatingLabel,
      validation,
      type
} : TextAreaDataType) => {
    const ref = useRef(null);

    const changeTextHandler = useCallback((e) => {
        onChange?.(e);
    }, [onChange]);

    const textAreaClasses = `${classes.TextArea} ${withMargin ? classes.withMargin : ''} ${validation?.errorMessage && classes.Error}`;

    return (
        <div itemType={type} className={textAreaClasses}>
        <textarea
            name={name}
            className={value && classes.NotEmpty}
            onBlur={blur}
            onChange={changeTextHandler}
            value={value || ''}
            placeholder={placeholder}
            ref={ref}
            maxLength={maxLength}
            style={
                {height: `${height}px`}
            }
        />
            {floatingLabel ? <label className={classes.FloatingLabel} htmlFor={`${id}`}>{floatingLabel}</label> : null}
            {validation?.errorMessage && <ErrorMessage errorMessage={validation?.errorMessage} />}
        </div>
    );
};

export default TextArea;
