import React, {useRef, useState} from 'react';
import Heading from 'js/components/Common/Heading/Heading';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Container from 'js/components/Common/Container/Container';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';
import ExpandableSection from 'js/components/Common/ExpandableSection/ExpandableSection';

import fetchData from './fetchData';
import classes from './FAQ.module.pcss';

export type Type = 'Product' | 'SubjectMatter' | 'Page';
export interface Props {
	pageId: number,
	pageType: Type,
	fieldGroupName?: string
}

type FAQListItemType = {
	caseHeader: string,
	caseBody: string
}

const FAQ = ({pageId, pageType, fieldGroupName = ''}: Props) => {
	const nodeRef = useRef(null);
	const [title, setTitle] = useState<string>('');
	const [list, setList] = useState<Array<FAQListItemType>>(null);

	const { isLoading, error } = useFetchData({
		query: `${fetchData(pageId, pageType, fieldGroupName)}`,
		condition: !!pageId,
		updateData: (data) => {
			switch (pageType) {
				case 'Product' : {
					setTitle(data?.product?.ProductGlobal?.faqTitleProduct);
					setList(data?.productLegacy?.ProductLegacyFields?.faqProductLegacy);
					break;
				}
				case 'SubjectMatter': {
					setTitle(data?.subjectMatter?.SubjectMatterGlobal?.faqTitleSm);
					setList(data?.productLegacy?.ProductLegacyFields?.faqProductLegacy);
					break;
				}
				case 'Page' : {
					setTitle(data?.page[fieldGroupName]?.titleFaq);
					setList(data?.page[fieldGroupName]?.faq);
					break;
				}
				default : return;
			}
		}
	});

    return (
        <DataValidator data={(title && list) && !isLoading && !error }>
			<section className={classes.FAQ} ref={nodeRef}>
				<Container additionalClass={classes.Wrap}>
					<Heading text={title} type={'h2'} />
					{list?.map((item, index) => (
						<div key={index} className={classes.Item}>
							<ExpandableSection
								title={item?.caseHeader}
								info={item?.caseBody}
								nodeRef={nodeRef}
								withoutLine={list?.length === index + 1}/>
						</div>
				))}
				</Container>
			</section>
		</DataValidator>
    );
};

export default FAQ;
