import React from 'react';
import FAQ from 'js/components/Taxonomies/FAQ/FAQ';
import Overview from 'js/components/Common/Overview/Overview';
import TopSection from 'js/components/Common/TopSection/TopSection';
import GettingStarted from 'js/components/Taxonomies/GettingStarted/GettingStarted';
import ProductContent from 'js/components/Taxonomies/ProductContent/ProductContent';
import SubTopic from 'js/components/Taxonomies/SubTopic/SubTopic';

const SubjectMatter = ({ pageId }) => (
	<main className="subjectMatter">
		<TopSection pageId={pageId} pageType={'product'} />
		<Overview pageId={pageId} pageType={'SubjectMatter'} />
        <SubTopic pageId={pageId} />
        <GettingStarted pageId={pageId} />
		<ProductContent pageId={pageId} />
		<FAQ pageId={pageId} pageType={'SubjectMatter'} />
	</main>
);

export default SubjectMatter;
