import {CourseType, ProductType} from './Offerings';

class OfferingsService {
    separateCoursesColumns = (courses: Array<CourseType>) => {
        const column1 = [];
        const column2 = [];

        courses?.forEach((course, index) => {
            course.id = index;
            course.productsTab?.map((item, subIndex) => item.id = `${index}_${subIndex}`);
            if (index % 2 === 0) {
                column1.push(course);
            } else {
                column2.push(course);
            }
        });

        return { column1, column2 };
    };

    getActiveCourse(activeTab:number, courses:Array<CourseType>):CourseType {
        return courses?.find((el, index) => index === activeTab);
    }
    getActiveCourseInfo(activeProduct: string, productsTab: Array<ProductType>) {
        return productsTab?.find(el => el?.id === activeProduct);
    }
}

export default new OfferingsService();
