import React, {useCallback, useMemo} from 'react';
import parse from 'html-react-parser';
import PopUp from 'js/components/Common/PopUp/PopUp';
import Label from 'js/components/Common/Label/Label';

import Attributes from './Attributes/Attributes';
import classes from './ILTCoursesPopUp.module.pcss';
import {ILTCoursesPopUpDataType} from '../ILTCoursesBlock/ILTCoursesDataInterface';

export interface Props {
	popUpTrigger: boolean,
	setPopUpTrigger: Function,
	popUpData: ILTCoursesPopUpDataType
}

const ILTCoursesPopUp = ({
	 popUpTrigger,
	 setPopUpTrigger,
	 popUpData
	 }:Props) => {
	const {title, about, content, productList, suitableForList, location} = popUpData || {};
	const {duration} = popUpData?.attributes || {};

	const getContent = useCallback((fieldName, label) => {
		if (!fieldName) {
			return '';
		}
		return (
			<div className={classes.ContentItem}>
				<strong>{label}</strong>
				{parse(fieldName)}
			</div>
		);
	}, []);

	const getProductsList = useMemo(() => {
		if (!productList?.length) {
			return null;
		}
		return (
			<div className={classes.ProductsList}>
				{
					productList?.map((item, index) =>
						<Label key={index}
							   value={item?.ShortName?.shortName || item?.name}
							   additionalClass={classes.ProductsListItem} />)
				}
			</div>
		);
	}, [productList]);

	const getSuitableForList = useMemo(() => {
		if (!suitableForList?.length) {
			return null;
		}
		return (
			<div className={`${classes.SuitableForList} ${classes.ContentItem}`}>
				<strong>Who Should Join?</strong>
				<ul className={classes.SuitableForListWrap}>
					{
						suitableForList?.map((item, index) => (
							<li key={index} className={classes.SuitableForListItem}>{item?.name}</li>
						))
					}
				</ul>
			</div>
		);
	}, [suitableForList]);


	return (
		<PopUp
			type={'L'}
			heading={title}
			additionalClass={classes.ILTCoursesPopUp}
			popUpTrigger={popUpTrigger}
			popUpClose={() => setPopUpTrigger(false)}>
			{getProductsList}
			<div className={classes.CoursesMoreInfoPopUpWrap}>
				<div className={classes.CoursesMoreInfoPopInfo}>
					{getContent(about, 'About This Training')}
					{getSuitableForList}
					<div className={classes.CoursesMoreInfoPopUpRow}>
						{getContent(duration, 'How Long?')}
						{getContent(location, 'Where?')}
					</div>
					{getContent(content, 'In This Training')}
				</div>
				<Attributes data={popUpData?.attributes} />
			</div>
		</PopUp>
	);
};

export default ILTCoursesPopUp;
