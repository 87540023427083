import {useCallback, useEffect, useState} from 'react';

const useAnchor = (anchorElementId: string, defaultHeaderHeight?: number, additionalOffset: number = 0) => {
	const header: HTMLElement = document?.getElementById('header');
	const [headerHeight, setHeaderHeight] = useState<number>(defaultHeaderHeight || 0);
	const getHeaderHeight = useCallback((): void => {
		if (!header) {
			return;
		}
		setHeaderHeight(header.offsetHeight);
	}, [header]);

	const scrollCallBack = useCallback((): void => {
		getHeaderHeight();
	}, [getHeaderHeight]);

	useEffect(() => {
		window.addEventListener('scroll', scrollCallBack);
		return (): void => {
			window.removeEventListener('scroll', scrollCallBack);
		};
	}, [scrollCallBack]);

	return (): void => {
		const anchorElement = document.getElementById(anchorElementId);
		if (!anchorElement) {
			return;
		}
		const anchorElementTop = anchorElement.offsetTop;
		window.scrollTo({
			top: anchorElementTop + headerHeight + additionalOffset,
			behavior: 'smooth'
		});
	};
};

export default useAnchor;
