import imageFragment from 'js/graphqlFragments/ImageFragment';
import thumbnailFragment from 'js/graphqlFragments/ThumbnailFragment';
export default pageId => `
	{
	  videosSingle(id: "${pageId}", idType: DATABASE_ID) {
		  title
		  updateVideoViews
		  videoViews
		  Videos {
		  	watchTime
			videoContent
			  attachedVideo {
                  videoLink
                  videoPreview {
                    ...ImageFragmentVideoPreview
                  }
			  }
			  
			  host {
				... on TeamSingle {
				  title
				  Team {
					position
				  }
				  ...ThumbnailFragment
				}
			  }
          }
          productList(first: 1) {
			  nodes {
				name
			  }
			}
			ResourceVisibility {
				resourceVisibility
			}
		  SeoMetadata {
			metaTitle
			  metaDescription
			}
		}
		monday {
			  Monday {
				feedback {
				  videoFeedbackTitle
				}
			  }
		}
	  }
	  ${imageFragment('VideoPreview')} 
	  ${thumbnailFragment('TeamSingle')} 
`;
