import React from 'react';
import Heading from 'js/components/Common/Heading/Heading';
import isEmptyObject from 'js/helpers/isEmptyObject';

import classes from './MadeEasyFlow.module.pcss';
import {MadeEasyFlowDataInterface} from '../MadeEasyBlock/MadeEasyFlowDataInterface';

export interface Props {
    data: MadeEasyFlowDataInterface[]
}

const MadeEasyFlow = ({data}: Props) => (
    <div className={classes.MadeEasyFlow}>
        {data?.map((item, index) => {
            if (!isEmptyObject(item)) {
                return;
            }
            return (
                <div key={index} className={classes.MadeEasyFlowItem}>
                    <div className={classes.MadeEasyFlowAbbr}>
                        <span>{item?.abbreviation}</span>
                    </div>
                    <div className={classes.MadeEasyFlowStepInfo}>
                        <span className={classes.Step}>{item?.step}</span>
                        <Heading text={item?.title} type={'h6'} />
                        <span className={classes.Subtitle}>{item?.subtitle}</span>
                    </div>
                </div>
            );
        })}
    </div>
);

export default MadeEasyFlow;
