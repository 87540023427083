export default (taxonomyName = '', realTaxName = '') => {
        let str = '';
        if (realTaxName === 'subjectMatter') {
                str = ', isSubjectMatter: true, shouldOutputInFlatList: true';
        }
        return (
            `
            {
                ${taxonomyName}(where: {orderby: TERM_ID${str}}, first: 50) {
                  nodes {
                    termTaxonomyId
                    name
                    slug
                    parent {
                        termTaxonomyId
                    }
                  }
                }
          }
        `
        );
};
