import React, {useCallback, useMemo, useState} from 'react';
import useAnchor from 'js/hooks/useAnchor/useAnchor';
import Button from 'js/components/Common/Button/Button';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';
import Heading from 'js/components/Common/Heading/Heading';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Container from 'js/components/Common/Container/Container';
import {customFetchData} from 'js/components/Common/TopBanner/fetchData';
import useCutBackground from 'js/hooks/useCutBackground/useCutBackground';
import {ImageBlockDataInterface} from 'js/components/Common/Image/ImageBlockDataInterface';

import classes from '../TopBanner.module.pcss';
import {TopBannerPageType} from '../TopBannerDataInterface';
import {getPageEntityId, setBannerStyle} from '../TopBannerHelpers';

export interface Props {
    pageId: number,
    pageType: TopBannerPageType
}

const Custom = ({pageType, pageId}: Props) => {
    const [getAnchorElementId, setAnchorElementId] = useState<string>('');
    const [bannerTagline, setBannerTagline] = useState<string>('');
    const [bannerTopic, setBannerTopic] = useState<string>('');
    const [bannerButtonText, setBannerButtonText] = useState<string>('');
    const [bannerButtonLink, setBannerButtonLink] = useState<string>('');
    const [bannerImage, setBannerImage] = useState<ImageBlockDataInterface>({});

    const bg = useCutBackground(bannerImage);
    const anchor = useAnchor(getAnchorElementId, 105, -200);

    const { isLoading, error } = useFetchData({
        query: customFetchData(pageId, pageType),
        condition: !!pageId,
        updateData: (data) => {
            if (!data) {
                return;
            }
            setBannerTagline(data?.page[pageType]?.bannerTagline);
            setBannerTopic(data?.page[pageType]?.bannerTopic);
            setBannerImage(data?.page[pageType]?.bannerImage);
            setBannerButtonText(data?.page[pageType]?.bannerButtonText);

            if (data?.page[pageType]?.buttonActionType === 'link') {
                setBannerButtonLink(data?.page[pageType]?.bannerButtonLink);
            } else {
                setBannerButtonLink('anchor');
                setAnchorElementId(getPageEntityId(pageType));
            }
        }
    });

    const page = useMemo(() => {
        switch (pageType) {
            case 'CoursesTemplate' : return 'Courses';
            case 'EventsTemplate' : return 'Live Events';
            case 'VideosTemplate' : return 'Videos';
            case 'ManualsHub' : return 'ManualsHub';
            case 'HelpTemplate' : return 'Help';
            default : return '';
        }
    }, [pageType]);

    const sendMixPanelButtonEvent = useCallback(async () => {
        await MixPanelWrapper.TrackEvent(
            'Top Banner Button Click',
            {
                category: 'Top Banner',
                value: `${page}`
            });
    }, [page]);

    const sendMixPanelAnchorEvent = useCallback(async () => {
        await MixPanelWrapper.TrackEvent(
            'Top Banner Anchor Click',
            {
                category: 'Top Banner',
                value: `${page}`
            });
    }, [page]);

    const setButtonType = useMemo(() => {
        if (!bannerButtonText) {
            return null;
        }
        if (bannerButtonLink === 'anchor') {
            return (
                <Button
                    additionalStylesClass={classes.Button}
                    text={bannerButtonText}
                    type={'button'}
                    handler={async () => {
                        anchor();
                        await sendMixPanelAnchorEvent();
                    }}
                />
            );
        }
        return (
            <Button
                text={bannerButtonText}
                type={'a'}
                target={true}
                link={bannerButtonLink}
                handler={async () => await sendMixPanelButtonEvent()}
            />
        );
    }, [
        anchor,
        bannerButtonText,
        bannerButtonLink,
        sendMixPanelButtonEvent,
        sendMixPanelAnchorEvent
    ]);
    return (
        (bannerTopic && bannerImage && !isLoading && !error) ?
            <section className={classes.TopBanner} style={setBannerStyle(bg)}>
                <Container>
                    {bannerTagline ? <strong className={classes.TopBannerTagline}>{bannerTagline}</strong> : null}
                    <Heading type="h1" text={bannerTopic} />
                    {setButtonType}
                </Container>
            </section> : null
    );
};

export default Custom;
