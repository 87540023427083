import React, {useContext, useEffect, useState} from 'react';
import BellImage from 'images/bell.svg';
import usePreviousState from 'js/hooks/usePreviousState/usePreviousState';
import {NotificationsContext} from 'js/context/NotificationsContext/NotificationsContext';

import classes from './Bell.module.pcss';

export interface Props {
    count?: number,
    click: any
}

const Bell = ({count, click}: Props) => {
    const [isTriggered, setIsTriggered] = useState<boolean>(false);
    const {updateTrigger} = useContext(NotificationsContext);
    const prevCount = usePreviousState(count) || 0;

    useEffect(() => {
        if (!count || !updateTrigger) {
            return;
        }

        if ((prevCount < count)) {
            return setIsTriggered(true);
        }

    }, [count, prevCount, updateTrigger]);

    return (
        <div className={classes.Bell} onClick={click}>
            {count ? <span className={classes.Count}>{count}</span> : null}
            <img
                src={BellImage}
                onAnimationEnd={() => {
                    setIsTriggered(false);
                }}
                className={`${isTriggered ? classes.Triggered : ''}`}
                alt="Notification Bell"/>
        </div>
    );
};

export default Bell;
