import React, {CSSProperties, useMemo} from 'react';
import parse from 'html-react-parser';

import classes from './ExpandableSection.module.pcss';

export interface Props {
    data: any,
    isOpened?: boolean
}

const ExpandableSectionInfo = ({data, isOpened = false}: Props) => {
    const openedStyle: CSSProperties = useMemo(() => (
        {
            boxSizing: 'border-box',
            display: 'block'
        }
    ), []);
    return (
        <div
            style={isOpened ? openedStyle : {}}
            className={`${classes.ExpandableSectionInfo} ${isOpened ? 'ShowExpandSection' : ''}`}>
            <article className={classes.Content}>
                {typeof(data) === 'string' && data?.length > 0 ? parse(data || '') : data}
            </article>
        </div>
    );
};
export default ExpandableSectionInfo;
