import React, {useCallback, useMemo} from 'react';
import youtube from 'images/youtube.svg';
import linkedin from 'images/linkedin.svg';
import Heading from 'js/components/Common/Heading/Heading';

import classes from './SocialMedia.module.pcss';

type Social = 'youtube' | 'linkedin' | '';

export interface SocialMediaType {
	youtube: string,
	linkedin: string
}

export interface Props {
	data: SocialMediaType,
	heading?: string
}

const SocialMedia = ({ data, heading }: Props) => {
	const socialMediaItems = useMemo(() => data ? Object.entries(data) : [], [data]);
	const setMediaIcon = useCallback((value: Social) => {
		switch (value) {
			case 'youtube' :
				return youtube;
			case 'linkedin' :
				return linkedin;
			default :
				return '';
		}
	}, []);

	return (
		<div className={classes.SocialMedia}>
			{heading ? <Heading text={heading} type={'h4'}/> : ''}
			{socialMediaItems ? socialMediaItems.map((item, index) => {
				const name: any = item[0];
				const link: any = item[1];
				if (link) {
					return (
						<a
							className={name}
							key={index}
							target="_blank"
							rel="nofollow noopener noreferrer"
							href={link}>
							<img src={setMediaIcon(name)} alt={name} />
						</a>
					);
				} else {
					return '';
				}
			}) : null}
		</div>
	);
};

export default SocialMedia;
