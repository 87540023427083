export default query => `
   {
        search {
          autoCompleteResult(query: "${query.replace(/[^a-zA-Z\d\s:]/g, '')}") {
            id,
            title
          }
        }
      }
`;
