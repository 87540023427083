const fetchData = pageId => `
{
    page(id: "${pageId}", idType: DATABASE_ID) {
      PreLoginPage {
          promoBoxSlider {
            heading
            headingColor
            subheading
            button {
              url
              title
              target
            }
            contentType
            animatedImage
            staticImage {
              altText
              mediaItemUrl
              mediaDetails {
                sizes {
                  file
                  sourceUrl
                }
              }
            }
            videoFile {
              mediaItemUrl
            }
            slideBackground {
                mediaItemUrl
            }
          }
        }
    }
  }
`;
export default fetchData;
