import React, {useMemo} from 'react';
import Holder from 'images/holder.png';

export interface Props {
	mediaItemUrl: string,
	alt: string,
	size?: string | boolean,
	mediaDetails?: {
		width?: number,
		height?: number,
		sizes?: {
			name?: string,
			file?: string,
			sourceUrl?: string
		}[]
	},
	mimeType?: string | boolean,
	srcSet?: string
}

const Image = ({ mediaItemUrl, alt, size = false, mediaDetails = {}, mimeType = false }: Props) => {
	const getThumbnailUrlBySize = useMemo(() => {
		let itemUrl;

		if (!mediaItemUrl) {
			itemUrl = Holder;
		} else {
			itemUrl = mediaItemUrl;
		}

		if (size && mediaDetails && mediaDetails?.sizes) {
			if (mimeType !== 'image/svg+xml') {
				const imgUrl = mediaDetails.sizes.filter(item => item.name === size);
				return imgUrl.length > 0 ? imgUrl[0].sourceUrl : itemUrl;
			} else {
				return itemUrl;
			}
		} else {
			return itemUrl;
		}

	}, [mediaDetails, mediaItemUrl, mimeType, size]);
	return <img src={getThumbnailUrlBySize} alt={alt || ''} />;
};

export default Image;
