import React, {useMemo} from 'react';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import classes from './PostDuration.module.pcss';

export interface Props {
	duration: number,
	price?: number | string,
	showPrice?: boolean,
	showIcon?: boolean
}

const PostDuration = ({ duration, price = '', showPrice = false, showIcon = true }: Props) => {
	const timeConvert = useMemo(() => {
		let result = '';
		const value = duration;

		const days = (value / 60) / 24;
		const rDays = Math.floor(days);

		const hours = (value / 60);
		const rHours = Math.floor(hours);

		const minutes = (hours - rHours) * 60;
		const rMinutes = Math.round(minutes);

		let hourLabel = 'Hour';
		let dayLabel = 'Day';
		let minuteLabel = 'Min';

		dayLabel = rDays > 1 ? `${dayLabel}s` : dayLabel;
		hourLabel = rHours > 1 ? `${hourLabel}s` : hourLabel;
		minuteLabel = rMinutes > 1 ? `${minuteLabel}s` : minuteLabel;

		if (rDays > 0) {
			return `${rDays} ${dayLabel}`;
		}

		result = rHours > 0 ? `${rHours} ${hourLabel}` : result;
		result = rMinutes > 0 ? `${result} ${rMinutes} ${minuteLabel}` : result;

		return result;
	}, [duration]);

	return (
		<DataValidator data={!!duration}>
			<div className={`${classes.DurationWrap} ${showPrice && classes.Flex} ${showIcon && classes.Icon}`}>
				<span className={`PostDurationWrap ${classes.Duration}`}><span>{timeConvert}</span></span>
				{price && showPrice ? <span className={classes.Price}>£{price}</span> : null}
			</div>
		</DataValidator>
	);
};


export default PostDuration;
