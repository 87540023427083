import React, {useCallback} from 'react';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';
import Heading from 'js/components/Common/Heading/Heading';
import Description from 'js/components/Common/Description/Description';
import MediaDuration from 'js/components/Common/MediaDuration/MediaDuration';
import VisibilityViewerIcon from 'js/components/Common/VisibilityViewerIcon/VisibilityViewerIcon';

import Views from '../../components/Views/Views';
import WatchTime from '../../components/WarchTime/WatchTime';
import Thumbnail from '../../components/Thumbnail/Thumbnail';
import commonClasses from '../../../SearchResultsItem/SearchResultsItem.module.pcss';
import ProductLabelsList from 'js/components/Common/ProductLabelList/ProductLabelsList';
import Label from 'js/containers/Pages/Search/SearchResults/Entities/components/Label/Label';

export interface Props {
    data: {
        id: number,
        title: string,
        featuredImage: {
            url: string,
            altText: string
        },
        type: 'videos',
        product: string,
        description: string,
        videoDuration: string,
        publishingDate: string,
        watchTime: string,
        views: number,
        link: string
    },
    isPromoted: boolean
}

const Video = ({data, isPromoted}: Props) => {
    const {
        title,
        videoDuration,
        featuredImage,
        description,
        link,
        watchTime,
        views
    } = data || {};

    const sendMixPanelEvent = useCallback(async () => {
        await MixPanelWrapper.TrackEvent(
            'Open Video page',
            {
                category: 'Search Results',
                value: data?.title
            });
    }, [data]);
    return (
        <div className={`${commonClasses.SearchResultItem} ${isPromoted && commonClasses.Promoted}`}>
            <Thumbnail thumbnail={featuredImage} type={'videos'} alt={title} />
            <span className={commonClasses.SearchResultItemInfo}>
                <a onClick={sendMixPanelEvent} href={link} target={'_blank'} rel={'noopener noreferrer'}>
                   <Heading text={title} type={'h5'}/>
                </a>
                <Description data={description}/>
                <div className={commonClasses.Wrap}>
                    <Label type={'videos'}/>
                    <MediaDuration data={videoDuration} />
                    <WatchTime data={watchTime} />
                    <Views data={views} />
                    <ProductLabelsList pageId={data?.id} />
                </div>
                <div className={commonClasses.Wrap}>
                    <VisibilityViewerIcon id={data?.id} />
                </div>
            </span>
        </div>
    );
};

export default Video;
