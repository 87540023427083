import thumbnailFragment from 'js/graphqlFragments/ThumbnailFragment';
import imageFragment from 'js/graphqlFragments/ImageFragment';
export const fetchData = pageId => (
	`
		{
		   articlesSingle(id: "${pageId}", idType: DATABASE_ID) {
			  title
			  link
			  updateViews
			  lastEditedAt
			  articleViews
			  readTime
			  articleCategoryList(first: 1) {
				  nodes {
					name
				  }
				}
				ResourceVisibility {
					resourceVisibility
			  	}
			  Articles {
				  articleContent
				  		attachedManuals {
				  			title
							manual {
							  ... on ManualsSingle {
								databaseId
								  title
								  Manuals {
									manual {
									  mediaItemUrl
									}
								  }
							  }
							}
					  	}
				  articleAuthor {
				  ... on TeamSingle {
					title
					Team {
					  position
					}
					...ThumbnailFragment
				  }
				}
			  }
			  SeoMetadata {
				metaTitle
				metaDescription
			 }
			 recommendedArticles {
			   category
			   id
			   readTime
			   title
			   link
			   date
			   articleID
			  }
		   }
		   monday {
			  Monday {
				feedback {
				  articleFeedbackTitle
				}
			  }
			}
		}
		${thumbnailFragment('TeamSingle')}
	`
);

export const attachedVideoFetchData = pageId => (
	`
		{
			articlesSingle(id: "${pageId}", idType: DATABASE_ID) {
			  Articles {
				attachedVideo {
				  videoLink
				  videoPreview {
					...ImageFragment
				  }
				}
			  }
			}
  		}
  		${imageFragment()} 
	`
);
