import React, {useCallback, useContext, useState, useEffect, useRef, useMemo} from 'react';
import {observer} from 'mobx-react';
import parse from 'html-react-parser';
import SearchStore from 'js/store/SearchStore';
import Heading from 'js/components/Common/Heading/Heading';
import {CommonContext} from 'js/context/CommonContext/CommonContext';
import useSearchSetViaHistory from 'js/hooks/useSearchSetViaHistory/useSearchSetViaHistory';

import fetchData from './fetchData';
import classes from './SearchFilterItem.module.pcss';
import SearchFilterService from 'js/containers/Pages/Search/SearchFilter/SearchFilterService';
import SearchFilterItemElement from 'js/containers/Pages/Search/SearchFilter/SearchFilterItem/SearchFilterItemElement';

export type TaxonomyType =
    'subjectMatter' |
    'suitableFor' |
    'skills' |
    'productLegacy' |
    'language' |
    'articleType' |
    'courseType' |
    'eventType' |
    'videoType' |
    'podcastType' |
    'presentationType' |
    'manualsType';

export interface Props {
    heading: string,
    parentId?: string,
    type: 'entities' | 'taxonomy',
    taxonomyName?: TaxonomyType
}

type FilterItemType = {
    id: number,
    label: string | JSX.Element | JSX.Element[],
    value: string,
    name?: string,
    count?: number,
    disabled?: any,
    parent?: string,
    isChecked?: boolean
}

const SearchFilterItem = ({heading, type, taxonomyName, parentId = ''}:Props) => {
    const {
        taxCounts,
        searchStatus,
        entitiesCounts,
        searchQuery,
        filterEntitiesData,
        entityTypes,
        typesTaxSections
    } = SearchStore;
    const nodeRef = useRef(null);

    const controllerRef = useRef<AbortController | null>();
    const [isCountsLoaded, setIsCountsLoaded] = useState<boolean>(false);
    const [itemSectionData, setItemSectionData] = useState<Array<FilterItemType>>([]);
    const [itemRawSectionData, setRawItemSectionData] = useState<Array<{
        termTaxonomyId: number,
        name: string,
        slug: string
    }>>([]);
    const {apiPath} = useContext(CommonContext);
    const [entitiesCountsCopy, setEntitiesCountsCopy] = useState([]);

    useSearchSetViaHistory(nodeRef, itemSectionData, type, taxonomyName);

    useEffect(() => {
        if (type !== 'entities') {
            return;
        }
        setEntitiesCountsCopy(entitiesCounts);
    }, [
        entitiesCounts,
        type
    ]);

    const showItemsCount = useCallback((count) => {
        if (!count) {
            return '';
        }
        return `(${new Intl.NumberFormat('en-IN').format(count)})`;
    }, []);

    const getTaxItemCount = useCallback((item) => {
        const key = `term_id_${item?.termTaxonomyId}`;
        const itemObj = taxCounts[taxonomyName]?.find(subItem => subItem?.name === key);
        return itemObj?.count || 0;
    }, [taxonomyName, taxCounts]);

    const setTaxCounts = useCallback(data => data?.map((item) => {

        const itemCount = getTaxItemCount(item);
        return (
            {
                id: item?.termTaxonomyId,
                label: parse(`${item?.name}&nbsp;${showItemsCount(itemCount)}`),
                value: item?.slug,
                name: item?.name,
                parent: item?.parent?.termTaxonomyId,
                count: itemCount
            }
        );
    }), [showItemsCount, getTaxItemCount]);

    const getTaxResponse = useCallback(async (ref) => {
        setIsCountsLoaded(false);

        const taxonomyMap = {
            subjectMatter: 'productLegacy'
        };

        const taxonomyNameQuery = taxonomyMap[taxonomyName] || taxonomyName;

        try {
            const response = await fetch(`${apiPath}/graphql`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                signal: ref?.signal,
                body: JSON.stringify({
                    query: fetchData(`${taxonomyNameQuery}List`, taxonomyName)
                })
            });

            const { data } = await response.json();
            const result = data[`${taxonomyNameQuery}List`]?.nodes;

            if (!result?.length) {
                setRawItemSectionData([]);
                return;
            }

            setRawItemSectionData(setTaxCounts(result));
        } catch (e) {

        }
    }, [apiPath, setTaxCounts, taxonomyName]);

    useEffect(() => {
        const arr = SearchFilterService.quickSort([...itemRawSectionData]);
        setItemSectionData(arr);
        setIsCountsLoaded(true);
    }, [itemRawSectionData]);

    useEffect(() => {
        if (type === 'taxonomy') {
            return;
        }

        setIsCountsLoaded(false);
        const counts = [];
        entitiesCountsCopy?.map((item) => {
            counts[item['name']] = item['count'];
        });
        const data:Array<FilterItemType> = SearchFilterService.quickSort([
            {
                id: 1,
                label: parse(`Articles&nbsp;<span datatype="count">${showItemsCount(counts['articles'])}</span>`),
                value: 'articles',
                disabled: counts['articles'],
                count: counts['articles']
            },
            {
                id: 2,
                label: parse(`Courses&nbsp;<span datatype="count">${showItemsCount(counts['courses'])}</span>`),
                value: 'courses',
                disabled: counts['courses'],
                count: counts['courses']
            },
            {
                id: 3,
                label: parse(`Manuals&nbsp;<span datatype="count">${showItemsCount(counts['manuals'])}</span>`),
                value: 'manuals',
                disabled: counts['manuals'],
                count: counts['manuals']
            },
            {
                id: 4,
                label: parse(`Videos&nbsp;<span datatype="count">${showItemsCount(counts['videos'])}</span>`),
                value: 'videos',
                disabled: counts['videos'],
                count: counts['videos']
            }
        ]);
        setItemSectionData(data);
        setIsCountsLoaded(true);
    }, [
        type,
        showItemsCount,
        entitiesCountsCopy
    ]);

    useEffect(() => {
        if (type !== 'taxonomy') {
            return;
        }
        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        controllerRef.current = new AbortController();
        (async () => {
            await getTaxResponse(controllerRef?.current);
        })();
        return () => {
            if (controllerRef.current) {
                controllerRef.current.abort();
                controllerRef.current = null;
            }
        };
    }, [
        type,
        getTaxResponse
    ]);

    const hideSection = useMemo(() => {
        if (parentId) {
            return itemSectionData?.some(item => parseInt(item?.parent) === parseInt(parentId) && item?.count > 0);
        }

        const isTaxonomyIncluded = typesTaxSections?.includes(taxonomyName);
        const isEntityIncluded = filterEntitiesData.includes(entityTypes[taxonomyName]);

        if (isTaxonomyIncluded && !isEntityIncluded) {
            return false;
        }

        return itemSectionData?.some(item => item?.count > 0);
    }, [entityTypes, filterEntitiesData, itemSectionData, parentId, taxonomyName, typesTaxSections]);

    return (
        <section
            className={`${classes.FilterSection} ${!hideSection ? classes.Hidden : ''} ${(searchStatus || searchQuery.length === 0) && isCountsLoaded ? classes.FilterSectionActive : ''}`}
            datatype={type}
            data-tax={taxonomyName}
            ref={nodeRef}>
            <Heading text={heading} type={'h6'}/>
            <div className={classes.FilterSectionItems}>
                {itemSectionData?.map(item =>
                    <SearchFilterItemElement
                        key={item?.id}
                        item={item}
                        taxonomyName={taxonomyName}
                        type={type}
                        parentId={parentId}
                        heading={heading}/>
                )}
            </div>
        </section>
    );
};

export default observer(SearchFilterItem);
