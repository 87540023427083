import React, { useState } from 'react';
import Heading from 'js/components/Common/Heading/Heading';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Container from 'js/components/Common/Container/Container';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import fetchData from './fetchData';
import classes from './Intro.module.pcss';
import IntroDescription from './IntroDescription/IntroDescription';
import { IntroDataInterface, IntroPageType } from './IntroDataInterface';
import AttributesItems from './IntroAttributes/AttributesItems/AttributesItems';

export interface Props {
	pageId: number,
	pageType: IntroPageType,
	additionalCondition?: boolean
}

const Intro = ({ pageId, pageType, additionalCondition = false }: Props) => {
	const [introByline, setIntroByline] = useState<string>('');
	const [introText, setIntroText] = useState<string>('');
	const [attributesList, setAttributesList] = useState<IntroDataInterface[]>([]);

	const { isLoading, error } = useFetchData({
	    query: `${fetchData(pageId, pageType)}`,
	    condition: !!pageId && !additionalCondition,
	    updateData: (data) => {
			setIntroByline(data?.page[pageType].introByline);
			setIntroText(data?.page[pageType].introText);
			setAttributesList(data?.page[pageType].attributesList);
	    }
	});

	return (
		<DataValidator data={!!attributesList?.length && !isLoading && !error}>
			<section className={classes.Intro}>
				<Container>
					<Heading text={introByline} />
					<div className={classes.IntroWrap}>
						<IntroDescription data={introText} />
						<AttributesItems data={attributesList} />
					</div>
				</Container>
			</section>
		</DataValidator>
	);
};

export default Intro;
