import React, {useState} from 'react';
import parse from 'html-react-parser';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Container from 'js/components/Common/Container/Container';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import classes from './TopInfo.module.pcss';
import fetchData from './fetchData';

const TopInfo = ({pageId}) => {
    const [info, setInfo] = useState<string>('');

    const { isLoading, error } = useFetchData({
        query: `${fetchData(pageId)}`,
        condition: !!pageId,
        updateData: (data) => {
            setInfo(data?.page?.PostLoginPage?.topSection);
        }
    });
    return (
        <DataValidator data={!!info && !isLoading && !error}>
            <section className={classes.TopInfo}>
                <Container>
                    {parse(info || '')}
                </Container>
            </section>
        </DataValidator>
    );
};

export default TopInfo;
