import imageFragment from 'js/graphqlFragments/ImageFragment';
import thumbnailFragment from 'js/graphqlFragments/ThumbnailFragment';

const globalFields:string = `
    product {
      ProductGlobal {
        contentTitleProduct
      }
    }
`;

const commonFields:string = `
    description
    customContent
`;

const setEntityFields = (name: string, singleName: string, fields: string):string => `
    ${name} {
            ${commonFields}
            onFocusItem {
              ... on ${singleName} {
                ${fields}
              }
            }
            items: customContentData {
              ... on ${singleName} {
                ${fields}
              }
            }
          }
`;


const videoFields:string = `
    databaseId
    title
    uri
    Videos {
        descriptionVideo
        attachedVideo {
          videoPreview {
            ...ImageFragmentVideoPreview
          }
        }
    }
`;

const articleFields:string = `
    databaseId
    title
    uri
    Articles {
        shortDescription
        date: releaseDate
    }
`;

const courseFields:string = `
    databaseId
    title
    uri
    Courses {
        courseDescription
    }
    ...ThumbnailFragment
`;

const manualFields:string = `
    databaseId
    title
    link
    Manuals {
        manualThumbnail {
            ...ImageFragmentManualThumbnail
        }
        descriptionManual
        date: lastUpdated
    }
   
`;

const releaseUpdatesFields:string = `
    releaseUpdatesContent {
      ${commonFields}
      onFocusItem {
        ... on ManualsSingle {
          ${manualFields}
        }
        ... on ArticlesSingle {
          ${articleFields}
        }
      }
      items: customContentData {
              ... on ManualsSingle {
              ${manualFields}
            }
            ... on ArticlesSingle {
              ${articleFields}
            }
      }
    }
`;
export const downloadManualFetchData = (id):string => `
{
        manualsSingle(id: "${id}", idType: DATABASE_ID) {
        updateDownloads
    }
}`;

export default (pageId: number):string =>  `
    {
    
    releaseUpdatesContent : productLegacy(id: "${pageId}", idType: DATABASE_ID) {
      articles : articlesArchive(where: {status: PUBLISH, isRelease: true, orderby: [{field: DATE, order: DESC}, {field: TITLE, order: ASC}]}, first: 3) {
        items: nodes {
          ${articleFields}
          
        }
      }
      manuals: manualsArchive(where: {status: PUBLISH, isRelease: true, orderby: [{field: DATE, order: DESC}, {field: TITLE, order: ASC}]}, first: 3) {
          items: nodes {
            ${manualFields}
          }
      }
    }
    productLegacy(id: "${pageId}", idType: DATABASE_ID) {
      name
      parent {
        termTaxonomyId
      }
      videosContent : videosArchive(where: {status: PUBLISH, orderby: [{field: DATE, order: DESC}, {field: TITLE, order: ASC}]}, first: 3) {
          items: nodes {
            ${videoFields}
          }
      }
      articlesContent : articlesArchive(where: {status: PUBLISH, isRelease: false, orderby: [{field: DATE, order: DESC}, {field: TITLE, order: ASC}]}, first: 3) {
        items: nodes {
          ${articleFields}
        }
      }
      coursesContent : coursesArchive(where: {status: PUBLISH, orderby: [{field: DATE, order: DESC}, {field: TITLE, order: ASC}]}, first: 3) {
          items: nodes {
            ${courseFields}
          }
      }
      manualsContent : manualsArchive(where: {status: PUBLISH, isRelease: false, orderby: [{field: DATE, order: DESC}, {field: TITLE, order: ASC}]}, first: 3) {
          items: nodes {
            ${manualFields}
          }
      }
      
      ProductLegacyFields {
        content {
          ${setEntityFields('videosContent', 'VideosSingle', videoFields)}
          ${setEntityFields('articlesContent', 'ArticlesSingle', articleFields)}
          ${setEntityFields('coursesContent', 'CoursesSingle', courseFields)}
          ${setEntityFields('manualsContent', 'ManualsSingle', manualFields)}
          ${releaseUpdatesFields}
        }
      }
    }
    ${globalFields}
  }
  ${imageFragment('VideoPreview')} 
  ${thumbnailFragment('CoursesSingle')} 
  ${imageFragment('ManualThumbnail')} 
`;
