import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {ToastMessagesContext} from 'js/context/ToastMessagesContext/ToastMessagesContext';

import classes from './Voting.module.pcss';

const Voting = ({title, eventHandler}) => {
    const location = useLocation();
    const {addToastMessage} = useContext(ToastMessagesContext);
    const [voteState, setVoteState] = useState<boolean | null>(null);
    const votingClickHandler = useCallback((el, state) => {
        if (voteState !== null) {
           return;
        }
        setVoteState(state);
        eventHandler(title, state ? 'Yes' : 'No');
        addToastMessage({
            type: 'success',
            key: 'articleVoteToasts'
        });
    }, [addToastMessage, eventHandler, title, voteState]);

    useEffect(() => {
        setVoteState(null);
    }, [location]);

    return (
        <div className={classes.Voting}>
            <div className={classes.VotingWrap}>
                <strong className={classes.Title}>Was this helpful?</strong>
                <div className={classes.Choices}>
                    <span className={voteState === true ? classes.Active : ''} onClick={el => votingClickHandler(el, true)}>Yes</span>
                    <span className={voteState === false ? classes.Active : ''} onClick={el => votingClickHandler(el, false)}>No</span>
                </div>
            </div>
        </div>
    );
};

export default Voting;
