import React from 'react';

import classes from './AttributesItem.module.pcss';

const AttributesItem = ({ item }) => {
	const { altText, mediaItemUrl } = item?.icon || {};
	return (
		<div className={classes.AttributesItem}>
			<figure className={classes.AttributesItemImage}>
				{mediaItemUrl ? <img src={mediaItemUrl} alt={altText || item.text} /> : null}
			</figure>
			<span className={classes.AttributesItemText}>{item.text}</span>
		</div>
	);
};

export default AttributesItem;
