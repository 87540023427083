import React, {useCallback, useEffect, useState} from 'react';

import { EventsListTypes } from '../Wrapper/WrapperDataInterface';

export const EventsListContext = React.createContext({
        emptyContent: null,
        setResult: (type, value) => value
    }
);

const EventsListContextProvider = (props) => {
    const [emptyContent, setEmptyContent] = useState<boolean | null>(null);
    const [eventsByDateResults, setEventsByDateResults] = useState<boolean | null>(null);
    const [eventsSkillBoosterResults, setEventsSkillBoosterResults] = useState<boolean | null>(null);
    const [eventsLearnNewResults, setEventsLearnNewResults] = useState<boolean | null>(null);
    const [eventsInspiredResults, setEventsInspiredResults] = useState<boolean | null>(null);

    const setResultHandler = useCallback((type:EventsListTypes, value)  => {
        switch (type) {
            case 'upcomingByDate': return setEventsByDateResults(value);
            case 'skillBooster': return setEventsSkillBoosterResults(value);
            case 'learnNew': return setEventsLearnNewResults(value);
            case 'inspired': return setEventsInspiredResults(value);
            default : return null;
        }
    }, []);

    useEffect(() => {
        const isEmpty =
            eventsByDateResults === false &&
            eventsSkillBoosterResults === false &&
            eventsLearnNewResults === false &&
            eventsInspiredResults === false;

        const isNull =
            eventsByDateResults === null ||
            eventsSkillBoosterResults === null ||
            eventsLearnNewResults === null ||
            eventsInspiredResults === null;

        if (isEmpty && !isNull) {
            setEmptyContent(true);
        }

        if (!isEmpty && !isNull) {
            setEmptyContent(false);
        }

    }, [
        eventsByDateResults,
        eventsSkillBoosterResults,
        eventsLearnNewResults,
        eventsInspiredResults
    ]);

    return (
        <EventsListContext.Provider value={
            {
                emptyContent,
                setResult: setResultHandler
            }
        }>
            {props.children}
        </EventsListContext.Provider>
    );
};

export default EventsListContextProvider;

