const fetchData = (pageId: number): string => `
    {
    subTopicData : productLegacyList(where: {parent: ${pageId}, orderby: NAME, hideEmpty: true}, first: 500) {
      items: nodes {
        id
        databaseId
        name
        productLegacySubTopic(page: -1) {
            id
        }
      }
    }
    productLegacy(id: "${pageId}", idType: DATABASE_ID) {
      name
      ProductLegacyFields {
        subTopicSection {
            title
            description
        }
      }
    }
  }
`;

export const contentFetchData = (productId:number, page: number):string => `
        {
    productLegacy(id: "${productId}", idType: DATABASE_ID) {
      productLegacySubTopic(page: ${page}) {
        id
        link
        title
        description
        date
        type
        total
      }
    }
  }
    `;

export default fetchData;
