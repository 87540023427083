export default (type, uri) => `{
   ${type}Single(id: "${uri}", idType: URI) {
          databaseId
          title
          link
          slug
          SeoMetadata {
            metaTitle
            metaDescription
          }
        }
    } `;
