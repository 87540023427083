import React, {useCallback, useState} from 'react';
import moment from 'moment';
import Button from 'js/components/Common/Button/Button';
import Heading from 'js/components/Common/Heading/Heading';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Container from 'js/components/Common/Container/Container';
import {entityFetchData} from 'js/components/Common/TopBanner/fetchData';
import useCutBackground from 'js/hooks/useCutBackground/useCutBackground';
import {ImageBlockDataInterface} from 'js/components/Common/Image/ImageBlockDataInterface';
import EventRegistrationWrap from 'js/components/Common/EventRegistrationWrap/EventRegistrationWrap';

import classes from '../TopBanner.module.pcss';
import {getPageEntityId, setBannerStyle} from '../TopBannerHelpers';

export interface Props {
    pageId: number,
    changeBannerDataType: any
}

const Event = ({pageId, changeBannerDataType}: Props) => {
    const [bannerTagline, setBannerTagline] = useState<string>('');
    const [bannerTopic, setBannerTopic] = useState<string>('');
    const [bannerImage, setBannerImage] = useState<ImageBlockDataInterface>({});
    const [joinEventButton, setJoinEventButton] = useState<string>('');
    const [viewEventButton, setViewEventButton] = useState<string>('');
    const [registerEventButton, setRegisterEventButton] = useState<string>('');
    const [registrationHash, setRegistrationHash] = useState<string | null>(null);
    const [eventLink, setEventLink] = useState<string>('');
    const [startDate, setStartDate] = useState<string>('');
    const [databaseId, setDatabaseId] = useState<string>('');
    const [meetingID, setMeetingID] = useState<number>(0);
    const [meetingDateID, setMeetingDateID] = useState<number>(0);
    const [eventType, setEventType] = useState<'Classroom' | 'Panel' | 'Conference' | ''>('');
    const bg = useCutBackground(bannerImage);

    const filterByUpcomingOnly = useCallback((date) => {
        const localStartDate = moment(new Date(date)).utc(true);
        const localNowDate = moment(new Date()).utc(false);
        return localNowDate.isBefore(localStartDate);
    }, []);

    const { isLoading, error } = useFetchData({
        query: entityFetchData(pageId, getPageEntityId('EventsTemplate')),
        condition: !!pageId,
        updateData: (data) => {
            const result = data?.page?.EventsTemplate;
            const {selectEntityBanner} = result || {};
            const buttonsData = data?.themeGeneralSettings?.Options;
            const eventCustomFields = selectEntityBanner?.Meetings;

            const eventDate = eventCustomFields?.startDate;
            if (!result || !selectEntityBanner) {
                return;
            }

            if (!filterByUpcomingOnly(eventDate)) {
                return changeBannerDataType();
            }

            setBannerTagline(result?.bannerTagline);
            setBannerTopic(result?.selectEntityBanner?.title);
            setBannerImage(selectEntityBanner?.featuredImage);

            setJoinEventButton(buttonsData?.joinEventButton);
            setViewEventButton(buttonsData?.viewEventButton);
            setRegisterEventButton(buttonsData?.registerEventButton);

            setEventLink(eventCustomFields?.eventLink);
            setEventType(eventCustomFields?.eventType);
            setStartDate(eventDate);
            setDatabaseId(selectEntityBanner?.databaseId);
            setMeetingID(selectEntityBanner?.meetingID);
            setMeetingDateID(selectEntityBanner?.meetingDateID);
            setRegistrationHash(selectEntityBanner?.userRegisteredHash);
        }
    });

    return (
        (bannerTopic && bannerImage && !isLoading && !error) ?
            <section className={classes.TopBanner} style={setBannerStyle(bg)}>
                <Container>
                    <strong className={classes.TopBannerTagline}>{bannerTagline}</strong>
                    <Heading type="h1" text={bannerTopic} />
                    {registrationHash !== null &&
                    <EventRegistrationWrap
                        eventType={eventType}
                        startDate={startDate}
                        eventLink={eventLink}
                        meetingID={meetingID}
                        meetingDateID={meetingDateID}
                        databaseId={databaseId}
                        initialRegistrationHash={registrationHash}
                        setRegistrationHashCallback={setRegistrationHash}
                        buttons={{
                            joinEventButton,
                            viewEventButton,
                            registerEventButton
                        }}
                        title={bannerTopic}
                        eventCategory={'Top Banner'}>
	                    <Button />
                    </EventRegistrationWrap>}
                </Container>
            </section> : null
    );
};

export default Event;
