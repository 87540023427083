import React, {ReactElement, useState, useCallback, useRef} from 'react';
import Input from 'js/components/Common/FormComponents/Input/Input';
import TextArea from 'js/components/Common/FormComponents/TextArea/TextArea';
import CheckBoxesList from 'js/components/Common/FormComponents/Input/ChechBoxesList/CheckBoxesList';

import classes from './Form.module.pcss';
import ReCaptcha from '../ReCaptcha/ReCaptcha';
import { ChildPropsType, formDataType } from './FormDataInterface';
import {validateFormElement, getInitialFormData, getItemValueByType} from './Helpers/Helpers';

export interface Props {
    children: any,
    onSubmit: Function,
    onValidForm?: Function,
    resetAfterSubmit?: boolean
}

const Form = ({ children, onSubmit, onValidForm, resetAfterSubmit = false }: Props) => {

    const [formData, setFormData] = useState<formDataType | {}>(getInitialFormData(children));
    const allowedFormElements = [Input, TextArea, CheckBoxesList, ReCaptcha];
    const formRef = useRef(null);

    // Collect all form elements (with rerender)
    const recursiveCollectFormElements = useCallback((childrenData) => {
        let results = [];

        for (const child of childrenData) {
            const childName:any = child.type;

            if (Array.isArray(child)) {
                for (const subChild of child) {
                    const subChildName = subChild.type;
                    if (React.isValidElement(subChild) && allowedFormElements.includes(subChildName)) {
                        results.push(subChild);
                    }
                }
            } else if (React.isValidElement(child) && allowedFormElements.includes(childName)) {
                results.push(child);
            }

            if (child && child.props && child.props.children) {
                if (Array.isArray(child.props.children)) {
                    const subResults = recursiveCollectFormElements(child.props.children);
                    results = [...results, ...subResults];
                } else {
                    const subResults = recursiveCollectFormElements([child.props.children]);
                    results = [...results, ...subResults];
                }
            }
        }
        return results;
    }, [allowedFormElements]);

    // Validate Form
    const recursiveValidate = useCallback(async (childrenData) => {
        const formElements = recursiveCollectFormElements(childrenData);

        const validationResults = await Promise.all(formElements.map((formElement) => {
            const { validation, name, checkBoxesData } = formElement.props || {};

            return validateFormElement(validation, formData[name].value, name, formData, checkBoxesData);
        }));

        const newFormData = {};
        validationResults.forEach((validationResult) => {
            const { name, value, errorMessage, isValid } = validationResult;
            newFormData[name] = { value, errorMessage, isValid };
        });

        setFormData(newFormData);

        return validationResults;
    }, [formData, recursiveCollectFormElements]);

    // Reset From
    const recursiveReset = useCallback(() => setFormData(getInitialFormData(children)), [children]);

    // Submit form handler
    const submitHandler = useCallback(async (e) => {
        e.preventDefault();

        const results = await recursiveValidate(children);

        const isValid = !results.find(result => result.isValid === false);

        isValid && onSubmit && onSubmit(results.map(({ name, value }) => ({ name, value })));

        isValid && resetAfterSubmit && recursiveReset();
    }, [children, onSubmit, recursiveReset, recursiveValidate, resetAfterSubmit]);

    const setDefaultDataForCheckbox = useCallback((child) => {
        const formDataValue = formData[child?.props?.name]?.value;
        if ((formDataValue === undefined) && child?.props?.defaultValue) {
            const checked = child?.props?.defaultValue;
            const clonedFormData = {...formData};
            clonedFormData[child?.props?.name].value = child?.props?.defaultValue;
            setFormData(clonedFormData);
            return checked;
        }
        return formDataValue;
    }, [formData]);

    const validateConfirmField = useCallback(async (child, childrenData, data) => {
        if (!child?.props?.confirmField) {
            return data;
        }

        const confirmFieldName = child?.props?.confirmField;

        if (!data[confirmFieldName] || !data[confirmFieldName]?.value) {
            return data;
        }

        const confirmField = childrenData.find(
            item => item.props.name === confirmFieldName);

        const { value } = data[confirmFieldName] || {};
        const { name, validation } =  confirmField?.props || {};

        const validationResult = validateFormElement(validation, value, name, data, []);

        return {...data, [name]: {
                value,
                isValid: validationResult.isValid,
                errorMessage: validationResult.errorMessage
            }};
    }, []);

    // Clone children with adding new props, including onChange handler
    const recursiveCloneChildren = useCallback(childrenData => (
        React.Children.map(childrenData, (child:ReactElement<ChildPropsType>) => {

            const childProps:ChildPropsType = {
                children: null,
                onChange: () => {},
                value: '',
                name: '',
                type: child?.props?.type,
                checked: false,
                checkBoxesData: child?.props?.checkBoxesData,
                validation: {
                    isValid: false,
                    rules: [],
                    errorMessage: ''
                }
            };

            const childName:any = child?.type;

            if ((React.isValidElement(child) && allowedFormElements?.includes(childName))) {

                const { validation } = child.props || {};
                childProps.onChange = async (e) => {

                    const { name, type, checkBoxesData } =  child?.props || {};

                    const value = getItemValueByType(type, e);
                    const validationResult = validateFormElement(
                        validation,
                        value,
                        name,
                        formData,
                        checkBoxesData
                    );

                    let newFormData = {...formData, [name]: {
                            value,
                            isValid: validationResult.isValid,
                            errorMessage: validationResult.errorMessage
                        }};

                    newFormData = await validateConfirmField(child, childrenData, newFormData);

                    setFormData(newFormData);

                    const isValid = !Object.values(newFormData).find(result => result.isValid === false);
                    onValidForm && onValidForm(isValid);

                };
                if (child.props) {
                    if (child.props.type === 'checkbox' || child.props.type === 'radio') {
                        childProps.checked = setDefaultDataForCheckbox(child);
                    } else {
                        childProps.value = formData[child?.props?.name]?.value;
                    }

                    childProps.name = child.props?.name;
                    childProps.validation.isValid = formData[child.props?.name]?.isValid;
                    childProps.validation.errorMessage = formData[child.props?.name]?.errorMessage;
                }
            }

            if (child && child.props && allowedFormElements.includes(childName)) {
                childProps.children = recursiveCloneChildren(child.props.children);
                return React.cloneElement(child, childProps);
            }

            return child;
        })
    ), [allowedFormElements, formData, setDefaultDataForCheckbox, onValidForm, validateConfirmField]);

    return (
        <form action=""
              onSubmit={submitHandler}
              ref={formRef}
              className={classes.Form}>
            {recursiveCloneChildren(children)}
        </form>
    );
};

export default Form;
