import React, {useContext, useMemo} from 'react';
import Moment from 'react-moment';
import Heading from 'js/components/Common/Heading/Heading';
import {CommonContext} from 'js/context/CommonContext/CommonContext';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import classes from './Top.module.pcss';

const Top = () => {
    const {currentUser} = useContext(CommonContext);
    const {firstName, lastName, registeredDate} = currentUser || {};
    const userNameAbbreviation = useMemo(() => {
        const first = firstName?.substring(0, 1) || '';
        const last = lastName?.substring(0, 1) || '';
        return first + last;
    },
    [firstName, lastName]);
    return (
        <DataValidator data={!!currentUser}>
            <section className={classes.Top}>
                <span className={classes.ProfileIcon}>Profile</span>
                <div className={classes.Wrap}>
                    <div className={classes.Abbreviation}>
                        <span>{userNameAbbreviation}</span>
                    </div>
                    <div className={classes.Info}>
                        <Heading
                            type={'h1'}
                            text={`${firstName || ''} ${lastName || ''}`} />
                        <div className={classes.LastLogged}>
                            <span>Last login: </span>
                            {registeredDate ? <Moment format={'DD/MM/YYYY'}>{registeredDate}</Moment> : ''}
                        </div>
                    </div>
                </div>
            </section>
        </DataValidator>
    );
};

export default Top;
