const fetchData = pageId => `
    {
        page(id: "${pageId}", idType: DATABASE_ID) {
          CoursesTemplate {
            headingAccreditation
                companiesAccreditation {
                  link
                  logo {
                    altText
                    mediaItemUrl
                  }
                }
              }
          }
        }
    `;

export default fetchData;

