export default (pageId, pageType) => `
{
	page(id: "${pageId}", idType: DATABASE_ID) {
	  ${pageType} {
		introByline
		introText
		attributesList {
		  icon {
			altText
			  mediaItemUrl
			}
		  	text
		}
	  }
	}
  }
`;
