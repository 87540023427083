import {useHistory} from 'react-router';
import {useEffect, useMemo} from 'react';
import {TaxonomyType} from 'js/containers/Pages/Search/SearchFilter/SearchFilterItem/SearchFilterItem';
import SearchStore from 'js/store/SearchStore';

const useSearchSetViaHistory = (
	nodeRef,
	trigger,
	filterType: 'entities' | 'taxonomy' | 'rating',
	taxType: TaxonomyType) => {
	const history = useHistory();

	const {searchStatus} = SearchStore;

	const filterKey = useMemo(() => {
		switch (filterType) {
			case 'entities' : return 'filterEntitiesData';
			case 'rating' : return 'filterRatingData';
			default : return '';
		}
	}, [filterType]);

	const filterData = useMemo(() => {
		if (filterType === 'taxonomy') {
			if (history?.location?.state) {
				return history?.location?.state.filterTaxData[taxType];
			}
		}
		if (history?.location?.state) {
			return history?.location?.state[filterKey];
		}
		return null;
	}, [filterKey, history, filterType, taxType]);

	const checkingCondition = useMemo(() =>
		!searchStatus ||
		!filterKey || history?.action !== 'PUSH' ||
		!nodeRef?.current
		|| !history?.location?.state
		|| trigger?.length === 0,
		[searchStatus, filterKey, history, nodeRef, trigger]);

	useEffect(
		() => {

			if (checkingCondition) {
				return;
			}

			const filterParent = nodeRef?.current;
			const filterDataNodes =  filterParent?.querySelectorAll('input');

			if (filterKey === 'filterEntitiesData' || filterKey === 'filterRatingData') {
				filterDataNodes?.forEach((item) => {
					item.checked = filterData[item?.name];
				});
				return;
			}

			filterDataNodes.forEach((item) => {
				item.checked = !!filterData?.find(searchItem => searchItem === item?.name);
			});
			history.replace({
				...history.location,
				state: undefined
			});
		},
		[checkingCondition, filterData, filterKey, history, nodeRef]
	);
};

export default useSearchSetViaHistory;
