import React from 'react';
import parse from 'html-react-parser';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import {ProductType} from '../../Offerings';
import classes from './ProductTabs.module.pcss';

interface Props {
    products: Array<ProductType>,
    activeProductTab: string,
    tabClickHandler: Function
}

const ProductTabs = ({products, activeProductTab, tabClickHandler}: Props) => (
    <DataValidator data={products?.length > 0}>
        <div className={classes.ProductTabs}>
            {products?.map((product, index) => (
                <div key={index}
                     onClick={() => tabClickHandler(product?.id)}
                     className={`${classes.ProductTabsItem} ${activeProductTab === product?.id ? classes.Active : ''}`}>
                    <span>{parse(product?.productName) || ''}</span>
                    <span>{parse(product?.productName) || ''}</span>
                </div>
            ))}
        </div>
    </DataValidator>
);

export default ProductTabs;
