export default `
   {
    cookiePolicy {
      CookiePolicyPopup {
        titleCookiePolicy
        mainInfoCookiePolicy
        secondaryInfoCookiePolicy
        secondaryTitleCookiePolicy
        cookiesDescriptions {
          analyticsCookiesInfo
          analyticsCookiesTitle
          essentialCookiesInfo
          essentialCookiesTitle
        }
      }
    }
  }
`;
