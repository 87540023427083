import React, {useState} from 'react';
import Image from 'js/components/Common/Image/Image';
import useAnchor from 'js/hooks/useAnchor/useAnchor';
import Button from 'js/components/Common/Button/Button';
import Heading from 'js/components/Common/Heading/Heading';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Container from 'js/components/Common/Container/Container';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import classes from './WelcomeSection.module.pcss';
import fetchData from './fetchData';

const WelcomeSection = ({pageId}) => {
	const [title, setTitle] = useState<string>('');
	const [subTitle, setSubTitle] = useState<string>('');
	const [buttonText, setButtonText] = useState<string>('');
	const [imageUrl, setImageUrl] = useState<string>('');
	const anchor = useAnchor('Catalogue');

	const { isLoading, error } = useFetchData({
		query: `${fetchData(pageId)}`,
		condition: !!pageId,
		updateData: (data) => {
			const result = data?.page?.CoursesCatalogue;
			setTitle(result?.headingWelcome || '');
			setSubTitle(result?.subheadingWelcome || '');
			setButtonText(result?.buttonText || '');
			setImageUrl(result?.welcomeImage?.mediaItemUrl || '');
		}
	});

    return (
		<DataValidator data={(title && subTitle && imageUrl) && !isLoading && !error}>
			<section className={classes.WelcomeSection}>
				<Container>
					<Heading text={title} type={'h1'}/>
					<Heading text={subTitle} type={'h2'}/>
					<Button type={'button'} text={buttonText} handler={anchor} />
					<Image mediaItemUrl={imageUrl} alt={title} />
				</Container>
			</section>
		</DataValidator>
    );
};

export default WelcomeSection;
