import React from 'react';
import TopBanner from 'js/components/Common/TopBanner/TopBanner';
import ManualsHubContextProvider from 'js/context/ManualsHubContext/ManualsHubContext';
import ManualsService from 'js/components/Templates/ManualsHub/ManualsService/ManualsService';

const ManualsHubTemplate = ({pageId}) => (
    <main className="templateManualsHub">
        <TopBanner
            pageType={'ManualsHub'}
            pageId={pageId} />
        <ManualsHubContextProvider>
            <ManualsService pageId={pageId}/>
        </ManualsHubContextProvider>
    </main>
);

export default ManualsHubTemplate;
