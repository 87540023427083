import React, {useCallback, useContext, useEffect, useState} from 'react';
import parse from 'html-react-parser';
import PopUp from 'js/components/Common/PopUp/PopUp';
import {useCookies, withCookies} from 'react-cookie';
import Button from 'js/components/Common/Button/Button';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Input from 'js/components/Common/FormComponents/Input/Input';
import {CommonContext} from 'js/context/CommonContext/CommonContext';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import FetchData from './fetchData';
import classes from './Pilot.module.pcss';

const Pilot = () => {
    const [cookies, setCookie] = useCookies([]);
    const {REACT_APP_PILOT_COOKIE} = process.env || {};
    const [title, setTitle] = useState<string>('');
    const [subtitle, setSubtitle] = useState<string>('');
    const [info, setInfo] = useState<string>('');
    const [confirmStatus, setConfirmStatus] = useState<boolean>(false);
    const {isCustomerLogged} = useContext(CommonContext);
    const [pilotCookies, setPilotCookies] = useState<boolean | undefined>(false);

    const { isLoading, error } = useFetchData({
        query: FetchData,
        condition: isCustomerLogged,
        updateData: (data) => {
            const result = data?.themeGeneralSettings?.Options;
            if (!result) {
                return;
            }
            setTitle(result?.pilotTitle);
            setSubtitle(result?.pilotsubtitle);
            setInfo(result?.pilotInfo);
        }
    });

    useEffect(() => {
        const cookie = cookies[REACT_APP_PILOT_COOKIE];
        setPilotCookies(cookie);
    }, [cookies, REACT_APP_PILOT_COOKIE]);

    const inputChangeHandler = useCallback((e) => {
        setConfirmStatus(e?.target?.checked);
    }, []);

    const confirmChangeHandler = useCallback(() => {
        setCookie(REACT_APP_PILOT_COOKIE, true,
            {
                path: '/',
                domain: `.${location.hostname}`,
                maxAge: 315360000
            }
        );
        setPilotCookies(true);
    }, [REACT_APP_PILOT_COOKIE, setCookie]);

    return (
        <DataValidator data={title && info && subtitle && isCustomerLogged && !pilotCookies && !isLoading && !error}>
            <PopUp
                showCloseButton={false}
                type={'M'}
                heading={title}
                popUpTrigger={!cookies[REACT_APP_PILOT_COOKIE]}
                headingType={'h2'}
                popUpClose={confirmChangeHandler}
                closeOnEsc={false}
                closeMaskOnClick={false}
                subtitle={subtitle}
                fixedInfo={
                    <>
                        <Input
                            type={'checkbox'}
                            withLabel={true}
                            name={'acceptedTermsOfConditions'}
                            slug={'acceptedTermsOfConditions'}
                            labelText={'I acknowledge that I have read and accepted the terms of conditions'}
                            id={'CheckBoxEntity'}
                            onChange={e => inputChangeHandler(e)} />
                        <div className={classes.ButtonWrap}>
                            <Button
                                type={'button'}
                                text={'Confirm'}
                                disabled={!confirmStatus}
                                handler={confirmChangeHandler}
                            />
                        </div>
                    </>
                }
                additionalClass={classes.Pilot}>
                <div className={classes.Info}>
                    {parse(info || '')}
                </div>
            </PopUp>
        </DataValidator>
    );
};

export default withCookies(Pilot);
