const presentationFetchData = `
    ... on PresentationsSearch {
          id
          type
          title
          description
          downloads
          language
          file
          publishingDate
          thumbnail
          isPromoted
        }
    `;
export default presentationFetchData;


export const updateDownloadsQueryPresentation = id => (`
    {
        presentationsSingle(id: "${id}", idType: DATABASE_ID) {
          updateDownloads
        }
    }
 `);
