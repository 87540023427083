import React from 'react';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import parse from 'html-react-parser';
import classes from './Content.module.pcss';

export interface Props {
	data: string
}

const Content = ({data}: Props) => (
	<DataValidator data={data?.length > 0}>
		<article className={classes.Content}>
			{parse(data || '', {
				trim : false,
				htmlparser2: {
					xmlMode: true
				}
			})}
		</article>
	</DataValidator>
);

export default Content;
