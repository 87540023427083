import React, {useCallback, useContext, useRef, useState} from 'react';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';
import Sidebar from 'js/components/Common/Sidebar/Sidebar';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Container from 'js/components/Common/Container/Container';
import FilterCount from 'js/components/Common/FilterCount/FilterCount';
import {HelpPageContext} from 'js/context/HelpPageContext/HelpPageContext';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import classes from './HelpService.module.pcss';
import HelpResults from '../HelpResults/HelpResults';
import {fetchData, subtitleFetchData} from './fetchData';

const HelpService = ({pageId}: {pageId: number}) => {
    const nodeRef = useRef(null);
    const [subtitle, setSubtitle] = useState<string>('');
    const {
        setLoading,
        activeItemId,
        setActiveItemId,
        activeItemName,
        setActiveItemName,
        totalCount
    } = useContext(HelpPageContext);

    const getObjectData = useCallback((obj) => {
        if (!obj) {
            return null;
        }
        let data = obj?.page?.HelpTemplate?.helpArea;
        data = data?.map((element, index) =>  ({
            id: index,
            ...element
        }));
        return data;
    }, []);

    const { isLoading, error } = useFetchData({
        query: `${subtitleFetchData(pageId)}`,
        condition: !!pageId,
        updateData: (data) => {
            setSubtitle(data?.page?.HelpTemplate?.subtitleHelp);
        }
    });

    const sendMixPanelEvent = useCallback(async (label:string) => {
        await MixPanelWrapper.TrackEvent(
            'Help Filtering',
            {
                category: 'Help Page',
                value: `${label}`
            });
    }, []);

    return (
        <DataValidator data={ !isLoading && !error }>
            <div id={'Help'} className={classes.HelpService} ref={nodeRef}>
                <Container>
                    <FilterCount
                        value={totalCount}
                        activeCat={activeItemName}
                        label={'case'}
                        topSubtitle={subtitle}
                    />
                    <div className={classes.Main}>
                        <Sidebar
                            pageId={pageId}
                            anchorId={'Help'}
                            fetchData={fetchData}
                            setLoading={setLoading}
                            activeItemId={activeItemId}
                            eventSendHandler={async value => await sendMixPanelEvent(value)}
                            getObjectData={getObjectData}
                            setActiveItemId={setActiveItemId}
                            setActiveItemName={setActiveItemName}

                        />
                        <HelpResults pageId={pageId} nodeRef={nodeRef} />
                    </div>
                </Container>
            </div>
        </DataValidator>
    );
};

export default HelpService;
