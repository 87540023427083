import imageFragment from 'js/graphqlFragments/ImageFragment';

const thumbnailFragment = (type: string): string =>
    `fragment ThumbnailFragment on ${type} {
	  featuredImage {
      	...ImageFragment
      }
      
    }
    ${imageFragment()}
    `;

export default thumbnailFragment;
