import React, {useMemo, useContext } from 'react';
import {Link, NavLink} from 'react-router-dom';
import Spinner from 'js/components/Common/Spinner/Spinner';
import { CommonContext } from 'js/context/CommonContext/CommonContext';

import classes from './Button.module.pcss';

export interface Props {
	type: 'a' | 'button' | 'input' | 'link',
	text: string | any,
	link: string,
	handler: (e) => void,
	buttonType?: 'ButtonColored' | 'ButtonUncolored' | 'ArticleButton',
	buttonColorType?: 'Blue' | 'Pink',
	target: string | boolean,
	customStylesClass?:string,
	additionalStylesClass?:string,
	download?:boolean,
	disabled?:boolean,
	withSpinner?:boolean,
	spinnerType?: 'white' | 'blue'
}

const Button = (
	{
		type,
		text,
		link = '#',
		handler = () => {},
		buttonType = 'ButtonColored',
		buttonColorType = 'Blue',
		target = false,
		customStylesClass,
		additionalStylesClass = '',
		disabled = false,
		download = false,
		withSpinner = false,
		spinnerType = 'white'
	}: Partial<Props>) => {
	const { apiPath } = useContext(CommonContext);
	return useMemo(() => {
		switch (type) {
			case 'a' : {
				if (link?.includes(apiPath) && !download && !link?.includes('courses')) {
					const slug = link.replace(apiPath, '');
					return (
						<NavLink
							exact
							className={`${classes.Button} ${additionalStylesClass} ${classes[buttonType] || ''} ${classes[buttonColorType] || ''}`}
							onClick={e => handler(e)}
							to={slug}>
							{text}
						</NavLink>
					);
				} else {
					return (
						<a
							href={link}
							className={`${classes.Button} ${additionalStylesClass} ${classes[buttonType] || ''} ${classes[buttonColorType] || ''}`}
							target={target ? '_blank' : '_self'}
							download={download}
							onClick={e => handler(e)}
							rel={target ? 'nofollow' : ''}>{text}
						</a>
					);
				}
			}
			case 'link' :
				return (
					<Link
						exact
						className={`${classes.Button} ${additionalStylesClass} ${classes[buttonType] || ''} ${classes[buttonColorType] || ''}`}
						onClick={e => handler(e)}
						to={link}>
						{text}
					</Link>
				);
			case 'button' :
				return <button
					disabled={withSpinner || disabled}
					onClick={e => handler(e)}
					className={customStylesClass || `${classes.Button} ${additionalStylesClass} ${classes[buttonType] || ''} ${classes[buttonColorType] || ''}`}>
					{withSpinner ? <div className={classes.Spinner}><Spinner type={spinnerType}/></div> : text}
				</button>;
			case 'input' :
				return <input
					disabled={disabled}
					onClick={handler ? e => handler(e) : () => {}}
					className={customStylesClass || `${classes.Button} ${additionalStylesClass} ${classes[buttonType] || ''} ${classes[buttonColorType] || ''}`}
					type="submit" value={text} />;
			default :
				return <a
					href={link}
					download={download}
					className={customStylesClass || `${classes.Button} ${additionalStylesClass} ${classes[buttonType] || ''} ${classes[buttonColorType] || ''}`}>{text}</a>;
		}
	}, [
		link,
		text,
		type,
		target,
		apiPath,
		handler,
		disabled,
		download,
		buttonType,
		spinnerType,
		withSpinner,
		buttonColorType,
		customStylesClass,
		additionalStylesClass
	]);
};

export default Button;
