import React, {MouseEventHandler} from 'react';
import parse from 'html-react-parser';

import classes from './ModalMessage.module.pcss';

export interface Props {
	message: string,
	position?: 'top' | 'bottom',
	closeHandler: MouseEventHandler<HTMLSpanElement>
}

const ModalMessage = ({message, position, closeHandler}: Props) => (
	<div className={`${classes.ModalMessage} ${position === 'top' ? classes.Top : classes.Bottom} `}>
		<div className={classes.Wrap}>{parse(message || '')}</div>
		<span className={classes.Close} onClick={closeHandler} />
	</div>
);

export default ModalMessage;
