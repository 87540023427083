import React, {useMemo} from 'react';

import classes from './SearchResultsCount.module.pcss';

export interface Props {
    query: string,
    count: number,
    countPerPage: number,
    isActive: boolean
}

const SearchResultsCount = ({query, count, countPerPage, isActive}: Props) => {
    const label = useMemo(() => count === 1 ? 'result' : 'results', [count]);
    const totalCount = useMemo(() => (
        new Intl.NumberFormat('en-IN').format(count)
    ), [count]);
    const perPage = useMemo(() => (
        new Intl.NumberFormat('en-IN').format(countPerPage)
    ), [countPerPage]);
    return (
        <div className={`${classes.SearchResultsCount} ${isActive ? classes.Active : ''}`}>
            <p>Showing <strong>{perPage}</strong> of <strong>{totalCount}</strong> {label} for &ldquo;{query}&rdquo;</p>
        </div>
    );
};

export default SearchResultsCount;
