import React, {useMemo} from 'react';
import Image from 'js/components/Common/Image/Image';
import Holder from 'images/holder.png';
import ArticleThumbnail from 'images/search/article-ico.jpg';
import VideoThumbnail from 'images/search/video-ico.jpg';

import classes from './Thumbnail.module.pcss';
export interface Props {
    type?: 'articles' | 'videos' | '',
    thumbnail?: {
        url: string,
        altText: string
    },
    alt?: string
}

const Thumbnail = ({type = '', alt, thumbnail}:Props) => useMemo(() => {
    if (type === 'articles') {
        return (
            <figure className={classes.Thumbnail}>
                <Image mediaItemUrl={ArticleThumbnail} alt={alt} />
            </figure>
        );
    }

    if (type === 'videos') {
        return (
            <figure className={classes.Thumbnail}>
                <Image mediaItemUrl={thumbnail?.url ? thumbnail?.url : VideoThumbnail} alt={alt} />
            </figure>
        );
    }

    return (
        <figure className={classes.Thumbnail}>
            <Image mediaItemUrl={thumbnail?.url ? thumbnail?.url : Holder} alt={alt} />
        </figure>
    );

}, [alt, thumbnail, type]);

export default Thumbnail;
