import React from 'react';

import classes from './AttributesItems.module.pcss';
import AttributesItem from '../AttributesItem/AttributesItem';

const AttributesItems = ({ data }) => (
	<div className={classes.AttributesItems}>
		{data?.map((item, index) =>
			<AttributesItem
				key={index}
				item={item} />)}
	</div>
);

export default AttributesItems;
