import React from 'react';
import Heading from 'js/components/Common/Heading/Heading';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import classes from './InformationItem.module.pcss';

export interface Props {
    heading: string,
    data: {
        label: string,
        value: string
    }[]
}

const InformationItem = ({heading, data}: Props) => (
    <DataValidator data={data}>
        <div className={classes.InformationItem}>
            <Heading colored={true} type={'h4'} text={heading}/>
            <ul>
                {data?.map((item, index) => (
                    <li key={index}>
                        <strong className={classes.Label}>{item?.label}</strong>
                        <span className={classes.Value}>{item?.value}</span>
                    </li>
                ))}
            </ul>
        </div>
    </DataValidator>
);

export default InformationItem;
