import React, {useCallback} from 'react';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';
import Description from 'js/components/Common/Description/Description';
import ProductLabelsList from 'js/components/Common/ProductLabelList/ProductLabelsList';
import VisibilityViewerIcon from 'js/components/Common/VisibilityViewerIcon/VisibilityViewerIcon';

import Label from '../../components/Label/Label';
import Views from '../../components/Views/Views';
import Heading from 'js/components/Common/Heading/Heading';
import Thumbnail from '../../components/Thumbnail/Thumbnail';
import ReadingTime from '../../components/ReadingTime/ReadingTime';
import commonClasses from '../../../SearchResultsItem/SearchResultsItem.module.pcss';

export interface Props {
    data: {
        id: number,
        title: string,
        link: string,
        readTime: number,
        type: 'articles',
        views: number,
        shortDescription: string
    },
    isPromoted: boolean
}

const Article = ({data, isPromoted}: Props) => {
    const sendMixPanelEvent = useCallback(async () => {
        await MixPanelWrapper.TrackEvent(
            'Open Article page',
            {
                category: 'Search Results',
                value: data?.title
        });
    }, [data]);
    return (
        <div
            className={`${commonClasses.SearchResultItem} ${isPromoted && commonClasses.Promoted}`}>
            <Thumbnail type={'articles'} alt={data?.title}/>
            <div className={commonClasses.SearchResultItemInfo}>
                <a onClick={sendMixPanelEvent} href={data?.link} target={'_blank'} rel={'noopener noreferrer'}>
                    <Heading text={`${data?.title}`} type={'h5'} />
                </a>
                <Description data={data?.shortDescription}/>
                <div className={commonClasses.Wrap}>
                    <Label type={'articles'}/>
                    <ReadingTime data={data?.readTime} />
                    <Views data={data?.views} />
                    <ProductLabelsList pageId={data?.id} />
                </div>
                <div className={commonClasses.Wrap}>
                    <VisibilityViewerIcon id={data?.id} />
                </div>
            </div>
        </div>
    );
};

export default Article;
