import React from 'react';
import parse from 'html-react-parser';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import classes from './FilterCount.module.pcss';

interface Props {
	value: number,
	activeCat: string,
	label: string,
	topSubtitle?: string
}

const FilterCount = ({value, activeCat, label, topSubtitle = ''}: Props) =>
	<DataValidator data={value > 0}>
		<div className={classes.Count}>
			<span className={classes.CountPrev} />
			<span className={classes.CountData}>
				{topSubtitle ? <p className={classes.TopSubtitle}>{topSubtitle}</p> : ''}
				<strong>{value} {label}{value > 1 ? 's' : ''}</strong> in {parse(activeCat || '')}
			</span>
		</div>
	</DataValidator>;

export default FilterCount;
