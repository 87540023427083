import React, {useContext, useState} from 'react';
import parse from 'html-react-parser';
import {useLocation} from 'react-router-dom';
import PopUp from 'js/components/Common/PopUp/PopUp';
import {useCookies, withCookies} from 'react-cookie';
import Button from 'js/components/Common/Button/Button';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import {CommonContext} from 'js/context/CommonContext/CommonContext';
import Interests from 'js/containers/Pages/Profile/Interests/Interests';
import useFetchCallback from 'js/hooks/useFetchCallback/useFetchCallback';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';
import {MyProfileContext} from 'js/context/MyProfileContext/MyProfileContext';
import {updateAPIFetchData} from 'js/containers/Pages/Profile/SaveProfileInfo/fetchData';
import {ToastMessagesContext} from 'js/context/ToastMessagesContext/ToastMessagesContext';

import FetchData from './fetchData';
import classes from './InterestsPopup.module.pcss';

const InterestsPopup = () => {
    const {REACT_APP_PILOT_COOKIE} = process.env || {};
    const [cookies] = useCookies([REACT_APP_PILOT_COOKIE]);
    const [title, setTitle] = useState<string>('');
    const [info, setInfo] = useState<string>('');
    const {addToastMessage} = useContext(ToastMessagesContext);
    const {isCustomerLogged, currentUser} = useContext(CommonContext);
    const [confirmStatus, setConfirmStatus] = useState<boolean>(false);
    const [activeSpinner, setActiveSpinner] = useState<boolean>(false);
    const { interests } = useContext(MyProfileContext);
    const location = useLocation();

    const { isLoading, error } = useFetchData({
        query: FetchData,
        condition: isCustomerLogged,
        updateData: (data) => {
            const result = data?.interestsPopup?.InterestsPopup;
            if (!result) {
                return;
            }
            setTitle(result?.titleInterestsPopup);
            setInfo(result?.infoInterestsPopup);
        }
    });

    const saveAPIDataClickHandler = useFetchCallback({
        updateData: data => data?.getResponse?.updateProfileAPIData,
        query: val => `${updateAPIFetchData(val, false, false, false, false)}`,
        onStart: () => setActiveSpinner(true),
        onSuccess: () => {
            addToastMessage({
                type: 'success',
                key: 'saveProfileToasts'
            });
            setConfirmStatus(true);
            setActiveSpinner(false);
            if (location.pathname === '/profile') {
                document.location.reload();
            }
        },
        onFailure: () => {
            addToastMessage({
                type: 'error',
                key: 'saveProfileToasts'
            });
            setActiveSpinner(false);
        },
        onError: () => {
            addToastMessage({
                type: 'error',
                key: 'saveProfileToasts'
            });
            setActiveSpinner(false);
        }
    });

    return (
        <DataValidator
            data={
            isCustomerLogged && !currentUser?.hasProducts && cookies[REACT_APP_PILOT_COOKIE] && !isLoading && !error}>
            <PopUp
                showCloseButton={false}
                type={'M'}
                heading={title}
                popUpTrigger={!confirmStatus}
                headingType={'h2'}
                popUpClose={() => {}}
                closeOnEsc={false}
                closeMaskOnClick={false}
                subtitle={`${parse(info || '')}`}
                fixedInfo={
                    <div className={classes.ButtonWrap}>
                        <Button
                            disabled={interests?.length === 0}
                            spinnerType={'white'}
                            withSpinner={activeSpinner}
                            type={'button'}
                            handler={() => saveAPIDataClickHandler(interests)}
                            text={'Save'}/>
                    </div>
                }
                additionalClass={classes.InterestsPopup}>
                <div className={classes.Info}>
                    <Interests size={'SM'} heading={'h5'} prefix={'interests_popup'}/>
                </div>
            </PopUp>
        </DataValidator>
    );
};

export default withCookies(InterestsPopup);
