import React, {useEffect, useState} from 'react';

import {CSSTransition} from 'react-transition-group';
const CourseTransitionContainer = ({ children, ...props }) => {
    const [init, setInit] = useState<boolean>(false);
    useEffect(() => {
        let isCanceled = false;
        setTimeout( () => {
            if (isCanceled) { return; }
            setInit(true);
        }, 300  * props.index);
        return () => {
            isCanceled = true;
        };
    }, [props.index]);
    const nodeRef = React.useRef(null);
    return (
        <CSSTransition
            in={init}
            timeout={300}
            nodeRef={nodeRef}
            classNames="BoxItemAnimation"
            appear
            enter
            {...props}
        >
            {React.cloneElement(children, {ref: nodeRef, init})}
        </CSSTransition>
    );
};

export default CourseTransitionContainer;

