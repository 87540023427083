import React from 'react';
import video from 'images/notifications/video.svg';
import course from 'images/notifications/course.svg';
import Image from 'js/components/Common/Image/Image';
import podcast from 'images/notifications/podcast.svg';
import recording from 'images/notifications/recording.svg';
import liveEvent from 'images/notifications/live-event.svg';
import liveEventHour from 'images/notifications/live-event-hour.svg';
import liveEventTomorrow from 'images/notifications/live-event-tomorrow.svg';

import {NotificationType} from '../NotificationsDataInterface';

const setIconByType = (type: NotificationType, customImg = '') => {
    let link:string = '';
    let alt:string = '';

    switch (type) {
        case 'video': {
            link = video;
            alt = 'videos';
            break;
        }
         case 'recording': {
            link = recording;
            alt = 'recording';
            break;
        }
        case 'podcast': {
            link = podcast;
            alt = 'podcasts';
            break;
        }
        case 'liveEventItem': {
            link = liveEvent;
            alt = 'Live Event';
            break;
        }
        case 'course': {
            link = course;
            alt = 'course';
            break;
        }
        case 'liveEventReminderTomorrow': {
            link = liveEventTomorrow;
            alt = 'Live Event Tomorrow';
            break;
        }
        case 'liveEventReminderHour': {
            link = liveEventHour;
            alt = 'Live Event Hour';
            break;
        }
        default : {
            link = customImg;
            alt = 'custom notification';
            break;
        }
    }
    return <Image mediaItemUrl={link} alt={alt} />;
};

export default setIconByType;
