export const filterMeetingByStartDate = (items) => {
	if (items) {
		const filteredData = items.filter(item => new Date(item.Meetings.startDate) > new Date());
		if (filteredData.length < 3) {
			return [];
		} else {
			return filteredData;
		}
	}
};

export const sortMeetingsByStartDate = (items) => {
	if (items) {
		return items.sort((a, b) => (new Date(a.Meetings.startDate) > new Date(b.Meetings.startDate)) ? 1 : -1);
	}
};
