import {NotificationType} from './NotificationsDataInterface';

export const notificationTypesList:NotificationType[] = [
	'course',
	'video',
	'podcast',
	'recording',
	'liveEventItem',
	'liveEventReminderHour',
	'liveEventReminderTomorrow'
];

const notificationsFetch = ():string => `
	{
		notifications {
		  getNotificationsForCurrentUser {
			id
			title
			description
			link
			time
			status
			type
			blockClearing
			sortingDate
			eventData {
			  date
			  dateId
			  dbId
			  link
			  type
			  registrationHash
			}
		  }
		  
		  getCustomNotificationsForCurrentUser {
			description
			id
			link
			img
			status
			time
			title
			type
			blockClearing
			highPriority
			}
		}
		
		themeGeneralSettings {
		  Options {
			joinEventButton
			registerEventButton
			viewEventButton
			dateEventsHeading
			skillBoosterEventsHeading
			learnNewEventsHeading
			inspiredEventsHeading
		  }
		}
  	}
`;

export default notificationsFetch;
