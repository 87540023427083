import {ItemCheckbox} from '../../FilterDataInterface';

export const durationList:ItemCheckbox[] = [
    {
        name: '0-20 min',
        slug: '0-20',
        id: 'duration0_20'
    },
    {
        name: '20-60 min',
        slug: '20-60',
        id: 'duration20_60'
    },
    {
        name: 'More than 60 min',
        slug: '60',
        id: 'duration60'
    }
];

export const priceList:ItemCheckbox[] = [
    {
        name: 'Free',
        slug: '0',
        id: 'free'
    },
    {
        name: '£1 to £99',
        slug: '1-99',
        id: 'from1to99'
    },
    {
        name: '£100 to £199',
        slug: '100-199',
        id: 'from100to199'
    },
    {
        name: '£200 to £299',
        slug: '200-299',
        id: 'from200to299'
    },
    {
        name: '£300+',
        slug: '300',
        id: 'upto300'
    }
];
