import React from 'react';
import parse from 'html-react-parser';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import classes from './WatchTime.module.pcss';

export interface Props {
    data: string
}

const WatchTime = ({data}: Props) => (
    <DataValidator data={data?.length > 0}>
        <span className={classes.WatchTime}>{parse(data || '')}</span>
    </DataValidator>
);

export default WatchTime;
