export const slideUp = (target, className, duration = 500, heading) => {
	target.style.transitionProperty = 'height, margin, padding';
	target.style.transitionDuration = `${duration}ms`;
	target.style.boxSizing = 'border-box';
	target.style.height = `${target.offsetHeight}px`;
	target.offsetHeight;
	target.style.overflow = 'hidden';
	target.style.height = 0;
	target.style.paddingTop = 0;
	target.style.paddingBottom = 0;
	target.style.marginTop = 0;
	target.style.marginBottom = 0;
	window.setTimeout( () => {
		target.style.display = 'none';
		target.style.removeProperty('height');
		target.style.removeProperty('padding-top');
		target.style.removeProperty('padding-bottom');
		target.style.removeProperty('margin-top');
		target.style.removeProperty('margin-bottom');
		target.style.removeProperty('overflow');
		target.style.removeProperty('transition-duration');
		target.style.removeProperty('transition-property');

		target.classList.remove(className);
		heading.classList.remove('Active');
	}, duration);
};

export const slideDown = (target, className, duration = 500, heading) => {
	target.style.removeProperty('display');
	let {display} = window.getComputedStyle(target) || {};
	if (display === 'none') display = 'block';
	target.style.display = display;
	const height = target.offsetHeight;
	target.style.overflow = 'hidden';
	target.style.height = 0;
	target.style.paddingTop = 0;
	target.style.paddingBottom = 0;
	target.style.marginTop = 0;
	target.style.marginBottom = 0;
	target.offsetHeight;
	target.style.boxSizing = 'border-box';
	target.style.transitionProperty = 'height, margin, padding';
	target.style.transitionDuration = `${duration}ms`;
	target.style.height = `${height}px`;
	target.style.removeProperty('padding-top');
	target.style.removeProperty('padding-bottom');
	target.style.removeProperty('margin-top');
	target.style.removeProperty('margin-bottom');
	window.setTimeout( () => {
		target.style.removeProperty('height');
		target.style.removeProperty('overflow');
		target.style.removeProperty('transition-duration');
		target.style.removeProperty('transition-property');

		target.classList.add(className);
		heading.classList.add('Active');
	}, duration);
};


export const slideToggle = (target, className, duration = 500, heading) => {
	if (window.getComputedStyle(target).display === 'none') {
		return slideDown(target, className, duration, heading);
	} else {
		return slideUp(target, className, duration, heading);
	}
};
