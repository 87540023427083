import React, { useState } from 'react';
import Button from 'js/components/Common/Button/Button';
import Heading from 'js/components/Common/Heading/Heading';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Container from 'js/components/Common/Container/Container';
import SubHeading from 'js/components/Common/SubHeading/SubHeading';
import CoursePopUp from 'js/components/Common/CoursePopUp/CoursePopUp';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';
import CourseItemBox from 'js/components/Common/CourseItemBox/CourseItemBox';
import CarouselSlider from 'js/components/Common/Carousel/CarouselSlider/CarouselSlider';
import {defaultCarouselParams} from 'js/components/Common/Carousel/CarouselParams/CarouselParams';
import { CourseItemBoxDataInterface } from 'js/components/Common/CourseItemBox/CourseItemBoxDataInterface';

import fetchData from './fetchData';
import classes from './MasterSkillsCarouselBlock.module.pcss';

const MasterSkillsCarouselBlock = ({ pageId }) => {
	const [masterSkillsTitle, setMasterSkillsTitle] = useState<string>('');
	const [masterSkillsSubTitle, setMasterSkillsSubTitle] = useState<string>('');
	const [masterSkillsTopButton, setMasterSkillsTopButton] = useState<{
		url: string,
		title: string,
		target: string | boolean
	}>({
		title: '', url: '', target: false
	});
	const [masterSkillsFirstButtonsText, setMasterSkillsFirstButtonsText] = useState<string>('');
	const [masterSkillsSecondButtonsText, setMasterSkillsSecondButtonsText] = useState<string>('');
	const [masterSkillsData, setMasterSkillsData] = useState<CourseItemBoxDataInterface[]>([]);
	const [popUpTrigger, setPopUpTrigger] = useState<boolean>(false);
	const [popUpData, setPopUpData] = useState<{
		title: string,
		content: {
			first: string,
			second: string
		}
	}>({
		title: '',
		content: {
			first: '',
			second: ''
		}
	});

	const { isLoading, error } = useFetchData({
	    query: `${fetchData(pageId)}`,
	    condition: !!pageId,
	    updateData: (data) => {
			const responseData = data?.page?.PreLoginPage;
			setMasterSkillsTitle(responseData?.headingMasterSkills);
			setMasterSkillsSubTitle(responseData?.introTextMasterSkills);
			setMasterSkillsTopButton(responseData?.topButtonMasterSkills);
			setMasterSkillsFirstButtonsText(data?.themeGeneralSettings?.Options?.buyCourseButton);
			setMasterSkillsSecondButtonsText(data?.themeGeneralSettings?.Options?.infoCourseButton);
			setMasterSkillsData(responseData?.masterSkillsCarousel);
	    }
	});

	return (
		<DataValidator data={!!masterSkillsData && !isLoading && !error}>
			<section className={classes.MasterSkills}>
				<Container additionalClass={`${classes.MasterSkillsContainer}`}>
					<Heading text={masterSkillsTitle} />
					<SubHeading text={masterSkillsSubTitle} />
					<Button
						type="a"
						text={masterSkillsTopButton?.title}
						link={masterSkillsTopButton?.url}
						target={masterSkillsTopButton?.target}
						buttonType="ButtonUncolored" />
					<CarouselSlider paramsProp={defaultCarouselParams} items={masterSkillsData}>
						<CourseItemBox
							buttonText={masterSkillsFirstButtonsText}
						   	secondButtonText={masterSkillsSecondButtonsText}
						   	buttonAction={'buy'}
							setPopUpData={setPopUpData}
							setPopUpTrigger={setPopUpTrigger}
						/>
					</CarouselSlider>
				</Container>
				<CoursePopUp
					type={'L'}
					popUpTrigger={popUpTrigger}
					setPopUpTrigger={setPopUpTrigger}
					popUpData={popUpData} />
			</section>
		</DataValidator>
	);
};

export default MasterSkillsCarouselBlock;
