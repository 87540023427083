import {useState} from 'react';

const useDebounce = (func:any, timeout:number = 300) => {
    const [timer, setTimer] = useState<any>(0);
    return (...args) => {
        clearTimeout(timer);
        setTimer(setTimeout(() => { func.apply(this, args); }, timeout));
    };
};

export default useDebounce;
