import parse from 'html-react-parser';
import React, { useState } from 'react';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import fetchData from './fetchData';
import classes from './ContactInfo.module.pcss';

const ContactInfo = () => {
    const [content, setContent] = useState<string>('');

    const { isLoading, error } = useFetchData({
        query: fetchData,
        condition: true,
        updateData: data => setContent(data?.themeGeneralSettings?.Options?.contactInfoVideos)
    });

    return (
        <DataValidator data={content?.length > 0 && !isLoading && !error}>
            <div className={classes.ContactInfo}><span>{parse(content || '')}</span></div>
        </DataValidator>
    );
};

export default ContactInfo;

