import React, {useMemo} from 'react';
import parse from 'html-react-parser';

import classes from './CarouselItemDescription.module.pcss';

export interface Props {
	description: string,
	cut: boolean
}

const CarouselItemDescription = ({ description, cut }: Partial<Props>) => {
	const validateDescriptionLength = useMemo((): string => {
		if (!description) {
			return '';
		}
		return description.replace(/(.$)/, '<span>$1</span>');
	}, [description]);

	if (!description) {
		return null;
	}
	return <p
		className={`${classes.CarouselItemShortDescription} ${cut ? classes.Cut : ''}`}>{cut ? parse(validateDescriptionLength) : description}</p>;
};

export default CarouselItemDescription;
