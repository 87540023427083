const carouselFetchData = pageId => `
    	{
            page(id: "${pageId}", idType: DATABASE_ID) {
              PreLoginPage {
                headingTopic
                topics {
                  images {
                    picture {
                      altText
                      mediaItemUrl
                      mediaDetails {
                        width
                      }
                    }
                    background {
                      mediaItemUrl
                    }
                  }
                  info {
                    title
                    text
                    list {
                      icon {
                        altText
                        mediaItemUrl
                      }
                      text
                    }
                  }
                }
              }
            }
      }
    `;

export default carouselFetchData;
