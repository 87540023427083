import React, {useCallback} from 'react';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';
import Heading from 'js/components/Common/Heading/Heading';
import Description from 'js/components/Common/Description/Description';
import MediaDuration from 'js/components/Common/MediaDuration/MediaDuration';
import ProductLabelsList from 'js/components/Common/ProductLabelList/ProductLabelsList';
import VisibilityViewerIcon from 'js/components/Common/VisibilityViewerIcon/VisibilityViewerIcon';

import Label from '../../components/Label/Label';
import Rating from '../../components/Rating/Rating';
import Thumbnail from '../../components/Thumbnail/Thumbnail';
import commonClasses from '../../../SearchResultsItem/SearchResultsItem.module.pcss';

export interface Props {
    data: {
        id: number,
        title: string,
        featuredImage: {
            url: string,
            altText: string
        },
        type: 'podcasts',
        description: string,
        language: string,
        podcastDuration: string,
        publishingDate: string,
        rating: string,
        link: string
    },
    isPromoted: boolean
}

const Podcast = ({data, isPromoted}: Props) => {
    const {
        title,
        podcastDuration,
        featuredImage,
        description,
        link,
        rating,
        type
    } = data || {};
    const sendMixPanelEvent = useCallback(async () => {
        await MixPanelWrapper.TrackEvent(
            'Open Podcast page',
            {
                category: 'Search Results',
                value: data?.title
            });
    }, [data]);

    return (
        <div className={`${commonClasses.SearchResultItem} ${isPromoted && commonClasses.Promoted}`}>
            <Thumbnail thumbnail={featuredImage} alt={title} />
            <span className={commonClasses.SearchResultItemInfo}>
                <a onClick={sendMixPanelEvent} href={link} target={'_blank'} rel={'noopener noreferrer'}>
                    <Heading text={`${title}`} type={'h5'}/>
                </a>
                <Description data={description}/>
                <div className={commonClasses.Wrap}>
                    <Label type={type}/>
                    <ProductLabelsList pageId={data?.id} />
		            <MediaDuration data={podcastDuration} />
                    <Rating rating={rating}/>
                </div>
                <div className={commonClasses.Wrap}>
                    <VisibilityViewerIcon id={data?.id}/>
                </div>
            </span>
        </div>
    );
};

export default Podcast;
