import React from 'react';

import classes from './Downloads.module.pcss';

export interface Props {
    count: number
}

const Downloads = ({count}: Props) => (
    <span className={classes.Downloads}>
        {`${count || 0} ${count === 0 ? 'Download' : 'Downloads'}`}
    </span>
);

export default Downloads;
