const fetchData = pageId => `
    {
        page(id: "${pageId}", idType: DATABASE_ID) {
          content
        }
        themeGeneralSettings {
          Options {
            socialMedia {
              youtube
              linkedin
            }
          }
        }
    }
    `;

export default fetchData;

