import React from 'react';
import TopBanner from 'js/components/Common/TopBanner/TopBanner';
import HelpService from 'js/components/Templates/Help/HelpService/HelpService';
import HelpPageContextProvider from 'js/context/HelpPageContext/HelpPageContext';
import Announcement from 'js/components/Templates/Help/Announcement/Announcement';

const HelpPageTemplate = ({ pageId }) => (
	<main className="templateHelp">
		<TopBanner
			pageType={'HelpTemplate'}
			pageId={pageId}/>
		<HelpPageContextProvider>
			<HelpService pageId={pageId} />
		</HelpPageContextProvider>
		<Announcement pageId={pageId} />
	</main>
);

export default HelpPageTemplate;
