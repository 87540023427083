import React, {useState} from 'react';
import parse from 'html-react-parser';
import Button from 'js/components/Common/Button/Button';
import Heading from 'js/components/Common/Heading/Heading';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Container from 'js/components/Common/Container/Container';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import fetchData from './fetchData';
import classes from './BeforeYouLeave.module.pcss';
import {FingerDirectionType, templateNameType} from './BeforeYouLeaveDataInterface';

export interface Props {
    pageId: number,
    templateName: templateNameType
}

const BeforeYouLeave = ({pageId, templateName}: Props) => {
    const [title, setTitle] = useState<string>('');
    const [info, setInfo] = useState<string>('');
    const [buttonText, setButtonText] = useState<string>('');
    const [buttonLink, setButtonLink] = useState<string>('');
    const [buttonTarget, setButtonTarget] = useState<'' | '_blank'>('');
    const [fingerDirection, setFingerDirection] = useState<FingerDirectionType>('Top');

    const { isLoading, error } = useFetchData({
        query: `${fetchData(templateName, pageId)}`,
        condition: !!pageId,
        updateData: (data) => {
            if (!data?.page) {
                return;
            }
            const result = data?.page[templateName];
            if (!result) {
                return;
            }
            setTitle(result?.titleBeforeYouLeave);
            setInfo(result?.infoBeforeYouLeave);
            setFingerDirection(result?.fingerDirection);
            setButtonText(result?.buttonLinkBeforeYouLeave?.title);
            setButtonLink(result?.buttonLinkBeforeYouLeave?.url);
            setButtonTarget(result?.buttonLinkBeforeYouLeave?.target);
        }
    });

    return (
        <DataValidator data={!!title && !!info && !!buttonText && !isLoading && !error}>
            <section className={`${classes.BeforeYouLeave} ${classes[fingerDirection]}`}>
                <Container additionalClass={classes.BeforeYouLeaveWrap}>
                    <Heading text={title} />
                    <div className={classes.BeforeYouLeaveInfo}>{parse(info || '')}</div>
                    <Button type={'a'} text={buttonText} target={buttonTarget} link={buttonLink} />
                </Container>
            </section>
        </DataValidator>
    );
};

export default BeforeYouLeave;
