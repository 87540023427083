import React, {useCallback, useEffect, useState} from 'react';
import LockIcon from 'images/lock.svg';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import ModalMessage from 'js/components/Common/ModalMessage/ModalMessage';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import fetchData from './fetchData';
import classes from './VisibilityViewerIcon.module.pcss';

export interface Props {
	id: number,
	additionalClass?: string,
	position?: 'top' | 'bottom'
}

const VisibilityViewerIcon = ({id, position = 'top', additionalClass = ''}: Props) => {
	const [message, setMessage] = useState<string>('');
	const [showMessage, setShowMessage] = useState<boolean>(false);
	const { isLoading, error } = useFetchData({
	    query: `${fetchData(id)}`,
	    condition: !!id,
	    updateData: data => setMessage(data?.resourceVisibility?.showVisibilityMessage)
	});
	const clickHandler = useCallback((val, e) => {
		e.stopPropagation();
		e.preventDefault();
		setShowMessage(val);
	}, []);

	const closeMessage = useCallback((e) => {
		if (!e.target.closest('[data-message]')) {
			setShowMessage(false);
		}
	}, []);

	useEffect(() => {
		window.addEventListener('click', closeMessage);
		return () => {
			window.removeEventListener('click', closeMessage);
		};
	}, [closeMessage]);

    return (
		<DataValidator data={!isLoading && !error && message?.length > 0}>
			<div className={`${classes.VisibilityViewerIcon} ${additionalClass}`} data-message={true}>
				<img src={LockIcon} alt={'lock icon'} onClick={e => clickHandler(true, e)}/>
				{ showMessage ?
					<ModalMessage
						message={message}
						position={position}
						closeHandler={e => clickHandler(false, e)} />
				: null}
			</div>
		</DataValidator>
    );
};

export default VisibilityViewerIcon;
