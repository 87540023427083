import React, {useEffect, useState} from 'react';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Container from 'js/components/Common/Container/Container';
import SubHeading from 'js/components/Common/SubHeading/SubHeading';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';
import CarouselSlider from 'js/components/Common/Carousel/CarouselSlider/CarouselSlider';
import { defaultCarouselParams } from 'js/components/Common/Carousel/CarouselParams/CarouselParams';
import { filterMeetingByStartDate } from 'js/components/Common/Carousel/CarouselFilters/CarouselFilters';

import fetchData from './fetchData';
import Heading from 'js/components/Common/Heading/Heading';
import classes from './IndustryTalksCarouselBlock.module.pcss';
import { IndustryTalksCarouselDataInterface } from './IndustryTalksCarouselDataInterface';
import IndustryTalksCarouselItem from '../IndustryTalksCarouselItem/IndustryTalksCarouselItem';

const IndustryTalksCarouselBlock = ({ pageId }) => {

	const [industryTalksTitle, setIndustryTalksTitle] = useState<string>('');
	const [industryTalksSubTitle, setIndustryTalksSubTitle] = useState<string>('');
	const [industryTalksButtonsText, setIndustryTalksButtonsText] = useState<string>('');
	const [industryTalksData, setIndustryTalksData] = useState<IndustryTalksCarouselDataInterface[]>([]);
	const [dataFiltered, setDataFiltered] = useState<IndustryTalksCarouselDataInterface[]>([]);

	const { isLoading, error } = useFetchData({
	    query: `${fetchData(pageId)}`,
	    condition: !!pageId,
	    updateData: (data) => {
			const responseData = data?.page?.PreLoginPage;
			setIndustryTalksTitle(responseData?.headingIndustryTalks);
			setIndustryTalksSubTitle(responseData?.subheadingIndustryTalks);
			setIndustryTalksButtonsText(responseData?.buttonTextIndustryTalks);
			setIndustryTalksData(responseData?.industryTalksCarousel);
	    }
	});

	useEffect(() => {
		setDataFiltered(filterMeetingByStartDate(industryTalksData));
	}, [industryTalksData]);

	return (
		<DataValidator data={!!dataFiltered?.length && !isLoading && !error}>
			<section className={classes.IndustryTalks}>
				<Container additionalClass={`${classes.IndustryTalksContainer}`}>
					<div className={classes.ContainerBg} />
					<Heading text={industryTalksTitle} />
					<SubHeading text={industryTalksSubTitle} />
					<CarouselSlider paramsProp={defaultCarouselParams} items={dataFiltered}>
						<IndustryTalksCarouselItem buttonText={industryTalksButtonsText} />
					</CarouselSlider>
				</Container>
			</section>
		</DataValidator>
	);
};

export default IndustryTalksCarouselBlock;
